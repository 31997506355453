import React from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'

const PromoTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Redeemed</TableCell>
                  <TableCell>Threshold</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(promo => (
                  <TableRow key={promo.id} hover>
                    <TableCell>{promo.id}</TableCell>
                    <TableCell>{promo.code}</TableCell>
                    <TableCell>{promo.product1 && promo.product1.sku}</TableCell>
                    <TableCell>{promo.group_id}</TableCell>
                    <TableCell>{promo.redeemed}</TableCell>
                    <TableCell>{promo.threshold}</TableCell>
                    <TableCell>{promo.active ? 'TRUE' : 'FALSE'}</TableCell>
                    <TableCell>{formatDateTime(promo.created_at)}</TableCell>
                    <TableCell>
                      <EditBtn module='promoCode' history={props.history} path={`/promo/edit/${promo.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default PromoTable