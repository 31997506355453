import superagent from 'superagent'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import _ from 'lodash'
import { getVideoDuration } from 'api/helper'
import {useInterval} from 'react-use'
export default function (props) {
  const { histories,  } = props
  const videoDurationsRef = useRef({})
  const keyForVideoDuration = 'course-videos-duration'
  const [isEnd, setIsEnd] = useState(false)
  const [videoDurations, setVideoDurations] = useState({})

  useEffect(() => {
    if (!isEnd) return 

    localStorage.setItem(keyForVideoDuration, JSON.stringify(videoDurations))
  }, [isEnd, videoDurations])

  const checkIfAccessible = useCallback(async (url) => {
    if (!url) return false

    try {
      const res = await superagent.head(url)
      if (res.statusCode === 200) return true
    } catch {}

    return false
  }, [])

  useInterval(() => {
    if (isEnd) return

    setVideoDurations(videoDurationsRef.current)

  }, 500)

  const getDuration = useCallback(async () => {
    if (_.isEmpty(histories)) return

    const preVideoDurations = localStorage.getItem(keyForVideoDuration)
    const videoDurationsFromStorage = JSON.parse(preVideoDurations || '{}')

    await Promise.all(histories.map(async ({url}) => {
      if (!url) return
      if (videoDurationsFromStorage[url])
        return videoDurationsRef.current = { ...videoDurationsRef.current, [url]: videoDurationsFromStorage[url] }

      const isAccessible = await checkIfAccessible(url)
      if (!isAccessible) return

      const duration = await getVideoDuration(url)
      if (_.isEmpty(duration)) return

      videoDurationsRef.current = { ...videoDurationsRef.current, [url]: duration[0] }
    }))

    setTimeout(() => {
      setIsEnd(true)
    }, 2000);
  }, [checkIfAccessible, histories])

  useEffect(() => {
    getDuration()
  }, [getDuration])

  return (
    <Grid container spacing={0} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {histories && histories.map(history => (
            <TableRow key={history.url}>
              <TableCell>{history.name}</TableCell>
              <TableCell>{history.url}</TableCell>
              <TableCell>{videoDurations[history.url]}</TableCell>
              <TableCell>{history.created_at}</TableCell>
            </TableRow>
          ))}
        </ TableBody>
      </ Table>
    </Grid>
  )
}
