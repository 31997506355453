import React, { useMemo } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { range } from 'lodash'

export function NumberAutoComplete (props) {
  const { handleSelectChange, from, to, value, variant, inputLeft, ...rest } = props

  const options = useMemo(
    () => range(from, to+1).map(num => ({ label: `${num}`, value: num })),
    [from, to]
  )

  return <AutoCompleteNew
    {...rest}
    limit={100}
    value={value}
    variant={variant}
    options={options}
    inputLeft={inputLeft}
    onChange={(event, selected) => handleSelectChange((selected || {value: null}).value)}
  />
}