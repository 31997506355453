import React, { useCallback } from 'react'
import { TextField } from '@material-ui/core'

export default function FormTextField (props) {
  const { onChange, label, ...other } = props

  const handleDataChange = useCallback(event => {
    onChange(event.target.name, event.target.value)
  }, [onChange])

  return <>
    <label className='full-width'>{label}</label>
    <TextField variant='outlined' size='small' className='full-width' onChange={handleDataChange} inputProps={{ 'data-lpignore': true }} {...other} />
  </>
}
