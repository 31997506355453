import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { Delete } from '@material-ui/icons'

const ThemeTable = props => {
  const { type } = props.match.params
  const { data, page, onPageChange, onDelete } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Theme Title</TableCell>
                  <TableCell>Theme Type</TableCell>
                  <TableCell>Theme ID</TableCell>
                  <TableCell>Item ID</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(theme => (
                  <TableRow key={theme.id}>
                    <TableCell>{theme.id}</TableCell>
                    <TableCell>{theme.theme && theme.theme.title}</TableCell>
                    <TableCell>{theme.theme && theme.theme.type}</TableCell>
                    <TableCell>{theme.theme_id}</TableCell>
                    <TableCell>{theme.item_id}</TableCell>
                    <TableCell>{theme.sort}</TableCell>
                    <TableCell>{formatDateTime(theme.created_at)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/themes/${type}/edit/${theme.id}`} />
                      &nbsp;&nbsp;
                      <Delete className='cursor-pointer' onClick={() => onDelete(theme.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ThemeTable