import React, { useState, useEffect, useCallback } from 'react'
import { mealCategoryListPromise } from 'api/MealCategory'
import MealCategoryTable from './components/MealCategoryTable'
import MealCategoryToolbar from './components/MealCategoryToolbar'

const MealCategoryList = props => {
  const [categories, setCategories] = useState([])

  const getCategories = useCallback(async () => {
    const categories = await mealCategoryListPromise()
    if (categories) {
      setCategories(categories)
    }
  }, [])

  useEffect(() => {
    getCategories()
  }, [getCategories])
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealCategoryToolbar {...props} />
        <br />
        <MealCategoryTable {...props} data={categories} />
      </div>
    </div>
  )
}

export default MealCategoryList