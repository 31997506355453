import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { userDetailPromise, updateUserPromise, resetPassword, grantPromoPromise } from 'api/User'
import _ from 'lodash'
import {
  Card,
  Avatar,
  Typography,
  Input,
  Button,
  Menu,
  MenuItem,
  Switch,
  Snackbar,
  TextField,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
// import { KeyboardDatePicker } from '@material-ui/pickers'
import { getInitials } from 'helpers'
import { formatAgeFromDOB } from 'helpers/utils'
import moment from 'moment'
import './index.scss'
import { CountryCitySelect } from 'components'
import { AutoCompleteNew, AutoCompleteRemote } from 'components/Select'
import Context from 'common/context'
import { promoListPromise } from 'api/Promo'
import { trainerListPromise, upsertTrainerPromise } from 'api/WorkoutTrainer'
import { ParamsForOption, UserRoleOptions } from 'common/enum'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Notification = ({ open, type, message, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}>
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

const UserEdit = props => {
  const {
    match: {
      params: { userId },
    },
  } = props

  const [user, setUser] = useState({})
  const [originUser, setOriginUser] = useState({})
  const [showHeightMenu, setShowHeightMenu] = useState()
  const [showNotification, setShowNotification] = useState()
  const [weightData, setWeightData] = useState([])
  const [showWeightMenu, setShowWeightMenu] = useState()
  const [pwd, setPwd] = useState()
  const [promos, setPromos] = useState([])
  const [promo, setPromo] = useState()
  const { handleNotification } = Context._currentValue
  const [trainers, setTrainers] = useState([])
  const [targetTrainer, setTargetTrainer] = useState()
  const [rawTrainer, setRawTrainer] = useState()

  const disabled = useMemo(() => {
    if (targetTrainer) return false
    if (_.isEmpty(originUser)) return true
    if (!_.isEqual(user, originUser)) return false
    return true
  }, [targetTrainer, originUser, user])

  const getUserDetail = useCallback(async () => {
    const response = await userDetailPromise(userId)
    if (response) {
      setUser({ ...response })
      setOriginUser({ ...response })
    }
  }, [userId])

  const getPromoList = useCallback(async () => {
    // const response = await promoListPromise()
    // const res = (response.rows || [])
    //   .filter(row => { return row.product1 && row.product1.price <= 0 })
    //   .map(row => ({ label: `${row.code}(${row.product1.type})`, value: row.code }))
    // response && setPromos(res)
    setPromos([
      { label: 'invite-referral-incentive(7day)', value: 'invite-referral-incentive' },
      { label: 'invite-referral-incentive-monthly(monthly)', value: 'invite-referral-incentive-monthly' },
    ])
  }, [])

  useEffect(() => {
    getUserDetail()
    getPromoList()
  }, [getPromoList, getUserDetail, userId])

  const onChange = useCallback(option => {
    setUser({ ...user, ...option })
  }, [user])

  const requestWeight = async () => {
    const rep = [{ 'id': 1, value: '1' }, { 'id': 2, value: '2' }]
    setWeightData(rep)
  }

  const handleLocationChange = (location) => {
    setUser({
      ...user,
      ...location
    })
  }

  const handleTrainerChange = useCallback((event, selected) => {
    setTargetTrainer(selected || {})
    onChange({ role: selected ? 6 : 1 }) // 6: TrainerCommonUser, 1: common
  }, [onChange])

  const handleSaveTrainer = useCallback(() => {
    if (!targetTrainer) return

    if (rawTrainer && rawTrainer.value)
      upsertTrainerPromise({ oldId: rawTrainer.value, personal_user_id: null, user_id: rawTrainer.userId })
    if (targetTrainer.value)
      upsertTrainerPromise({ oldId: targetTrainer.value, personal_user_id: user.id, user_id: targetTrainer.userId })
  }, [rawTrainer, targetTrainer, user.id])

  const handleSave = useCallback(() => {
    handleSaveTrainer()
    updateUserPromise(_.omit(user, 'avatar')).then(msg => {
      if (msg !== 'success') return

      setShowNotification(true)
      getUserDetail()
    })
  }, [getUserDetail, handleSaveTrainer, user])

  const handleGrantPromo = async () => {
    const msg = await grantPromoPromise({ userId: user.id, promoCode: promo })
    if (msg === 'success') {
      setShowNotification(true)
    }
  }

  const updatePassword = async () => {
    const msg = await resetPassword(user.id, pwd)
    if (msg === 'success') {
      setShowNotification(true)
    }
  }

  const handleDeleteAvatar = useCallback(() => {
    if (!user.avatar) return

    if (window.confirm('are you sure?')) {
      const payload = { id: user.id, avatar: null }
      updateUserPromise(payload).then(msg => {
        if (msg !== 'success') return
        setUser({ ...user, ...payload })
      })
    }
  }, [user])

  const handleDeletePhone = async () => {
    if (window.confirm('are you sure?')) {
      const payload = { id: user.id, phone: null, countryCode: null }
      const msg = await updateUserPromise(payload)
      setUser(Object.assign(user, payload))
      if (msg === 'success') {
        setShowNotification(true)
      }
    }
  }

  const fetchPromoCodes = useCallback((code) => {
    return promoListPromise({ page: 1, size: 50, code, ...ParamsForOption }).then(data => {
      data.rows.forEach(r => {
        r.label = r.code
        r.value = r.code
      })
      return Promise.resolve(data)
    })
  }, [])

  useEffect(() => {
    trainerListPromise({ size: 1000 }).then(resp => {
      const options = resp.rows
        .filter(d => !d.personal_user_id || d.personal_user_id === user.id)
        .map(d => ({ label: d.name, value: d.id, personalUserId: d.personal_user_id, userId: d.user_id }))
      setTrainers(options)
    })
  }, [user.id])

  useEffect(() => {
    const trainer = trainers.find(t => t.personalUserId === user.id)
    setRawTrainer(trainer)
  }, [targetTrainer, trainers, user.id])

  const isEmailValid = useMemo(() => /^\S+@\S+\.\S+$/.test(user.email), [user.email])

  return (
    <div className={'root'}>
      <Card className={'user-content'}>
        <Avatar className={user.avatar ? 'avatar-not-empty' : 'avatar'} src={user.avatar} onClick={handleDeleteAvatar}>
          {getInitials(user.name || '')}
        </Avatar>
        <Typography className="name">{user.name}</Typography>
        <p className="info">
          {user.country_or_state}
          <span className="divide-dot"/>
          {[2, 1].includes(user.gender) && !user.genderOther && (
            <>
              {user.gender === 2 ? 'Female' : 'Male'}
              <span className="divide-dot"/>
            </>
          )}
          {user.birthday && formatAgeFromDOB(user.birthday)}
        </p>
        <div className="info-item">
          <span className="label">User Name</span>
          <span className="value">{user.user_name}</span>
        </div>

        <div className="info-item">
          <span className="label">Birthday</span>
          <span className="value">
            {moment(user.birthday).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="info-item">
          <span className="label">bmi</span>
          <span className="value">{user.bmi}</span>
        </div>
        <div className="info-item">
          <span className="label">Country code</span>
          <span className="value">{user.country_code}</span>
        </div>
        <div className="info-item">
          <span className="label">Location</span>
          <div className={'half-width'}>
            <CountryCitySelect
              data={user}
              handleSelectChange={handleLocationChange}
            />
          </div>

        </div>
        {/* <div className="info-item">
          <span className="label">Create at</span>
          <div className={'half-width'}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              fullWidth
              value={moment(user.created_at)}
              onChange={date => {
                onChange({
                  created_at: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                })
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>
        </div> */}
        <div className="info-item">
          <span className="label">Updated at</span>
          <span className="value">
            {moment(user.updated_at).format('DD/MM/YYYY')}
          </span>
        </div>

        {/* <div className="info-item">
          <span className="label">Email</span>
          <span className="value">{user.email}</span>
        </div> */}

        <div className="info-item">
          <span className="label">Email</span>
          <TextField value={user.email}
            className='half-width input-right'
            InputProps={{ disableUnderline: true, className: isEmailValid || 'color-red' }}
            onChange={event => onChange({ email: event.target.value })} />
        </div>

        <div className="info-item">
          <span className="label">Height</span>
          <p className="value">
            {user.height + ' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={e => {
                setShowHeightMenu(e.currentTarget)
              }}>
              {user.height_unit}
            </span>
          </p>
        </div>
        <div className="info-item">
          <span className="label">Weight</span>
          <p className="value">{user.weight + ' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={e => {
                setShowWeightMenu(e.currentTarget)
                requestWeight()
              }}>
            {user.weight_unit || 'test'}
            </span></p>
        </div>
        <div className="info-item">
          <span className="label">IP</span>
          <span className="value">{user.ip}</span>
        </div>
        <div className="info-item">
          <span className="label">Phone</span>
          <span className="value">
            {user.phone}
            &nbsp;
            { !user.phone ? null : <Button color='primary' variant="outlined" size='small' onClick={handleDeletePhone}>Delete</Button> }
          </span>
        </div>
        <div className="info-item">
          <span className="label">Timezone</span>
          <span className="value">{user.timezone}</span>
        </div>
        <div className="info-item">
          <AutoCompleteRemote
            value={promo}
            placeholder='Enter a keyword'
            style={{ width: 300, height: '1.5em' }}
            inputLeft={true}
            fetchData={fetchPromoCodes}
            onChange={(event, selected) => setPromo((selected || {}).value)}
          />
          <Button
            className="right-btn"
            color="primary"
            type="submit"
            variant="contained"
            disabled={!promo}
            onClick={handleGrantPromo}>
            Grant
          </Button>
        </div>

        <input hidden={true} /> {/* disable password reminder */}
        <div className="info-item">
          <Input
            className="left-input"
            disableUnderline
            value={pwd}
            placeholder={'new password'}
            onChange={e => {
              setPwd(e.target.value)
            }}
          />
          <Button
            className="right-btn"
            color="primary"
            type="submit"
            variant="contained"
            disabled={!pwd}
            onClick={() => {
              updatePassword()
            }}>
            Set Password
          </Button>
        </div>

        <div className="info-item">
          <span className="label">Role</span>
          <div className={'half-width'}>
            <AutoCompleteNew
              placeholder='select a role'
              value={user.role}
              options={UserRoleOptions}
              style={{ height: '1.5em' }}
              onChange={(event, selected) => onChange({ role: selected && selected.value })}
            />
          </div>
        </div>

        <div className="info-item">
          <span className="label">Active</span>
          <Switch
            checked={Boolean(user && user.active)}
            onChange={e => {
              onChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>

        <Button
          className="save-btn"
          color="primary"
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={() => {
            handleSave()
          }}>
          Save
        </Button>
      </Card>
      <Menu
        anchorEl={showWeightMenu}
        keepMounted
        open={Boolean(showWeightMenu)}
        onClose={() => {
          setShowWeightMenu(null)
        }}>

        {weightData && weightData.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setShowWeightMenu(null)
              onChange({ weight_unit: item.value })
            }}>
            {item.value}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={showHeightMenu}
        keepMounted
        open={Boolean(showHeightMenu)}
        onClose={() => {
          setShowHeightMenu(null)
        }}>
        <MenuItem
          onClick={() => {
            setShowHeightMenu(null)
            onChange({ height_unit: 'inch' })
          }}>
          inch
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowHeightMenu(null)
            onChange({ height_unit: 'cm' })
          }}>
          cm
        </MenuItem>
      </Menu>

      <Notification
        open={showNotification}
        type="success"
        message="Save success."
        onClose={() => {
          setShowNotification(false)
        }}
      />
    </div>
  )
}

export default UserEdit
