import { productListPromise } from 'api/Product'
import { CorporateWellnessSKUs } from 'common/enum'
import { useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'

const useProductOptions = (selectedProductIds=[], isCheckFree=false, isCheckAdmin=true, isCheckActive=true, isCheckCW=false) => {
  const [products, setProducts] = useState([])

  useEffectOnce(() => {
    productListPromise().then(res => setProducts(res))
  })

  return useMemo(() => {
    const _products = products.filter(product => {
      if ([].concat(selectedProductIds).includes(product.id)) return true

      return (!isCheckFree || (0 === +product.price))
        && (!isCheckAdmin  || product.admin)
        && (!isCheckActive || product.active)
        && (!isCheckCW     || CorporateWellnessSKUs.includes(product.sku))
    })

    return _products.map(p => ({ label: `${p.name} (${p.id})`, value: p.id }))
  }, [isCheckActive, isCheckAdmin, isCheckCW, isCheckFree, products, selectedProductIds])
}

export default useProductOptions
