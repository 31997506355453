import React, { useState, useEffect } from 'react'
import MealCategoryForm from './_form'
import { mealCategoryDetailPromise } from 'api/MealCategory'

export default function MealCategoryEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealCategory () {
      setData(
        await mealCategoryDetailPromise(id)
      )
    }
    fetchMealCategory()
  }, [id])
  return (
    <MealCategoryForm mode='edit' data={data} />
  )
}