/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react'
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { isArray } from 'lodash'
import './index.scss'

export default function MultipleAutoCompleteSelect (props) {
  const { options, label, placeholder, values, onChange, className, disableUnderline = true, variant='standard', ...rest } = props

  const filterOptions = useMemo(() => (
    createFilterOptions({
      limit: 50
    })
  ), [])

  const defaultValues = useMemo(() => {
    const _values = isArray(values) ? values : (values ? [values] : [])
    return (options || []).filter(o => _values.includes(o.value))
  }, [values, options])

  return (
    <Autocomplete
      { ...rest }
      multiple
      disableCloseOnSelect
      id="tags-standard"
      className={`multiple-auto-complete-select ${className}`}
      options={options || []}
      getOptionLabel={(option) => option.label}
      value={defaultValues}
      size={'small'}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline,
          }}
        />
      )}
    />
  )
}