import AddBtn from 'components/AddBtn'
import { Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { fetchWorkoutOptions, optionEnum } from '../../../concerns/workoutOptions'

export default function WorkoutResourceToolbar (props) {
  const [options, setOptions] = useState({})
  const { onChange, params } = props

  useEffect(() => {
    async function fetchOptions () {
      const _options = await fetchWorkoutOptions(optionEnum.partner)
      setOptions(_options)
    }
    fetchOptions()
  }, [])

  return (
    <div className={'tool-bar'}>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <AutoCompleteNew
            label='Partner'
            disableUnderline={false}
            options={options.partner || []}
            value={parseInt(params.partnerId)}
            onChange={(event, selected) => onChange({ partnerId: selected && selected.value })}
          />
        </Grid>

        <Grid item xs={8} />

        <Grid container item xs={1} justify={'flex-end'}>
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/partner-workout-resource/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
