import React, { useState, useEffect, useCallback } from 'react'
// import { isUndefined } from 'lodash'
import { userFeedbackListPromise } from 'api/User'
import FeedbackTable from './components/UserFeedbackTable'
// import FeedbackToolbar from './components/UserFeedbackToolbar'

const PromoList = props => {
  const [feedbacks, setFeedbacks] = useState([])
  const [params, setParams] = useState({ page: 1 })

  const getFeedbacks = useCallback(async () => {
    const promos = await userFeedbackListPromise(params)
    if (promos) {
      setFeedbacks(promos)
    }
  }, [params])

  function handlePageChange (event, page) {
    setParams({
      ...params,
      page: page+1
    })
  }

  useEffect(() => {
    getFeedbacks()
  }, [getFeedbacks])

  // function handleSearchChange (str) {
  //   if (!isUndefined(str)) {
  //     setParams({
  //       ...params,
  //       page: 1,
  //       code: str
  //     })
  //   }
  // }

  return (
    <div className={'root'}>
      <div className={'content'}>
        {/* <FeedbackToolbar handleSearchChange={handleSearchChange} {...props} />
        <br /> */}
        <FeedbackTable data={feedbacks || []} page={params.page} onPageChange={handlePageChange} {...props} />
      </div>
    </div>
  )
}

export default PromoList