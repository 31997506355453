import './index.css'
import React from 'react'
import { Grid, Input } from '@material-ui/core'
import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='Course Name' value={params.name} className={'block-value'} onChange={(event) => { handleSearchChange({name: event.target.value}, 500) }} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Group ID' value={params.groupId} className={'block-value'} onChange={(event) => { handleSearchChange({groupId: event.target.value}, 500) }} />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={1} >
          <AddBtn
            color="primary"
            type="submit"
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/social-groups/create`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
