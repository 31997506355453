import React, { useState, useEffect } from 'react'
import WorkoutEquipmentForm from './_form'
import { workoutEquipmentDetailPromise } from 'api/WorkoutEquipment'

export default function WorkoutEquipmentEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchWorkoutEquipment () {
      setData(
        await workoutEquipmentDetailPromise(id)
      )
    }
    fetchWorkoutEquipment()
  }, [id])
  return (
    <WorkoutEquipmentForm data={data} />
  )
}