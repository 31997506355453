import React, { useCallback } from 'react'
import { booleanElement } from 'helpers/utils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardActions, CardContent, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'

export default function (props) {
  const { data, params, handleParamsChange } = props

  const handlePageChange = useCallback((event, page) => {
    handleParamsChange({ studioPage: page + 1 })
  }, [handleParamsChange])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>STUDIOS</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content'>
      <Card className='full-width'>
        <CardContent className={'table-content'}>
          <PerfectScrollbar>
            <div className={'inner'}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>_ID</TableCell> */}
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Instruction</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Active</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data && data.rows && data.rows.map(({ id, studio_id, studio }) => {
                    return <TableRow className={'table-row cursor-pointer'} key={id} hover>
                      {/* <TableCell>{ id }</TableCell> */}
                      <TableCell>{ studio_id }</TableCell>
                      <TableCell>{ studio && studio.name }</TableCell>
                      <TableCell>{ studio && studio.instruction }</TableCell>
                      <TableCell>{ studio && studio.address }</TableCell>
                      <TableCell>{ booleanElement(studio && studio.active) }</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={'flex-right'}>
          <TablePagination
            component="div"
            count={data.count || 0}
            onChangePage={handlePageChange}
            page={params.studioPage - 1}
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
      </Card>
    </Grid>
  </Card>
}
