import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container>
        <Grid item xs={2}>
          <Input value={params.courseName} placeholder='Course Name' className={'block-value'} onChange={(event) => { handleSearchChange({courseName: event.target.value}) }} />
        </Grid>
        <Grid item xs={8} />
        <Grid container xs={2} justify='flex-end'>
          <AddBtn
            color="primary"
            type="submit"
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/course-weeks/create`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
