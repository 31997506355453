import { getApiClient } from './ApiClient'

export const featureBannerPlacementListPromise = (params) => {
  return getApiClient()
    .get('/feature-banner-placements', { params })
    .then(resp => resp.data)
}

export const featureBannerPlacementDetailPromise = (id) => {
  return getApiClient()
    .get(`/feature-banner-placements/${id}`)
    .then(resp => resp.data)
}

export const featureBannerPlacementUpsertPromise = (body) => {
  return getApiClient()
    .post('/feature-banner-placements/upsert', { body })
    .then(resp => resp.data)
}

export const featureBannerListPromise = (body) => {
  return getApiClient()
    .post('/feature-banners', { body })
    .then(resp => resp.data)
}

export const featureBannerDetailPromise = (id) => {
  return getApiClient()
    .get(`/feature-banners/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const featureBannerContentDetailPromise = id => {
  return getApiClient()
    .get(`/feature-banner/contents/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const featureBannerCampaignUpsertPromise = (body) => {
  return getApiClient()
    .post('/feature-banner/campaigns/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const featureBannerContentUpsertPromise = (body) => {
  return getApiClient()
    .post('/feature-banner/contents/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const featureBannerContentListPromise = params => {
  return getApiClient()
    .get('/feature-banner/contents', { params })
    .then(resp => resp.data)
}

export const getFeatureBannerTargetUsersPromise = () => {
  return getApiClient()
    .get('feature-banner/target-users')
    .then(resp => resp.data)
}
