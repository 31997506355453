import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import DeleteBtn from 'components/DeleteBtn'
import { booleanElement } from 'helpers/utils'
import _ from 'lodash'
import { NutritionProgramValueToType } from 'common/enum'

const ProgramNutritionTable = props => {
  const { data, page, onPageChange, programs, onDelete } = props
  const [programMap, setProgramMap] = useState({})

  useEffect(() => {
    setProgramMap(_.keyBy(programs, 'id'))
  }, [programs])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Program</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Content ID</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>Sequence</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{`${(programMap[row.program_id] || {}).name} (${row.program_id})`}</TableCell>
                    <TableCell>{NutritionProgramValueToType[row.content_type]}</TableCell>
                    <TableCell>{row.content_id}</TableCell>
                    <TableCell>{row.week}</TableCell>
                    <TableCell>{row.sequence}</TableCell>
                    <TableCell>
                      { booleanElement(row.active) }
                    </TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/program-nutrition/edit/${row.id}`} />
                      &nbsp;&nbsp;
                      {
                        row.active
                          && <DeleteBtn onClick={event => onDelete(row.id)} />
                          // <Delete className='cursor-pointer' onClick={event => onDelete(row.id)} />
                      }
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramNutritionTable