import _ from 'lodash'
import 'assets/scss/form2.scss'
import titleDesc from '../../titleDesc.json'
import { Card, Grid } from '@material-ui/core'
import { getLocaleLanguages } from 'api/helper'
import React, { useEffect, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { getLocaleCountryOptions } from 'helpers/utils'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'

export default function (props) {
  const { data, handleChange } = props
  const [countries, setCountries] = useState([])
  const [languages, setLanguages] = useState([])
  
  useEffect(() => {
    getLocaleCountryOptions().then(resp => setCountries(resp.map(c => ({ label: c.label, value: c.id }))))
  }, [])

  useEffect(() => {
    getLocaleLanguages().then(resp => setLanguages(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>Target</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Country</label>
          <AutoCompleteNew options={countries} value={data.device_country} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => handleChange('device_country', selected && selected.value)}
          />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Locale Language</label>
          <AutoCompleteNew options={languages} value={data.device_language} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => handleChange('device_language', selected && selected.value)}
          />
        </div>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <BooleanToggleButtonGroup
          label='PRO' name='is_pro' value={data.is_pro} onChange={handleChange} titles={titleDesc.is_pro}
        />
      </Grid>

      {
        ['senior', 'non_senior', 'female', 'male'].map(foo =>
          <Grid item xs={4} className='bottom-dashed' key={foo}>
            <BooleanToggleButtonGroup
              label={_.startCase(foo)} name={foo} withoutNull={true} value={data[foo]} onChange={handleChange} titles={titleDesc[foo]}
            />
          </Grid>
        )
      }
    </Grid>
  </Card>
}
