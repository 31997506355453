import { getPurchaseGroupDetailPromise } from 'api/Product'
import React, { useEffect, useState } from 'react'
import Form from './form'

export default function CourseAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({ active: false})

  useEffect(() => {
    if (!id) return

    getPurchaseGroupDetailPromise(id).then(resp => {
      resp && setData(resp)
    })
  }, [id])

  return <Form data={data} setData={setData} isEdit={true} {...props} />
}
