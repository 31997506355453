import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { Card, Button, Switch, Input } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { S3PreSignType, TrainerTypeOptions } from 'common/enum'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { upsertTrainerPromise } from 'api/WorkoutTrainer'
import UserCreateModal from './userCreateModal'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function WorkoutCategoryUpsertForm (props) {
  const { oldId, data, setData } = props
  const [modalStatus, setModalStatus] = useState(false)

  const handleChange = useCallback((pair) => {
    if (pair.user_id) pair.id = pair.user_id
    setData({ ...data, ...pair })
  }, [data, setData])

  const handleSubmit = useCallback(() => {
    data.oldId = oldId
    upsertTrainerPromise(data).then(() => props.history.goBack())
  }, [data, oldId, props.history])

  const formItem = useCallback((name, label, props) => {
    return <FormItem label={label || _.startCase(name)} name={name} value={data[name]} onChange={handleChange} {...props} />
  }, [data, handleChange])

  const Uploader = useCallback((name, prefix, subtitle) =>
    <S3UploaderWithInput name={name} label={`${subtitle || ''}${_.startCase(name)}(${prefix})`} filenames={data[name]}
      fileType={S3PreSignType.Trainer} prefix={prefix}
      setFilenames={(value) => { setData({ ...data, [name]: value })}} />
  , [data, setData])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        {/* { formItem('id', 'ID', { readonly: !!oldId }) } */}
        {/* { formItem('user_id') } */}

        <FormItem name='user_id'>
          <div>
            <Button size='small' variant='outlined' onClick={() => { setModalStatus(true) }}>Create a new user</Button>
            <span>&nbsp; OR INPUT &nbsp;</span>
            <Input id='user_id' name='user_id' disableUnderline value={data.user_id} className={'value'} classes={{ input: 'text' }} style={{ width: '200px' }} placeholder='User ID'
              onChange={event => handleChange({ user_id: event.target.value })}/>
          </div>
        </FormItem>

        { formItem('celebrity_trainer_id') }
        { formItem('personal_user_id') }
        { formItem('name') }
        { formItem('introduction', null, { multiline: true }) }
        { formItem('tags') }
        { formItem('cover_button') }

        { formItem('instagram_url') }
        { formItem('website_url') }
        {/* { formItem('congrats_video_url') } */}
        
        <FormItem label={'Type'}>
          <AutoCompleteNew disableUnderline={true} value={data.type} options={TrainerTypeOptions} onChange={(event, selected) => { handleChange({ type: selected && selected.value }) }} />
        </FormItem>

        <S3UploaderWithInput name={'avatar_url'} label='Avatar Url(500x500)' filenames={data.avatar_url} fileType={S3PreSignType.TrainerAvatar}
          setFilenames={(value) => { setData({ ...data, avatar_url: value })}} />

        { Uploader('cover_url', '1125x1412') }
        { Uploader('cover_url_horizontal', '960x1200', 'Partner - ') }
        { Uploader('cover_url_big', '1125x1500', 'Partner - ') }
        
        <FormItem name={'celebrity'}>
          <Switch name='celebrity' checked={Boolean(data.celebrity)} onChange={ e => handleChange({ celebrity: e.target.checked }) } />
        </FormItem>
        <FormItem name={'enable_search'}>
          <Switch name='enable_search' checked={Boolean(data.enable_search)} onChange={ e => handleChange({ enable_search: e.target.checked }) } />
        </FormItem>
        <FormItem name={'show_browse'}>
          <Switch name='show_browse' checked={Boolean(data.show_browse)} onChange={ e => handleChange({ show_browse: e.target.checked }) } />
        </FormItem>
        <FormItem name={'partner'}>
          <Switch name='partner' checked={Boolean(data.partner)} onChange={ e => handleChange({ partner: e.target.checked }) } />
        </FormItem>
        <FormItem name={'Partner Sort'}>
          <NumberAutoComplete name='sort' from={1} to={100} value={data.sort} handleSelectChange={sort => { handleChange({ sort }) }} />
        </FormItem>

        <FormItem name={'active'}>
          <Switch name='active' checked={Boolean(data.active)} onChange={ e => handleChange({ active: e.target.checked }) } />
        </FormItem>

        <Button
          className='save-btn'
          color='primary'
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </Card>

      <UserCreateModal modalStatus={modalStatus} setTrainer={handleChange} setModalStatus={setModalStatus} />
    </div>
  )
}