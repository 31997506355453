import { getProgramByIdPromise } from 'api/program'
import React, { useEffect, useState } from 'react'
import Step1 from './step1'
import Step2 from './step2'
import _ from 'lodash'
import { send, formatDateTimeUTC } from 'helpers/utils'

export default function ProgramAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({ id: id })
  const copyFromProgram = send(props.location, 'state', 'program')
  const [program, setProgram] = useState(copyFromProgram || {
    oldId: data.id,
    is_pro: 'true',
    senior: true,
    non_senior: true,
    female: true,
    male: true,
    featured: false,
    free_user: true,
    pro_health_user: true,
    health_plus_user: true,
    is_wlc: false,
    wlc_user: true,
    enrollment_begin: formatDateTimeUTC(data.enrollment_begin),
    enrollment_end: formatDateTimeUTC(data.enrollment_end),
  })
  const [currentStep, setCurrentStep] = useState('step1')
  

  function handleStepChange(step){
    setCurrentStep(step)
  }

  useEffect(() => {
    if (id) {
      // getProgramByIdPromise(id).then(resp => resp && setProgram(_.assign({}, resp, { oldId: id }, { trainers: (resp.trainer_programs || []).map(tc => tc.trainer_id) })))
      getProgramByIdPromise(id).then(resp => resp && setProgram({
        ...resp,
        oldId: id,
        programTools: (resp.programTools || []).map(pt => pt.id),
        trainers: (resp.trainer_programs || []).map(tc => tc.trainer_id),
      }))
    } else {
      const initData = { id: null, active: false, cover_url: null, members: 0, copyFrom: copyFromProgram && copyFromProgram.id }
      setProgram(prevProgram => ({ ...prevProgram, ...initData }))
    }
  }, [id, copyFromProgram])

  // useMemo(() => {
  //   setProgram(data)
  // }, [data])

  function handleProgramValueChange(map){
    if (_.has(map, 'name') && !data.id)
      map.deeplink = (map.name || '').replace(/\s/g, '-').replace(/[^\w-]/g, '').toLowerCase()
    setProgram({ ...program, ...map })
  }

  // const handleValueChange = useCallback((map) => {
  //   console.log(JSON.stringify(map))
  //   if (_.has(map, 'name') && !data.id)
  //     map.deeplink = (map.name || '').replace(/\s/g, '-').replace(/[^\w-]/g, '').toLowerCase()
  //   setProgram({ ...program, ...map })
  // }, [data.id, program])

  return <div className='form-page'>
    <Step1 
      {...props}
      mode={id ? 'edit' : 'add'} 
      hidden={currentStep === 'step2'}
      handleStepChange={handleStepChange}
      handleValueChange={handleProgramValueChange}
      program={program}
      data={data}
      setProgram={setProgram}
      setData={setData} />
    <Step2
      {...props} 
      hidden={currentStep !== 'step2'}
      handleStepChange={handleStepChange}
      handleValueChange={handleProgramValueChange}
      program={program}
      data={data}
      setProgram={setProgram}
      setCurrentStep={setCurrentStep} />
  </div>
}