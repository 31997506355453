import _ from 'lodash'
import { Card, Grid, Switch } from '@material-ui/core'
import FormTextField from 'components/FormTextField'
import React, { useLayoutEffect, useMemo, useState } from 'react'

const columns = ['name', 'category_code', 'city', 'country']
export default function (props) {
  const { data } = props
  const [merchant, setMerchant] = useState({})

  useLayoutEffect(() => {
    if (!data) return
    if (!_.isEmpty(merchant)) return

    setMerchant(data)
  }, [data, merchant])

  const verifiedText = useMemo(() => {
    if (merchant.verified === true) return '(Verified)'
    if (merchant.verified === false) return '(Unverified)'
  }, [merchant.verified])

  function MerchantColumn (props) {
    const { columnName } = props

    return <Grid item xs={6}>
      <div className='form-input-elem'>
        <FormTextField name={columnName} label={_.startCase(columnName)} value={merchant[columnName]} readonly={true} onChange={() => {}} />
      </div>
    </Grid>
  }

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>MERCHANT {verifiedText}</label>
      <Switch name={'active'} checked={Boolean(merchant.active)} readOnly={true} onChange={() => {}} />
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      {
        columns.map(columnName => <MerchantColumn columnName={columnName} />)
      }
    </Grid>
  </Card>
}
