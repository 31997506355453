import React, { useCallback, useMemo } from 'react'
import { Card, Button, Switch, Grid } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { send } from 'helpers/utils'
import { editAdminPromise } from 'api/Admin'
import { pages } from 'layouts/Main/components/Sidebar'
import { getPermissionObjByPath } from 'helpers/permission'
import './index.scss'

export default function AdminForm (props) {
  const { data, setData } = props

  const permissions = useMemo(() => {
    return new Set(data.permissions)
  }, [data.permissions])

  const handleChange = useCallback((pair) => {
    setData({ ...data, ...pair })
  }, [data, setData])

  const handleSubmit = useCallback(() => {
    editAdminPromise(data).then(() => props.history.goBack())
  }, [data, props.history])

  const Checker = useCallback((codes) => {
    return <Grid container item>
      {
        Object.keys(codes).map((method, idx) => {
          return <Grid container item xs={2} key={idx}>
            <Grid container item xs={6} justify='flex-end' alignItems='center'>
              {method === 'upsert' ? 'add/edit' : method}
            </Grid>
            <Grid container item xs={6} justify='flex-start'>
              <Switch name='active' checked={permissions.has(codes[method])} onChange={ e => {
                const mtd = e.target.checked ? 'add' : 'delete'
                permissions[mtd](codes[method])
                setData({ ...data, permissions: Array.from(permissions) })
              } } />
            </Grid>
          </Grid>
        })
      }
    </Grid>
  }, [data, permissions, setData])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container>
          <Grid container item xs={3} justify='flex-start' alignItems='center'> {send(data.user, 'name')} - { send(data.user, 'email') } </Grid>

          <Grid container item xs={9}>
            <Grid container item xs={6} justify='flex-start' alignItems='center'>
              active
              <Switch name='active' checked={Boolean(data.active)} onChange={ e => handleChange({ active: e.target.checked }) } />
            </Grid>
          </Grid>

          {
            pages.map((page, idx) => {
              const parent = getPermissionObjByPath(page.href)
              if (!parent && !page.subItems) return <></>

              return <Grid container key={`${page.title}-${idx}`} className='bottom-color'>
                <Grid container item xs={1} justify='flex-end' alignItems='center' className='font-bold'> { page.title }&nbsp;&nbsp; </Grid>
                <Grid item xs={2} />
                <Grid container item xs={9} justify='flex-start'> { parent && parent.codes && Checker(parent.codes) } </Grid>
                {
                  (page.subItems || []).map((item, idx2) => {
                    const child = getPermissionObjByPath(item.href)
                    if (!child || child.notEditable) return <></>

                    return <Grid container key={`${item.title}-${idx2}`}>
                      <Grid item xs={1}></Grid>
                      <Grid container item xs={2} justify='flex-start' alignItems='center'> { item.title } </Grid>
                      <Grid container item xs={9} justify='flex-start'>
                        { Checker(child.codes) }
                      </Grid>
                    </Grid>
                  })
                }
              </Grid>
            })
          }
        </Grid>

        <br />

        <Button
          color='primary'
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>

      </Card>
    </div>
  )
}