import './index.scss'
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import ActiveSwitch from 'components/ActiveSwitch'
import { AutoCompleteNew } from 'components/Select'
import { InviteTemplateUseImageType, ParamsForOption, S3PreSignType } from 'common/enum'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { getInviteTemplateTypesPromise, upsertInviteTemplatePromise } from 'api/Util'

const requiredColumns = []
export default function InviteTemplateUpsertForm (props) {
  const { mode, data, history } = props
  const [types, setTypes] = useState([])
  const [record, setRecord] = useState({})

  useLayoutEffect(() => {
    getInviteTemplateTypesPromise(ParamsForOption)
      .then(resp => {
        setTypes(resp.map(({ type }) => ({ label: type, value: type })))
      })
  }, [])

  useEffect(() => {
    setRecord({
      oldId: data.id,
      ...data,
    })
  }, [data])

  function handleSubmit () {
    upsertInviteTemplatePromise(record)
      .then(() => history.goBack())
  }

  const handleValueChange = useCallback((map) => {
    setRecord({ ...record, ...map })
  }, [record])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name={'title'} value={record.title} multiline={true} onChange={handleValueChange} />
        <FormItem name={'url'} value={record.url} onChange={handleValueChange} />

        <FormItem label='Type'>
          <AutoCompleteNew
            value={record.type}
            options={types}
            onChange={(event, selected) => handleValueChange({ type: selected && selected.value })}
          />
        </FormItem>

        <FormItem label='Which Image To Use'>
          <AutoCompleteNew
            value={record.use_image_type}
            options={InviteTemplateUseImageType}
            onChange={(event, selected) => handleValueChange({ use_image_type: selected && selected.value })}
          />
        </FormItem>

        <S3UploaderWithInput label='Image' name={'image_url'} filenames={record.image_url}
          fileType={S3PreSignType.InviteTemplateImage}
          setFilenames={image_url => handleValueChange({ image_url })} />
   
        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={record} columns={requiredColumns} setBody={setRecord} isNeedCheck={true} />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}