import { getProgramWorkoutDetail } from 'api/ProgramWorkout'
import React, { useLayoutEffect, useState } from 'react'
import Form from './form'

export default function (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useLayoutEffect(() => {
    getProgramWorkoutDetail(id).then(resp => setData(resp))
  }, [id])

  return <Form oldId={id} data={data} setData={setData} {...props} />
}
