import React, { useCallback, useEffect } from 'react'
import { Switch } from '@material-ui/core'
import _ from 'lodash'

function ActiveSwitch (props) {
  const { mode, body, setBody, columns = [], isNeedCheck = false, name = 'active', disabled = false } = props

  const handleChange = useCallback((event) => {
    const active = event.target.checked

    if (active && isNeedCheck) {
      const blankColumns = columns.filter(({ key }) => !body[key])
      if (blankColumns.length > 0) return window.alert(`${blankColumns.map(c => c.label || _.startCase(c.key)).join(', ')}\n\ncannot be blank`)
    }

    setBody({ ...body, active })
  }, [body, columns, isNeedCheck, setBody])

  useEffect(() => {
    if (mode === 'edit' || !isNeedCheck || !body.active) return

    columns.forEach(({ key }) => {
      if (!body[key]) setBody({ ...body, active: false })
    })
  }, [body, columns, isNeedCheck, mode, setBody])

  return <Switch
    name={'active'}
    checked={Boolean(body[name])}
    disabled={disabled}
    onChange={handleChange}
  />
}

export default ActiveSwitch
