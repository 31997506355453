import 'assets/scss/form2.scss'
import { Button, Grid } from '@material-ui/core'
import SetupCard from './components/setup'
import ContentCard from './components/content'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { upsertProgramNutritionPromise } from 'api/programNutrition'

export default function (props) {
  const { data, history, isEdit } = props
  const [programNutrition, setProgramNutrition] = useState({})
  const columnsNotNull = ['program_id']

  useLayoutEffect(() => {
    if (data) setProgramNutrition(data)
  }, [data])

  const handleChange = useCallback((key, value, other) => {
    setProgramNutrition({ ...programNutrition, [key]: value, ...other })
  }, [programNutrition])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertProgramNutritionPromise(programNutrition)
      .then(() => history.goBack())
  }, [programNutrition, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!programNutrition[cname]) return true
  }, [programNutrition, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Program Nutrition
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SetupCard data={programNutrition} isEdit={isEdit} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ContentCard data={programNutrition} isEdit={isEdit} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

  </div>
}
