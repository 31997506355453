import React, { Component, useState, useEffect, useCallback } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Chart } from 'react-chartjs-2'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'
import { chartjs } from './helpers'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import './assets/scss/form.scss'
import './assets/scss/detail.scss'
import validators from './common/validators'
import Routes from './Routes'
import { getToken } from './api/ApiClient'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Context from 'common/context'
import Notification from 'components/Notification'
import { getApiClient } from 'api/ApiClient'
import ProcessCircle from 'components/ProgressCircle'
import { intiAdminPermission } from 'helpers/permission'

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

validate.validators = {
  ...validate.validators,
  ...validators,
}

export default class App extends Component {
  constructor(props) {
    super(props)
    const history = browserHistory
    const token = getToken()
    if (!token) {
      history.push('/login')
    }
  }

  render() {
    return <Main />
  }
}

function Main () {
  const [notification, setNotification] = useState({})
  const [processOpen, setProcessOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  
  function handleNotification (type, message, callback) {
    setNotification({
      open: true,
      type,
      message,
      callback
    })
  }

  function handleNotificationClose () {
    notification.callback && notification.callback()
    setNotification({})
  }

  const toggleProgress = useCallback((open) => {
    const currentProgressCount = getApiClient()._progressCount_ || 0

    if (open) {
      setProcessOpen(true)
      getApiClient()._progressCount_ = currentProgressCount + 1
      return
    }

    getApiClient()._progressCount_ = Math.max(currentProgressCount - 1, 0)
    if (getApiClient()._progressCount_ > 0) return

    setProcessOpen(false)
  }, [])

  useEffect(() => {
    getApiClient().handleNotification = handleNotification
    getApiClient().toggleProgress = toggleProgress
  }, [toggleProgress])

  useEffect(() => {
    intiAdminPermission().then(data => setIsLoaded(true))
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <ProcessCircle open={processOpen} />
        <Notification open={notification.open || false} type={notification.type || 'info'} message={notification.message || ''} onClose={handleNotificationClose} />
        {isLoaded &&
          <Context.Provider value={{ handleNotification, toggleProgress }}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </Context.Provider>
        }
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
