import _ from 'lodash'

const formatUrl = path => {
  const adjustedPath = path[0] !== '/' ? '/' + path : path
  if (_.startsWith(path, 'http') || _.startsWith(path, '/api')) {
    return path
  }

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
      return 'https://api-staging.fitonapp.com/admin' + adjustedPath;
    case 'prod':
      return 'https://api.fitonapp.com/admin' + adjustedPath;
    default:
      return 'http://localhost:3000/admin' + adjustedPath;
  }
}
export default formatUrl
