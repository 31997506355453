import _ from 'lodash'
import 'assets/scss/form2.scss'
import SetupCard from './components/setup'
import AssetsCard from './components/asset'
import { Button, Grid } from '@material-ui/core'
import { upsertSocialBadgePromise } from 'api/SocialBadge'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'

export default function ChallengeForm (props) {
  const { data, history } = props
  const [challenge, setChallenge] = useState({})
  const columnsNotNull = []

  useLayoutEffect(() => {
    if (!_.isEmpty(challenge)) return

    setChallenge(data)
  }, [challenge, data])

  const handleChange = useCallback((key, value, other) => {
    setChallenge({ ...challenge, [key]: value, ...other })
  }, [challenge])

  const cancelClicked = useCallback(() => {
    history.push('/social-badges')
  }, [history])

  const saveClicked = useCallback(() => {
    upsertSocialBadgePromise(challenge)
      .then(() => history.push('/social-badges'))
  }, [challenge, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!challenge[cname]) return true
  }, [challenge, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Social Badge
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SetupCard data={challenge} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AssetsCard data={challenge} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>
  </div>
}
