import React, { useCallback, useLayoutEffect, useState } from 'react'
import { Button, Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { BooleanOptions } from 'common/enum'
import { featureBannerListPromise } from 'api/FeatureBanner'
import AddBtn from 'components/AddBtn'

export default function WorkoutCategoryToolbar (props) {
  const { query, setQuery } = props
  const [search, setSearch] = useState({})
  const [campaignOptions, setCampaignOptions] = useState([])

  const handleChange = useCallback((key, value) => {
    setSearch({ ...search, [key]: value || undefined })
  }, [search])

  useLayoutEffect(() => {
    featureBannerListPromise({ size: 1000 })
      .then(resp => resp && resp.rows && setCampaignOptions(resp.rows.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Input name='name' placeholder='name' className='full-width' value={search.name} onChange={event => handleChange('name', event.target.value)} />
        </Grid>
        <Grid item xs={2}>
          <Input name='type' placeholder='type' className='full-width' value={search.type} onChange={event => handleChange('type', event.target.value)} />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew
            placeholder='Campaign'
            disableUnderline={false}
            options={campaignOptions}
            value={search.campaign_id}
            onChange={(event, selected) => handleChange('campaign_id', (selected || {}).value)}
          />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew
            placeholder='active'
            value={search.active}
            options={BooleanOptions}
            disableUnderline={false}
            onChange={(event, selected) => handleChange('active', (selected || {}).value)}
          />
        </Grid>

        <Grid item container xs={2}>
          <Button color='primary' type='submit' variant='contained' onClick={e => setQuery({ ...query, ...search, page: 1 }) }>
            Search
          </Button>
        </Grid>

        <Grid item container xs={2} justify='flex-end'>
          <AddBtn color='primary' type='submit' style={{marginRight: 0}} variant='contained'
            onClick={e => {
              e.stopPropagation()
              props.history.push('/feature-banner/contents/add')
            }}
          > Add </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
