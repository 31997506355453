import { getApiClient } from './ApiClient'

export const getUserProgramListPromise = (params) => {
  return getApiClient()
    .get('/user-programs', { params })
    .then(resp => resp.data)
}

export const getUserProgramDetailPromise = (id) => {
  return getApiClient()
    .get(`/user-programs/${id}`)
    .then(resp => resp.data)
}

export const updateUserProgramPromise = (body) => {
  return getApiClient()
    .put('/user-programs', { body })
    .then(resp => resp.data)
}

export const addUserToProgramPromise = (body) => {
  return getApiClient()
    .post('/user-programs', { body })
    .then(resp => resp.data)
}
