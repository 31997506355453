import React, { useState, useLayoutEffect, useEffect, useCallback, useRef } from 'react'
import Toolbar from './Toolbar'
import Table from './Table'
import { adminListPromise } from 'api/Admin'
import { useLocation } from 'react-router-dom'
import QS from 'qs'
import _ from 'lodash'

const AdminList = props => {
  const [admins, setAdmins] = useState({})
  const [params, setParams] = useState({ page: 1 })
  const location = useLocation()
  const ref = useRef()

  // start
  // load data
  useEffect(() => {
    const foo = QS.myParse(location.search)
    setParams({ page: 1, ...foo })
    adminListPromise(foo).then(resp => setAdmins(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = QS.myParse(location.search)
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${QS.stringify(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload) {
    handleParamsChange(payload, 500)
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar handleSearchChange={handleSearchChange} params={params} {...props} />
        <br />
        <Table data={admins} page={params.page} onPageChange={handlePageChange} {...props} />
      </div>
    </div>
  )
}

export default AdminList
