import { getApiClient } from './ApiClient'

export const partnerWorkoutResourceListPromise = (params) => {
  return getApiClient()
    .get('/partner-workout-resources', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const partnerWorkoutResourceDetailPromise = (id) => {
  return getApiClient()
    .get(`/partner-workout-resources/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const partnerWorkoutResourceUpsertPromise = (body) => {
  return getApiClient()
    .post('/partner-workout-resources/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
