import React, { useState, useEffect, useCallback } from 'react'
import { workoutTrendingListPromise } from 'api/WorkoutTrending'
import PerfectScrollbar from 'react-perfect-scrollbar'
// import Close from '@material-ui/icons/Close'
// import Check from '@material-ui/icons/Check'
import { formatDateTime } from 'common/usual'
// import { workoutTrendingUpsertPromise } from 'api/WorkoutTrending'
import EditBtn from 'components/EditBtn'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid
} from '@material-ui/core'
import AddBtn from 'components/AddBtn'

function WorkoutTrendingList (props) {
  const [data, setData] = useState([])

  const fetchTrending = useCallback(async () => {
    const res = await workoutTrendingListPromise()
    setData(res)
  }, [])
  
  useEffect(() => {
    fetchTrending()
  }, [fetchTrending])

  // function toggle (id, active) {
  //   workoutTrendingUpsertPromise({id, active})
  //     .then(res => {
  //       fetchTrending()
  //     })
  // }

  return (
    <div className={'root'}>
      <div className={'tool-bar'}>
        <Grid container item xs={12} justify={'flex-end'} >
          <AddBtn
            color="primary"
            type="submit"
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/workout-trending/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </div>
      <div className={'content'}>
        <Card>
          <CardContent className={'table-content'}>
            <PerfectScrollbar>
              <div className={'inner'}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Workout</TableCell>
                      <TableCell>Partner</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map(trending => (
                        <TableRow key={trending.id} hover>
                          <TableCell>{trending.id}</TableCell>
                          <TableCell>{trending.type}</TableCell>
                          <TableCell>{trending.resource && trending.resource.name}</TableCell>
                          <TableCell>{trending.partner && trending.partner.name}</TableCell>
                          <TableCell>{trending.active ? 'TRUE' : 'FALSE'}</TableCell>
                          <TableCell>{formatDateTime(trending.createdAt)}</TableCell>
                          <TableCell>
                            <EditBtn history={props.history} path={`/workout-trending/edit/${trending.id}`} />
                            {/* {
                              trending.active
                                ? <Close className={'cursor-pointer'} onClick={() => { toggle(trending.id, false) }} />
                                : <Check className={'cursor-pointer'} onClick={() => { toggle(trending.id, true) }} />
                            } */}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default WorkoutTrendingList