import React, { useCallback } from 'react'
import MyModal from 'components/MyModal'
import { Button } from '@material-ui/core'

export default function (props) {
  const {contents, modalOpen, setModalOpen, handleChoose} = props

  const handleChooseClicked = useCallback((content) => {
    handleChoose(content)
  }, [handleChoose])

  return <MyModal open={modalOpen} setOpen={setModalOpen}>
    <div className='modal-container'>
      <div className='full-width modal-title'>
        <h2>Choose an existing asset</h2>
      </div>

      {
        contents.map(content => <div className='full-width modal-content-row'>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className='modal-image' style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
              <img src={content.background_en} alt='' style={{maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}} />
            </div>
            &nbsp;&nbsp;
            <div>
              <div style={{marginBottom: '5px', fontSize: '1.2em'}}>{content.name}</div>
              <div style={{marginTop: '5px'}}>CTA: {content.cta_text}</div>
            </div>
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button color='secondary' variant='outlined' onClick={() => handleChooseClicked(content)}>CHOOSE</Button>
          </div>
        </div>)
      }
    </div>
  </MyModal>
}
