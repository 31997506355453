import React, { useLayoutEffect, useState } from 'react'
import Form from './form'
import { send } from 'helpers/utils'
import { getVideoResourceDetail } from 'api/VideoResource'

export default function (props) {
  const record = send(props.location, 'state', 'record')
  const [data, setData] = useState({ is_pro: false, is_exclusive: false, active: false })

  useLayoutEffect(() => {
    if (!send(record, 'id')) return

    getVideoResourceDetail(record.id).then(resp => setData({ ...resp, id: null, video_subtitle_id: null, download_url: null, active: false }))
  }, [record])

  return <Form data={data} setData={setData} {...props} />
}
