import { getApiClient } from './ApiClient'

export const getStickerImageListPromise = (params) => {
  return getApiClient()
    .get('/sticker-images', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const getStickerImageDetailPromise = (id) => {
  return getApiClient()
    .get(`/sticker-images/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertStickerImagePromise = (body) => {
  return getApiClient()
    .post('/sticker-images', { body })
    .then(resp => Promise.resolve(resp.data))
}
