import { getProgramByIdPromise } from 'api/program'
import React, { useEffect, useMemo, useState } from 'react'
import Form from './form'

export default function ProgramAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useMemo(() => {
    if (!id) return

    getProgramByIdPromise(id).then(resp => {
      resp && setData(resp)
    })
  }, [id])

  useEffect(() => {
  }, [props])

  return <Form data={data} setData={setData} {...props} />
}