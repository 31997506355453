import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='title' className={'block-value'} value={params.title} onChange={(event) => { handleSearchChange({title: event.target.value}) }} />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2} container justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push('/invite-templates/add')
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
