import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { ParamsForOption, S3PreSignType, VideoResourceDownloadUrlPrefix, VideoResourceTypeOptions } from 'common/enum'
import { workoutTrainerListPromise } from 'api/WorkoutTrainer'
import { AutoCompleteNew } from 'components/Select'
import JSONEditor from 'components/JSONEditor'
import { getVideoResourceList, upsertVideoResource } from 'api/VideoResource'
import { send } from 'helpers/utils'
import AgeAndGenderSwitch from 'components/AgeAndGenderSwitch'


export default function WorkoutCategoryUpsertForm (props) {
  const { oldId, data, setData } = props
  const [trainers, setTrainers] = useState([])
  const [jsonGetter, setJsonGetter] = useState()

  const handleChange = useCallback((pair) => {
    setData(prev => ({ ...prev, ...pair }))
  }, [setData])

  useEffect(() => {
    const download_url = data.type === 'nutrition program'
      ? null
      : `${VideoResourceDownloadUrlPrefix[data.type]}/${(data.id) % 10000}.mp4`

    handleChange({ download_url })
  }, [data.id, data.type, handleChange])

  const handleSubmit = useCallback(() => {
    data.oldId = oldId
    data.content_json = jsonGetter && jsonGetter()
    upsertVideoResource(data).then(() => props.history.goBack())
  }, [data, jsonGetter, oldId, props.history])

  const formItem = useCallback((name, label, props) => {
    return <FormItem label={label} name={name} value={data[name]} onChange={handleChange} {...props} />
  }, [data, handleChange])

  useEffect(() => {
    workoutTrainerListPromise({ type: 'option', ...ParamsForOption })
      .then(resp => setTrainers(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  useEffect(() => {
    if (oldId || !data.type) return

    getVideoResourceList({ type: data.type, page: 1, size: 1, ...ParamsForOption })
      .then(resp => handleChange({ id: 1 + send(resp, 'rows', ['at', 0], 'id') || 0 }))
  }, [data.type, handleChange, oldId])

  // const handleTypeChange = useCallback(async (event, selected) => {
  //   const _data = { type: selected && selected.value }

  //   if (!oldId && _data.type) {
  //     const resp = await getVideoResourceList({ type: _data.type, page: 1, size: 1 })
  //     _data.id = 1 + send(resp, 'rows', ['at', 0], 'id') || 0
  //   }

  //   handleChange(_data)
  // }, [handleChange, oldId])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label='Type' name='type'>
          <AutoCompleteNew
            value={data.type}
            options={VideoResourceTypeOptions}
            onChange={(event, selected) => {
              handleChange({ type: selected && selected.value })
            }}
          />
        </FormItem>

        { formItem('id', 'ID', { readonly: !!oldId }) }
        { formItem('name') }
        { formItem('category') }
        { formItem('video_url') }
        { formItem('continue_time') }

        <FormItem label='Trainer' name='trainer_id'>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.trainer_id}
            options={trainers}
            onChange={(event, selected) => {
              handleChange({ trainer_id: selected && selected.value })
            }}
          />
        </FormItem>

        {/* <FormItem label='Category' name='category'>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.category}
            options={VideoResourceCategoryOptions}
            onChange={(event, selected) => {
              handleChange({ category: selected && selected.value })
            }}
          />
        </FormItem> */}

        <AgeAndGenderSwitch data={data} setData={setData} />

        <FormItem name='is_pro'>
          <Switch name='is_pro' checked={Boolean(data.is_pro)}
            onChange={ e => handleChange({ is_pro: e.target.checked }) }
          />
        </FormItem>
        
        <FormItem name='is_exclusive'>
          <Switch name='is_exclusive' checked={Boolean(data.is_exclusive)}
            onChange={ e => handleChange({ is_exclusive: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'active'}>
          <Switch name='active' checked={Boolean(data.active)}
            onChange={ e => handleChange({ active: e.target.checked }) }
          />
        </FormItem>

        <S3UploaderWithInput label='Cover URL Horizontal (960x756)' name={'cover_url_horizontal'} filenames={data['cover_url_horizontal']}
          fileType={S3PreSignType.VideoCover} prefix='course/horizontal'
          setFilenames={filenames => handleChange({ cover_url_horizontal: filenames })} />

        <S3UploaderWithInput label='Cover URL Vertical (1005x1260)' name={'cover_url_vertical'} filenames={data['cover_url_vertical']}
          fileType={S3PreSignType.VideoCover} prefix='course/vertical'
          setFilenames={filenames => handleChange({ cover_url_vertical: filenames })} />

        <S3UploaderWithInput label='Cover Url Thumbnail (280x380)' name={'cover_url_thumbnail'} filenames={data['cover_url_thumbnail']}
          fileType={S3PreSignType.VideoCover} prefix='course/thumbnail'
          setFilenames={filenames => handleChange({ cover_url_thumbnail: filenames })} />

        <FormItem name='content_json'>
          <JSONEditor initValue={data.content_json || []} setJSONGetter={setJsonGetter} height='300px' />
        </FormItem>

        <Button
          className='save-btn'
          color='primary'
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}