import 'assets/scss/form2.scss'
import React, { useState, useEffect } from 'react'
import { Button, Card, Grid } from '@material-ui/core'
import GuidePdfUploader from 'components/GuidePdfUploader'
import { CourseTypes } from 'common/enum'
import { formatDateTimeToUTC, send } from 'helpers/utils'
import { upsertCoursePromise } from 'api/course'

export default function ProgramUpsertStep2(props) {
  const { data, hidden, currentStep, handleStepChange, history } = props
  const [step2BtnStatus, setStep2BtnStatus] = useState(false)
  const [guidePdfUrls, setGuidePdfUrls] = useState([])

  useEffect(() => {
    if (!data.guide_pdf_urls) return

    setGuidePdfUrls(data.guide_pdf_urls)
  }, [data.guide_pdf_urls])

  if (hidden) return <></>



  function handleSubmit () {
    data.type = send(CourseTypes.find(x => x.value === data.type), 'value')
    // TODO: fix it
    data.duration_unit = 'weeks'

    const payload = {
      ...data,
      enrollment_begin: formatDateTimeToUTC(data.enrollment_begin),
      enrollment_end: formatDateTimeToUTC(data.enrollment_end),
      launch_time: formatDateTimeToUTC(data.launch_time),
      cut_off_date: formatDateTimeToUTC(data.cut_off_date),
      guide_pdf_urls: guidePdfUrls.filter(g => !g.isDeleted),
    }

    return upsertCoursePromise(payload)
  }

  if (currentStep !== 'step2') return <></>

  return <div className='form-elem-container' >
    <Card className='form-card-content'>
      <GuidePdfUploader files={guidePdfUrls} isNutritionProgramType={false} setFiles={setGuidePdfUrls} type='ProgramGuidePdfUrl' />

      <br />

      <Grid container justify='center'>
        <Grid container spacing={2} style={{ width: '80%', maxWidth: '1000px', paddingBottom: '1em'}}>
          <Grid item xs={4}>
            <Button fullWidth size='large' color='secondary' type='submit' className='previous-btn' variant='contained' onClick={() => handleStepChange('step1')}>
              Previous
            </Button>
          </Grid>

          <Grid item xs={8}>
            <Button fullWidth className='submit-btn' color='primary' size='large' type='submit' variant='contained' disabled={step2BtnStatus}
              onClick={() => {
                setStep2BtnStatus(true)
                handleSubmit()
                  .then(() => props.history.goBack())
                  .finally(() => setStep2BtnStatus(false))
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  </div>
}