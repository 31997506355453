import React, { useState, useEffect } from 'react'
import { Card, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { ParamsForOption } from 'common/enum'
import { mealListPromise } from 'api/Meal'
import { MultipleAutoCompleteSelect } from 'components/Select'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, setData, addRequiredColumns } = props
  const [recipes, setRecipes] = useState([])

  // const courseWeekRecipes = useMemo(() => {
  //   return data.week_course_recipe_ids || (data.week_course_recipe || []).map(x => x.recipe_id)
  // }, [data.week_course_recipe_ids, data.week_course_recipe])

  const courseWeekFormItem = (label, name, isRequired) => {
    isRequired && addRequiredColumns(name)
    return <FormItem label={label} name={name} value={data[name]} isRequired={isRequired}
      onChange={map => setData({...data, ...map}) } />
  }

  useEffect(() => {
    mealListPromise({type: 'option', ...ParamsForOption}).then(res => {
      if (!res || !res.rows) return

      setRecipes(res.rows.map(row => ({ label: row.title, value: row.id })))
    })
  }, [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { courseWeekFormItem('Course Id', 'program_id', true) }
        { courseWeekFormItem('Week', 'week', true) }
        { courseWeekFormItem('Title', 'title', true) }
        { courseWeekFormItem('Description', 'description', true) }

        { courseWeekFormItem('Video Id', 'video_id', false) }
        
        {/* { courseWeekFormItem('Preview Video', 'preview_video_url') } */}

        {/* <S3UploaderWithInput label='Preview Video Cover Url (1080x600)' name={'preview_video_cover_url'} parentPath={ParentPath.enUS} filenames={data['preview_video_cover_url']} fileType={S3PreSignType.WeekCoursePreviewCover}
          setFilenames={filenames => setData({ ...data, preview_video_cover_url: filenames })} /> */}

        <FormItem label={'Recipes'} name={'active'}>
          <MultipleAutoCompleteSelect
            options={recipes}
            values={data.week_course_recipe_ids }
            onChange={(event, selected) => { setData({ ...data, week_course_recipe_ids: selected.map(s => s.value)}) }} />
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(data['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => setData({...data, active: e.target.checked}) }
          />
        </FormItem>
      </Card>
    </div>
  )
}