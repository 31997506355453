import _ from 'lodash'
import Table from './components/Table'
import { useEffectOnce } from 'react-use'
import Toolbar from './components/Toolbar'
import { useLocation } from 'react-router-dom'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { requestCourseWebsiteListPromise } from '../../../api/CourseWebsite'

const WorkoutResourceList = props => {
  const ref = useRef()
  const location = useLocation()
  const [params, setParams] = useState({ page: 1 })
  const [courseWebsite, setCourseWebsite] = useState({})

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    requestCourseWebsiteListPromise(too).then(resp => setCourseWebsite(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload) {
    handleParamsChange(payload, 500)
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar handleSearchChange={handleSearchChange} params={params} {...props} />
        <br />
        <Table
          {...props}
          data={courseWebsite}
          page={params.page}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default WorkoutResourceList
