import { getApiClient } from './ApiClient'

export const getWeekProgramList = (params) => {
  return getApiClient().get('/week-programs', { params }).then(resp => resp.data)
}

export const getWeekProgramDetail = (id) => {
  return getApiClient().get(`/week-programs/${id}`).then(resp => resp.data)
}

export const upsertWeekProgram = (...body) => {
  return getApiClient().post('/week-programs', { body }).then(resp => resp.data)
}
