import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid } from '@material-ui/core'

export default function Toolbar (props) {
  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={11}>&nbsp;</Grid>
        <Grid container item xs={1} justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/sku-feature-relations/create`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
