import React from 'react'

export default function DetailRow (props) {
  const { label, children } = props
  return (
    <div className={'detail-row'}>
      <span className="label">{label}</span>
      <span className="value">{children}</span>
    </div>
  )
}