import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import { assign, startCase } from 'lodash'
import { mealUpsertPromise } from 'api/Meal'
import { mealCategoryListPromise } from 'api/MealCategory'
import DirectionInput from './components/DirectionInput'
import NoteInput from './components/NoteInput'
import { isBlank } from 'common/usual'
import IngredientForm from './components/IngredientForm'
import ActiveSwitch from 'components/ActiveSwitch'
import { ParamsForOption } from 'common/enum'
import { workoutTrainerListPromise } from 'api/WorkoutTrainer'
import UploaderForSingleImage from 'components/UploaderForSingleImage'

const columnsNeedCheck = [
  { key: 'title' },
  { key: 'coverUrl', label: 'Cover Url' },
]

export default function MealUpsertForm (props) {
  const { mode, data } = props
  const [meal, setMeal] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [categories, setCategories] = useState([])
  const [trainers, setTrainers] = useState([])

  const disableBtn = useMemo(() => (
    btnStatus ||
      isBlank(meal.title)
  ), [btnStatus, meal.title])

  useEffect(() => {
    workoutTrainerListPromise({ type: 'option', ...ParamsForOption })
      .then(resp => setTrainers(resp.map(r => ({ label: `${r.name} (${r.id})`, value: r.id }))))
  }, [])

  useEffect(() => {
    setMeal(data)
  }, [data])
  
  useEffect(() => {
    async function fetchCategories () {
      const mealCategories = await mealCategoryListPromise({ type: 'option', ...ParamsForOption })
      setCategories(
        mealCategories.map(mc => ({
          label: mc.title,
          value: mc.id
        }))
      )
    }
    fetchCategories()
  }, [])

  function handleSubmit () {
    setBtnStatus(true)
    mealUpsertPromise(meal)
      .then(res => {
        props.history.goBack()
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setMeal(
      assign({}, meal, ...values)
    )
  }, [meal])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: meal
  }), [handleChange, meal])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title'} name={'title'} {...commonProps} />
        <FormItem label={'Description'} name={'description'} {...commonProps} />
        <FormItem label={'Serving Quantity'} name={'servingQuantity'} type={'number'} {...commonProps} />
        <FormItem label={'Serving Quantity Min'} name={'servingQuantityMin'} type={'number'} {...commonProps} />
        <FormItem label={'Serving Quantity Max'} name={'servingQuantityMax'} type={'number'} {...commonProps} />
        <FormItem label={'Serving Size'} name={'servingSize'} {...commonProps} type={'number'} />
        {/* <FormItem label={'Cover Url'} name={'coverUrl'} {...commonProps} />
        <FormItem label={'Cover Url Big'} name={'coverUrlBig'} {...commonProps} /> */}
        
        <FormItem label={'Vegetarian'} name={'vegetarian'}>
          <Switch
            checked={Boolean(meal.vegetarian)}
            onChange={e => {
              handleChange({ vegetarian: e.target.checked })
            }}
          />
        </FormItem>

        <FormItem label={'Vegan'} name={'vegan'}>
          <Switch
            checked={Boolean(meal.vegan)}
            onChange={e => {
              handleChange({ vegan: e.target.checked })
            }}
          />
        </FormItem>

        <FormItem label={'Pescatarian'} name={'pescatarian'}>
          <Switch
            checked={Boolean(meal.pescatarian)}
            onChange={e => {
              handleChange({ pescatarian: e.target.checked })
            }}
          />
        </FormItem>

        <FormItem label={''} name={'recipeCategoryId'}>
          <AutoCompleteNew
            options={categories}
            value={meal.recipeCategoryId}
            onChange={(event, selected) => {
              handleChange({recipeCategoryId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Trainer'} name={'trainer'}>
          <AutoCompleteNew
            options={trainers}
            value={meal.trainerId}
            onChange={(event, selected) => {
              handleChange({trainerId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Secondary Category'} name={'secondaryCategoryIds'}>
          <MultipleAutoCompleteSelect
            options={categories}
            values={meal.secondaryCategoryIds || []}
            onChange={(event, selected) => {
              handleChange({secondaryCategoryIds: selected.map(s => s.value)})
            }}
          />
        </FormItem>

        <IngredientForm data={meal.ingredients} handleChange={handleChange} />

        <DirectionInput data={meal.instructions} handleChange={handleChange} />

        <NoteInput data={meal.details} handleChange={handleChange} />

        <FormItem label={'Active'} name={'active'}>
          {/* <Switch
            checked={Boolean(meal.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          /> */}
          <ActiveSwitch mode={mode} body={meal} setBody={setMeal} isNeedCheck={true} columns={columnsNeedCheck} />
        </FormItem>

        <div style={{ width: '80%', margin: '0 auto', maxWidth: '1000px', marginTop: '10px' }}>
          {
            ['coverUrl', 'coverUrlBig'].map(key => <>
              <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                <span>{startCase(key)} (PNG, JPG) &nbsp;&nbsp;&nbsp; Image size: 1125x1125 px</span>
              </div>
              <UploaderForSingleImage keyName={key} isForceRename={true} filename={meal[key]} pathInFilename='sk/1125x1125' fileType='MealCover' handleChange={(key, val) => handleChange({ [key]: val })} />
            </>
            )
          }
        </div>
        

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(meal)}</div> */}
    </div>
  )
}