import _ from 'lodash'
import 'assets/scss/form2.scss'
import Context from 'common/context'
import SetupCard from './components/setup'
import { Button, Grid } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { upsertUserSocialBadgePromise, requestUserSocialBadgeListPromise } from 'api/SocialBadge'
import { ParamsForOption } from 'common/enum'

export default function ChallengeForm (props) {
  const columnsNotNull = []
  const { data, history } = props
  const { handleNotification } = Context._currentValue
  const [userSocialBadge, setUserSocialBadge] = useState({})

  useLayoutEffect(() => {
    if (!_.isEmpty(userSocialBadge)) return

    setUserSocialBadge(data)
  }, [userSocialBadge, data])

  const handleChange = useCallback((key, value, other) => {
    setUserSocialBadge({ ...userSocialBadge, [key]: value, ...other })
  }, [userSocialBadge])

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const persistUserSocialBadge = useCallback(async () => {
    if (!userSocialBadge.user_id) return handleNotification('error', 'User ID cannot be null')
    if (!userSocialBadge.social_badge_id) return handleNotification('error', 'Social Badge cannot be null')

    const userSocialBadges = await requestUserSocialBadgeListPromise({ user_id: userSocialBadge.user_id, social_badge_id: userSocialBadge.social_badge_id, size: 100, ...ParamsForOption })
    const theOne = userSocialBadges.rows.find(row => row.active && row.id !== userSocialBadge.id)
    if (theOne) return handleNotification('error', 'already exist')
    
    upsertUserSocialBadgePromise(userSocialBadge).then(goBack)
  }, [goBack, userSocialBadge])

  const saveClicked = useCallback(() => {
    persistUserSocialBadge()
  }, [persistUserSocialBadge])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!userSocialBadge[cname]) return true
  }, [userSocialBadge, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} User Social Badge
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={goBack}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SetupCard data={userSocialBadge} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

  </div>
}
