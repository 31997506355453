import React, { useCallback, useState } from 'react'
import { send } from 'helpers/utils'
import MyModal from 'components/MyModal'
import { Button, Grid, Input } from '@material-ui/core'
import { removeUserFromGroupPromise } from 'api/Product'


export default function Toolbar (props) {
  const { modalOpen, setModalOpen, modalData } = props
  const [removeUserIds, setRemoveUserIds] = useState('')

  const handleCancel = useCallback(() => {
    setModalOpen(false)
    setRemoveUserIds('')
  }, [setModalOpen, setRemoveUserIds])

  const handleSubmit = useCallback(() => {
    const userIds = removeUserIds.split(',').map(foo => +foo)
    if (userIds.length <= 0) return

    const removed = userIds.map(userId => ({ groupId: modalData.group_id, userId }))
    removeUserFromGroupPromise({ removed })
      .then(() => setModalOpen(false))

  }, [modalData.group_id, removeUserIds, setModalOpen])

  return <MyModal open={modalOpen} setOpen={setModalOpen}>
    <br />
    <Grid container spacing={2}>
      <Grid item xs={3} />
      <Grid container item xs={6}>
        <label>remove users from group: { send(modalData, 'group', 'name') }</label>
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={3} />
      <Grid container item xs={6}>
        <Input placeholder='1,2,3,...' fullWidth value={removeUserIds} onChange={event => setRemoveUserIds(send(event, 'target', 'value') || '')} />
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={3} />
      <Grid container item xs={2}>
        <Button variant='contained' component='label' color='secondary' size='small' fullWidth onClick={handleCancel}>
          cancel
        </Button>
      </Grid>
      <Grid container item xs={4}>
        <Button variant='contained' component='label' color='primary' size='small' fullWidth onClick={handleSubmit} disabled={!removeUserIds}>
          submit
        </Button>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  </MyModal>
}
