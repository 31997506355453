import { Button, Card } from '@material-ui/core'
import { userReportDeletePromise, userReportDetailPromise } from 'api/User'
import { UserReportStatus, UserReportType, UserReportTypeText } from 'common/enum'
import { send } from 'helpers/utils'
import React, { useCallback, useLayoutEffect, useState } from 'react'

export default function (props) {
  const { id } = props.match.params
  const [detail, setDetail] = useState({})
  const [feed, setFeed] = useState()
  const [comment, setComment] = useState()
  const [attachments, setAttachments] = useState()

  const handleDeleteReport = useCallback((action) => {
    userReportDeletePromise(detail.id, action)
    setDetail({ ...detail, status: action })
  }, [detail])

  useLayoutEffect(() => {
    userReportDetailPromise(id).then(resp => {
      setDetail(resp)
      if (resp.type === UserReportType.SocialFeed) {
        setFeed(resp.target)
        setAttachments(send(resp.target, 'social_feed_attachments'))
      }
      if (resp.type === UserReportType.SocialComment) {
        setComment(resp.target)
        setFeed(send(resp.target, 'post'))
      }
    })
  }, [id])

  return (
    <div className={'root'}>
      <Card className={'user-content'}>
        <DetailRow label='ID' value={detail.id} />
        <DetailRow label='Type' value={UserReportTypeText[detail.type]} />
        <DetailRow label='Reporter ID' value={<a href={`/user/${detail.user_id}`}>{detail.user_id}</a>} />
        <DetailRow label='Poster ID' value={<a href={`/user/${detail.complainted_user_id}`}>{detail.complainted_user_id}</a>} />

        <div className='info-item'>
          <span className='label'>Status</span>
          <span className='value'>
            { UserReportStatus[detail.status] } 
            {
              detail.status === 0 && <>
                (<Button onClick={() => { handleDeleteReport(2) }} variant="text">Ignore</Button>
                |
                <Button onClick={() => { handleDeleteReport(1) }} variant="text">Block</Button>)
              </>
            }
          </span>
        </div>
        <DetailRow label='Comment' value={detail.comment} />
      </Card>

      { FeedPostCard(feed) }
      { FeedAttachmentCard(attachments) }
      { FeedCommentCard(comment) }

      <br />
    </div>
  )
}

function FeedPostCard (feedPost) {
  if (!feedPost) return <></>

  return <>
    <br />
    <p>Feed Post</p>
    <Card className={'user-content'}>
      <DetailRow label='ID' value={feedPost.id} />
      <DetailRow label='User ID' value={<a href={`/user/${feedPost.user_id}`}>{feedPost.user_id}</a>} />
      <DetailRow label='Description' value={feedPost.description} />
    </Card>
  </>
}

function FeedAttachmentCard (attachments) {
  if (!attachments) return <></>

  return <>
    <br />
    <p>Feed Attachment</p>

    { attachments.filter(a => a.type === 22).map(attachment =>
      <Card className={'user-content'}>
        <DetailRow label='ID' value={attachment.id} />
        <div className='info-item' style={{ height: '200px' }}>
          <a href={send(attachment.target, 'url')} rel="noopener noreferrer" target='_blank'>
            <img height='150px' width='200px' alt={send(attachment.target, 'rawUrl')} src={send(attachment.target, 'url')} />
          </a>
        </div>
      </Card>
    ) }
    
  </>
}

function FeedCommentCard (feedComment) {
  if (!feedComment) return <></>
  return <>
    <br />
    <p>Feed Comment</p>
    <Card className={'user-content'}>
      <DetailRow label='ID' value={feedComment.id} />
      <DetailRow label='User ID' value={<a href={`/user/${feedComment.user_id}`}>{feedComment.user_id}</a>} />
      <DetailRow label='Description' value={feedComment.description} />
    </Card>
  </>
}

function DetailRow (props) {
  const { label, value } = props
  return <div className='info-item'>
    <span className='label'>{ label }</span>
    <span className='value line-break-anywhere'>{ value }</span>
  </div>
}
