import { isBlank } from 'common/usual'
import FormItem from 'components/FormItem'
import { upsertWeekProgram } from 'api/ProgramWeek'
import { Button, Card, Switch } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, setData, history } = props
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)


  const handleSubmit = useCallback(() => {
    setIsBtnDisabled(true)

    upsertWeekProgram(data)
      .then(() => history.goBack())
      .catch(() => setIsBtnDisabled(false))
  }, [data, history])

  useEffect(() => {
    setIsBtnDisabled(isBlank(data.program_id) || isBlank(data.week))
  }, [data.program_id, data.week])

  const courseWeekFormItem = (label, name, isRequired, props) => {
    return <FormItem label={label} name={name} value={data[name]} isRequired={isRequired} 
      onChange={map => setData({...data, ...map}) } {...props} />
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { courseWeekFormItem('Program Id', 'program_id', true) }
        
        <FormItem label={'Week*'}>
          <NumberAutoComplete name='week' from={1} to={100} value={data.week} handleSelectChange={week => setData({...data, week})} />
        </FormItem>

        { courseWeekFormItem('Title', 'title', false) }
        { courseWeekFormItem('Description', 'description', false, { multiline: true }) }

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(data['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => setData({...data, active: e.target.checked}) }
          />
        </FormItem>

        <br />

        <Button className='submit-btn' color='primary' size='large' type='submit' variant='contained' style={{ width: '75%' }}
          disabled={isBtnDisabled}
          onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}