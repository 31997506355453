import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { functionSwitchDetailPromise, functionSwitchUpdatePromise } from 'api/FunctionSwitch'
import { isBlank } from 'common/usual'

export default function FunctionSwitchUpdate (props) {
  const [functionSwitch, setFunctionSwitch] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const {
    match: { params: { id } }
  } = props

  const disableBtn = useMemo(() => (
    btnStatus ||
      isBlank(functionSwitch.name)
  ), [btnStatus, functionSwitch.name])

  const getFunctionSwitchDetail = useCallback(async () => {
    setFunctionSwitch(await functionSwitchDetailPromise(id))
  }, [id])

  useEffect(() => {
    getFunctionSwitchDetail()
  }, [getFunctionSwitchDetail])

  useEffect(() => {
    setFunctionSwitch(functionSwitch)
  }, [functionSwitch])

  function handleSubmit () {
    setBtnStatus(true)
    functionSwitchUpdatePromise(id, functionSwitch)
      .then(res => {
        props.history.goBack()
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setFunctionSwitch(
      assign({}, functionSwitch, ...values)
    )
  }, [functionSwitch])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: functionSwitch
  }), [handleChange, functionSwitch])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Name'} name={'name'} {...commonProps} />
        <FormItem label={'Description'} name={'description'} {...commonProps} />
        <FormItem label={'Value'} name={'value'} {...commonProps} />
        <FormItem label={'Status'} name={'status'} {...commonProps} />
        <FormItem label={'Weight'} name={'weight'} {...commonProps} />
        
        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(functionSwitch.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(category)}</div> */}
    </div>
  )
}