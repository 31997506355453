import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const workoutCategoryListPromise = (params) => {
  return getApiClient()
    .get('/workout-categories', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutCategoryDetailPromise = (id) => {
  return getApiClient()
    .get(`/workout-category/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutCategoryShowingTypeListPromise = () => {
  return getApiClient()
    .get('/workout-category/showing-types', { params: ParamsForOption })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutCategoryUpsertPromise = body => {
  return getApiClient()
    .post('/workout-category/upsert', { body })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}