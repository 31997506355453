import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { SearchInput } from 'components'
import './index.scss'

const PurchaseToolbar = props => {
  const { className, params, onSearchChange, ...rest } = props

  return (
    <div {...rest} className={clsx('tool-bar', className)}>
      <div className={'row'}>
        <SearchInput
          value={params.userId}
          className={'search-input'}
          placeholder="Search by user ID"
          onChange={e => {
            onSearchChange({ userId: e.target.value })
          }}
        />
      </div>
    </div>
  )
}

PurchaseToolbar.propTypes = {
  className: PropTypes.string,
}

export default PurchaseToolbar
