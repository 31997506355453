import { getApiClient } from './ApiClient'

export const workoutTrendingListPromise = params => {
  return getApiClient()
    .get('/workout-trendings', {
      body: params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutTrendingTypeListPromise = params => {
  return getApiClient()
    .get('/workout-trendings/types', { params })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutTrendingDetailPromise = id => {
  return getApiClient()
    .get(`/workout-trendings/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutTrendingUpsertPromise = params => {
  return getApiClient()
    .post('/workout-trendings/upsert', {
      body: params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
