import _ from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { functionSwitchListPromise } from 'api/FunctionSwitch'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

const MealCategoryTable = props => {
  const ref = useRef()
  const location = useLocation()
  const [data, setData] = useState([])
  const [params, setParams] = useState({})

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    functionSwitchListPromise(too).then(resp => setData(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Card>
          <CardContent className={'table-content'}>
            <PerfectScrollbar>
              <div className={'inner'}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Updated At</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.rows && data.rows.map(functionSwitch => (
                      <TableRow className={'table-row'} key={functionSwitch.id} hover>
                        <TableCell>{functionSwitch.id}</TableCell>
                        <TableCell>{functionSwitch.name}</TableCell>
                        <TableCell>{functionSwitch.status }</TableCell>
                        <TableCell>{functionSwitch.weight }</TableCell>
                        <TableCell>{functionSwitch.active ? 'TRUE' : 'FALSE'}</TableCell>
                        <TableCell>{formatDateTime(functionSwitch.updatedAt)}</TableCell>
                        <TableCell>{formatDateTime(functionSwitch.createdAt)}</TableCell>
                        <TableCell>
                          <EditBtn history={props.history} path={`/function-switches/edit/${functionSwitch.id}`} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className='flex-right'>
            <TablePagination
              component="div"
              count={data.count || 0}
              onChangePage={handlePageChange}
              page={params.page-1}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
            />
          </CardActions>
        </Card>
      </div>
    </div>
  )
}

export default MealCategoryTable