import AddBtn from 'components/AddBtn'
import React, { useCallback } from 'react'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  const handleChange = useCallback(event => {
    handleSearchChange({ [event.target.name]: event.target.value })
  }, [handleSearchChange])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input name='user_id' value={params.user_id} placeholder='User ID' className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={2}>
          <Input name='course_name' value={params.course_name} placeholder='Course Name' className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={6} />
        <Grid container item xs={2} justify='flex-end'>
          <AddBtn
            color="primary"
            type="submit"
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/user-courses/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
