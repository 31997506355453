import './index.scss'
import React from 'react'
import PropTypes from 'prop-types'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

const PromoToolbar = props => {
  const { params, handleSearchChange } = props

  return (
    <Grid container item xs={12} justify={'flex-end'} >
      <Grid item xs={6}>
        <Input placeholder='Code' value={params.code} onChange={(event) => handleSearchChange(event.target.value) } />
      </Grid>

      <Grid container item xs={6} justify={'flex-end'}>
        <AddBtn
          className={'add-btn'}
          color="primary"
          type="submit"
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/promo/add`)
          }}
          variant="contained"
        > Add </AddBtn>
        &nbsp;
        <AddBtn
          className={'add-btn'}
          color="primary"
          type="submit"
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/promo/add-in-bulk`)
          }}
          variant="contained"
        > Add in Bulk </AddBtn>
      </Grid>
    </Grid>
  )
}

PromoToolbar.propTypes = {
  className: PropTypes.string,
}

export default PromoToolbar
