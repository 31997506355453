import React from 'react'
import { List, Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

export default function CheckboxList(props) {
  const { list, checked, setChecked } = props

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  return (
    <List sx={{ width: '100%', maxWidth: 360 }}>
      {list.map(value => {
        const labelId = `checkbox-list-label-${value}`

        return (
          <ListItem key={value} onClick={handleToggle(value)} style={{ height: '30px' }}>
            <ListItemIcon>
              <Checkbox edge='start' checked={checked.includes(value)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value} />
          </ListItem>
        )
      })}
    </List>
  )
}