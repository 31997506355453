import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

export default function WorkoutCategoryToolbar (props) {
  const { params, handleSearchChange } = props


  return (
    <div className={'tool-bar'}>
      <Grid container item xs={12} justify={'flex-end'} >
        <Grid item xs={11}>
          <Input value={params.title} placeholder='Title Single' onChange={(event) => { handleSearchChange({title: event.target.value}) }} />
        </Grid>

        <Grid container item xs={1} justify={'flex-end'}>
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/meal-ingredient/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
