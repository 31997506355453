import { Grid } from '@material-ui/core'
import DetailCard from 'components/DetailCard'
import { booleanElement, send, tryParseJson } from 'helpers/utils'
import { flexCardEventDetailPromise } from 'api/FlexNetwork'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { formatDateTime } from 'common/usual'
import ReactJson from 'react-json-view'

const FlexCardEventDetail = props => {
  const { match: { params: { id } } } = props
  const [flexCardEvent, setFlexCardEvent] = useState({})

  const getFlexCardEventDetail = useCallback(async () => {
    setFlexCardEvent(await flexCardEventDetailPromise(id))
  }, [id])

  useEffect(() => {
    getFlexCardEventDetail()
  }, [getFlexCardEventDetail])

  const setupData = useMemo(() => {
    const items = [
      { label: 'user_id', value: flexCardEvent.user_id },
      { label: 'user_name', value: send(flexCardEvent.user, 'name') },
      { label: 'merchant_id', value: flexCardEvent.merchant_id },
      { label: 'studio_id', value: flexCardEvent.studio_id },
      { label: 'type', value: flexCardEvent.type },
      { label: 'successful', value: booleanElement(flexCardEvent.successful) },
      { label: 'response_code', value: flexCardEvent.response_code },
      { label: 'card_id', value: flexCardEvent.card_id },
      { label: 'created_at', value: formatDateTime(flexCardEvent.created_at) },
      { label: 'updated_at', value: formatDateTime(flexCardEvent.updated_at) },
    ]

    return [{ items }]
  }, [flexCardEvent])

  return (
    <div className='detail-page'>
      <div className='root'>
        <Grid container spacing={4} >
          <Grid item xs={12}>
            <DetailCard title='Details' isTwoColumns={true} blocks={setupData} />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='Body' isTwoColumns={false}>
              <div style={{ padding: '10px 20px' }}>
                <ReactJson src={tryParseJson(flexCardEvent.body, {})} />
              </div>
            </DetailCard>
          </Grid>
        </Grid>
        
      </div>
    </div>
  )
}

export default FlexCardEventDetail
