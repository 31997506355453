import React, { useState, useEffect, useCallback, useRef } from 'react'
import { deleteThemeRelationRoute, getThemeRelationRoute } from 'api/Theme'
import PromoTable from './table'
import PromoToolbar from './toolbar'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import _ from 'lodash'

export default function ThemeRelationList (props) {
  const ref = useRef()
  const location = useLocation()
  const { type } = props.match.params
  const [data, setData] = useState({})
  const [params, setParams] = useState({ type, page: 1 })

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    getThemeRelationRoute({...too, type}).then(resp => setData(resp))
  }, [location.search, type])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  const handleDelete = useCallback((id) => {
    if (!window.confirm('are you sure?')) return

    deleteThemeRelationRoute(id).then(() => window.location.reload())
  }, [])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <PromoToolbar params={params} handleSearchChange={handleParamsChange} {...props} />
        <br />
        <PromoTable data={data} page={params.page} onPageChange={handlePageChange} onDelete={handleDelete} {...props} />
      </div>
    </div>
  )
}
