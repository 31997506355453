import React, { useMemo, useState } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { S3Path, S3PreSignType } from 'common/enum'

export default function WorkoutCategoryUpsertForm (props) {
  const [requiredColumns, setRequiredColumns] = useState([])
  const { data, setData, changeCurrentStep } = props

  function handleSubmit () {
    changeCurrentStep(2)
  }

  const courseWebsiteFormItem = (label, name, isRequired) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])

    return <S3UploaderWithInput
      label={label} name={name} filenames={data[name]} isUseable={!!data.id}
      prefix={`${S3Path}/web/assets/course/${data.id}`} filenameMask={/[^/]+$/} subpath={`${data.id}`} fileType={S3PreSignType.WebAssetsCourse}
      setFilenames={filenames => setData({ ...data, [name]: filenames })} />
  }

  const disableBtn = useMemo(() => {
    return Array.from(requiredColumns).map(column => isBlank(data[column])).includes(true)
  }, [data, requiredColumns])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'ID'} name={'id'} value={data['id']} placeholder={'input ID first'} isRequired={true} onChange={map => setData({...data, ...map}) } />
        { courseWebsiteFormItem('Logo', 'logo', false) }
        { courseWebsiteFormItem('Thumbnail', 'thumbnail', false) }
        { courseWebsiteFormItem('Hero Desktop', 'hero_desktop', false) }
        { courseWebsiteFormItem('App Hero Mobile', 'hero_mobile', false) }
        { courseWebsiteFormItem('Section 1', 'section_1', false) }
        { courseWebsiteFormItem('Section 2-1', 'section_2_1', false) }
        { courseWebsiteFormItem('Section 2-2', 'section_2_2', false) }
        { courseWebsiteFormItem('Section 3-1', 'section_3_1', false) }
        { courseWebsiteFormItem('Section 3-2', 'section_3_2', false) }
        { courseWebsiteFormItem('Trainer Desktop', 'trainer_desktop', false) }
        { courseWebsiteFormItem('Trainer Mobile', 'trainer_mobile', false) }
        { courseWebsiteFormItem('Testimonial 1', 'testimonial_1', false) }
        { courseWebsiteFormItem('Testimonial 2', 'testimonial_2', false) }
        { courseWebsiteFormItem('Testimonial 3', 'testimonial_3', false) }
        { courseWebsiteFormItem('Testimonial 4', 'testimonial_4', false) }
        { courseWebsiteFormItem('Preview Video Portrait', 'preview_video_portrait') }
        { courseWebsiteFormItem('Preview Video Landscape', 'preview_video_landscape') }
        { courseWebsiteFormItem('Trainer Video 1 Portrait', 'trainer_video_1_portrait') }
        { courseWebsiteFormItem('Trainer Video 1 Landscap', 'trainer_video_1_landscape') }
        { courseWebsiteFormItem('Trainer Video 2 Portrait', 'trainer_video_2_portrait') }
        { courseWebsiteFormItem('Trainer Video 2 Landscap', 'trainer_video_2_landscape') }
        { courseWebsiteFormItem('Trainer Video 3 Portrait', 'trainer_video_3_portrait') }
        { courseWebsiteFormItem('Trainer Video 3 Landscap', 'trainer_video_3_landscape') }
        { courseWebsiteFormItem('Trainer Video 1 Cover', 'trainer_video_1_cover') }
        { courseWebsiteFormItem('Trainer Video 2 Cover', 'trainer_video_2_cover') }
        { courseWebsiteFormItem('Trainer Video 3 Cover', 'trainer_video_3_cover') }

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(data['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => setData({...data, active: e.target.checked}) }
          />
        </FormItem>
        
        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          NEXT
        </Button>
      </Card>
    </div>
  )
}