import AddBtn from 'components/AddBtn'
import React, { useCallback } from 'react'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  const handleChange = useCallback(event => {
    handleSearchChange({ [event.target.name]: event.target.value })
  }, [handleSearchChange])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input name='user_id' value={params.user_id} placeholder='User ID' className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={2}>
          <Input name='program_id' value={params.program_id} placeholder='Program ID' className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2} container justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/user-programs/add`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
