import superagent from 'superagent'
import formatUrl from './formatUrl'
import { isFunction } from 'lodash'

const methods = ['get', 'post', 'put', 'patch', 'delete']

const localStorageKey = 'fiton-admin:local:token'
const localStorageUserKey = 'fiton-admin:local:admin-id'
const localStorageUserNameKey = 'fiton-admin:local:admin-name'

export function getToken() {
  return localStorage.getItem(localStorageKey)
}

export function setToken(token) {
  return localStorage.setItem(localStorageKey, token)
}

export function getAdminId() {
  return localStorage.getItem(localStorageUserKey)
}

export function setAdminId(id) {
  return localStorage.setItem(localStorageUserKey, id)
}

export function setAdminName(name) {
  return localStorage.setItem(localStorageUserNameKey, name)
}

export function getAdminName() {
  return localStorage.getItem(localStorageUserNameKey)
}

export function clearUserInfo() {
  setToken('')
  setAdminId('')
  setAdminName('')
}

export default class ApiClient {
  constructor () {
    methods.forEach(method => {
      this[method] = (path, { params, body, header } = {}) => {
        this.toggleProcess(this.needProcess && true)

        const request = superagent[method](formatUrl(path))
        if (params) {
          request.query(params)
        }

        if (header) {
          for (let i in header) {
            request.set(i, header[i])
          }
        }

        request.set('Authorization', `Bearer ${getToken()}`)

        if (body) {
          request.send(body)
        }

        return new Promise((resolve, reject) => {
          request.end((err, { body, text } = {}) => {
            let msgType = 'success'
            if (err) {
              msgType = 'error'
              reject(err)
              if (body && body.code === 401 && path.indexOf('/login') !== -1) {
                clearUserInfo()
                this.toggleProcess(false)
                return (window.location.href = '/login')
              }
            }
            resolve(body || text || err)

            if (body && body.msg && this.handleNotification) {
              this.handleNotification(msgType, body.msg)
            }
            this.toggleProcess(false)
          })
        })
      }
    })
  }
  
  toggleProcess (open) {
    isFunction(this.toggleProgress) && this.toggleProgress(open)
  }

  empty() {}
}

let _apiClient
export const getApiClient = (props = {}) => {
  const { needProcess = true } = props

  if (!_apiClient) {
    _apiClient = new ApiClient()
  }

  _apiClient.needProcess = needProcess

  return _apiClient
}
