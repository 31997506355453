import { getApiClient } from './ApiClient'

export const adminListPromise = (params) => {
  return getApiClient()
    .get('/admins', { params })
    .then(resp => resp.data)
}

export const adminDetailPromise = (id) => {
  return getApiClient()
    .get(`/admins/${id}`)
    .then(resp => resp.data)
}

export const addAdminPromise = (body) => {
  return getApiClient()
    .post('/admins', { body })
    .then(resp => resp.data)
}

export const editAdminPromise = (body) => {
  return getApiClient()
    .put(`/admins/edit/${body.id}`, { body })
    .then(resp => resp.data)
}
