import './index.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'

const PostAndCommentToolbar = props => {
  const { params, handleSearchChange } = props
  const [search, setSearch] = useState(params)
  const options = [{ label: 'All', value: 1 }, { label: 'Post', value: 2 }, { label: 'Comment', value: 3 }]

  return (
    <Grid container item xs={12} justify={'flex-end'} >
      <Grid item xs={2}>
        <Input placeholder='ID' style={{ width: '90%' }} onChange={(event) => { setSearch({ ...search, id: event.target.value || undefined }) }} />
      </Grid>

      <Grid item xs={2}>
        <Input placeholder='User ID' style={{ width: '90%' }} onChange={(event) => { setSearch({ ...search, userId: event.target.value || undefined }) }} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew
          label={''}
          options={options}
          disableUnderline={false}
          value={search.range}
          onChange={(event, selected) => {
            setSearch({ ...search, range: selected && selected.value })
          }}
        />
      </Grid>

      <Grid container item xs={1} justify={'flex-end'}>
        <Button
          color="primary"
          type="submit"
          onClick={e => {
            handleSearchChange(search)
          }}
          disabled={(!search.id && !search.userId) || !search.range}
          variant="contained"
        > Search </Button>
      </Grid>

      <Grid xs={5}></Grid>
    </Grid>
  )
}

PostAndCommentToolbar.propTypes = {
  className: PropTypes.string,
}

export default PostAndCommentToolbar
