import _ from 'lodash'
import moment from 'moment-timezone'

export function isBlank(value) {
  return (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value)
}

export function isBlankV2 (value) {
  if (_.isNumber(value) || _.isBoolean(value))
    return false

  return _.isNull(value)
      || _.isUndefined(value)
      || _.isEmpty(value)
}

export function formatDate (time) {
  if (time) {
    return moment(time).format('DD/MM/YYYY')
  }
}

export function formatDateTime (time) {
  if (time) {
    return moment(time).tz('Etc/UTC').format('DD/MM/YYYY HH:mm:ss')
  }
}

export function hasKeys (obj, ...keys) {
  const res = keys.map(key => (
    _.has(obj, key)  
  ))

  return _.every(res)
}

export function booleanString (val) {
  return val ? 'TRUE' : 'FALSE'
}