import uuid from 'uuid'
import { Button, Grid } from '@material-ui/core'
import { getS3ObjectListPromise } from 'api/helper'
import { ValueToUploadObjectTypeOptions } from 'common/enum'
import React, { useCallback, useMemo, useState } from 'react'
import Context from 'common/context'
import MyModal from 'components/MyModal'
import MyCheckboxList from 'components/MyCheckBoxList'
import _ from 'lodash'

export default function S3Object (props) {
  const { type, createConvertVideoJob } = props
  const { handleNotification } = Context._currentValue
  const [s3Objects, setS3Objects] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedObjects, setSelectedObjects] = useState([])

  const sourceInfo = useMemo(() => ValueToUploadObjectTypeOptions[type], [type])

  const getS3ObjectList = useCallback(() => {
    if (!sourceInfo) return handleNotification('error', 'Invalid type')

    getS3ObjectListPromise({ bucket: sourceInfo.bucket, path: sourceInfo.value }).then(resp => {
      let list = (resp && resp.Contents) || []
      list = list.filter(x => x.Size > 0)
      list = _.orderBy(list, 'LastModified', 'desc')
      setS3Objects(list.map(l => l.Key))
    })
  }, [handleNotification, sourceInfo])

  const onChooseClick = useCallback(() => {
    setS3Objects(null)
    getS3ObjectList()
    setModalOpen(true)
  }, [getS3ObjectList])

  const onUpload = useCallback(async () => {
    const payload = []
    for (const obj of selectedObjects) {
      const filename = (/([^/]+)\.[^/]+$/.exec(obj))[1]
      if (!filename) {
        handleNotification('error', `invalid filename ${obj}`)
        continue
      }

      payload.push({ rawFilename: obj, filename, uuid: uuid(), fromBucket: sourceInfo.bucket, fromPath: obj })
    }
    
    await createConvertVideoJob(payload)
    setSelectedObjects([])
  }, [createConvertVideoJob, handleNotification, selectedObjects, sourceInfo.bucket])

  return (
    <div>
      <Grid container>
        <Grid container item xs={4}>
          <Button variant='contained' component='label' color='primary' onClick={onChooseClick}>
            Choose
          </Button>
        </Grid>

        <Grid container xs={8}>
          <Button onClick={onUpload} disabled={_.isEmpty(selectedObjects)} variant='contained' color='primary'> UPLOAD </Button>
        </Grid>

        <Grid container xs={12}>&nbsp;</Grid>

        <Grid container xs={12}>
          { selectedObjects.join(', ') }
        </Grid>
      </Grid>

      <MyModal open={modalOpen} setOpen={setModalOpen}>
        {
          !s3Objects
            ? <div>loading...</div>
            : <MyCheckboxList list={s3Objects} checked={selectedObjects} setChecked={setSelectedObjects} />
        }
      </MyModal>
    </div>
  )
   
}
