import _ from 'lodash'
// https://www.pivotaltracker.com/n/projects/2189044/stories/179992148
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Grid, Input } from '@material-ui/core'
import { videoUpload } from 'api/Video'
import Context from 'common/context'

export default function FunctionSwitchUpdate (props) {
  const { handleNotification } = Context._currentValue
  const [workoutWithMusicIds, setWorkoutWithMusicIds] = useState('')
  const [workoutWithoutMusicIds, setWorkoutWithoutMusicIds] = useState('')
  const [type, setType] = useState(1)
  const [filesNotFound, setFilesNotFound] = useState([])
  const [musicVideos, setMusicVideos] = useState([])
  const [nomusicVideos, setNomusicVideos] = useState([])

  useMemo(() => {
    if (!musicVideos) return
    setWorkoutWithMusicIds(musicVideos.map(x => x.name.match(/\d+/)[0]).join(','))
  }, [musicVideos])

  useMemo(() => {
    if (!nomusicVideos) return
    setWorkoutWithoutMusicIds(nomusicVideos.map(x => x.name.match(/\d+/)[0]).join(','))
  }, [nomusicVideos])

  const upload = useCallback(async () => {
    const result= await videoUpload({
      type,
      workoutWithMusicIds: _.compact(workoutWithMusicIds.split(',')),
      workoutWithoutMusicIds: _.compact(workoutWithoutMusicIds.split(',')),
    })

    setFilesNotFound(result.filesNotFound || [])
    if (result.status === 0) handleNotification('success', 'success')
  }, [handleNotification, type, workoutWithMusicIds, workoutWithoutMusicIds])

  const isBtnDisabled = useMemo(() => {
    return !type || (_.isEmpty(workoutWithMusicIds) && _.isEmpty(workoutWithoutMusicIds))
  }, [type, workoutWithMusicIds, workoutWithoutMusicIds])

  function truncateBlankSpace (event) {
    return event.target.value.replace(/[^\d|,]/g, '')
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container spacing={4}>
          {
            filesNotFound.length > 0 &&
              <Grid item xs={12}>
                <div className={'color-red'}>files not found: {filesNotFound}</div>
              </Grid>
          }

          <Grid item xs={12} className='text-align-left'>
            <h3>Convert Videos</h3>
          </Grid>

          <Grid item xs={6}>
            <Input name={'ids'} value={workoutWithMusicIds} onChange={(event) => setWorkoutWithMusicIds(truncateBlankSpace(event))} placeholder={'workout id with music (e.g. 1,2,3)'} className={'full-width'} />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <Input name={'ids'} value={workoutWithoutMusicIds} onChange={(event) => setWorkoutWithoutMusicIds(truncateBlankSpace(event))} placeholder={'workout id without music (e.g. 1,2,3)'} className={'full-width'} />
          </Grid>
          <Grid item xs={6}></Grid>

          {/* <Grid item xs={3} justify={'flex-end'} >
            <AutoCompleteNew value={type} options={UploadVideoTypes} onChange={(event, selected) => setType((selected || {}).value)} inputLeft={true} disableUnderline={false} />
          </Grid> */}
          <Grid item xs={12} className='text-align-left'>
            <Button color='primary' variant='contained' onClick={upload} disabled={isBtnDisabled}> CONVERT </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}