import React, { useCallback } from 'react'
import { Box, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: 200,
  maxHeight: 600,
  overflow: 'auto',
  border: '2px solid #E5E5E5',
  backgroundColor: 'white',
};

const fullModalStyle = {
  width: '100%',
  height: '100vh',
  overflow: 'auto',
  position: 'absolute',
  border: '2px solid #E5E5E5',
  backgroundColor: 'black',
}

const deleteIconStyle = {
  top: '10px',
  width: '5%',
  height: '5%',
  right: '10px',
  color: 'grey',
  minWidth: '20px',
  minHeight: '20px',
  cursor: 'pointer',
  position: 'absolute',
  zIndex: '99999',
}

export default function S3Modal (props) {
  const { open, setOpen, onClose, children, isFullScreen } = props

  const closeAction = useCallback((event) => {
    setOpen(false)
    onClose && onClose()

    event.stopPropagation()
  }, [onClose, setOpen])

  return <Modal open={open} onClose={closeAction} aria-labelledby='parent-modal-title' aria-describedby='parent-modal-description'>
    <Box style={isFullScreen ? fullModalStyle : modalStyle}>
      <CloseIcon style={deleteIconStyle} onClick={closeAction} />
      { children }
    </Box>
  </Modal>
}
