import './box.scss'
import AddBtn from 'components/AddBtn'
import { BooleanOptions2, LocaleMatchingOptions, OSTypeOptions, ParamsForOption } from 'common/enum'
import { Button, Grid, TextField } from '@material-ui/core'
import { featureBannerPlacementListPromise } from 'api/FeatureBanner'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import { getLocaleCountryOptions } from 'helpers/utils'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import QS from 'qs'

export default function WorkoutCategoryToolbar (props) {
  const targetOptions = [{ label: 'Null', value: undefined }, ...BooleanOptions2]
  const osTypeOptions = OSTypeOptions.map(option => ({ ...option, value: `[${option.value}]` }))
  const { params, changeParams } = props
  const [placements, setPlacements] = useState([])
  const [localeCountries, setLocaleCountries] = useState([])
  const [hideMoreFilters, setHideMoreFilters] = useState(true)
  const location = useLocation()

  useLayoutEffect(() => {
    getLocaleCountryOptions().then(setLocaleCountries)
  }, [])

  useEffectOnce(() => {
    const keys = ['name', 'placement_id', 'active', 'target_pro', 'page', 'size']
    const foo = QS.myParse(location.search)
    const too = [..._.keys(foo), ..._.keys(foo.filters)]
    setHideMoreFilters(!_.difference(too, keys).length)
  })

  useLayoutEffect(() => {
    featureBannerPlacementListPromise(ParamsForOption)
      .then(resp => setPlacements(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  const handleChange = useCallback((key, value, delay) => {
    changeParams({ ...params, [key]: value }, delay)
  }, [params, changeParams])

  const handleFilterChange = useCallback((key, value) => {
    changeParams({ ...params, filters: { ...params.filters, [key]: value === null ? undefined : value } })
  }, [params, changeParams])

  const handleMoreFiltersClicked = useCallback(() => {
    setHideMoreFilters(!hideMoreFilters)
  }, [hideMoreFilters])

  const handleClearFilters = useCallback(() => {
    changeParams({ name: params.name, filters: {} })
  }, [params.name, changeParams])

  const currentFilters = useMemo(() => {
    return params.filters || {}
  }, [params.filters])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='Search' size='small' className='background-color' value={params.name} onChange={event => handleChange('name', event.target.value, 500)} />
        </Grid>
        <Grid container item xs={8} justify={'flex-end'}>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push('/feature-banner/campaigns/add')
            }}
            variant='contained'
          >
            Add Campaign
          </AddBtn>
        </Grid>

        <Grid item xs={6}>
          <MultipleAutoCompleteSelect label='Placement' values={params.placement_id} options={placements} inputLeft={true} variant='outlined' multiple className='background-color'
            onChange={(event, selected) => handleChange('placement_id', selected.length > 0 ? selected.map(s => s.value) : null)}
          />
        </Grid>
        <Grid item xs={1}>
          <AutoCompleteNew label='Active' disableUnderline={false} value={currentFilters.active} options={targetOptions} inputLeft={true} variant='outlined' className='background-color'
            onChange={(event, selected) => handleFilterChange('active', selected && selected.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew label='PRO' disableUnderline={false} name='target_pro' value={currentFilters.target_pro} options={targetOptions} inputLeft={true} variant='outlined' className='background-color'
            onChange={(event, selected) => handleFilterChange('target_pro', selected && selected.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth={true} variant='outlined' onClick={handleMoreFiltersClicked}>
            { hideMoreFilters ? 'More Filters' : 'LESS FILTERS' }
          </Button>
        </Grid>
        <Grid item xs={1} className='cursor-pointer' onClick={handleClearFilters} style={{color: '#A4A4A4', display: 'flex', alignItems: 'center'}}>
          Clear Filters
        </Grid>

        { !hideMoreFilters && <>
            <Grid item xs={2}>
              <AutoCompleteNew label='Notification Permission' disableUnderline={false} value={currentFilters.target_notification_permission} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_notification_permission', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Program Reminder' disableUnderline={false} value={currentFilters.target_program_reminder} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_program_reminder', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Daily Fix' disableUnderline={false} value={currentFilters.target_daily_fix} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_daily_fix', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Phone Verification' disableUnderline={false} value={currentFilters.target_phone_verification} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_phone_verification', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Meal Onboarding' disableUnderline={false} value={currentFilters.target_meal_onboarding} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_meal_onboarding', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Cast' disableUnderline={false} value={currentFilters.target_cast} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_cast', selected && selected.value)} />
            </Grid>
            <Grid item xs={1}>
              <AutoCompleteNew label='Download' disableUnderline={false} value={currentFilters.target_download} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_download', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Premium Music' disableUnderline={false} value={currentFilters.target_premium_music} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_premium_music', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Fitness Tracker' disableUnderline={false} value={currentFilters.target_fitness_tracker} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_fitness_tracker', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Health' disableUnderline={false} value={currentFilters.target_health} options={targetOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_health', selected && selected.value)} />
            </Grid>
            {/* <Grid item xs={2}>
              <AutoCompleteNew label='Min App Version' disableUnderline={false} value={currentFilters.active} options={targetOptions} inputLeft={true} variant='outlined' className='filter'
                onChange={(event, selected) => handleFilterChange('target_pro', selected && selected.value)} />
            </Grid> */}
            <Grid item xs={2}>
              <AutoCompleteNew label='Country' disableUnderline={false} value={params.target_country} options={localeCountries} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleChange('target_country', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew label='Locale Language' disableUnderline={false} value={params.target_locale_language} options={LocaleMatchingOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleChange('target_locale_language', selected && selected.value)} />
            </Grid>
            <Grid item xs={2}>
              <AutoCompleteNew
                label='Platforms' value={currentFilters.target_platform} disableUnderline={false}
                options={osTypeOptions} inputLeft={true} variant='outlined' className='filter background-color'
                onChange={(event, selected) => handleFilterChange('target_platform', selected && selected.value)} />
            </Grid>
            <Grid item xs={1}>
              <TextField label='Users' fullWidth variant='outlined' placeholder='10,20' size='small' value={params.target_users} onChange={event => handleChange('target_users', event.target.value, 500)} className='background-color' />
            </Grid>
          </>
        }
      </Grid>
    </div>
  )
}
