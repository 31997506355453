import { getApiClient } from './ApiClient'
import { ParamsForOption } from 'common/enum'

export const productListPromise = () => {
  return getApiClient()
    .get('/products', { params: { size: 1000, ...ParamsForOption } })
    .then(resp => resp.data.rows)
}

export const productsPromise = (params) => {
  return getApiClient().get('/products', { params }).then(resp => resp.data)
}

export const productDetailPromise = (id) => {
  return getApiClient().get(`/products/${id}`).then(resp => resp.data)
}

export const productUpsertPromise = (body) => {
  return getApiClient().post('/products', { body }).then(resp => resp.data)
}

// f4w
export const getPurchaseGroupListPromise = (params) => {
  return getApiClient().get('/purchase-groups', { params }).then(resp => resp.data)
}

export const getPurchaseGroupDetailPromise = (id) => {
  return getApiClient().get(`/purchase-groups/${id}`).then(resp => resp.data)
}

export const upsertPurchaseGroupPromise = (body) => {
  return getApiClient().post('/purchase-group', { body }).then(resp => resp.data)
}

export const addUserToGroupPromise = (body) => {
  return getApiClient().post('/group/user-add', { body }).then(resp => resp.data)
}

export const removeUserFromGroupPromise = (body) => {
  return getApiClient().post('/company/user-remove', { body }).then(resp => resp.data)
}

export const addSeatsToGroupPromise = (body) => {
  return getApiClient().post('/company/add-seats', { body }).then(resp => resp.data)
}

export const getFeatureSetList = params => {
  return getApiClient().get('/feature-sets', { params }).then(resp => resp.data)
}

export const getFeatureSetDetailRoute = id => {
  return getApiClient().get(`/feature-sets/${id}`).then(resp => resp.data)
}

export const upsertFeatureSetRoute = body => {
  return getApiClient().post('/feature-sets', { body }).then(resp => resp.data)
}

export const getSkuCategoryList = params => {
  return getApiClient().get('/product-sku-categories', { params }).then(resp => resp.data)
}

export const getSkuCategoryDetailRoute = id => {
  return getApiClient().get(`/product-sku-categories/${id}`).then(resp => resp.data)
}

export const upsertSkuCategoryRoute = body => {
  return getApiClient().post('/product-sku-categories', { body }).then(resp => resp.data)
}

export const getSkuFeatureRelationList = params => {
  return getApiClient().get('/sku-feature-relations', { params }).then(resp => resp.data)
}

export const getSkuFeatureRelationDetailRoute = id => {
  return getApiClient().get(`/sku-feature-relations/${id}`).then(resp => resp.data)
}

export const upsertSkuFeatureRelationRoute = body => {
  return getApiClient().post('/sku-feature-relations', { body }).then(resp => resp.data)
}
