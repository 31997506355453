import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const getProgramWorkoutList = (params) => {
  return getApiClient().get('/program-workouts', { params }).then(resp => resp.data)
}

export const getProgramWorkoutDetail = (id) => {
  return getApiClient().get(`/program-workouts/${id}`).then(resp => resp.data)
}

export const upsertProgramWorkout = (body) => {
  return getApiClient().post('/program-workouts', { body }).then(resp => resp.data)
}

export const getProgramOptions = async () => {
  return getApiClient().get('/program', { params: { size: 10000, ...ParamsForOption } }).then(resp => resp.data.rows)
}
