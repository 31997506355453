import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealIngredientUnitUpsertPromise } from 'api/MealIngredientUnit'
import { isBlank } from 'common/usual'

export default function MealIngredientUnitUpsertForm (props) {
  const { data } = props
  const [unit, setUnit] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus
      || isBlank(unit.titleSingle)
      || isBlank(unit.titlePlural)
  ), [btnStatus, unit])

  useEffect(() => {
    setUnit(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    mealIngredientUnitUpsertPromise(unit)
      .then(res => {
        if (!unit.id) {
          props.history.push('/meal-ingredient/units')
        }
        unit.id = res.id
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setUnit(
      assign({}, unit, ...values)
    )
  }, [unit])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: unit
  }), [handleChange, unit])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title Single'} name={'titleSingle'} {...commonProps} />
        <FormItem label={'Title Plural'} name={'titlePlural'} {...commonProps} />
        <FormItem label={'Level'} name={'level'} type={'number'} {...commonProps} />

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(unit.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}