import Table from './Table'
import Toolbar from './Toolbar'
import { getSkuCategoryList } from 'api/Product'
import React, { useState, useLayoutEffect } from 'react'

const FilterList = props => {
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    getSkuCategoryList().then(setData)
  }, [])


  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar {...props} />
        <br />
        <Table data={data} {...props} />
      </div>
    </div>
  )
}

export default FilterList
