import React from 'react'
import { Card, CardActions, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import EditBtn from 'components/EditBtn'

const FeatureBannerContentList = props => {
  const { contents, query, setQuery } = props

  function handlePageChange (event, page) {
    setQuery({ ...query, page: page + 1 })
  }

  return (
    <Card className={'form-container promo-form'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Sequence</TableCell>
            <TableCell>Campaign ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Subtitle</TableCell>
            <TableCell>CTA Text</TableCell>
            <TableCell>CTA Deep Link</TableCell>
            <TableCell>Text Color</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {contents && contents.rows && contents.rows.map(content => (
            <TableRow className={'table-row'} key={content.id} hover>
              <TableCell>{content.id}</TableCell>
              <TableCell>{content.name}</TableCell>
              <TableCell>{content.sequence}</TableCell>
              <TableCell>{content.campaign_id}</TableCell>
              <TableCell>{content.title}</TableCell>
              <TableCell>{content.subtitle}</TableCell>
              <TableCell>{content.ctaText}</TableCell>
              <TableCell>{content.ctaDeeplink}</TableCell>
              <TableCell>{content.textColor}</TableCell>
              <TableCell>{`${content.active}`}</TableCell>
              <TableCell>
                <EditBtn module='bannerContent' history={props.history} path={`/feature-banner/contents/edit/${content.id}`} />
              </TableCell>
            </TableRow>
          ))}
        </ TableBody>
      </ Table>
      <CardActions className={'flex-right'}>
        <TablePagination
          component='div'
          count={contents.count || 0}
          onChangePage={handlePageChange}
          page={query.page-1}
          rowsPerPage={query.size}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default FeatureBannerContentList
