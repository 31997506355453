import _ from 'lodash'
import ContentTable from './table'
import ContentToolBar from './toolbar'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import { flexCardEventListPromise } from 'api/FlexNetwork'
import React, { useState, useEffect, useCallback, useRef } from 'react'

const FlexCardEventList = props => {
  const ref = useRef()
  const location = useLocation()
  const [events, setEvents] = useState({})
  const [params, setParams] = useState({ page: 1, filters: {} })

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    flexCardEventListPromise(too).then(resp => setEvents(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  return (
    <div className={'root'}>
      <ContentToolBar {...props} params={params} onParamsChange={handleParamsChange} />
      <div className={'content'}>
        <ContentTable {...props} data={events} page={params.page} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default FlexCardEventList
