import path from 'path'
import CampaignForm from './form'
import { copyS3Object } from 'api/helper'
import { randString, send } from 'helpers/utils'
import { featureBannerDetailPromise } from 'api/FeatureBanner'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { ImageLanguageOptions, S3Bucket, S3FilePath } from 'common/enum'

export default function WorkoutDailyfixAdd (props) {
  const [data, setData] = useState({})
  const id = send(props.location, 'state', 'id')

  const handleAdd = useCallback(() => {
    setData({
      weight: 100,
      target_pro: null,
      target_notification_permission: null,
      target_phone_verification: null,
      target_cast: null,
      target_program_reminder: null,
      target_meal_onboarding: null,
      target_download: null,
      target_daily_fix: null,
      target_premium_music: null,
      target_fitness_tracker: null,
      target_submitted_company_info: null,
      target_health: null,
    })
  }, [])

  const handleDuplicate = useCallback(async () => {
    const campaign = await featureBannerDetailPromise(id)
    if (!campaign.contents) return

    const blankColumns = ['campaign_id', 'created_at', 'updated_at']
    const bgColumns = ['background', 'background_en', 'background_without_language']
    const tasks = campaign.contents.map(content => {
      if (!content.background) return []

      const oldName = content.background
      const newName = `${randString()}${path.extname(oldName)}`

      content.id = randString()
      blankColumns.forEach(column => content[column] = null)
      bgColumns.forEach(column => content[column] = content[column].replace(oldName, newName))
      
      return ImageLanguageOptions.map(({ value }) => {
        const pathWithLanguage = S3FilePath.FeatureBannerContentBackground(value)
        return {
          params: { ACL: 'public-read' },
          from: `${S3Bucket}/${pathWithLanguage}/${oldName}`,
          to: { bucket: S3Bucket, key: `${pathWithLanguage}/${newName}` },
        }
      })
    })

    await copyS3Object(tasks.flat())
    setData({ ...campaign, name: `${campaign.name} - copy`, id: null, created_at: null, updated_at: null, active: false })
  }, [id])

  useLayoutEffect(() => {
    id ? handleDuplicate() : handleAdd()
  }, [handleAdd, handleDuplicate, id])

  return (
    <CampaignForm originalCampaign={data} {...props} />
  )
}
