import { getApiClient } from './ApiClient'

export const mealIngredientUnitListPromise = (params) => {
  return getApiClient()
    .get('/meal-ingredient/units', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealIngredientUnitDetailPromise = (id) => {
  return getApiClient()
    .get(`/meal-ingredient/units/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealIngredientUnitUpsertPromise = (body) => {
  return getApiClient()
    .post('/meal-ingredient/units/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
