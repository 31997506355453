import { Button, Grid } from '@material-ui/core'
import { getUploadUrlPromise } from 'api/course'
import { uploadFileToS3 } from 'api/helper'
import { S3PreSignType } from 'common/enum'
import React, { useCallback, useEffect, useState } from 'react'
import Context from 'common/context'

// { width, height, filename, fromPath, rawFilename }
export default function LocaleFile (props) {
  const { createConvertVideoJob } = props
  const { handleNotification } = Context._currentValue
  const [videoInfo, setVideoInfo] = useState({})
  const [inputFile, setInputFile] = useState(null)
  const [filename, setFilename] = useState({ name: '' })
  const [filenameAlert, setFilenameAlert] = useState('')
  const [codecAlert, setCodecAlert] = useState('')
  const [disableUploadBtn, setDisableUploadBtn] = useState(true)

  const handleFileUpload = useCallback(async () => {
    setDisableUploadBtn(true)
    const rawFilename = `${filename.name}.${filename.extension}`

    const uploadUrl = await getUploadUrlPromise({ filenames: [rawFilename], type: S3PreSignType.RawCourse, isPublic: false })
    if (!uploadUrl || !uploadUrl[0])
      return handleNotification('error', 'retrieve AWS presigned url failed')

    // toggleProgress(true)
    const uploadResult = await uploadFileToS3(uploadUrl[0].url, inputFile)
    // toggleProgress(false)
    if (uploadResult.status !== 200)
      return handleNotification('error', 'upload to AWS failed')

    await createConvertVideoJob([{ ...videoInfo, filename: filename.name, rawFilename }])
  }, [createConvertVideoJob, filename, handleNotification, inputFile, videoInfo])

  function handleInputChange (event) {
    const file = event.target.files[0]
    if (!file || !file.name) return handleNotification('error', 'invalid file')

    const [, name, extension] = file.name.match(/(.*)\.([^.]+)$/) || []
    if (!name) return handleNotification('error', 'invalid filename')
    setFilename({ name, extension })

    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    video.addEventListener("loadedmetadata", function () {
      setVideoInfo({ height: Math.trunc(this.videoHeight), width: Math.trunc(this.videoWidth), duration: this.duration, size: file.size })
      setInputFile(file)
    })
  }

  // function handleFileNameChange (event) {
  //   filename.name = event.target.value
  //   setFilename({ ...filename, name: event.target.value })
  // }

  useEffect(() => {
    const regexp = /^[\w|.|]+$/
    const isValidName = regexp.test(filename.name)
    const isLoaded = videoInfo.width !== 0 && videoInfo.height !== 0
    setFilenameAlert(isValidName ? '' : '• Filename only allows alphanumerics and underscores')
    setCodecAlert(isLoaded ? '' : '• Loading media data failed, please check if your browser supports this codec via https://caniuse.com/{codec}. (e.g. only safari support https://caniuse.com/hevc on 7/5/2021)')
    setDisableUploadBtn(!inputFile || ! isValidName || !isLoaded)
  }, [filename, inputFile, setDisableUploadBtn, videoInfo])

  return (
    <div>
    <Grid container spacing={0} style={{ color: 'red', 'textAlign': 'left' }}>
          <Grid container item xs={12}>
            <span> {filenameAlert} </span>
          </Grid>
          <Grid container item xs={12}>
            <span> {codecAlert} </span>
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={2} >
          <Grid container item xs={10}>
            <Grid container item xs={4}>
              <Button variant="contained" component="label" color="primary">
                Choose
                <input type="file" hidden onChange={ handleInputChange } />
              </Button>
            </Grid>

            <Grid container item xs={6}>
              <Button onClick={handleFileUpload} disabled={disableUploadBtn} variant="contained" color="primary"> UPLOAD </Button>
            </Grid>
          </Grid>
          
        </Grid>

        <br />

        <Grid container spacing={0} >
          <Grid container item xs={6}>
            {/* <Input fullWidth readOnly={true} value={filename.name} placeholder={'Filename'} onChange={ handleFileNameChange } /> */}
            { filename.name }
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={0} >
          <Grid container item xs={2}>
            <p>Height: { videoInfo.height }</p>
          </Grid>

          <Grid container item xs={2}>
            <p>Width: { videoInfo.width }</p>
          </Grid>

          <Grid container item xs={2}>
            <p>Duration: { videoInfo.duration }</p>
          </Grid>

          <Grid container item xs={2}>
            <p>Size: { videoInfo.size }</p>
          </Grid>
        </Grid>
      </div>
  )
}
