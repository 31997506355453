import React, { useCallback } from 'react'
import { ChallengeFromCustomToOfficialColumnsNeedBeNull, ChallengeTypeOptions } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import { Card, Grid } from '@material-ui/core'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { CourseSocialGroupAutoComplete} from '../../../../concerns/courseOptions'

export default function (props) {
  const { isEdit, data, handleChange } = props

  const handlePrivateChange = useCallback((key, val) => {
    if (val && (data.feature) ) return window.alert('Featured & Private cannot be True at the same time!')
    handleChange(key, val)
  }, [data.feature, handleChange])

  const handleFeatureChange = useCallback((key, val) => {
    if (val && (data.private || data.group_challenge) ) return window.alert('Featured & Private cannot be True at the same time!')
    const other = (isEdit && val && window.confirm('Do you want to change it from a user created challenge to a FitOn challenge'))
      ? ChallengeFromCustomToOfficialColumnsNeedBeNull
      : {}
    handleChange(key, val, other)
  }, [data.group_challenge, data.private, handleChange, isEdit])

  const handleGroupChange = useCallback((key, val) => {
    if (val && data.feature ) return window.alert('Featured & GroupChallenge cannot be True at the same time!')
    handleChange(key, val)
  }, [data.feature, handleChange])


  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>DETAILS</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Duration ({data.unit})</label>
          <NumberAutoComplete name='duration' from={1} to={100} value={data.duration} inputLeft={true} placeholder={data.unit}
            handleSelectChange={value => handleChange('duration', value) } variant='outlined' />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Type</label>
          <AutoCompleteNew options={ChallengeTypeOptions} value={data.challenge_type} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => handleChange('challenge_type', selected && selected.value)}
          />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Sort</label>
          <NumberAutoComplete name='sort' from={1} to={100} value={data.sort} inputLeft={true} handleSelectChange={value => handleChange('sort', value) } variant='outlined' />
        </div>
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={4} className='bottom-dashed'>
        <BooleanToggleButtonGroup
          label='Private' name='private' withoutNull={true} value={data.private} onChange={handlePrivateChange}
        />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <BooleanToggleButtonGroup
          label='Featured' name='feature' withoutNull={true} value={data.feature} onChange={handleFeatureChange}
        />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={4} className='bottom-dashed'>
        <BooleanToggleButtonGroup
          label='Group Challenge' name='group_challenge' withoutNull={true} value={data.group_challenge} onChange={handleGroupChange}
        />
      </Grid>
      {
        data.group_challenge === true &&
        <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Group</label>
           <CourseSocialGroupAutoComplete name='social_group_id' value={data.social_group_id} handleSelectChange={(social_group_id) => { handleChange('social_group_id', social_group_id ) }} />
        </div>
      </Grid>
      }
    </Grid>
  </Card>
}
