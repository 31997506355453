import './index.scss'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { userReportListPromise } from 'api/User'
import ReportTable from './components/UserReportTable'
import ReportToolbar from './components/UserReportToolbar'
import QS from 'qs'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'

const ReportList = props => {
  const ref = useRef()
  const location = useLocation()
  const [reports, setReports] = useState([])
  const [params, setParams] = useState({})

  // start
  // load data
  useEffect(() => {
    const foo = QS.myParse(location.search, false)
    setParams({ page: 1, type: 6, ...foo })
    userReportListPromise(foo).then(resp => setReports(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = QS.myParse(location.search, false)
    const too = _.pickBy({ type: 6, ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${QS.stringify(too)}`), delay)
  }, [location.search, props.history])

  const handleTabChange = useCallback(payload => {
    if (ref.current) clearTimeout(ref.current)
    props.history.push(`?${QS.stringify(payload)}`)
  }, [props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload, delay=500) {
    handleParamsChange(payload, delay)
  }
  // end

  const handleOrderChange = useCallback((orderBy) => {
    handleParamsChange(orderBy)
  }, [handleParamsChange])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <ReportToolbar params={params} onTabChange={handleTabChange} handleSearchChange={handleSearchChange} />

        <ReportTable params={params} data={reports || []} page={params.page} onPageChange={handlePageChange} onOrderByChange={handleOrderChange} tabValue={params.type} {...props} />
      </div>
    </div>
  )
}

export default ReportList