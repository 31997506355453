import './index.css'
import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { RequestBlacklistType as types } from 'common/enum'

export default function WorkoutCategoryToolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <AutoCompleteNew
            label={'Type'}
            options={types}
            disableUnderline={false}
            value={params.type}
            onChange={(event, selected)=>{
              handleSearchChange({ type: (selected || {}).value })
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Block Value' className={'block-value'} onChange={(event) => { handleSearchChange({blockValue: event.target.value}, 500) }} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2} >
          <AddBtn
            color="primary"
            type="submit"
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/request-blacklist/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
