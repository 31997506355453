import React, { useCallback, useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { Button } from '@material-ui/core'
import Context from 'common/context'
import { getUploadUrlPromise } from 'api/course'
import { cloudFrontInvalidatePromise, compressImage, uploadFileToS3 } from 'api/helper'
import { getImageInfo, splitWidthAndHeight } from 'helpers/utils'

const Dropzone = props => {
  const { filenames, setFilenames, multiple, fileType, parentPath, subpath, filenameMask, isPublic=true, isUseable=true, afterUpload, metadata, sizeConstraint, dropzoneText, needCompress=true } = props
  const { handleNotification } = Context._currentValue
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleChange = useCallback(async targets => {
    if (!targets || targets.length <= 0) return
    const _files = [...targets]

    if (sizeConstraint) {
      const [width, height] = splitWidthAndHeight(sizeConstraint)

      for (const _file of _files) {
        const img = await getImageInfo(_file)

        if (`${img.width}` !== width || `${img.height}` !== height)
          return handleNotification('error', `invalid image size, expected: ${sizeConstraint}`)
      }
    }

    setFilenames(_files.map(file => file.name).join(','))

    setFiles(_files)
  }, [handleNotification, setFilenames, sizeConstraint])

  const upload = useCallback(async function () {
    if (filenames.includes(' ')) {
      setFiles([])
      setFilenames('')
      return handleNotification('error', 'Filename cannot contain spaces')
    }

    let _filenames = filenames.split(',')
    if (_filenames.length !== files.length) return handleNotification('error', 'bad files')

    if (filenameMask) {
      try {
        _filenames = _filenames.map(fn => /[^/]+$/.exec(fn)[0])
      } catch (err) {
        return handleNotification('error', err)
      }
    }

    try {
      const presignedUrls = await getUploadUrlPromise({ filenames: _filenames, type: fileType, parentPath, subpath, isPublic, metadata })
      
      if (!presignedUrls || presignedUrls.length !== files.length)
        return handleNotification('error', 'retrieve AWS presigned url failed')

      const failedUpload = []
      await Promise.all(presignedUrls.map(async (presignedUrl, index) => {
        const res = await uploadFileToS3(presignedUrl.url, files[index])
        compressImage(presignedUrl)
        if (res.status !== 200) failedUpload.push(filenames[index])
      }))

      cloudFrontInvalidatePromise(presignedUrls.map(foo => `/${foo.key}`))

      if (failedUpload.length > 0) return handleNotification('error', `upload failed: ${failedUpload.join(',')}`)

      handleNotification('success', 'file uploaded successfully ')
    } catch (err) {
      handleNotification('error', err.message)
    } finally {
      afterUpload && afterUpload()
    }
  }, [afterUpload, fileType, filenameMask, filenames, files, handleNotification, isPublic, metadata, parentPath, setFilenames, subpath])

  const handleSave = useCallback(async () => {
    if (files.length <= 0) return
    setOpen(false)
    await upload()
    setFiles([])
  }, [files.length, upload])

  return <div>
    <Button size='small' variant='outlined' disabled={!isUseable} onClick={() => setOpen(true)}>Upload</Button>

    <DropzoneDialog
      open={open}
      showAlerts={false}
      onSave={handleSave}
      showPreviews={false}
      clearOnUnmount={true}
      onClose={handleClose}
      onChange={handleChange}
      initialFiles={[]}
      showPreviewsInDropzone={true}
      filesLimit={multiple ? 10 : 1}
      maxFileSize={30000000000}
      dropzoneText={dropzoneText || 'drag image here or upload from computer'}
    />
  </div>
}

export default Dropzone
