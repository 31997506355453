import React, { useMemo, useState, useLayoutEffect } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { getProgramListPromise } from 'api/program'
import { AutoCompleteNew } from 'components/Select'
import { addUserToProgramPromise } from 'api/UserProgram'
import { ParamsForOption } from 'common/enum'

export default function ProgramTableUpsertForm (props) {
  const { history } = props
  const [userProgram, setUserProgram] = useState({})
  const [programs, setPrograms] = useState([])
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => {
    return btnStatus ||
      !(userProgram.user_id && userProgram.program_id && userProgram.start_at)
  }, [btnStatus, userProgram])

  useLayoutEffect(() => {
    getProgramListPromise({ size: 10000, ...ParamsForOption }).then(resp => {
      setPrograms(
        resp.data.rows.map(row => ({ label: row.name, value: row.id }))
      )
    })
  }, [])

  function handleSubmit () {
    setBtnStatus(true)
    addUserToProgramPromise(userProgram)
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name='user_id' value={userProgram.user_id} {...props}
          onChange={map => { setUserProgram({ ...userProgram, ...map }) } }
        />

        <FormItem
          label='Start At' name='start_at' value={userProgram.start_at} placeholder='YYYY-MM-DD HH:mm:ss' {...props}
          onChange={map => { setUserProgram({ ...userProgram, ...map }) } }
        />

        <FormItem label={'Program'}>
          <AutoCompleteNew value={userProgram.program_id}
            options={programs}
            onChange={(event, selected) => {
              setUserProgram({ ...userProgram, program_id: selected && selected.value })
            }}
          />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}