import React from 'react'
import Group from './group'
import Member from './member'

export default function Detail (props) {
  const { id } = props.match.params

  return <div className={'root'}>
    <div className={'content'}>
      <Group groupId={id} />
      <br />
      <Member groupId={id} />
    </div>
  </div>
}
