import { Card, CardActions, CardContent, TableBody, TableCell, TableHead, TablePagination, TableRow, Table } from '@material-ui/core'
import { groupMembersPromise } from 'api/Business'
import { formatDateTime } from 'common/usual'
import { boolStr } from 'helpers/utils'
import React, { useCallback, useEffect, useState } from 'react'

export default function Member (props) {
  const [data, setData] = useState({})
  const [params, setParams] = useState({ page: 1 })

  const onPageChange = useCallback((event, page) => {
    setParams({ page: page + 1 })
  }, [])

  useEffect(() => {
    if (!props.groupId) return

    groupMembersPromise({ ...params, groupId: props.groupId }).then(resp => setData(resp))
  }, [params, props.groupId])

  return <Card>
    <CardContent className={'table-content'}>
        <div className={'inner'}>
          <Table>
            <TableHead style={{ tableLayout: 'fixed' }}>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Field1</TableCell>
                <TableCell>Field2</TableCell>
                <TableCell>Field3</TableCell>
                <TableCell>Field4</TableCell>
                <TableCell>Verified</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {((data && data.rows) || []).map(record => (
                  <TableRow key={record.id} hover>
                    <TableCell>{record.user_id}</TableCell>
                    <TableCell>{record.email}</TableCell>
                    <TableCell>{record.field1}</TableCell>
                    <TableCell>{record.field2}</TableCell>
                    <TableCell>{record.field3}</TableCell>
                    <TableCell>{record.field4}</TableCell>
                    <TableCell>{boolStr(record.verified)}</TableCell>
                    <TableCell>{formatDateTime(record.created_at)}</TableCell>
                    <TableCell>{boolStr(record.active)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
    </CardContent>
    <CardActions className={'flex-right'}>
      <TablePagination
        component="div"
        count={(data && data.count) || 0}
        onChangePage={onPageChange}
        page={params.page - 1}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </CardActions>
  </Card>
}
