import React, { useCallback } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardActions, CardContent, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { formatDateTime } from 'common/usual'

export default function (props) {
  const { data, params, handleParamsChange } = props

  const handlePageChange = useCallback((event, page) => {
    handleParamsChange({ userPurchasePage: page + 1 })
  }, [handleParamsChange])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>User Virtual Card Purchase</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content'>
      <Card className='full-width'>
        <CardContent className={'table-content'}>
          <PerfectScrollbar>
            <div className={'inner'}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Studio ID</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Updated At</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data && data.rows && data.rows.map(row => {
                    return <TableRow className={'table-row cursor-pointer'} key={row.id} hover>
                      <TableCell>{ row.id }</TableCell>
                      <TableCell>{ row.user_id }</TableCell>
                      <TableCell>{ row.studio_id }</TableCell>
                      <TableCell>{ row.total_amount }</TableCell>
                      <TableCell>{ row.currency }</TableCell>
                      <TableCell>{ row.status }</TableCell>
                      <TableCell>{ formatDateTime(row.created_at) }</TableCell>
                      <TableCell>{ formatDateTime(row.updated_at) }</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={'flex-right'}>
          <TablePagination
            component="div"
            count={data.count || 0}
            onChangePage={handlePageChange}
            page={params.userPurchasePage - 1}
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
      </Card>
    </Grid>
  </Card>
}
