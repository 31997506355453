import React, { useCallback } from 'react'
import ChallengeToolbar from './challenge'
import UserToolbar from './user'
import PhotoToolBar from './photo'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import TabPanel from './_tabPanel'

const UserReportToolbar = props => {
  const { params, onTabChange, handleSearchChange } = props

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  
  function handleTabChange (event, newValue) {
    if (params.type === newValue) return

    onTabChange({ type: newValue, page: 1 }, 0)
  }

  const handleChange = useCallback(event => {
    const { name, value } = event.target
    handleSearchChange({ [name]: value })
  }, [handleSearchChange])

  return (
    <>
      <AppBar position="static">
        <Tabs value={params.type} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="User" value={6} {...a11yProps(6)} />
          <Tab label="Photo Wall" value={1} {...a11yProps(1)} />
          <Tab label="Challenge" value={3} {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <TabPanel value={params.type} index={6}>
        <UserToolbar params={params} onChange={handleChange} onSearchChange={handleSearchChange} />
      </TabPanel>
      <TabPanel value={params.type} index={1}>
        <PhotoToolBar  params={params} onChange={handleChange} />
      </TabPanel>
      <TabPanel value={params.type} index={3}>
        <ChallengeToolbar  params={params} onChange={handleChange} />
      </TabPanel>
    </>
  )
}

export default UserReportToolbar
