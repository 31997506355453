import { getApiClient } from './ApiClient'

export const logListPromise = (params) => {
  return getApiClient()
    .get('/logs', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const logDetailPromise = (id) => {
  return getApiClient()
    .get(`/logs/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const logOptionListPromise = (params) => {
  return getApiClient()
    .get('/log/options', { params })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
