import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealCategoryUpsertPromise } from 'api/MealCategory'
import { isBlank } from 'common/usual'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { S3PreSignType } from 'common/enum'
import ActiveSwitch from 'components/ActiveSwitch'

const columnsNeedCheck = [
  { key: 'title' },
  { key: 'deeplink' },
  { key: 'coverUrl' },
  { key: 'sort' },
]

export default function MealCategoryUpsertForm (props) {
  const { mode, data } = props
  const [category, setCategory] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus ||
      isBlank(category.title)
  ), [btnStatus, category.title])

  useEffect(() => {
    setCategory(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    mealCategoryUpsertPromise(category)
      .then(res => {
        if (!category.id) {
          props.history.push('/meal-categories')
        }
        category.id = res.id
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setCategory(
      assign({}, category, ...values)
    )
  }, [category])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: category
  }), [handleChange, category])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title'} name={'title'} {...commonProps} />
        <FormItem label={'Deeplink'} name={'deeplink'} {...commonProps} />
        {/* <FormItem label={'Cover Url'} name={'coverUrl'} placeholder={'e.g. ReceipeCategory4.jpg'} {...commonProps} /> */}

        <S3UploaderWithInput label='Cover URL (1005x570)' name='coverUrl' prefix='jsh/1005x570'
          filenames={category.coverUrl} fileType={S3PreSignType.MealCategoryCover}
          setFilenames={filenames => handleChange({ coverUrl: filenames })} />

        <FormItem label={'Sort'} name={'sort'} {...commonProps} />
        
        {/* <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(category.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem> */}

        <FormItem name={'active'}>
          <ActiveSwitch mode={mode} body={category} setBody={setCategory} isNeedCheck={true} columns={columnsNeedCheck} />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(category)}</div> */}
    </div>
  )
}