import './index.scss'
import _ from 'lodash'
import moment from 'moment-timezone'
import MyModal from 'components/MyModal'
import React, { useMemo, useState } from 'react' 

export default function ImagePreviewRow(props) {
  const { srcEn, imageName, language } = props
  const [modalOpen, setModalOpen] = useState(false)

  const src = useMemo(() => {
    const foo = _.replace(srcEn, 'en_us', language)
    return `${foo}?t=${moment().unix()}`
  }, [language, srcEn])

  return <div className='full-width image-preview-content'>
    <div className='preview-image'>
      <img alt={imageName} src={src} className='cursor-pointer' style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
        onClick={() => setModalOpen(true)} />
    </div>

    <div className='full-width'>
      <div>
        <span className='chip background-color-grey font-bold'>{language}</span>
      </div>
      <div>{imageName}</div>
    </div>

    <MyModal open={modalOpen} setOpen={setModalOpen} isFullScreen={true}>
      <img className='image-position' alt={imageName} src={src} />
    </MyModal>
  </div>
}