import React, { useState, useEffect } from 'react'
import MealIngredientCategoryForm from './_form'
import { mealIngredientCategoryDetailPromise } from 'api/MealIngredientCategory'

export default function MealIngredientCategoryEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealIngredientCategory () {
      setData(
        await mealIngredientCategoryDetailPromise(id)
      )
    }
    fetchMealIngredientCategory()
  }, [id])
  return (
    <MealIngredientCategoryForm data={data} />
  )
}