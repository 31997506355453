import { Add } from '@material-ui/icons'
import EditBtn from 'components/EditBtn'
import AddSeatModal from './AddSeatModal'
import { formatDateTime } from 'common/usual'
import RemoveUserModal from './RemoveUserModal'
import RemoveUserIcon from 'icons/remove_user.svg'
import React, { useCallback, useState } from 'react'
import { Card, CardActions, CardContent, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'

export default function (props) {
  const { data, params, onPageChange } = props
  const [modalData, setModalData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [addSeatModalOpen, setAddSeatModalOpen] = useState(false)

  const openModal = useCallback((record) => {
    setModalData(record)
    setModalOpen(true)
  }, [])

  const openAddSeatModal = useCallback((record) => {
    setModalData(record)
    setAddSeatModalOpen(true)
  }, [])

  function reloadData () {
    window.location.reload()
  }

  return <Card>
    <CardContent className={'table-content'}>
        <div className={'inner'}>
          <Table>
            <TableHead style={{ tableLayout: 'fixed' }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Group ID</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>Promo ID</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Seats</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Expire</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {((data && data.rows) || []).map(record => (
                  <TableRow key={record.id} hover>
                    <TableCell>{record.id}</TableCell>
                    <TableCell>{record.user_id}</TableCell>
                    <TableCell>
                      <a href={`/business/groups/${record.group_id}`}>
                        {record.group_id}
                      </a>
                    </TableCell>
                    <TableCell>{record.group.name}</TableCell>
                    <TableCell>{record.promo_id}</TableCell>
                    <TableCell>{record.price}</TableCell>
                    <TableCell>{record.seats}</TableCell>
                    <TableCell>{record.group && record.group.members}</TableCell>
                    <TableCell>{formatDateTime(record.expire)}</TableCell>
                    <TableCell>{formatDateTime(record.created_at)}</TableCell>
                    
                    <TableCell style={{ display: 'flex' }}>
                      <EditBtn path={`/business/corporate-wellness/edit/${record.id}`} {...props} />
                      &nbsp;&nbsp;
                      <img src={RemoveUserIcon} alt='remove user' height='20px' width='20px' className='cursor-pointer' onClick={() => openModal(record)} />
                      &nbsp;&nbsp;
                      <Add className='cursor-pointer' onClick={() => openAddSeatModal(record)}/>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
    </CardContent>
    <CardActions className={'flex-right'}>
      <TablePagination
        component="div"
        count={(data && data.count) || 0}
        onChangePage={onPageChange}
        page={params.page - 1}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </CardActions>

    <RemoveUserModal modalOpen={modalOpen} setModalOpen={setModalOpen} modalData={modalData} />
    <AddSeatModal modalOpen={addSeatModalOpen} setModalOpen={setAddSeatModalOpen} modalData={modalData} reloadData={reloadData} />
  </Card>
}
