import React, { useCallback, useEffect, useState } from 'react'
import { Card, Grid } from '@material-ui/core'
import { createVideoConvertJobPromise } from 'api/course'
import Context from 'common/context'
import { AutoCompleteNew } from 'components/Select'
import LocaleFile from './local'
import { UploadObjectTypeOptions } from 'common/enum'
import S3ObjectList from './s3'
import History from './history'

export default function FunctionSwitchUpdate (props) {
  const videoCdn = 'https://video.fitonapp.com'
  const keyForVideoHistory = 'course-videos-history'
  const { handleNotification, toggleProgress } = Context._currentValue
  const [histories, setHistories] = useState([])
  const [type, setType] = useState('locale')

  useEffect(() => {
    const preVideoHistories = localStorage.getItem(keyForVideoHistory)
    setHistories(JSON.parse(preVideoHistories || '[]'))
  }, [])

  useEffect(() => {
    localStorage.setItem(keyForVideoHistory, JSON.stringify(histories))
  }, [histories])

  // { width, height, filename, uuid, fromPath, rawFilename }
  async function createConvertVideoJob (objects) {
    const resp = await createVideoConvertJobPromise(objects)

    resp.forEach(obj => {
      const { uuid, filename, rawFilename } = obj
      const videoUrl = `${videoCdn}/${uuid}/hls/${filename}.m3u8`
      histories.unshift({ name: rawFilename, url: videoUrl, created_at: `${new Date()}`})
      if (histories.length > 50) histories.pop()
      setHistories([...histories])
    })

    handleNotification('success', 'create job success')
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container item xs={6}>
          <AutoCompleteNew options={UploadObjectTypeOptions} value={type} inputLeft={true} disableUnderline={false}  onChange={(event, selected) => setType((selected || {}).value)} />
        </Grid>

        <br />

        {
          type === 'locale'
            ? <LocaleFile createConvertVideoJob={createConvertVideoJob} />
            : <S3ObjectList type={type} createConvertVideoJob={createConvertVideoJob} />
        }

        <br />

        <History
          histories={histories}
          setHistories={setHistories}
        />

      </Card>
    </div>
  )
}