import AddBtn from 'components/AddBtn'
import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function ProgramNutritionToolbar (props) {
  const { params, onParamsChange, programs } = props
  const [programOptions, setProgramOptions] = useState([])

  useEffect(() => {
    setProgramOptions(programs.map(p => ({ label: `${p.name} (${p.id})`, value: `${p.id}` })))
  }, [programs])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AutoCompleteNew name='program-autocomplete' label='Program' disableUnderline={false} value={params.programId} options={programOptions} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({programId: selected && selected.value})}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberAutoComplete name='week-autocomplete' label='Week' from={1} to={100} value={parseInt(params.week)} inputLeft={true} className='background-color'
            handleSelectChange={week => onParamsChange({ week }) } variant='outlined' />
        </Grid>
        <Grid container item xs={6} justify={'flex-end'}>
          <AddBtn
            id='add-btn'
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push({
                pathname: '/program-nutrition/add',
                state: { params }
              })
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
