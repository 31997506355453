import { Card, Grid } from '@material-ui/core'
import { featureBannerPlacementListPromise } from 'api/FeatureBanner'
import { ParamsForOption } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

export default function (props) {
  const { data, onPlacementChange, handleChange } = props
  const [errors, setErrors] = useState({})
  const [activePlacements, setActivePlacements] = useState([])

  useLayoutEffect(() => {
    featureBannerPlacementListPromise(ParamsForOption)
      .then(resp => setActivePlacements(resp.filter(r => r.id !== 2).map(r => ({ label: r.name, value: r.id, image_size: r.image_size }))))
  }, [])

  const handleErrorCheck = useCallback(event => {
    const attrName = event.target.name
    setErrors({ ...errors, [attrName]: !data[attrName] })
  }, [data, errors])

  useEffect(() => {
    const placement = activePlacements.find(placement => placement.value === data.placement_id)
    onPlacementChange(placement || {})
  }, [data.placement_id, onPlacementChange, activePlacements])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>PLACEMENT</label>
    </Grid>

    <Grid item xs={12} className='form-card-content'>
      <div className='form-input-elem'>
        <label className='full-width'>Placement*</label>
        <AutoCompleteNew
          name='placement_id'
          error={errors['placement_id']}
          inputLeft={true}
          variant='outlined'
          value={data.placement_id}
          options={activePlacements}
          onBlur={handleErrorCheck}
          onChange={(event, selected) => {
            handleChange('placement_id', selected && selected.value, { placement: selected && selected.label })
          }}
        />
      </div>
    </Grid>
  </Card>
}
