import _ from 'lodash'
import Table from './Table'
import Toolbar from './Toolbar'
import { isBlankV2 } from 'common/usual'
import { useEffectOnce } from 'react-use'
import { groupListPromise } from 'api/Business'
import { useLocation } from 'react-router-dom'
import React, { useCallback, useEffect, useRef, useState } from 'react'

export default function List (props) {
  const ref = useRef()
  const location = useLocation()
  const [data, setData] = useState({})
  const [params, setParams] = useState({ page: 1 })

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    groupListPromise(too).then(resp => setData(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, foo => !isBlankV2(foo))
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload, duration = 500) {
    handleParamsChange(payload, duration)
  }
  // end

  return <div className={'root'}>
    <div className={'content'}>
      <Toolbar params={params} onParamsChange={handleSearchChange} {...props} />
      <br />
      <Table data={data} page={params.page} onPageChange={handlePageChange} {...props} />
    </div>
  </div>
}
