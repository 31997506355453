import React from 'react'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { VideoResourceTypeOptions } from 'common/enum'
import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  function handleQueryChange (event) {
    handleSearchChange({ [event.target.name]: event.target.value }, 500)
  }

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='ID' name='id' value={params.id} className={'block-value'} onChange={handleQueryChange} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Name' name='name' value={params.name} className={'block-value'} onChange={handleQueryChange} />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew disableUnderline={false} value={params.type} options={VideoResourceTypeOptions} className='autocomplete-toolbar' onChange={(event, selected) => { handleSearchChange({ type: selected && selected.value }) }} />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={1} >
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/videos/metadata/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
