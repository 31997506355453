import React, { useState, useEffect } from 'react'
import PartnerWorkoutResourceForm from './_form'
import { partnerWorkoutResourceDetailPromise } from 'api/PartnerWorkoutResource'

export default function PartnerWorkoutResourceEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchPartnerWorkoutResource () {
      setData(
        await partnerWorkoutResourceDetailPromise(id)
      )
    }
    fetchPartnerWorkoutResource()
  }, [id])
  return (
    <PartnerWorkoutResourceForm data={data} {...props} />
  )
}