import React, { useState, useEffect } from 'react'
import MealIngredientCategoryGroceryForm from './_form'
import { mealIngredientCategoryGroceryDetailPromise } from 'api/MealIngredientCategoryGrocery'

export default function MealIngredientCategoryGroceryEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealIngredientCategoryGrocery () {
      setData(
        await mealIngredientCategoryGroceryDetailPromise(id)
      )
    }
    fetchMealIngredientCategoryGrocery()
  }, [id])
  return (
    <MealIngredientCategoryGroceryForm data={data} />
  )
}