import 'assets/scss/detail2.scss'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { featureBannerDetailPromise } from 'api/FeatureBanner'
import { Grid } from '@material-ui/core'
import { ImageLanguageOptions } from 'common/enum'
import DetailCard from './components/detailCard'
import { formatDateTimeUTC, booleanElement, send, platformHumanize } from 'helpers/utils'
import ImagePreviewRow from 'components/ImagePreview'
import EditBtn from 'components/EditBtn'
import targetDesc from '../targetDesc.json'
import { changeNameByEndDate } from '../share'

const FeatureBannerCampaignDetail = props => {
  const { match: { params: { id } } } = props

  const [campaign, setCampaign] = useState({})

  const getCampaignDetail = useCallback(async () => {
    setCampaign(await featureBannerDetailPromise(id))
  }, [id])

  useEffect(() => {
    getCampaignDetail()
  }, [getCampaignDetail])

  const setupData = useMemo(() => {
    const items = [
      { label: 'Name', value: changeNameByEndDate(campaign) },
      { label: 'Start Date (optional)', value: campaign.start_date && formatDateTimeUTC(campaign.start_date * 1000) },
      { label: 'End date (optional)', value: campaign.end_date && formatDateTimeUTC(campaign.end_date * 1000) },
      { label: 'Platform (optional)', value: platformHumanize(campaign.target_platform) },
    ]
    return [{ items }]
  }, [campaign])

  const placementData = useMemo(() => {
    const items = [{  label: 'Placement', value: send(campaign, 'feature_banner_placement', 'name') }]
    return [{ items }]
  }, [campaign])

  const targetData = useMemo(() => {
    const items = [
      { label: 'PRO', name: 'target_pro', rawValue: campaign.target_pro, value: booleanElement(campaign.target_pro) },
      { label: 'Premium Music', name: 'target_premium_music', rawValue: campaign.target_premium_music, value: booleanElement(campaign.target_premium_music) },
      { label: 'Notification Permission', name: 'target_notification_permission', rawValue: campaign.target_notification_permission, value: booleanElement(campaign.target_notification_permission) },
      { label: 'Fitness Tracker', name: 'target_fitness_tracker', rawValue: campaign.target_fitness_tracker, value: booleanElement(campaign.target_fitness_tracker) },
      { label: 'Submitted Company Info', name: 'target_submitted_company_info', rawValue: campaign.target_submitted_company_info, value: booleanElement(campaign.target_submitted_company_info) },
      { label: 'Health', name: 'target_health', rawValue: campaign.target_health, value: booleanElement(campaign.target_health) },
      { label: 'Program Reminder', name: 'target_program_reminder', rawValue: campaign.target_program_reminder, value: booleanElement(campaign.target_program_reminder) },
      { label: 'Min App Version', name: 'target_min_app_version', rawValue: campaign.target_min_app_version, value: campaign.target_min_app_version },
      { label: 'Daily Fix', name: 'target_daily_fix', rawValue: campaign.target_daily_fix, value: booleanElement(campaign.target_daily_fix) },
      { label: 'Locale Language', name: 'target_locale_language', rawValue: campaign.target_locale_language, value: campaign.target_locale_language },
      { label: 'Phone Verification', name: 'target_phone_verification', rawValue: campaign.target_phone_verification,  value: booleanElement(campaign.target_phone_verification) },
      { label: 'Country', name: 'target_country', rawValue: campaign.target_country, value: campaign.target_country },
      { label: 'Meal Onboarding', name: 'target_meal_onboarding', rawValue: campaign.target_meal_onboarding, value: booleanElement(campaign.target_meal_onboarding) },
      { label: 'Users', name: 'target_users', rawValue: campaign.target_users, value: campaign.target_users },
      { label: 'Cast', name: 'target_cast', rawValue: campaign.target_cast, value: booleanElement(campaign.target_cast) },
      { label: 'Download', name: 'target_download', rawValue: campaign.target_download, value: booleanElement(campaign.target_download) },
      { label: 'Active', name: 'active', rawValue: campaign.active, value: booleanElement(campaign.active) },
    ]
    return [{ items }]
  }, [campaign])

  const contentData = useMemo(() => {
    if (!campaign.contents) return

    return campaign.contents.map(content => {
      return {
        head: content.name,
        items: [
          { label: 'Name', value: content.name },
          { label: 'CTA Deep Link', value: <a href={content.cta_deeplink} rel='noopener noreferrer' target='_blank'>{content.cta_deeplink}</a> },
          // { label: 'Title', value: content.title },
          { label: 'CTA Text Color', value: content.text_color },
          // { label: 'Subtitle', value: content.subtitle },
          { label: 'CTA Text', value: content.cta_text },

          { customElement:
            ImageLanguageOptions.map(language =>
              <ImagePreviewRow imageName={content.background} srcEn={content.background_en} language={language.value} />
            )
          }
        ],
      }
    })
  }, [campaign.contents])

  return (
    <div className='detail-page'>
      <div className='root' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>{campaign.name}</h2>
        <span>
          <EditBtn module='campaign' history={props.history} path={`/feature-banner/campaigns/edit/${campaign.id}`} />
        </span>
      </div>

      <div style={{ borderBottom: '1px solid #E5E5E5' }} />

      <div className='root'>
        <Grid container spacing={4} >
          <Grid item xs={6}>
            <DetailCard title='SETUP' blocks={setupData} />
          </Grid>

          <Grid item xs={6}>
            <DetailCard title='PLACEMENT' blocks={placementData} />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='TARGET' blocks={targetData} isTwoColumns={true} targetDesc={targetDesc} />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='CONTENT' isTwoColumns={true} blocks={contentData} />
          </Grid>
        </Grid>
        
      </div>
    </div>
  )
}

export default FeatureBannerCampaignDetail
