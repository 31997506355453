import { getApiClient } from './ApiClient'

export const flexNetworkMerchantListPromise = (params) => {
  return getApiClient()
    .get('/flex-network/merchants', { params })
    .then(resp => resp.data)
}

export const flexNetworkMerchantDetailPromise = (id, params) => {
  return getApiClient()
    .get(`/flex-network/merchants/${id}`, { params })
    .then(resp => resp.data)
}

export const flexNetworkMerchantVerifyAndUnverifyPromise = body => {
  return getApiClient()
    .put('/flex-network/merchants/verify', { body })
    .then(resp => resp.data)
}

export const flexCardEventListPromise = (params) => {
  return getApiClient()
    .get('/flex-network/card-events', { params })
    .then(resp => resp.data)
}

export const flexCardEventDetailPromise = (id) => {
  return getApiClient()
    .get(`/flex-network/card-events/${id}`)
    .then(resp => resp.data)
}
