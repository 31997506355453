import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { logOptionListPromise } from 'api/Log'
import { AutoCompleteNew } from 'components/Select'
import { ParamsForOption } from 'common/enum'

export default function LogListToolbar (props) {
  const { params, handleChange } = props
  const [adminActions, setAdminActions] = useState([])
  const [adminModules, setAdminModules] = useState([])

  useEffect(() => {
    logOptionListPromise(ParamsForOption).then(({ actions, modules }) => {
      setAdminActions(actions.map(action => ({ label: action, value: action })))
      setAdminModules(modules.map(module => ({ label: module, value: module })))
    })
  }, [])

  return (
    <div className={'tool-bar'}>
      <Grid container item xs={12} justify={'flex-end'} spacing={3} >
        <Grid item xs={2}>
          <AutoCompleteNew
            disableUnderline={false}
            options={adminActions || []}
            value={params.action}
            onChange={(event, selected) => {
              handleChange({action: selected && selected.value || undefined})
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew
            disableUnderline={false}
            options={adminModules || []}
            value={params.module}
            onChange={(event, selected) => {
              handleChange({module: selected && selected.value || undefined})
            }}
          />
        </Grid>
        <Grid item xs={8} />
      </Grid>
    </div>
  )
}
