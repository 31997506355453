import React, { useCallback } from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  const handleChange = useCallback(event => {
    handleSearchChange({ [event.target.name]: event.target.value })
  }, [handleSearchChange])
 
  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='Workout ID' name='workout_id' value={params.workout_id} className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Program ID' name='program_id' value={params.program_id} className={'block-value'} onChange={handleChange} />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={1} >
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/program-workouts/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
