import './index.scss'
import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { CourseProductAutoComplete, coursePromoOptions, CourseSocialGroupAutoComplete, CourseTrainerMultipleAutoComplete, CourseTypeAutoComplete } from '../../concerns/courseOptions'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { CourseTypes, ParentPath, S3PreSignType } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import UploaderForI18nImage2 from 'components/UploaderForI18nImages/index2'
import ActiveSwitch from 'components/ActiveSwitch'
import AgeAndGenderSwitch from 'components/AgeAndGenderSwitch'
import _ from 'lodash'

const columnsNeedCheck = [
  { key: 'name' },
  { key: 'deeplink'},
  { key: 'enrollment_begin' },
  { key: 'launch_time' },
  { key: 'promo_id', label: 'Promo' },
  { key: 'trainers', label: 'Trainer' },
  { key: 'type' },
  { key: 'duration' },
  { key: 'late_enrollment_product_id', label: 'Evergreen Price Regular' },
  { key: 'pro_late_enrollment_product_id', label: 'Evergreen Price PRO' },
  { key: 'product_id', label: 'Launch Price Regular' },
  { key: 'pro_product_id', label: 'Launch Price PRO' },
  { key: 'logo_new'  },
  { key: 'hero_desktop' },
  { key: 'hero_mobile' },
]

export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, setData, currentStep, handleStepChange, history } = props
  const [requiredColumns, setRequiredColumns] = useState(['type'])
  const [promoOptions, setPromoOptions] = useState([])

  const disableBtn = useMemo(() =>
      requiredColumns.map(column => isBlank(data[column])).includes(true)
  , [data, requiredColumns])

  // useEffect(() => {
  //   setData(prev => ({
  //     ...prev,
  //     launch_time: formatDateTime(prev.launch_time),
  //     cut_off_date: formatDateTime(prev.cut_off_date),
  //     enrollment_begin: formatDateTime(prev.enrollment_begin),
  //     enrollment_end: formatDateTime(prev.enrollment_end),
  //   }))
  // }, [setData])

  useLayoutEffect(() => {
    coursePromoOptions().then(resp => setPromoOptions(resp))
  }, [])

  // const trainerIds = useMemo(() => {
  //   return data.trainers || (data.trainer_courses || []).map(tc => tc.trainer_id)
  // }, [data.trainer_courses, data.trainers])

  const handleValueChange = useCallback((map) => {
    setData(prev => ({ ...prev, ...map }))
  }, [setData])

  const courseFormItem = (label, name, isRequired, props) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])
    return <FormItem
      label={label} name={name} value={data[name]} isRequired={isRequired} {...props}
      onChange={map => { setData({ ...data, ...map }) } }
    />
  }

  const UploadAndInput = (name, label) => {
    return <S3UploaderWithInput label={label} name={name} filenames={data[name]} fileType={S3PreSignType.Course} parentPath={ParentPath.enUS} subpath={name}
      setFilenames={(value) => { setData({ ...data, [name]: value })}} />
  }

  if (currentStep !== 'step1') return <></>

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { courseFormItem('ID', 'id', true, { readonly: !!data.oldId }) }
        { courseFormItem('Name', 'name', true) }
        { courseFormItem('Deeplink', 'deeplink', true) }
        {/* { courseFormItem('Promo ID', 'promo_id', true) } */}
        { courseFormItem('Description', 'description', true, { multiline: true }) }

        <FormItem label={'Type*'}>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.type}
            options={CourseTypes.map(ct => ({ label: ct.label, value: ct.value }))}
            onChange={(event, selected) => {
              handleValueChange({ type: selected && selected.value })
            }}
          />
        </FormItem>

        { courseFormItem('Enrollment Begin', 'enrollment_begin', true, { placeholder: 'YYYY-MM-DD HH:mm:ss' }) }
        { courseFormItem('Enrollment End', 'enrollment_end', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' }) }
        { courseFormItem('Launch Time', 'launch_time', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' }) }
        { courseFormItem('Cut Off Date', 'cut_off_date', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' }) }

        <FormItem label='Promo' name='promo_id'>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.promo_id}
            options={promoOptions}
            onChange={(event, selected) => {
              handleValueChange({ promo_id: selected && selected.value })
            }}
          />
        </FormItem>

        {/* <FormItem label={'Enrollment Begin'}>
          <UTCDateTimePicker value={course.enrollment_begin} onChange={enrollment_begin => handleValueChange({ enrollment_begin })} {...datepickerProp} />
        </FormItem>
        <FormItem label={'Launch Time'}>
          <UTCDateTimePicker value={course.launch_time} onChange={launch_time => handleValueChange({ launch_time })} {...datepickerProp} />
        </FormItem>
        <FormItem label={'Cut Off Date'}>
          <UTCDateTimePicker value={course.cut_off_date} onChange={cut_off_date => handleValueChange({ cut_off_date })} {...datepickerProp} />
        </FormItem> */}

        <FormItem label={'Trainer'}>
          <CourseTrainerMultipleAutoComplete label='Trainer' name='trainer_name' value={data.trainers} handleSelectChange={(trainers) => handleValueChange({ trainers })} {...props} />
        </FormItem>

        <FormItem label={'Duration(weeks)'}>
          <NumberAutoComplete name='duration' from={1} to={100} value={data.duration} handleSelectChange={(duration) => { handleValueChange({ duration }) }} {...props} />
        </FormItem>

        <FormItem label={'Item Count'}>
          <NumberAutoComplete name='item_count' from={1} to={100} value={data.item_count} handleSelectChange={(item_count) => { handleValueChange({ item_count }) }} {...props} />
        </FormItem>

        <FormItem label={'Social Group'}>
          <CourseSocialGroupAutoComplete name='social_group_id' value={data.social_group_id} handleSelectChange={(social_group_id) => { handleValueChange({ social_group_id, socialGroupId: social_group_id }) }} {...props} />
        </FormItem>

        <FormItem label={'Evergreen Price Regular'}>
          <CourseProductAutoComplete name='late_enrollment_product_id' value={data.late_enrollment_product_id} handleSelectChange={(late_enrollment_product_id) => { handleValueChange({ late_enrollment_product_id }) }} {...props} />
        </FormItem>
        <FormItem label={'Evergreen Price PRO'}>
          <CourseProductAutoComplete name='pro_late_enrollment_product_id' value={data.pro_late_enrollment_product_id} handleSelectChange={(pro_late_enrollment_product_id) => { handleValueChange({ pro_late_enrollment_product_id }) }} {...props} />
        </FormItem>
        <FormItem label={'Launch Price Regular'}>
          <CourseProductAutoComplete name='product_id' value={data.product_id} handleSelectChange={(product_id) => { handleValueChange({ product_id, productId: product_id }) }} {...props} />
        </FormItem>
        <FormItem label={'Launch Price PRO'}>
          <CourseProductAutoComplete name='pro_product_id' value={data.pro_product_id} handleSelectChange={(pro_product_id) => { handleValueChange({ pro_product_id }) }} {...props} />
        </FormItem>

        { UploadAndInput('logo', 'Logo(deprecated)') }

        {/* { UploadAndInput('logo_new') }
        { UploadAndInput('hero_desktop') }
        { UploadAndInput('hero_mobile', 'App Hero Mobile') } */}

        <AgeAndGenderSwitch data={data} setData={setData} />

        {
          ['free_user', 'pro_health_user', 'health_plus_user', 'featured'].map(name =>
            <FormItem name={name}>
              <Switch checked={Boolean(data[name])} onChange={ e => handleValueChange({ [name]: e.target.checked }) } />
            </FormItem>
          )
        }

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={data} setBody={setData} isNeedCheck={true} columns={columnsNeedCheck} />
        </FormItem>

        {/* {
          ['logo_new', 'hero_desktop', 'hero_mobile'].map(keyName =>
            <UploaderForI18nImage key={keyName} type={S3PreSignType.Course} keyName={keyName} data={course} subpath={keyName} setData={setCourse} />
          )
        } */}

        {
          ['logo_new', 'hero_desktop', 'hero_mobile'].map(keyName =>
            <div key={keyName} style={{ width: '80%', maxWidth: '1000px', margin: '30px auto 0' }}>
              <div>
                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                  <span>
                    {_.startCase(keyName)} (PNG, JPG)
                  </span>
                  {/* <div>{keyName} - {course[keyName]}</div> */}
                </div>
              </div>
              
              <UploaderForI18nImage2
                filename={data[keyName]}
                fileType={_.chain(`course_${keyName}`).camelCase().upperFirst().value()}
                setFilename={fname => handleValueChange({ [keyName]: fname })}
              />
            </div>
          )
        }

        <Button fullWidth className='save-btn' color='secondary' size='large' variant='contained' onClick={() => handleStepChange('step2')} disabled={disableBtn}>
          Next
        </Button>
      </Card>
    </div>
  )
}