import { getApiClient } from './ApiClient'

export const getSocialFeedPostAndCommentPromise = (params) => {
  return getApiClient()
    .get('/social-feed/post-and-comment', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const deleteSocialFeedPostOrCommentPromise = (body) => {
  return getApiClient()
    .delete('/social-feed/post-or-comment', { body })
    .then(resp => Promise.resolve(resp.data))
}

export const socialFeedPostOrCommentDetailPromise = (id, params) => {
  return getApiClient()
    .get(`/social-feed/post-or-comment/${id}`, { params })
    .then(resp => Promise.resolve(resp.data))
}

export const updateSocialFeedPostOrCommentPromise = async (body) => {
  return getApiClient()
    .post('/social-feed/post-or-comment', { body })
    .then(resp => resp.data)
}

export const shareSocialFeedPost = async (body) => {
  return getApiClient()
    .post('/social-feed/share', { body })
}

export const updateUserSocialGroup = async (body) => {
  return getApiClient()
    .put(`/social-group/edit/${body.id}`, { body })
}

export const toggleSocialFeedPostOrCommentSpam = async (body) => {
  return getApiClient()
    .post('/social-feed/toggle-spam', { body })
}

export const getSocialGroupCategoryList = async () => {
  return getApiClient().get('/social-group-categories').then(resp => resp.data)
}
