import 'assets/scss/detail2.scss'
import React, { useEffect, useState, useCallback, useMemo, useLayoutEffect } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import DetailCard from 'components/DetailCard'
import { booleanElement } from 'helpers/utils'
import EditBtn from 'components/EditBtn'
import { challengeDetailPromise, challengeUpsertPromise } from 'api/Challenge'
import ImageView from 'components/ImageView'
import { S3FilePath, S3Path } from 'common/enum'
import { getLocaleCountries, getLocaleLanguages } from 'api/helper'
import titleDesc from '../titleDesc.json'
import Public from '@material-ui/icons/Public'

const ChallengeDetail = props => {
  const { match: { params: { id } } } = props
  const [challenge, setChallenge] = useState({})
  const [countries, setCountries] = useState([])
  const [languages, setLanguages] = useState([])

  useLayoutEffect(() => {
    getLocaleCountries().then(resp => setCountries(resp))
  }, [])

  useLayoutEffect(() => {
    getLocaleLanguages().then(resp => setLanguages(resp))
  }, [])

  const getChallengeDetail = useCallback(async () => {
    setChallenge(await challengeDetailPromise(id))
  }, [id])

  useEffect(() => {
    getChallengeDetail()
  }, [getChallengeDetail])

  const imageSrcGen = useCallback(key => {
    return challenge[key] && `${S3Path}/${S3FilePath.ChallengeBasePath()}/${challenge[key]}`
  }, [challenge])

  const setupData = useMemo(() => {
    const items = [
      { label: 'name', value: challenge.name },
      { label: 'male_name', value: challenge.male_name },
      { label: 'description', value: challenge.description },
      { label: 'code', value: challenge.code },
    ]

    return [{ items }]
  }, [challenge])

  const detailData = useMemo(() => {
    const items = [
      { label: 'duration', value: <div>{challenge.duration} <span style={{color: '#1A9066'}}>{challenge.unit}</span></div> },
      { label: 'private', value: booleanElement(challenge.private) },
      { label: 'type', value: challenge.type },
      { label: 'featured', value: booleanElement(challenge.feature) },
      { label: 'sort', value: challenge.sort },
      { label: 'groupChallenge', value: booleanElement(challenge.group_challenge) },
      { label: 'groupName', value: challenge.social_group_name },
    ]

    return [{ items }]
  }, [challenge])

  const targetData = useMemo(() => {
    const items = [
      { label: 'PRO', name: 'is_pro', rawValue: challenge.is_pro, value: booleanElement(challenge.is_pro) },
      { label: 'senior', name: 'senior', rawValue: challenge.senior, value: booleanElement(challenge.senior) },
      { label: 'non senior', name: 'non_senior', rawValue: challenge.non_senior, value: booleanElement(challenge.non_senior) },
      { label: 'female', name: 'female', rawValue: challenge.female, value: booleanElement(challenge.female) },
      { label: 'male', name: 'male', rawValue: challenge.male, value: booleanElement(challenge.male) },
      { label: 'country', value: (countries.find(c => `${c.id}` === `${challenge.device_country}`) || {}).code },
      { label: 'locale language', value: (languages.find(l => l.id === challenge.device_language) || {}).code },
    ]

    return [{ items }]
  }, [challenge.device_country, challenge.device_language, challenge.female, challenge.is_pro, challenge.male, challenge.non_senior, challenge.senior, countries, languages])

  const assetData = useMemo(() => {
    const columns = ['cover_vertical', 'cover_horizontal', 'male_cover_thumbnail', 'male_cover_vertical', 'male_cover_horizontal']
    const items = columns.map(column => ({
      label: column,
      value: <ImageView src={imageSrcGen(column)} />
    }))

    items.push({
      label: 'achievement_icon_grey',
      value: <ImageView src={challenge.achievement_icon_grey && `${S3Path}/${S3FilePath.AchievementBasePath()}/${challenge.achievement_icon_grey}`} />
    })

    return [{ items }]
  }, [challenge.achievement_icon_grey, imageSrcGen])

  const makeItFitOnChallenge = useCallback(() => {
    if (!window.confirm('are you sure?')) return

    challengeUpsertPromise({ ...challenge, created_user_id: null, challenge_avatar: null, feature: true })
  }, [challenge])

  return (
    <div className='detail-page'>
      <div className='root' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>{challenge.name}</h2>
        <span>
          <Tooltip title='change it to a FitOn challenge'>
            <Public module='campaign' history={props.history} path={`/challenges/${challenge.id}/edit`} className='cursor-pointer'
              onClick={makeItFitOnChallenge} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <EditBtn module='campaign' history={props.history} path={`/challenges/${challenge.id}/edit`} />
        </span>
      </div>

      <div style={{ borderBottom: '1px solid #E5E5E5' }} />

      <div className='root'>
        <Grid container spacing={4} >
          <Grid item xs={6}>
            <DetailCard title='SETUP' isTwoColumns={false} blocks={setupData} />
          </Grid>

          <Grid item xs={6}>
            <ImageView src={imageSrcGen('cover_thumbnail')} height='152px' width='112px' />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='Details' isTwoColumns={true} blocks={detailData} />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='Target' isTwoColumns={true} blocks={targetData} targetDesc={titleDesc} />
          </Grid>

          <Grid item xs={12}>
            <DetailCard title='ASSETS' isTwoColumns={false} blocks={assetData} />
          </Grid>
        </Grid>
        
      </div>
    </div>
  )
}

export default ChallengeDetail
