import React, { useState, useEffect } from 'react'
import MealIngredientUnitForm from './_form'
import { mealIngredientUnitDetailPromise } from 'api/MealIngredientUnit'

export default function MealIngredientUnitEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealIngredientUnit () {
      setData(
        await mealIngredientUnitDetailPromise(id)
      )
    }
    fetchMealIngredientUnit()
  }, [id])
  return (
    <MealIngredientUnitForm data={data} />
  )
}