import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import EditBtn from 'components/EditBtn'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { boolStr } from 'helpers/utils'
import { formatDateTime } from 'common/usual'

const WorkoutCategory = (props) => {
  const { data } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Image Size</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(data || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.name}</TableCell>
                      <TableCell>{record.image_size}</TableCell>
                      <TableCell>{boolStr(record.active)}</TableCell>
                      <TableCell>{formatDateTime(record.created_at)}</TableCell>
                      <TableCell>
                        <EditBtn path={`/feature-banner/placements/edit/${record.id}`} {...props} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default WorkoutCategory
