import { getApiClient } from './ApiClient'

export const requestCourseVideoListPromise = (params) => {
  return getApiClient()
    .get('/course/videos', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const requestCourseVideoDetailPromise = (id) => {
  return getApiClient()
    .get(`/course/videos/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertCourseVideoPromise = (body) => {
  return getApiClient()
    .post('/course/videos', { body: { courseVideos: [].concat(body) }})
    .then(resp => Promise.resolve(resp.data))
}
