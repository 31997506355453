import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Input, TextField } from '@material-ui/core'

const ContentToolbar = props => {
  const { params, onChange } = props

  return (
    <Grid container item xs={12}>
      <TextField name='reporterId' value={params.reporterId} placeholder='Reported by user id' onChange={onChange} inputProps={{ 'data-lpignore': true }} />
      &nbsp;
      <TextField name='challengeId' value={params.challengeId} placeholder='Challenge ID' onChange={onChange} inputProps={{ 'data-lpignore': true }} />
      &nbsp;
      <TextField name='challengeName' value={params.challengeName} placeholder='Challenge Name' onChange={onChange} inputProps={{ 'data-lpignore': true }} />
    </Grid>
  )
}

ContentToolbar.propTypes = {
  className: PropTypes.string,
}

export default ContentToolbar
