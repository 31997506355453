import _ from 'lodash'
import { getApiClient } from './ApiClient'

export const loginPromise = (email, password) => {
  return getApiClient()
    .post('/login', {
      body: {
        email: email,
        password: password,
      },
    })
    .then(resp => {
      const data = _.get(resp, 'data', {})
      if (!data.token) {
        return Promise.resolve(resp)
      }
      return Promise.resolve({
        ...data,
      })
    })
}

export const logoutPromise = () => {
  return getApiClient().post('/logout')
}
