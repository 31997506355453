import React, { useCallback, useState } from 'react'
import MyModal from 'components/MyModal'
import { Button, Grid, Switch } from '@material-ui/core'
import { TableBody, TableHead, TableRow, Table, TableCell } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { updateUserSocialGroup } from 'api/SocialFeed'
import { boolStr } from 'helpers/utils'
import { checkPermission } from 'helpers/permission'

export default function (props) {
  const { data, getUserSocialGroup } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleEditClicked = useCallback((row) => {
    setModalOpen(true)
    setModalData(row)
  }, [])

  const handleChange = useCallback((event) => {
    const { name, checked } = event.target
    console.log(name, checked)
    setModalData({ ...modalData, [name]: checked })
  }, [modalData])

  const handleSubmit = useCallback(() => {
    updateUserSocialGroup(modalData)
      .then(() => {
        getUserSocialGroup()
        setModalOpen(false)
      })
  }, [getUserSocialGroup, modalData])
  
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Social Group ID</TableCell>
            <TableCell>Social Group Name</TableCell>
            <TableCell>Is Admin</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            data.map((row, idx) => (
              <TableRow key={`row-${idx}`}>
                <TableCell key={`id-${row.id}`}>{row.id}</TableCell>
                <TableCell key={`social-group-id-${row.id}`}>{row.social_group_id}</TableCell>
                <TableCell key={`social-group-name-${row.id}`}>{row.social_group_name}</TableCell>
                <TableCell key={`is-admin-${row.id}`}>{boolStr(row.is_admin)}</TableCell>
                <TableCell key={`active-${row.id}`}>{boolStr(row.active)}</TableCell>
                <TableCell key={`created_at-${row.id}`}>{row.created_at}</TableCell>
                <TableCell key={`action-${row.id}`}>
                  {
                    checkPermission('104902')
                      && <Edit className='cursor-pointer' onClick={() => handleEditClicked(row)} />
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>

      <MyModal open={modalOpen} setOpen={setModalOpen} isFullScreen={false}>
        <Grid container>
          <Grid item xs={12}>&nbsp;</Grid>

          <Grid item xs={2}>&nbsp;</Grid>
          <Grid item xs={4}>
            Is Admin
          </Grid>
          <Grid container item xs={4} justify='flex-end'>
            <Switch name={'is_admin'} checked={Boolean(modalData.is_admin)} onChange={handleChange} />
          </Grid>
          <Grid item xs={2}>&nbsp;</Grid>

          <Grid item xs={2}>&nbsp;</Grid>
          <Grid item xs={8}>
            <Button color='primary' fullWidth size='tiny' type='submit' variant='contained' onClick={handleSubmit}>
              SUBMIT
            </Button>
          </Grid>
          <Grid item xs={2}>&nbsp;</Grid>
        </Grid>
      </MyModal>
    </>
  )
}
