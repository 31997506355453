import React, { useState, useEffect } from 'react'
import Detail from './components/Detail'
import GuidePdf from './components/GuidePdf'
import Recipe from './components/Recipe'
import { requestCourseWeekDetailPromise } from 'api/CourseWeek'

const WorkoutResourceList = props => {
  const { id } = props.match.params
  const [courseWeek, setCourseWeek] = useState({})

  useEffect(() => {
    requestCourseWeekDetailPromise(id)
      .then(data => setCourseWeek(data))
  }, [id])

  return (
    <div className={'root course-week detail-container'}>
      <Detail courseWeek={courseWeek} />
      <GuidePdf courseWeek={courseWeek} />
      <Recipe courseWeek={courseWeek} />
    </div>
  )
}

export default WorkoutResourceList
