import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import { booleanString as boolStr } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'

const MealIngredientPreparationTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Single</TableCell>
                  <TableCell>Plural</TableCell>
                  <TableCell>Category Grocery</TableCell>
                  <TableCell>Hidden for Shopping</TableCell>
                  <TableCell>Vegetarian</TableCell>
                  <TableCell>Vegan</TableCell>
                  <TableCell>Pescatarian</TableCell>
                  <TableCell>Paleo</TableCell>
                  <TableCell>Keto</TableCell>
                  <TableCell>Whole 30</TableCell>
                  <TableCell>Dairy</TableCell>
                  <TableCell>Gluten</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(preparation => (
                  <TableRow className={'table-row'} key={preparation.id} hover>
                    <TableCell>{preparation.id}</TableCell>
                    <TableCell>{preparation.titleSingle}</TableCell>
                    <TableCell>{preparation.titlePlural}</TableCell>
                    <TableCell>{preparation.ingredientCategoryGrocery && preparation.ingredientCategoryGrocery.title}</TableCell>
                    <TableCell>{boolStr(preparation.hidden_for_shopping_list)}</TableCell>
                    <TableCell>{boolStr(preparation.vegetarian)}</TableCell>
                    <TableCell>{boolStr(preparation.vegan)}</TableCell>
                    <TableCell>{boolStr(preparation.pescatarian)}</TableCell>
                    <TableCell>{boolStr(preparation.paleo)}</TableCell>
                    <TableCell>{boolStr(preparation.keto)}</TableCell>
                    <TableCell>{boolStr(preparation.whole30)}</TableCell>
                    <TableCell>{boolStr(preparation.dairy)}</TableCell>
                    <TableCell>{boolStr(preparation.gluten)}</TableCell>
                    <TableCell>{boolStr(preparation.active)}</TableCell>
                    <TableCell>{formatDateTime(preparation.createdAt)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/meal-ingredient/edit/${preparation.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default MealIngredientPreparationTable