import React, { useState, useEffect } from 'react'
import MealIngredientForm from './_form'
import { mealIngredientDetailPromise } from 'api/MealIngredient'

export default function MealIngredientEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealIngredient () {
      setData(
        await mealIngredientDetailPromise(id)
      )
    }
    fetchMealIngredient()
  }, [id])
  return (
    <MealIngredientForm data={data} {...props} />
  )
}