import UserGroupForm from './form'
import React, { useState, useLayoutEffect, useCallback } from 'react'
import { groupMemberDetailPromise } from 'api/Business'

export default function UserGroupEdit (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  const fetchDetail = useCallback(() => {
    groupMemberDetailPromise(id).then(resp => setData(resp))
  }, [id])

  useLayoutEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return (
    <UserGroupForm { ...props } isEdit={true} data={data} reload={fetchDetail} />
  )
}
