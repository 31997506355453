import React, { useState } from 'react'
import Form from './form'
import { WeekProgramType } from 'common/enum'

export default function BlacklistAdd (props) {
  const [data, setData] = useState({ type: WeekProgramType.Original, active: false })

  return <div className='course-week'>
    <Form data={data} setData={setData} {...props} />
  </div>
}