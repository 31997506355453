import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { S3FilePath, S3Path } from 'common/enum'

const VideoResourceTable = (props) => {
  const { data } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Icon</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.name}</TableCell>
                      <TableCell>
                        { record.icon && <img src={`${S3Path}/${S3FilePath.SocialBadgeCover()}/${record.icon}`} width='30px' height='30px' alt='' /> }
                      </TableCell>
                      <TableCell>{record.priority}</TableCell>
                      <TableCell>
                        <EditBtn path={`/social-badges/${record.id}/edit`} {...props} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default VideoResourceTable
