import React from 'react'
import { Card, Grid } from '@material-ui/core'
import { send } from 'helpers/utils'

const Row = props => {
  const { weekCourseRecipe = {} } = props
  const { recipe = {} } = weekCourseRecipe

  return <div>
    <Grid container spacing={3} className='detail-table-row'>
      <Grid item xs={1} className='value'>{ weekCourseRecipe.recipe_id }</Grid>
      <Grid item xs={3} className='value'>{ recipe.title }</Grid>
      <Grid item xs={3} className='value'>{ recipe.quality_score }</Grid>
      <Grid item xs={3} className='value'>{ `${weekCourseRecipe.active}` }</Grid>
    </Grid>
  </div>
}

const Recipe = props => {
  const { week_course_recipe = [] } = props.courseWeek
  const sorted_week_course_recipe = week_course_recipe.sort((a, b) => +send(b, 'recipe', 'quality_score') - +send(a, 'recipe', 'quality_score'))

  return (
    <div className={'root'}>
      <Card>
        <Grid container spacing={3} className={'detail-table-row'}>
          <Grid item xs={1} className='label'>ID</Grid>
          <Grid item xs={3} className='label'>Title</Grid>
          <Grid item xs={3} className='label'>Quality Score</Grid>
          <Grid item xs={3} className='label'>Active</Grid>
        </Grid>
        {
          sorted_week_course_recipe.map(weekCourseRecipe => <Row weekCourseRecipe={weekCourseRecipe} />)
        }
      </Card>
    </div>
  )
}

export default Recipe
