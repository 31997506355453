import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { userWorkoutListPromise } from 'api/Workout'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'

function WorkoutTable (props) {
  const { userId } = props
  const [userWorkout, setUserWorkout] = useState({})
  const [page, setPage] = useState(1)

  const fetchUserWorkout = useCallback(async () => {
    setUserWorkout(await userWorkoutListPromise(page, 10, userId))
  }, [page, userId])

  const handlePageChange = (event, page) => {
    setPage(page+1)
  }

  useEffect(() => {
    fetchUserWorkout()
  }, [fetchUserWorkout])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Burn Calorie</TableCell>
                  <TableCell>Heart Rate</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (userWorkout.rows || []).map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{_.at(row, 'workout.resource.name')}</TableCell>
                      <TableCell>{row.burn_calorie}</TableCell>
                      <TableCell>{row.heart_rate}</TableCell>
                      <TableCell>{row.status_text}</TableCell>
                      <TableCell>{row.created_at}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'actions'}>
        <TablePagination
          component="div"
          count={userWorkout.count || 0}
          onChangePage={handlePageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

WorkoutTable.propTypes = {
  userId: PropTypes.any.isRequired
}

export default WorkoutTable