import { getApiClient } from './ApiClient'

export const getFohStudioListPromise = (params) => {
  return getApiClient().get(`/foh-studios`, { params }).then(resp => resp.data)
}

export const getFohStudioDetailPromise = (id) => {
  return getApiClient().get(`/foh-studios/${id}`).then(resp => resp.data)
}

export const upsertFohStudioPromise = (body) => {
  return getApiClient().post(`/foh-studios`, { body }).then(resp => resp.data)
}

export const syncFohStudioPromise = (body = {}) => {
  return getApiClient().post(`/foh-studios/sync`, { body }).then(resp => resp.data)
}
