import ChallengeForm from './form'
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { challengeDetailPromise } from 'api/Challenge'

export default function ChallengeEdit (props) {
  const [challenge, setChallenge] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    async function fetchFeatureBanner () {
      const campaign = await challengeDetailPromise(id)
      setChallenge(campaign)
    }
    fetchFeatureBanner()
  }, [id])
  return (
    <ChallengeForm { ...props } isEdit={true} data={challenge} />
  )
}
