import React, { useCallback, useLayoutEffect, useState } from 'react'
import { Card, Grid, Switch } from '@material-ui/core'
import FormTextField from './formTextField'
import { MultipleAutoCompleteSelect } from 'components/Select'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import { OSType, OSTypeOptions } from 'common/enum'
moment.tz.setDefault('Etc/UTC')

export default function (props) {
  const {data, handleChange} = props
  const [errors, setErrors] = useState({})

  useLayoutEffect(() => {
    if (data.target_platform) return

    handleChange('target_platform', JSON.stringify([OSType.iOS, OSType.Android]))
  }, [data.target_platform, handleChange])

  const handleErrorCheck = useCallback(event => {
    const attrName = event.target.name
    setErrors({ ...errors, [attrName]: !data[attrName] })
  }, [data, errors])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
      <div>
        <label>Active</label>
        <Switch name={'active'} checked={Boolean(data.active)} onChange={e => handleChange('active', e.target.checked)} />
      </div>
    </Grid>

    <Grid item xs={12} className='form-card-content'>
      <div className='form-input-elem'>
        <FormTextField error={errors['name']} name='name' label='Name*' value={data.name} onChange={handleChange} onBlur={handleErrorCheck} />
      </div>

      <div className='form-input-elem'>
        <label className='full-width'>Start Date (optional) </label>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDateTimePicker
            fullWidth
            inputVariant='outlined'
            size='small'
            format='YYYY-MM-DD HH:mm:ss'
            value={ data.start_date || null }
            onChange={ date => handleChange('start_date', date) }
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className='form-input-elem'>
        <label className='full-width'>End Date (optional)</label>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDateTimePicker
            fullWidth
            inputVariant='outlined'
            size='small'
            format='YYYY-MM-DD HH:mm:ss'
            value={ data.end_date || null }
            onChange={ date => handleChange('end_date', date) }
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className='form-input-elem'>
        <label className='full-width'>Platform(s)</label>
          <MultipleAutoCompleteSelect options={OSTypeOptions} values={JSON.parse(data.target_platform || '[]')} className='full-width' variant='outlined'
            onChange={(event, selected) => {
              let val = selected.map(s => s.value)
              handleChange('target_platform', JSON.stringify(val))
            }}
          />
      </div>
    </Grid>
  </Card>
}
