import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import { deleteSocialFeedPostOrCommentPromise, updateSocialFeedPostOrCommentPromise } from 'api/SocialFeed'
import './index.scss'
import PushpinIcon from 'icons/pushpin.svg'

const PostAndCommentTable = props => {
  const { data, page, history, onPageChange } = props
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(data)
  }, [data])

  const deleteSocialFeed = useCallback(record => {
    deleteSocialFeedPostOrCommentPromise({ id: record.id, kind: 'comment' }).then(() => {
      record.active = false
      setRecords({ ...records })
    })
    
  }, [records])

  const toggleSocialFeedPostPin = useCallback(record => {
    const pin = !record.isPinned
    updateSocialFeedPostOrCommentPromise({ id: record.id, pin }).then(() => {
      record.isPinned = pin
      setRecords({ ...records })
    })
  }, [records])

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Post ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {records.rows && records.rows.map(record => (
                  <TableRow className={'table-row'} key={record.id} hover onClick={() => { history.push(`/user-feed-post-or-comment/${record.id}?kind=comment`) }}>
                    <TableCell>{record.id}</TableCell>
                    <TableCell>{record.post_id}</TableCell>
                    <TableCell>{record.user_id}</TableCell>
                    <TableCell>{record.description}</TableCell>
                    <TableCell>{formatDateTime(record.created_at)}</TableCell>
                    <TableCell>
                      {
                        !record.active ? 'DELETED' : <DeleteIcon className={'cursor-pointer'} onClick={(e) => {
                          e.stopPropagation()
                          deleteSocialFeed(record)
                        }} />
                      }
                      &nbsp;
                      {
                        record.kind === 'post' &&
                          <img alt='pushpin' src={PushpinIcon} height={'24px'} width={'24px'} class={record.isPinned && 'rotate-seven'} onClick={e => {
                            e.stopPropagation()
                            toggleSocialFeedPostPin(record)
                          }} />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={records.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default PostAndCommentTable