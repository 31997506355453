import _ from 'lodash'
import { Card } from '@material-ui/core'
import { groupDetailPromise } from 'api/Business'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { PromoBenefitTypeValueToLabel } from 'common/enum'
import { boolStr } from 'helpers/utils'
import { requestSocialGroupDetailPromise } from 'api/SocialGroup'
import { productDetailPromise } from 'api/Product'

export default function Group (props) {
  const [data, setData] = useState({})
  const [socialGroup, setSocialGroup] = useState({})
  const [product, setProduct] = useState({})

  useLayoutEffect(() => {
    groupDetailPromise(props.groupId).then(data => setData(data))
  }, [props.groupId])

  useEffect(() => {
    if (!data.social_group_id) return

    requestSocialGroupDetailPromise(data.social_group_id)
      .then(resp => setSocialGroup(resp))
  }, [data.social_group_id])

  useEffect(() => {
    if (!data.promo || !data.promo.product_id1) return

    productDetailPromise(data.promo.product_id1)
      .then(resp => setProduct(resp))
  }, [data.promo])

  const row = useCallback((column, label, value) => {
    return <div className='info-item'>
      <span className='label'>{ label || _.startCase(column) }</span>
      <span className='value'>{ value || data[column] }</span>
    </div>
  }, [data])

  return (
    <div className={'root'}>
      <Card className={'user-content'}>
        { row('id') } 
        { row('name') }
        { row('country_code') }
        { row('group_type', 'Type', PromoBenefitTypeValueToLabel[data.group_type]) }
        { row('name_abbr') }
        { row('contact_name') }
        { row('contact_title') }
        { row('contact_email') }
        { row('domain', 'Domain', data.domains && data.domains.map(d => d.domain).join(',')) }
        { row('website', 'Website', data.websites && data.websites.map(d => d.url).join(',')) }
        { row('threshold', 'Total Licenses', data.promo && data.promo.threshold) }
        { row('social_group_id', 'Social Group', socialGroup && socialGroup.name) }
        { row('product_id', 'Product', product && product.sku) }
        { row('active', 'Active', boolStr(data.active)) }
      </Card>
    </div>
  )
}
