import _ from 'lodash'
import 'assets/scss/form2.scss'
import { upsertFeatureSetRoute } from 'api/Product'
import FormTextField from 'components/FormTextField'
import { Button, Card, Grid } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'

export default function ChallengeForm (props) {
  const { data, history } = props
  const [payload, setPayload] = useState({})
  const columnsNotNull = []

  useLayoutEffect(() => {
    if (!_.isEmpty(payload)) return

    setPayload(data)
  }, [payload, data])

  const handleChange = useCallback((key, val) => {
    setPayload(prev => ({ ...prev, [key]: val }))
  }, [])

  const cancelClicked = useCallback(() => {
    history.push('/feature-sets')
  }, [history])

  const saveClicked = useCallback(() => {
    upsertFeatureSetRoute(payload)
      .then(() => history.push('/feature-sets'))
  }, [payload, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!payload[cname]) return true
  }, [payload, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Feature Set
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
    <Card>
      <Grid item xs={12} className='form-card-title'>
        <label className='form-card-label'>SETUP</label>
      </Grid>

      <Grid container item xs={12} className='form-card-content' >
        <Grid item xs={12}>
          <FormTextField name='name' label='Name' value={payload.name} onChange={handleChange} />
        </Grid>

        <Grid item xs={12}>
          <FormTextField name='permanent_name' label='Permanent Name' value={payload.permanent_name} onChange={handleChange} />
        </Grid>

      </Grid>
    </Card>
    </div>
  </div>
}
