import React, { useMemo } from 'react'
import WorkoutResourceForm from './components'

export default function WorkoutResourceFormView (props) {
  const data = useMemo(() => ({
    active: false,
    schedule: false,
    is_exclusive: false,
    show_calories: false,
    post_workout_skip: false,
    senior: true,
    non_senior: true,
    female: true,
    male: true,
  }), [])

  return (
    <WorkoutResourceForm {...props} data={data} type={'add'} />
  )
}