import React, { useState, useEffect } from 'react'
import MealIngredientPreparationForm from './_form'
import { mealIngredientPreparationDetailPromise } from 'api/MealIngredientPreparation'

export default function MealIngredientPreparationEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealIngredientPreparation () {
      setData(
        await mealIngredientPreparationDetailPromise(id)
      )
    }
    fetchMealIngredientPreparation()
  }, [id])
  return (
    <MealIngredientPreparationForm data={data} {...props} />
  )
}