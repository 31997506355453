import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const workoutResourceListPromise = (params) => {
  return getApiClient()
    .post('/workout-resource-list', {
      body: params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutResourceDetailPromise = (id) => {
  return getApiClient()
    .get(`/workout-resource/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutResourceCSVPromise = (filter, sort, type) => {
  return getApiClient()
    .post('/workout-resource-list', {
      body: { filter, sort, type }
    }).then(resp => {
      return Promise.resolve(resp)
    })
}

export const workoutResourceUploadPromise = (file, id, target) => {
  const formData = new FormData()
  formData.append('parts', file)

  return getApiClient().put('/workout-resource-upload', {
    body: formData,
    params: { id, target },
  })
}

export const workoutResourceCreateOrUpdatePromise = promo => {
  return getApiClient().post('/workout-resource-upsert', {
    body: promo
  }).then(resp => {
    return Promise.resolve(resp.data)
  })
}

export const workoutResourceOptionPromise = (params) => {
  return getApiClient()
    .get('/workout-resources/option', {
      params: { ...params, ...ParamsForOption },
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutResourceBulkCreatePromise = (body) => {
  return getApiClient()
    .post('/workout-resources/bulk-create', { body })
    .then(resp => resp.data)
}

export const getWorkoutSubcategoryPromise = (params) => {
  return getApiClient()
    .get('/workout/subcategories', { params })
    .then(resp => resp.data)
}
