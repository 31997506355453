import { isBlank } from 'common/usual'
import FormItem from 'components/FormItem'
import { Card, Button } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react'
import { upsertPurchaseGroupPromise } from 'api/Product'
import { formatDateTimeToUTC, formatDateTimeUTC } from 'helpers/utils'
import useProductOptions from 'helpers/productOptions'

const requiredColumns = [ 'productId', 'userId', 'originalTransactionId', 'seats', 'companyName', 'domainName', 'title', 'pricePerSeat', 'amount']
export default function Form (props) {
  const { data, history, isEdit } = props
  const [formData, setFormData] = useState({})

  useLayoutEffect(() => {
    setFormData({
      ...data,
      expireTime: formatDateTimeUTC(data.expireTime),
    })
  }, [data])

  const disableBtn = useMemo(() =>
      requiredColumns.find(column => isBlank(formData[column]))
  , [formData])

  const productOptions = useProductOptions(data.productId, true)

  function handleSubmit () {
    upsertPurchaseGroupPromise({
      ...formData,
      expireTime: formatDateTimeToUTC(formData.expireTime),
      domainName: formData.domainName && formData.domainName.split(','),
    }).then(() => history.goBack())
  }

  const handleGroupChange = useCallback((pair) => {
    setFormData({ ...formData, ...pair })
  }, [formData])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Product*'}>
          <AutoCompleteNew
            disabled={isEdit}
            value={formData.productId}
            options={productOptions}
            onChange={(event, selected) => {
              handleGroupChange({ productId: selected && selected.value })
            }}
          />
        </FormItem>

        <FormItem label='User ID*' name={'userId'} value={formData.userId} onChange={handleGroupChange} />
        <FormItem label='Original Transaction ID*' name={'originalTransactionId'} value={formData.originalTransactionId} onChange={handleGroupChange} />
        <FormItem label='Seats*' name={'seats'} value={formData.seats} readonly={isEdit} onChange={handleGroupChange} />
        <FormItem label='Company Name*' name={'companyName'} value={formData.companyName} readonly={isEdit} onChange={handleGroupChange} />
        <FormItem label='Domain Name*' name={'domainName'} value={formData.domainName} readonly={isEdit} onChange={handleGroupChange} placeholder='name1,name2,name3' />
        <FormItem label='Title*' name={'title'} value={formData.title} readonly={isEdit} onChange={handleGroupChange} />
        <FormItem label='Price Per Seat*' name={'pricePerSeat'} value={formData.pricePerSeat} onChange={handleGroupChange} />
        {
          !isEdit && <FormItem label='Amount*' name={'amount'} value={formData.amount} onChange={handleGroupChange} />
        }
        <FormItem name='expireTime' value={formData.expireTime} onChange={handleGroupChange} placeholder='YYYY-MM-DD HH:mm:ss' />

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}