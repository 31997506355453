import React from 'react'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='post id' className={'block-value'} value={params.feedPostId} onChange={(event) => {
            handleSearchChange({ feedPostId: event.target.value })
          }} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='comment id' className={'block-value'} value={params.feedCommentId} onChange={(event) => {
            handleSearchChange({ feedCommentId: event.target.value })
          }} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='poster id' className={'block-value'} value={params.posterId} onChange={(event) => {
            handleSearchChange({ posterId: event.target.value })
          }} />
        </Grid>

        <Grid item xs={6} />
      </Grid>
    </div>
  )
}
