import { getApiClient } from './ApiClient'

export const workoutEquipmentListPromise = (params) => {
  return getApiClient()
    .get('/workout-equipments', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutEquipmentDetailPromise = id => {
  return getApiClient()
    .get(`/workout-equipments/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutEquipmentUpsertPromise = body => {
  return getApiClient()
    .post('/workout-equipments/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
