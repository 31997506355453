// import AddBtn from 'components/AddBtn'
import React, { useCallback } from 'react'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, onSearchChange } = props

  const handleChange = useCallback(event => {
    onSearchChange({ [event.target.name]: event.target.value })
  }, [onSearchChange])

  return <div className={'tool-bar'}>
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Input name='user_id' className='full-width' placeholder='user id' value={params.user_id} onChange={handleChange} />
      </Grid>

      <Grid item xs={2}>
        <Input name='group_id' className='full-width' placeholder='group id' value={params.group_id} onChange={handleChange} />
      </Grid>

      <Grid item xs={8} />

      {/* <Grid container item xs={7} justify='flex-end'>
        <AddBtn color='primary' type='submit' variant='contained' style={{marginRight: 0}}
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/business/corporate-wellness/create`)
          }}
        >
          Add
        </AddBtn>
      </Grid> */}
    </Grid>
  </div>
}
