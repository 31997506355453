import _ from 'lodash'
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { ThemeDefaultCoverMap, ThemeTypeOptions } from 'common/enum'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import { getCategoryOptionRoute, getItemOptionRoute, getTargetOptionRoute, upsertThemePromise } from 'api/Theme'
import ActiveSwitch from 'components/ActiveSwitch'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, history } = props
  const [theme, setTheme] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [targetOptions, setTargetOptions] = useState()
  const [categoryOptions, setCategoryOptions] = useState()
  const [itemOptions, setItemOptions] = useState()

  const requiredColumns = ['type', 'title']
  const disableBtn = useMemo(() => 
    btnStatus || requiredColumns.map(column => isBlank(theme[column])).includes(true)
  , [btnStatus, theme, requiredColumns])

  const getItemIdFromCardImage = useCallback((filename) => {
    if (!filename) return

    const res = filename.match(/-(\d+)\./) || []
    return res[1]
  }, [])

  useEffect(() => {
    // getItemOptionRoute(_type).then(resp => resp && setItemOptions(resp))

    if (theme.type !== 'Recipe') {
      setTargetOptions()
      setCategoryOptions()
      setItemOptions()
      setTheme(t => ({ ...t, card_image: ThemeDefaultCoverMap[theme.type] }))
      return
    }

    getTargetOptionRoute(theme.type).then(resp => resp && setTargetOptions(resp))
    getCategoryOptionRoute(theme.type).then(resp => resp && setCategoryOptions(resp))
    getItemOptionRoute(theme.type).then(resp => resp && setItemOptions(resp.map(({ label, value }) => ({ label: `${label} (${value})`, value: `${value}` }))))
  }, [theme.type])

  useEffect(() => {
    setTheme({ private: true, active: false, type: _.capitalize(data.type), itemIdForCardImage: getItemIdFromCardImage(data.card_image), ...data })
  }, [data, getItemIdFromCardImage])

  function handleSubmit () {
    setBtnStatus(true)
    upsertThemePromise({ ...theme, card_image: theme.card_image || '' })
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  const setValue = useCallback((value) => {
    setTheme({...theme, ...value})
  }, [theme])

  const isNeedDesc = useMemo(() => {
    return !['Course', 'Program', 'Nutrition Program'].includes(theme.type)
  }, [theme.type])

  const isNeedFeaturedForYou = useMemo(() => {
    return ['Workout', 'Recipe'].includes(theme.type)
  }, [theme.type])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        {/* <FormItem label={'ID'} name={'id'} value={theme.id} onChange={setValue}/> */}
        <FormItem label={'Target'}>
          <AutoCompleteNew options={ThemeTypeOptions} placeholder='Type' value={theme.type} disabled={!!data.id}
            onChange={(event, selected) => { setValue({type: selected && selected.value, featured_for_you: false}) }}
          />
        </FormItem>
        <FormItem label={'Title'} name={'title'} value={theme.title} onChange={setValue}/>

        {
          isNeedDesc &&
            <FormItem label={'Description'} name={'description'} value={theme.description} onChange={setValue} multiline={true}/>
        }

        { categoryOptions &&
          <FormItem label={'Category'}>
            <MultipleAutoCompleteSelect options={categoryOptions} values={theme.categories || (theme.recipeCategories || []).map(x => x.id)} onChange={(event, selected) => { setValue({categories: selected.map(s => s.value)}) }} />
          </FormItem>
        }

        { targetOptions &&
          <FormItem label={'Target'}>
            <MultipleAutoCompleteSelect options={targetOptions} values={theme.targets || (theme.recipeTargets || []).map(x => x.id)} onChange={(event, selected) => { setValue({targets: selected.map(s => s.value)}) }} />
          </FormItem>
        }

        {/* { itemOptions &&
            <FormItem label={'Item'}>
              <MultipleAutoCompleteSelect options={itemOptions} values={theme.items || (theme.recipes || []).map(x => x.id)} onChange={(event, selected) => { setValue({items: selected.map(s => s.value)}) }} />
            </FormItem>
        } */}

        {/* {
          ['Program', 'Nutrition Program'].includes(theme.type) &&
            <S3UploaderWithInput label='Cover Photo (966x966)' name={'card_image'} filenames={theme.card_image} fileType={S3PreSignType.Theme}
              setFilenames={filenames => setValue({ card_image: filenames })} />
        } */}

        <FormItem label={'Sort'}>
          <NumberAutoComplete name='sort' from={1} to={100} value={theme.sort} handleSelectChange={(sort) => { setValue({ sort }) }} />
        </FormItem>

        {
          theme.type === 'Recipe' && itemOptions &&
            <FormItem label={'Cover Photo'}>
              <span>{theme.card_image}</span>
              <AutoCompleteNew value={theme.itemIdForCardImage} options={itemOptions} onChange={(event, selected) => { setValue({ itemIdForCardImage: selected && selected.value, card_image: selected && `${theme.id}-${selected.value}.jpg` }) }} />
            </FormItem>
        }

        <FormItem label={'Featured'}>
          <span>{theme.featured}</span>
          <Switch name='featured' checked={Boolean(theme.featured)}
            onChange={ e => setValue({ featured: e.target.checked }) } />
        </FormItem>

        {
          isNeedFeaturedForYou &&
            <FormItem label={'Featured on For You'}>
              <span>{theme.featured_for_you}</span>
              <Switch name='featured_for_you' checked={Boolean(theme.featured_for_you)}
                onChange={ e => setValue({ featured_for_you: e.target.checked }) } />
            </FormItem>
        }

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={theme} setBody={setTheme} isNeedCheck={['Program', 'Nutrition Program', 'Recipe'].includes(theme.type)} columns={[{ key: 'title' }]} />
        </FormItem>
  
        <Button fullWidth className="save-btn" color="primary" size="large" type="submit" variant="contained" onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}
