import { getApiClient } from './ApiClient'

export const workoutTargetListPromise = (params) => {
  return getApiClient()
    .get('/workout-target-all', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}