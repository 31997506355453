import React, { useMemo, useState } from 'react'
import { Card, Button, Input, Grid } from '@material-ui/core'
import { retrieveSubscriptionStatusPromise } from 'api/SubscriptionStatus'
import { isBlank } from 'common/usual'

export default function FunctionSwitchUpdate (props) {
  const [btnStatus, setBtnStatus] = useState(false)
  const [queryString, setQueryString] = useState(null)
  const [result, setResult] = useState({})

  const disableBtn = useMemo(() => (
    btnStatus ||
      isBlank(queryString)
  ), [btnStatus, queryString])

  function handleSubmit () {
    setBtnStatus(true)
    retrieveSubscriptionStatusPromise({ query: queryString })
      .then(res => {
        setResult(res.data)
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>

        <Grid container spacing={0} >
          <Grid container item xs={6}>
            <Input
              className={'full-width'}
              id={'queryString'} name={'queryString'}
              disableUnderline
              value={queryString}
              onChange={event => { setQueryString(event.target.value) }}
              placeholder={'User id or email'} />
          </Grid>
          <Grid container item xs={6}>
            <Button
            className="save-btn"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableBtn} >
              SUBMIT
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <table>
            <tr>
              <td>subscription status:</td>
              <td>{ result.status }</td>
            </tr>
            <tr>
              <td>auto renew status:</td>
              <td>{ `${ !!result.autoRenewStatus }` }</td>
            </tr>
          </table>
        </Grid>

      </Card>

      {/* <div>{JSON.stringify(result)}</div> */}
    </div>
  )
}