import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Button, Card, Switch } from '@material-ui/core'
import { promoDetailPromise, promoCreateOrUpdatePromise } from 'api/Promo'
import { AutoCompleteNew } from 'components/Select'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import useProductOptions from 'helpers/productOptions'

const PromoAdd = props => {
  const {
    match: {
      params: { promoId },
    },
  } = props

  const [promo, setPromo] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const productOptions = useProductOptions(promo.productId1)

  const getPromoDetail = useCallback(async (id) => {
    const response = await promoDetailPromise(id)
    if (response) {
      setPromo({ ...response })
    }
  }, [])

  useEffect(() => {
    getPromoDetail(promoId)
  }, [getPromoDetail, promoId])

  const handleChange = useCallback((...objs) => {
    setPromo(
      assign({}, promo, ...objs)
    )
  }, [promo])

  const onSaveClick = () => {
    setBtnStatus(true)
    promoCreateOrUpdatePromise({ ...promo, groupId: promo.groupId || undefined })
      .finally(() => {
        setBtnStatus(false)
      })
  }

  const commonProps = useMemo(() => ({
    data: promo,
    onChange: handleChange
  }), [promo, handleChange])

  const btnDisabled = useMemo(() => (
    btnStatus || !promo.code || !promo.threshold || !promo.productId1
  ), [btnStatus, promo])

  return (
    <div className={'root'}>
      <Card className={'form-container promo-form'}>
        <FormItem label={'Promo Code'} name={'code'} readonly {...commonProps} />
        <FormItem label={'Threshold'} name={'threshold'} type={'number'} {...commonProps} />
        <FormItem label={'redeemed'} name={'redeemed'} type={'number'} readonly {...commonProps} />

        <FormItem label={'Product'} name={'productId1'} >
          <AutoCompleteNew
            disabled
            options={productOptions}
            value={promo.productId1}
            onChange={(event, selected) => {
              handleChange({ productId1: (selected || {value: null}).value })
            }}
          />
        </FormItem>
        <FormItem label={'Group'} name={'groupId'} type={'number'} readonly {...commonProps} />

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(promo && promo.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          disabled={btnDisabled}
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={onSaveClick}>
          Save
        </Button>
      </Card>
    </div>
  )
}

export default PromoAdd