import { featureBannerContentListPromise } from 'api/FeatureBanner'
import React, { useLayoutEffect, useState } from 'react'
import Toolbar from './toolbar'
import Table from './table'

export default function FeatureBannerContentList (props) {
  const [query, setQuery] = useState({ page: 1, size: 10 })
  const [contents, setContents] = useState([])

  useLayoutEffect(() => {
    featureBannerContentListPromise(query)
      .then(resp => setContents(resp))
  }, [query])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar query={query} setQuery={setQuery} {...props} />
        <br />
        <Table contents={contents} query={query} setQuery={setQuery} {...props} />
      </div>
    </div>
  )
}
