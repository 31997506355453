import React, { useState, useMemo, useCallback } from 'react'
import { Button, Card, Switch } from '@material-ui/core'
import { promoCreateOrUpdatePromise } from 'api/Promo'
import { AutoCompleteNew, AutoCompleteRemote } from 'components/Select'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { isBlank } from 'common/usual'
import './index.scss'
import { fuzzySearchGroupPromise } from 'api/Business'
import useProductOptions from 'helpers/productOptions'
import { ParamsForOption } from 'common/enum'

const PromoAdd = props => {
  const [promo, setPromo] = useState({active: true})
  const [btnStatus, setBtnStatus] = useState(false)

  const productOptions = useProductOptions()

  const fetchGroupFuzzySearch = useCallback((term) => {
    return fuzzySearchGroupPromise({ keywords: term, ...ParamsForOption }).then(data => {
      data.rows.forEach(r => {
        r.label = `${r.name} (${r.id})`
        r.value = r.id
      })
      return Promise.resolve(data)
    })
  }, [])

  const handleChange = useCallback((...objs) => {
    setPromo(
      assign({}, promo, ...objs)
    )
  }, [promo])

  const onSaveClick = () => {
    if (!(promo.code && promo.code.length >= 6 && promo.code.length <= 16)){
      window.alert('Promo code length must between 6 and 16')
      return
    }
    if (!(parseInt(promo.threshold) >= 1 && parseInt(promo.threshold) <= 10000)){
      window.alert('Promo threshold must less than 10000')
      return
    }
    setBtnStatus(true)
    promo.code = promo.code && promo.code.toUpperCase()
    promoCreateOrUpdatePromise(promo)
      .then(resp => {
        props.history.push(`/promos`)
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const commonProps = useMemo(() => ({
    data: promo,
    onChange: handleChange
  }), [promo, handleChange])

  const handlePromoCodeChange = useCallback((obj) => {
    if (obj.code){
      if (obj.code.length > 16) obj.code = obj.code.slice(0, 16)
    }
    setPromo({ ...promo, code: obj.code })
  }, [promo])

  const handleThresholdChange = useCallback((obj) => {
    if (obj.threshold){
      obj.threshold = Math.min(obj.threshold, 10000)
    }
    setPromo({ ...promo, threshold: obj.threshold })
  }, [promo])

  const btnDisabled = useMemo(() => (
    btnStatus ||
      isBlank(promo.code) ||
      isBlank(promo.threshold) ||
      isBlank(promo.productId1)
  ), [btnStatus, promo])

  return (
    <div className={'root'}>
      <Card className={'form-container promo-form'}>
        <FormItem label={'Promo Code'} name={'code'} placeholder={'Length between 6 and 16'} maxLength={'16'} {...commonProps} onChange={handlePromoCodeChange}/>
        <FormItem label={'Threshold'} name={'threshold'} type={'number'} placeholder={'Less than or equal to 10000'} {...commonProps} onChange={handleThresholdChange}/>

        <FormItem label={'Product'} name={'productId1'} >
          <AutoCompleteNew
            options={productOptions}
            value={promo.productId1}
            style={{ width: '300px' }}
            onChange={(event, selected) => {
              handleChange({ productId1: (selected || {value: null}).value })
            }}
          />
        </FormItem>
        <FormItem label={'Group (optional)'} name={'group_id'} >
          <AutoCompleteRemote
            placeholder='Enter a ID or Name'
            value={promo.groupId}
            style={{ width: 300, height: '1.5em' }}
            fetchData={fetchGroupFuzzySearch}
            onChange={(event, selected) => {
              handleChange({ groupId: (selected || {}).value })
            }}
          />
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(promo && promo.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          disabled={btnDisabled}
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={onSaveClick}>
          Add
        </Button>
      </Card>
    </div>
  )
}

export default PromoAdd