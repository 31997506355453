import React, { useEffect, useState } from 'react'
import FormTextField from 'components/FormTextField'
import { Card, Grid, Switch } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { requestSocialBadgeListPromise } from 'api/SocialBadge'
import { ParamsForOption, SocialBadgeDescriptions } from 'common/enum'
import InfoTag from 'components/InfoTag'

export default function (props) {
  const { data, handleChange } = props
  const [socialBadges, setSocialBadges] = useState([])

  useEffect(() => {
    const too = [SocialBadgeDescriptions.Health, SocialBadgeDescriptions.PRO]
    requestSocialBadgeListPromise(ParamsForOption).then(resp => {
      const options = resp
        .filter(foo => !too.includes(foo.description))
        .map(foo => ({ label: foo.name, value: foo.id }))
      setSocialBadges(options)
    })
  }, [])
  
  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
      <Switch name={'active'} checked={Boolean(data.active)} onChange={e => handleChange('active', e.target.checked)} />
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={12}>
        <div className='form-input-elem'>
          <FormTextField name='user_id' label='User ID' value={data.user_id} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className='form-input-elem'>
          <label className='full-width'>
            Social Badge
            &nbsp;
            <InfoTag title='how to make a user trainer: set trainer.personal_user_id under Workout > Trainer' />
          </label>
          <AutoCompleteNew options={socialBadges} value={data.social_badge_id} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => handleChange('social_badge_id', selected && selected.value)}
          />
        </div>
      </Grid>

    </Grid>
  </Card>
}
