import _ from 'lodash'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { editThemeRelationRoute, getItemOptionRoute, getThemeListPromise, getCategoryOptionRoute, getTargetOptionRoute } from 'api/Theme'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { ParamsForOption, ProgramVideoTypes } from 'common/enum'

export default function WorkoutCategoryUpsertForm (props) {
  // kind: recipe course program
  // type: item target category
  const { rawData, history, match: { params: { type } } } = props
  const [disableBtn, setDisableBtn] = useState(false)
  const [themeOptions, setThemeOptions] = useState([])
  const [secondaryOptions, setSecondaryOptions] = useState([])
  const [themeType, setThemeType] = useState()
  const [data, setData] = useState({})
  const typeToMethod = useRef({ target: getTargetOptionRoute , category: getCategoryOptionRoute, item: getItemOptionRoute })
  const specialThemeTypeMap = useRef({
    'Program': foo => _.at(ProgramVideoTypes, ['Video', 'Move-Based']).includes(foo.type),
    'Nutrition Program': foo => foo.type === ProgramVideoTypes.Nutrition
  })

  useEffect(() => {
    setData(_.pick(rawData, ['id', 'theme_id', 'item_id', 'sort']))
  }, [rawData])

  useEffect(() => {
    const themeOption = themeOptions.find(to => to.value === data.theme_id)
    if (!themeOption) return setThemeType()

    setThemeType(themeOption.type)
  }, [data.theme_id, themeOptions])

  function handleSubmit () {
    setDisableBtn(true)

    editThemeRelationRoute(type, [data])
      .then(() => history.goBack())
      .catch(() => setDisableBtn(false))
  }

  const handleChange = useCallback(pair => {
    if (pair.theme_id) data.item_id = null
    setData({ ...data, ...pair })
  }, [data])

  useLayoutEffect(() => {
    getThemeListPromise({ size: 10000, ...ParamsForOption })
      .then(resp => setThemeOptions(
        resp.rows.map(row => ({ label: `${row.title} (${row.id})`, value: row.id, type: row.type }))
      ))
  }, [])

  useEffect(() => {
    setSecondaryOptions([])

    if (!themeType) return

    const _filter = specialThemeTypeMap.current[themeType] || (() => true)
    typeToMethod.current[type](themeType).then(resp => {
      setSecondaryOptions(resp.filter(_filter).map(r => ({ label: `${r.label} (${r.value})`, value: r.value })))
    })
  }, [themeType, type])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Theme'}>
          <AutoCompleteNew value={data.theme_id} options={themeOptions} onChange={(event, selected) => {
            handleChange({ theme_id: selected && selected.value })
          }} />
        </FormItem>

        <FormItem label={'Item'}>
          <AutoCompleteNew value={data.item_id} options={secondaryOptions} onChange={(event, selected) => handleChange({ item_id: selected && selected.value })} />
        </FormItem>

        <FormItem label={'Sort'}>
          <NumberAutoComplete name='sort' from={1} to={100} value={data.sort} handleSelectChange={sort => handleChange({ sort })} />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}
