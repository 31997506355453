import React, { useState, useLayoutEffect } from 'react'
import { workoutCategoryListPromise } from '../../api/WorkoutCategory'
import { WorkoutCategoryTable, WorkoutCategoryToolbar } from './components'

const WorkoutResourceList = props => {
  const [workoutCategories, setWorkoutCategories] = useState([])

  useLayoutEffect(() => {
    async function fetchData() {
      const res = await workoutCategoryListPromise()
      setWorkoutCategories(res)
    }
    fetchData()
  }, []);

  return (
    <div className={'root'}>
      <div className={'content'}>
        <WorkoutCategoryToolbar {...props} />
        <br />
        <WorkoutCategoryTable
          {...props}
          data={workoutCategories || []} 
        />
      </div>
    </div>
  )
}

export default WorkoutResourceList