import React, { useCallback } from 'react'
import { Button, Grid, Input } from '@material-ui/core'
// import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  const navigateToCropCoverView = useCallback(() => {
    props.history.push('/move-workouts/crop-cover')
  }, [props.history])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='workout id' className={'block-value'} value={params.workoutId} onChange={(event) => { handleSearchChange({workoutId: event.target.value}) }} />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2} container justify='flex-end'>
          <Button color='primary' variant='contained' onClick={navigateToCropCoverView}> Crop Cover </Button>
        </Grid>
      </Grid>
    </div>
  )
}
