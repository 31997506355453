import _ from 'lodash'
import FormItem from 'components/FormItem'
import React, { useCallback } from 'react'
import { Input } from '@material-ui/core'
import S3Uploader from './uploader'
import PropTypes from 'prop-types'
import { S3Path } from 'common/enum'

export default function S3UploaderWithInput (props) {
  const { name, filenames, setFilenames, fileType, isPublic, parentPath, subpath, label, isUseable, prefix, filenameMask, children, sizeConstraint, dropzoneText, withInput=true } = props

  const attachPrefix = useCallback((currentValue) => {
    if (!prefix || !currentValue) return setFilenames(currentValue)
    if (currentValue.startsWith(prefix) || currentValue.startsWith(S3Path)) return setFilenames(currentValue)

    return setFilenames(`${prefix}/${currentValue}`)
  }, [prefix, setFilenames])

  // const handleBlur = useCallback(() => {
  //   if (!filenames) return
  //   attachPrefix(filenames.trim())
  // }, [filenames, attachPrefix])

  return <FormItem name={name} label={label || _.startCase(name)}>
    { withInput && <Input id={name} name={name} value={filenames || ''} disableUnderline className={'input-right value'} classes={{ input: 'text' }} style={{ width: '50%' }}
      onChange={ event => attachPrefix(event.target.value) } readOnly /> }
    {/* OR */}
    <S3Uploader multiple={false} filenames={filenames} fileType={fileType} isPublic={isPublic} parentPath={parentPath} subpath={subpath} isUseable={isUseable} filenameMask={filenameMask} sizeConstraint={sizeConstraint} dropzoneText={dropzoneText}
      setFilenames={attachPrefix} />
    { children }
  </FormItem>
}

S3UploaderWithInput.propTypes = {
  name: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  setFilenames: PropTypes.func.isRequired,
}
