import React, { useState, useEffect } from 'react'
import WorkoutCategoryForm from './components'
import { workoutCategoryDetailPromise } from 'api/WorkoutCategory'

export default function WorkoutCategoryAdd (props) {
  const [category, setCategory] = useState({})
  const {
    match: { params: { id } }
  } = props
  
  useEffect(() => {
    async function fetchCategory () {
      const res = await workoutCategoryDetailPromise(id)
      setCategory(res)
    }
    fetchCategory()
  }, [id])

  return (
    <WorkoutCategoryForm data={category} />
  )
}