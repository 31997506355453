import superagent from 'superagent'
import { getApiClient } from './ApiClient'
import { ParamsForOption } from 'common/enum'

export async function uploadFileToS3 (presignedUrl, file) {
  const apiClient = getApiClient()
  apiClient.toggleProcess(true)

  try {
    return await superagent
    .put(presignedUrl)
    .send(file)
  }
  catch (err) { throw err }
  finally { apiClient.toggleProcess(false) }
  
}

export function getTimezonesPromise (params) {
  return getApiClient()
    .get('/timezones', { params })
    .then(resp => resp.data)
}

export function getS3ObjectListPromise (params) {
  return getApiClient()
    .get('/tools/s3/objects', { params })
    .then(resp => resp.data)
}

export async function getVideoDuration (urls) {
  return getApiClient({ needProcess: false })
    .post('/tools/get-video-duration', { body: [].concat(urls) })
    .then(resp => resp.data)
}

export async function getS3ObjectHeadInfo (body) {
  return getApiClient({ needProcess: false })
    .post('/tools/s3/head-info', { body })
    .then(resp => resp.data)
}

export async function copyS3Object (body) {
  return getApiClient()
    .post('/tools/s3/copy-object', { body })
}

export async function compressImage ({ bucket, key }) {
  return getApiClient()
    .post('/tools/lambda/compress-image', { body: { bucket, key } })
    .then(resp => resp.data)
}

export async function getLocaleCountries () {
  return getApiClient()
    .get('/tools/locale-countries', { params: ParamsForOption })
    .then(resp => resp.data)
}

export async function getLocaleLanguages () {
  return getApiClient()
    .get('/tools/locale-languages', { params: ParamsForOption })
    .then(resp => resp.data)
}

export async function getUploadUrlSimplePromise (body) {
  return getApiClient()
    .post('/tools/upload/presigned-url-simple', { body })
    .then(resp => resp.data)
}

export async function deleteS3ObjectPromise (body) {
  return getApiClient()
    .post('/tools/s3/delete-objects', { body })
}

export async function cloudFrontInvalidatePromise (paths, type = 'static') {
  return getApiClient()
    .post('/tools/cloud-front/invalidate', { body: { type, paths } })
}

export async function checkIfS3ObjectExistPromise (body) {
  return getApiClient()
    .post('/tools/s3/check-exist', { body })
    .then(resp => resp.data)
}
