import { Card, Grid } from '@material-ui/core'
import { S3PreSignType, SubtitleLanguageOptions } from 'common/enum'
import { S3Uploader } from 'components/S3UploadInput'
import { AutoCompleteNew } from 'components/Select'
import React, { useState } from 'react'

export default function (props) {
  const [subpath, setSubpath] = useState('en')
  const [files, setFiles] = useState([])

  return <div className={'root'}>
    <Card className={'form-container'}>
      <Grid container spacing={2}>
        <Grid item xs={2} className='text-align-left'>
          *filename should be id.vtt
        </Grid>
        <Grid item xs={10} />

        <Grid item xs={2}>
          <AutoCompleteNew
            value={subpath}
            disableUnderline={false}
            options={SubtitleLanguageOptions}
            onChange={(event, selected) => {
              setSubpath((selected || {value: 'en'}).value)
            }}
          />
        </Grid>

        <Grid item xs={10} className='text-align-left'>
          <S3Uploader multiple={true} filenames={files} fileType={S3PreSignType.WorkoutSubtitle} isPublic={true} subpath={subpath}
            setFilenames={filenames => { setFiles(filenames) }} />
        </Grid>
      </Grid>
    </Card>
  </div>
}
