import _ from 'lodash'
import React, { useCallback } from 'react'
import Input from '@material-ui/core/Input'
import PropTypes from 'prop-types'
import { isBlank } from 'common/usual'
import { Button } from '@material-ui/core'
import { trimString } from 'helpers/utils'
import InfoTag from 'components/InfoTag'

export default function FormItem (props) {
  const {label, name, title, value, onChange, readonly=false, type='text', children, data={}, placeholder, isRequired=false, defaultBtn, multiline=false, ...rest} = props

  const handleChange = useCallback((event) => {
    const value = event.target.value
    if (!isBlank(value) && type === 'number' && !(/^\d*\.?\d*$/).test(value))
      return

    onChange({[name]: isBlank(value) ? null : value})
  }, [name, onChange, type])

  const handleBlur = useCallback(() => {
    const _value = value || data[name]
    if (!_.isString(_value)) return
    if (!_value.includes('/')) return
    const too = trimString(_value)
    if (too === _value) return

    onChange({[name]: too})
  }, [data, name, onChange, value])

  return (
    <div className="info-item">
      <label className="label" htmlFor={name}>
        {label || _.startCase(name)}{isRequired && '*'}
        { title && <InfoTag title={title} /> }
      </label>
      { children ||
        <div className='full-width' style={{ textAlign: 'right' }}>
          <Input {...rest} id={name} multiline={multiline} name={name} /* type={type} */ className={'value'} classes={{ input: 'text' }} disableUnderline
            value={
              !isBlank(value) ? value
                : !isBlank(data[name]) ? data[name]
                : ''
            }
            autoComplete='off'
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={readonly}
            placeholder={placeholder}
            inputProps={{ 'data-lpignore': true }}
          />
          &nbsp;
          { defaultBtn && <Button size='small' variant='outlined' onClick={defaultBtn}>Default</Button> }
        </div>
      }
    </div>
  )
}

FormItem.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.any,
  data: PropTypes.object
}
