import 'assets/scss/form2.scss'
import React, { useMemo, useState, useEffect } from 'react'
import { Button, Card, Grid } from '@material-ui/core'
import GuidePdfUploader from 'components/GuidePdfUploader'
import ProgramWeeklyTask from 'components/ProgramWeeklyTask'
import { upsertProgramPromise } from 'api/program'
import { ProgramVideoTypes } from 'common/enum'

export default function ProgramUpsertStep2(props) {
  const { program, hidden, handleStepChange } = props
  const [step2BtnStatus, setStep2BtnStatus] = useState(false)
  const [guidePdfUrls, setGuidePdfUrls] = useState([])
  const [weeklyTasks, setWeeklyTasks] = useState([])

  const isNutritionProgramType = useMemo(() => program.type === ProgramVideoTypes.Nutrition, [program.type])
  const dailyTask = useMemo(() => program.daily_task, [program.daily_task])

  useEffect(() => {
    if (!program.weekly_tasks) return

    setWeeklyTasks(program.weekly_tasks)
  },[program.weekly_tasks])

  useEffect(() => {
    if (!program.guide_pdf_urls) return

    setGuidePdfUrls(program.guide_pdf_urls)
  }, [program.guide_pdf_urls])

  if (hidden) return <></>

  function handleSubmit() {
    // program.type = send(CourseTypes.find(x => x.value === program.type), 'value')
    // TODO: fix it
    return upsertProgramPromise({ ...program, duration_unit: 'weeks', guide_pdf_urls: guidePdfUrls.filter(g => !g.isDeleted), weekly_tasks: weeklyTasks.filter(t => !t.isDeleted) })
  }

  return <div className='form-elem-container' >
    <Card className='form-card-content'>
      <GuidePdfUploader files={guidePdfUrls} isNutritionProgramType={isNutritionProgramType} setFiles={setGuidePdfUrls} type='ProgramGuidePdfUrl' />

      {isNutritionProgramType && dailyTask && <ProgramWeeklyTask files={weeklyTasks} dailyTask={dailyTask} setFiles={setWeeklyTasks}/>}

      <br />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button fullWidth size='large' color='secondary' type='submit' className='previous-btn' variant='contained' onClick={() => handleStepChange('step1')}>
            Previous
          </Button>
        </Grid>

        <Grid item xs={8}>
          <Button fullWidth className='submit-btn' color='primary' size='large' type='submit' variant='contained' disabled={step2BtnStatus}
            onClick={() => {
              setStep2BtnStatus(true)
              handleSubmit()
                .then(() => props.history.goBack())
                .finally(() => setStep2BtnStatus(false))
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Card>
  </div>
}