import { isBlankV2 } from 'common/usual'
import { localeMapForMoveWorkout } from 'common/enum'
import { generateMoveWorkoutVideos } from 'api/Video'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Grid, Input } from '@material-ui/core'
import MultipleButtonGroups from 'components/MultipleButtonGroups'
import _ from 'lodash'

export default function FunctionSwitchUpdate (props) {
  const [payload, setPayload] = useState({ids: '' })
  const [messages, setMessages] = useState({})
  const types = [{ label: 'video', value: 'video' }, { label: 'subtitle', value: 'subtitle' }]

  const handleConvert = useCallback(async () => {
    generateMoveWorkoutVideos({ ...payload, ids: payload.ids.split(',') })
      .then(resp => setMessages(resp.messages))
  }, [payload])

  const isBtnDisabled = useMemo(() => {
    return isBlankV2(payload.types) || isBlankV2(payload.locales) || isBlankV2(payload.ids)
  }, [payload])

  function truncateBlankSpace (event) {
    return event.target.value.replace(/[^\d|,]/g, '')
  }

  const handlePayloadChange = useCallback((key, val) => {
    setPayload({ ...payload, [key]: val })
  }, [payload])

  const warningMessage = useMemo(() => {
    if (!messages) return
    return Object.entries(messages).reduce((res, message) => {
      const [label, value] = message
      if (!isBlankV2(value)) res = (res || []).concat({ label, value })
      return res
    }, null)
  }, [messages])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container spacing={4}>
          <Grid item xs={12} className='text-align-left'>
            <h3>Generate Move Workout Videos</h3>
          </Grid>

          {
            warningMessage && <Grid container item xs={12} alignItems='flex-start' className='color-red'>
              <ul>
                { warningMessage.map(({ label, value }) => <li key={label}>{_.startCase(label)}: {value.join(',')}</li>) }
              </ul>
            </Grid>
          }

          <Grid container item xs={12}>
            <Grid container item xs={2} alignItems='flex-start'>
              <MultipleButtonGroups name='types' value={payload.types} onChange={handlePayloadChange} options={types} />
            </Grid>
            <Grid container item xs={10} alignItems='flex-start'>
              <MultipleButtonGroups name='locales' value={payload.locales} onChange={handlePayloadChange} options={localeMapForMoveWorkout} />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Input name={'ids'} value={payload.ids} placeholder={'move workout ids (e.g. 1,2,3)'} className={'full-width'}
              onChange={(event) => handlePayloadChange('ids', truncateBlankSpace(event))} />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12} className='text-align-left'>
            <Button color='primary' variant='contained' onClick={handleConvert} disabled={isBtnDisabled}> GENERATE </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
