import { getCourseListPromise } from "api/course"
import { getProgramListPromise } from "api/program"
import { getStickerImageListPromise } from "api/StickerImage"
import { ParamsForOption, S3PreSignType } from "common/enum"

export const languages = ['fr_FR', 'de_DE', 'es_ES', 'pt_BR']

export const kinds = {
  'course': {
    req: () => getCourseListPromise({ size: 10000 }),
    fileType: S3PreSignType.Course,
    columns: [
      { name: 'logo_new', label: 'Logo New (300x160)', subpath: 'logo_new' },
      { name: 'hero_mobile', label: 'App Hero Mobile (837x543)', subpath: 'hero_mobile' },
      { name: 'hero_desktop', label: 'Hero Desktop (1950x1008)', subpath: 'hero_desktop' }
    ]
  },
  'sticker (Social.Filter)': {
    req: () => getStickerImageListPromise({ size: 10000 }),
    fileType: S3PreSignType.StickerImageCover,
    columns: [
      { name: 'image_url', label: 'Image (1029x1284)' },
      { name: 'icon', label: 'Icon (180x180)', subpath: 'icon' }
    ]
  },
  'program': {
    req: () => getProgramListPromise({ size: 10000, ...ParamsForOption }).then(resp => resp.data),
    fileType: S3PreSignType.Program,
    columns: [
      { name: 'cover_url', label: 'Cover (1029x1029)' }
    ]
  }
}