import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const getThemeListPromise = (params) => {
  return getApiClient().get(`/themes`, { params }).then(resp => resp.data)
}

export const getThemeDetailPromise = (id) => {
  return getApiClient().get(`/themes/${id}`).then(resp => resp.data)
}

export const upsertThemePromise = (body) => {
  return getApiClient().post(`/themes`, { body }).then(resp => resp.data)
}

export const getItemOptionRoute = (kind) => {
  return getApiClient().get('/themes/item/options', { params: { kind, ...ParamsForOption } }).then(resp => resp.data.map(x => ({ label: x.key, value: x.value, type: x.type })))
}

export const getTargetOptionRoute = (kind) => {
  return getApiClient().get('/themes/target/options', { params: { kind, ...ParamsForOption } }).then(resp => resp.data.map(x => ({ label: x.key, value: x.value })))
}

export const getCategoryOptionRoute = (kind) => {
  return getApiClient().get('/themes/category/options', { params: { kind, ...ParamsForOption } }).then(resp => resp.data.map(x => ({ label: x.key, value: x.value })))
}

export const getThemeRelationRoute = (params) => {
  return getApiClient()
    .get('/themes/relation', { params })
    .then(resp => resp.data)
}

export const getThemeRelationDetailRoute = (id, params) => {
  return getApiClient()
    .get(`themes/relation/${id}`, { params })
    .then(resp => resp.data)
}

export const editThemeRelationRoute = (type, themeRelation) => {
  return getApiClient()
    .post('/themes/relation', { body: { type, themeRelation } })
    .then(resp => resp.data)
}

export const deleteThemeRelationRoute = id => {
  return getApiClient()
    .delete(`themes/relation/${id}`)
    .then(resp => resp.data)
}
