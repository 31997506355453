import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import EditBtn from 'components/EditBtn'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
} from '@material-ui/core'
import { boolStr } from 'helpers/utils'

const WorkoutCategory = (props) => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Promotion</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data && data.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.type}</TableCell>
                      <TableCell>{record.sku} {record.duration_unit}</TableCell>
                      <TableCell>{boolStr(record.promotion)}</TableCell>
                      <TableCell>{record.price}</TableCell>
                      <TableCell>{boolStr(record.active)}</TableCell>
                      <TableCell>
                        <EditBtn path={`/products/${record.id}/edit`} {...props} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={(data && data.count) || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default WorkoutCategory
