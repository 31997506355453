import React, { useLayoutEffect, useState } from 'react'
import ProgramNutritionForm from './form'
import { send } from 'helpers/utils'

export default function (props) {
  const { week, programId } = send(props.location, 'state', 'params') || {}
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    setData({ week: parseInt(week), program_id: parseInt(programId), active: false })
  }, [programId, week])

  return (
    <ProgramNutritionForm data={data} {...props} />
  )
}
