import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, Input } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='ID' name='id' value={params.id} className={'block-value'} onChange={handleSearchChange} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Name' name='name' value={params.name} className={'block-value'} onChange={handleSearchChange} />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={1} >
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/filters/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
