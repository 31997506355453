import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core'
import { getInitials } from 'helpers'
import './index.scss'
import EditBtn from 'components/EditBtn'

const UsersTable = (props, ref) => {
  const { data, onPageChange, page } = props

  const handlePageChange = (event, page) => {
    onPageChange(page + 1)
  }

  return (
    <Card className={clsx('user-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.rows &&
                  data.rows.map(user => (
                    <TableRow
                      className={'table-row'}
                      hover
                      key={user.id}
                      onClick={() => {
                        props.history.push(`/user/${user.id}`)
                      }}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>
                        <div className={'name-container'}>
                          <Avatar className={'avatar'} src={user.avatar}>
                            {getInitials(user.name || '')}
                          </Avatar>
                          <Typography variant="body1">{user.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.city}, {user.country_or_state}
                      </TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>
                        {moment(user.created_at).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <EditBtn module='user' method='edit' history={props.history} path={`/user/edit/${user.id}`} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'actions'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={handlePageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

UsersTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired
}

export default UsersTable
