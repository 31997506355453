import { Button, Card, CardContent, FormControlLabel, Grid, Input, Radio, RadioGroup } from '@material-ui/core'
import { cropCoverForMoveResourcePromise } from 'api/MoveWorkout'
import React, { useCallback, useEffect, useState } from 'react'

const radioMap = {
  video: { targetSecond: 2, targetColumn: 'video_url' },
  previewVideo: { targetSecond: 5, targetColumn: 'preview_video_url' },
}
export default function Toolbar (props) {
  const [cropAll, setCropAll] = useState(false)
  const [workoutIds, setWorkoutIds] = useState('')
  const [videoColumn, setVideoColumn] = useState('previewVideo')
  
  const handleCropCover = useCallback(() => {
    cropCoverForMoveResourcePromise({ workoutIds, ...radioMap[videoColumn] })
  }, [videoColumn, workoutIds])

  useEffect(() => {
    if (!cropAll) return

    setCropAll(false)
    cropCoverForMoveResourcePromise({ workoutIds: 'all', ...radioMap[videoColumn] })
  }, [cropAll, videoColumn])

  const handleVideoColumnChange = useCallback((event) => {
    setVideoColumn(event.target.value)
  }, [])

  return <div className={'root'}>
    <div className={'content'}>
      <Card>
        <CardContent className={'table-content'}>
          <Grid container>
            <RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={videoColumn} onChange={handleVideoColumnChange}>
              <FormControlLabel value='previewVideo' control={<Radio />} label='Preview Video' />
              <FormControlLabel value='video' control={<Radio />} label='Video' />
            </RadioGroup>
          </Grid>

          <Grid>
            <Button color='primary' variant='contained' onClick={() => setCropAll(true) }>
              CROP PREVIEW COVER FOR ALL MOVE WORKOUTS
            </Button>
          </Grid>

          <br />

          <Grid>OR</Grid>

          <Grid container item xs={12}>
            <Grid container item xs={3}>
              <Input placeholder='11,12,13' className={'block-value'} value={workoutIds} onChange={event => setWorkoutIds(event.target.value)} />
            </Grid>
            <Grid container item xs={9}>
              &nbsp;
              <Button color='primary' variant='contained' onClick={handleCropCover}>
                CROP
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  </div>
}
