import React, { useCallback } from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { deleteSocialFeedPostOrCommentPromise } from 'api/SocialFeed'

const AttachmentTable = props => {
  const { content } = props
  const deleteSocialFeed = useCallback(record => {
    deleteSocialFeedPostOrCommentPromise({ id: record.id, kind: record.kind }).then(() => {
      record.active = false
    })
    
  }, [])

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {content && content.map(record => (
                  <TableRow className={'table-row'} key={record.id}>
                    <TableCell>{record.id}</TableCell>
                    <TableCell>{record.typeText}</TableCell>
                    <TableCell>{record.targetId}</TableCell>
                    <TableCell>{record.sort}</TableCell>
                    <TableCell>{formatDateTime(record.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default AttachmentTable