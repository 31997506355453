import React from 'react'
import { Grid, Input } from '@material-ui/core'
import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='name' className={'block-value'} value={params.name} onChange={(event) => { handleSearchChange({name: event.target.value}) }} />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2} container justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/move-resources/create`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
