import React, { useCallback } from 'react'
import DeleteBtn from 'components/DeleteBtn'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, CardActions, TablePagination } from '@material-ui/core'
import { unlinkUserToPartnerPromise } from 'api/Partner'
import { booleanElement } from 'helpers/utils'

const UserGroupTable = props => {
  const { data, page, onPageChange, reloadData } = props

  const handleUnlink = useCallback((userId) => {
    if (!window.confirm('are you sure?')) return

    unlinkUserToPartnerPromise({ userId }).then(reloadData)
  }, [reloadData])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Partner ID</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Partner User ID</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Unlink</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.partner_id}</TableCell>
                    <TableCell>{row.partner.name}</TableCell>
                    <TableCell>{row.partner_user_id}</TableCell>
                    <TableCell>{booleanElement(row.active)}</TableCell>
                    <TableCell>
                      <DeleteBtn onClick={() => { handleUnlink(row.id) }} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default UserGroupTable