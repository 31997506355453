import './index.scss'
import React, { useState, useEffect } from 'react'
import { orderBy } from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import EditBtn from 'components/EditBtn'
import SortableTableCells from 'components/SortableTableCells'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

const WorkoutCategory = (props) => {
  const { data } = props
  const [categories, setCategories] = useState()

  useEffect(() => {
    setCategories(data)
  }, [data])

  function onOrderByChange ({column, order}) {
    setCategories(orderBy(categories, [column], [order]))
  }

  return (
    <Card className={clsx('workout-category-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCells
                    onOrderByChange={onOrderByChange}
                    cells={[
                      { name: 'id', children: 'ID' },
                      { name: 'name', children: 'Name' },
                      { name: 'value', children: 'Value' },
                      { name: 'deeplink', children: 'Deeplink' },
                      { name: 'enable_search', children: 'Enable Search' },
                      { name: 'is_pro', children: 'Is Pro' },
                      { name: 'created_at', children: 'Created At' },
                    ]}
                  />
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(categories || []).map(category => (
                    <TableRow key={category.id} hover>
                      <TableCell>{category.id}</TableCell>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>{category.value}</TableCell>
                      <TableCell>{category.deeplink}</TableCell>
                      <TableCell>{category.enable_search ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>{category.is_pro  ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>{formatDateTime(category.created_at)}</TableCell>
                      <TableCell>
                        <EditBtn history={props.history} path={`/workout-category/edit/${category.id}`} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

WorkoutCategory.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
}

export default WorkoutCategory
