import React, { useCallback, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import { SimpleModal } from 'components'
import { userReportDeletePromise } from 'api/User'
import SortableTableCells from 'components/SortableTableCells'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
  Button
} from '@material-ui/core'
import { UserReportStatus, UserReportType } from 'common/enum'

const ReportTable = props => {
  const { params, data, page, onPageChange, onOrderByChange, tabValue } = props
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState()

  function handleClose () {
    setModal(false)
    setModalContent(null)
  }

  function handleDeleteReport (report, action) {
    userReportDeletePromise(report.id, action)
    report.status = action
  }

  const statusText = useCallback(status => {
    return UserReportStatus[status] || status
  }, [])

  const isPhotoWallTab = useMemo(() => tabValue === UserReportType.PhotoWall, [tabValue])
  const targetAlias = useMemo(() => {
    const names = {
      [UserReportType.PhotoWall]: 'Photo Name',
      [UserReportType.Challenge]: 'Challenge Name',
    }

    return names[tabValue] || 'target'
  }, [tabValue])

  const cols = useMemo(() => {
    const foo = [
      { name: 'id', children: 'ID' },
      { name: 'user_id', children: 'User' },
    ]

    if (isPhotoWallTab) foo.push(
      { children: 'Photo Wall' },
      { children: 'Private' }
    )

    if (!isPhotoWallTab) foo.push({ children: 'Reason' })

    foo.push(
      { children: targetAlias },
      { name: 'created_at', children: 'Created At' },
      { children: 'Status' }
    )

    return foo
  }, [isPhotoWallTab, targetAlias])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCells
                    cells={cols}
                    onOrderByChange={onOrderByChange}
                    customOrderBy={{ column: params.column, order: params.order }}
                  />
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(report => (
                  <TableRow className={'table-row'} key={report.id}>
                    <TableCell>{report.id}</TableCell>
                    <TableCell>{report.userId}</TableCell>
                    { isPhotoWallTab && <TableCell>{report.photoWallId}</TableCell> }
                    { isPhotoWallTab && <TableCell>{report.photoWall && report.photoWall.isPrivate ? 'TRUE' : 'FALSE'}</TableCell> }
                    { isPhotoWallTab || <TableCell>{report.comment}</TableCell> }
                    <TableCell>
                      {
                        (report.photoWall && <a target='_blank' rel="noopener noreferrer" href={report.photoWall.photoUrl}>{(report.photoWall.photoUrl || '').match(/[^\/]*$/)}</a>) ||
                        (report.challenge && report.challenge && report.challenge.name) ||
                        (report.reportId  && (<p color='blue' onClick={() => { props.history.push(`/user/${report.reportId}`) }}>{report.reportId}</p>))
                      }
                    </TableCell>
                    
                    <TableCell>{formatDateTime(report.createdAt)}</TableCell>
                    <TableCell>
                      {
                        (report.status !== 0 && statusText(report.status)) ||
                        ([
                          <Button onClick={() => { handleDeleteReport(report, 2) }} variant="text">Ignore</Button>,
                          ' | ',
                          <Button onClick={() => { handleDeleteReport(report, 1) }} variant="text">Block</Button>,
                        ])
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>

      <SimpleModal open={modal} handleClose={handleClose} content={modalContent} />
    </Card>
  )
}

export default ReportTable
