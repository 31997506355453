import React from 'react'
import Convert from './convert'
import Generate from './generate'

export default function (props) {
  return <div>
    <Convert />
    <Generate />
  </div>
}
