import _ from 'lodash'
import 'assets/scss/form2.scss'
import StudioCard from './components/studio'
import { Button, Grid } from '@material-ui/core'
import MerchantCard from './components/merchant'
import UserPurchase from './components/userPurchase'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { flexNetworkMerchantVerifyAndUnverifyPromise } from 'api/FlexNetwork'
import { checkPermission } from 'helpers/permission'
import { send } from 'helpers/utils'

export default function MerchantForm (props) {
  const { data, history, params, handleParamsChange } = props
  const [merchant, setMerchant] = useState({})
  const [studios, setStudios] = useState({})
  const [userPurchases, setUserPurchases] = useState({})

  const goBack = useCallback(() => {
    history.push(send(history.location, 'state', 'from') || '/flex-network/merchants')
  }, [history])

  const handleVerifyAndUnverify = useCallback(verified => {
    flexNetworkMerchantVerifyAndUnverifyPromise({ id: merchant.id, verified })
      .then(goBack)
  }, [merchant, goBack])

  useLayoutEffect(() => {
    if (!data) return

    setMerchant(data.merchant)
    setStudios(data.merchantStudios || {})
    setUserPurchases(data.userPurchases || {})
  }, [merchant, data])

  const isPermitted = useMemo(() => checkPermission('108002'), [])

  // const cancelClicked = useCallback(() => {
  //   history.goBack()
  // }, [history])

  // const saveClicked = useCallback(() => {
  //   challengeUpsertPromise(merchant)
  //     .then(() => history.goBack())
  // }, [merchant, history])

  // const isSaveBtnDisabled = false

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2> Edit Merchant </h2>
        <span>
          {/* <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button> */}
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MerchantCard data={merchant} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StudioCard data={studios} params={params} handleParamsChange={handleParamsChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UserPurchase data={userPurchases} params={params} handleParamsChange={handleParamsChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <div style={{display: 'flex', gap: '2px'}}>
        {
          merchant && merchant.verified !== false
            && <Button color="secondary" variant="contained" fullWidth disabled={!isPermitted} onClick={() => handleVerifyAndUnverify(false)}> Unverify </Button>
        }
        
        {
          merchant && merchant.verified !== true
            && <Button color="primary" variant="contained" fullWidth disabled={!isPermitted} onClick={() => handleVerifyAndUnverify(true)}> Verify </Button>
        }
      </div>
    </div>
  </div>
}
