import React from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
// type ["error","info","success","warning"].
const Notification = ({ open, type, message, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open || false}
      autoHideDuration={10000}
      onClose={onClose || (() => {})}>
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

Notification.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func
}

export default Notification