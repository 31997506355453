import { Card } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import Friend from './friend'
import Group from './group'
import UserIdInput from './userIdInput'
import SearchButton from './searchButton'
import UserSocialTable from './table'
import { userDetailPromise } from 'api/User'

const CourseVideoList = props => {
  const [userId, setUserId] = useState('')
  const [data, setData] = useState([])

  const getUserSocialGroup = useCallback(async () => {
    const userDetail = await userDetailPromise(userId)
    const formatted = (userDetail.socialGroups || []).map(userSocialGroup => ({
      id: userSocialGroup.id,
      social_group_id: userSocialGroup.social_group_id,
      social_group_name: (userSocialGroup.social_group || {}).name,
      is_admin: userSocialGroup.is_admin,
      active: userSocialGroup.active,
      created_at: userSocialGroup.created_at,
    }))

    setData(formatted)
  }, [setData, userId])

  return (
    <div className={'root'}>
      <Card className={'form-container promo-form'}>
        <UserIdInput  userId={userId} setUserId={setUserId} />
        <SearchButton userId={userId} data={data} getUserSocialGroup={getUserSocialGroup} />
        <Friend userId={userId} />
        <Group  userId={userId} />

        <br />

        <div>
          <UserSocialTable data={data} getUserSocialGroup={getUserSocialGroup} />
        </div>
      </Card>
    </div>
  )
}

export default CourseVideoList
