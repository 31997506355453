import PropTypes from 'prop-types'
import React from 'react'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'

function MultipleSelect (props) {
  const { values, setValues, options, multiple=true } = props

  return (
    <Select
      multiple={multiple}
      value={values || []}
      onChange={setValues}
      input={<Input disableUnderline />}
    >
      {(options || []).map((o) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  )
}

MultipleSelect.propTypes = {
  values: PropTypes.array,
  setValues: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default MultipleSelect
