import './index.scss'
import React, { useCallback } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

export default function BooleanToggleButtonGroup (props) {
  const { name, label, value, onChange, titles={}, trueTitle, falseTitle, nullTitle, withoutNull } = props

  const handleValueChange = useCallback((event, value) => {
    if (withoutNull && value === null) return

    onChange(name, value)
  }, [withoutNull, onChange, name])

  return <div className='toggle-btn-group'>
    <label>{label}</label>
    <ToggleButtonGroup color='primary' value={value} exclusive onChange={handleValueChange} size='small'>
      <ToggleButton title={trueTitle || titles.true} value={true}  className='btn-true'>True</ToggleButton>
      <ToggleButton title={falseTitle || titles.false} value={false} className='btn-false'>False</ToggleButton>
      { !withoutNull && <ToggleButton title={nullTitle || titles.null} value={null}  className='btn-null'>Null</ToggleButton> }
    </ToggleButtonGroup>
  </div>
}
