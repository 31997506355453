import React, { useState, useEffect } from 'react'
import CampaignForm from './form'
import { featureBannerDetailPromise } from 'api/FeatureBanner'

export default function WorkoutDailyfixAdd (props) {
  const [newCampaign, setNewCampaign] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchFeatureBanner () {
      const campaign = await featureBannerDetailPromise(id)
      setNewCampaign(campaign)
    }
    fetchFeatureBanner()
  }, [id])
  return (
    <CampaignForm { ...props } originalCampaign={newCampaign} />
  )
}
