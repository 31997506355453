import { groupDetailPromise } from 'api/Business'
import React, { useEffect, useState } from 'react'
import Form from './form'

export default function CourseAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({ active: false})

  useEffect(() => {
    if (!id) return

    groupDetailPromise(id).then(resp => {
      resp && setData(resp)
    })
  }, [id])

  useEffect(() => {
  }, [props])

  return <Form isEdit={!!id} data={data} setData={setData} {...props} />
}
