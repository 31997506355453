import React, { useCallback } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // CardActions,
  // TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { FileCopy } from '@material-ui/icons'
import { booleanElement, send } from 'helpers/utils'
import { PlatformIcons } from 'components/PlatformIcons'
import { changeNameByEndDate } from 'views/Marketing/FeatureBannerCampaign/share'

const MealTable = props => {
  const { data, page, onPageChange, history } = props

  const handleDuplicate = useCallback((event, campaign) => {
    const check = window.confirm('Do you want to duplicate this campaign')
    if (check) history.push({
      pathname: '/feature-banner/campaigns/add',
      state: { id: campaign.id }
    })

    event.stopPropagation()
  }, [history])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Placement</TableCell>
                  <TableCell>Platforms</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>User Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(campaign => {
                  let platform = JSON.parse(campaign.target_platform || '[]')

                  return <TableRow className={'table-row cursor-pointer'} key={campaign.id} hover onClick={() => { history.push(`/feature-banner/campaigns/${campaign.id}`) }}>
                    <TableCell>
                      {changeNameByEndDate(campaign)}
                    </TableCell>
                    <TableCell>{send(campaign, 'feature_banner_placement', 'name') }</TableCell>
                    <TableCell>
                      <PlatformIcons platforms={platform} />
                    </TableCell>
                    <TableCell>
                      { booleanElement(campaign.active) }
                    </TableCell>
                    <TableCell>
                      { campaign.target_pro === false || <span className='chip background-color-secondary color-white'>PRO</span> }
                      &nbsp;
                      { campaign.target_pro === true || <span className='chip background-color-grey'>Non-PRO</span> }
                    </TableCell>
                    <TableCell>
                      <EditBtn module='campaign' history={props.history} path={`/feature-banner/campaigns/edit/${campaign.id}`} />
                      &nbsp;&nbsp;
                      <FileCopy className='cursor-pointer' titleAccess='duplicate' onClick={event => handleDuplicate(event, campaign)} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {/* <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions> */}
    </Card>
  )
}

export default MealTable