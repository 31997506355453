import './index.scss'
import { requestCourseWeekDetailPromise, upsertCourseWeekPromise } from 'api/CourseWeek'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Form from './form'
import { Button } from '@material-ui/core'
import { isBlank } from 'common/usual'
import { WeekProgramType } from 'common/enum'

export default function CourseWeekUpsert (props) {
  const { history } = props
  const { id } = props.match.params
  const [data, setData] = useState({ type: WeekProgramType.Course, week_course_recipe_ids: [] })
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [requiredColumns, setRequiredColumns] = useState(new Set())

  useEffect(() => {
    if (!id) return

    requestCourseWeekDetailPromise(id).then(resp => {
      resp && setData({ ...resp, week_course_recipe_ids: (resp.week_course_recipe || []).map(x => x.recipe_id) })
    })
  }, [id])

  const addRequiredColumns = useCallback(column => {
    setRequiredColumns(requiredColumns.add(column))
  }, [requiredColumns])

  const disableBtn = useMemo(() => {
    return isBtnDisabled || Array.from(requiredColumns).map(column => isBlank(data[column])).includes(true)
  }, [data, isBtnDisabled, requiredColumns])

  const handleSubmit = useCallback(() => {
    setIsBtnDisabled(true)

    data.guide_pdf_urls = data.guide_pdf_urls && data.guide_pdf_urls.filter(foo => !isBlank(foo.url) || !isBlank(foo.name))

    upsertCourseWeekPromise(data)
      .then(() => history.goBack())
      .catch(() => setIsBtnDisabled(false))
  }, [data, history])

  return <div className='course-week'>
    <Form data={data} setData={setData} addRequiredColumns={addRequiredColumns} {...props} />

    {/* {
      data.id && <GuidePdf data={data} setData={setData} {...props} />
    } */}

    <div className='submit-btn-container'>
      <Button className='submit-btn' color="primary" size="large" type="submit" variant="contained"
        disabled={disableBtn}
        onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  </div>
}