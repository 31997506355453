import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { fetchWorkoutOptions, optionEnum } from '../concerns/workoutOptions'
import { workoutTrendingUpsertPromise, workoutTrendingTypeListPromise } from 'api/WorkoutTrending'
import { AutoCompleteNew } from 'components/Select'
import { isBlank } from 'common/usual'
import { ParamsForOption } from 'common/enum'

const WorkoutTrending = props => {
  const { trending } = props
  const [data, setData] = useState({})
  const [options, setOptions] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [types, setTypes] = useState([])
  const { resource, partner } = optionEnum

  useEffect(() => {
    setData(trending)
  }, [trending])

  useEffect(() => {
    async function getOptions () {
      const res = await fetchWorkoutOptions(resource, partner)
      setOptions(res)
    }
    getOptions()
  }, [resource, partner])

  useEffect(() => {
    async function getTypes () {
      const res = await workoutTrendingTypeListPromise(ParamsForOption)
      setTypes(res.map(r => ({
        label: r.name,
        value: r.value
      })))
    }
    getTypes()
  }, [])

  useEffect(() => {
    setBtnStatus(isBlank(data.workoutId) || isBlank(data.type))
  }, [data])

  const handleChange = useCallback((...values) => {
    setData(
      assign({}, data, ...values)
    )
  }, [data])

  const handleSubmit = () => {
    setBtnStatus(true)
    workoutTrendingUpsertPromise(data)
      .then(resp => {
        !trending.id && props.history.push('/workout-trendings')
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Type'} name={'type'}>
          <AutoCompleteNew
            options={types || []}
            value={data.type}
            onChange={(event, selected) => {
              handleChange({type: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Workout'} name={'workoutId'}>
          <AutoCompleteNew
            options={options[resource] || []}
            value={data.workoutId || ''}
            onChange={(event, selected) => {
              handleChange({workoutId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Partner'} name={'partnerId'} >
          <AutoCompleteNew
            options={options[partner] || []}
            value={data.partnerId || ''}
            onChange={(event, selected) => {
              handleChange({partnerId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name="active"
            checked={Boolean(data.active)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({active: event.target.checked})
            }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={btnStatus}
         >
          Save
        </Button>
      </Card>
    </div>
  )
}

export default WorkoutTrending