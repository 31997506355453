import React, { useCallback, useState } from 'react'
import { Card, Button, Grid } from '@material-ui/core'
import { updatePurchasePromise } from 'api/Purchase'
import DateTimeUTCInput from 'components/DateTimeUTCInput'
import FormTextField from 'components/FormTextField'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, history } = props
  const [payload, setPayload] = useState({})

  function handleSubmit () {
    updatePurchasePromise(data.id, payload)
      .then(() => history.goBack())
  }

  const handleCancel = useCallback(() => {
    history.goBack()
  }, [history])

  const handleChange = useCallback(pair => {
    setPayload(original => ({ ...original, ...pair }))
  }, [])

  const val = useCallback(key => {
    if (key in payload) return payload[key]

    return data[key]
  }, [data, payload])

  return (
    <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          Edit Purchase
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={handleCancel}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={handleSubmit}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>Expire</label>
            </Grid>

            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <FormTextField name='user_id' label='User ID' readonly={true} value={data.user_id} onChange={() => {}} />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <FormTextField name='sku' label='SKU' readonly={true} value={data.sku} onChange={() => {}} />
                </div>
              </Grid>


              <Grid item xs={12}>
                <DateTimeUTCInput label='Expire' format='YYYY-MM-DD HH:mm:ss' value={val('expire')} handleChange={expire => handleChange({ expire })} />
              </Grid>
              
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>


  </div>
  )
}