import { send } from 'helpers/utils'
import React, { useMemo } from 'react'
import ChallengeForm from './form'
import _ from 'lodash'

export default function ChallengeAdd (props) {
  const challenge = send(props.location, 'state', 'challenge')

  const defaultValueForDuplicate = useMemo(() => {
    if (!challenge) return

    const keys = ['id', 'created_at', 'updated_at', 'cover_horizontal', 'cover_vertical', 'cover_thumbnail', 'male_cover_horizontal', 'male_cover_vertical', 'male_cover_thumbnail']
    if (challenge.challengeStep) keys.push('challengeStep.id', 'challengeStep.challenge_id', 'challengeStep.created_at', 'challengeStep.updated_at')

    return keys.reduce((foo, key) => _.set(foo, key, undefined), {...challenge, source_challenge_id: challenge.id, active: false})
  }, [challenge])

  const defaultValueForCreate = useMemo(() =>
    ({ unit: 'week', private: false, feature: false, senior: true, non_senior: true, female: true, male: true, is_pro: false, active: false, group_challenge: false, social_group_id: null })
  , [])

  return (
    <ChallengeForm data={defaultValueForDuplicate || defaultValueForCreate} {...props} />
  )
}
