import { getApiClient } from './ApiClient'

export const userWorkoutListPromise = (page, size, userId) => {
  const params = { page, size }
  if (userId) params.userId = userId

  return getApiClient()
    .get('/user-workout-list', {
      params: params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}