import { send } from 'helpers/utils'
import { Card } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import _ from 'lodash'

export default function DetailCard (props) {
  const { title, blocks, isTwoColumns, targetDesc, children } = props
  
  return <Card className='detail-card'>
    <div className='detail-card-title'>
      <label className='detail-card-label'>{ title }</label>
    </div>

    { children }

    { blocks && blocks.map(block => (
      <Detail targetDesc={targetDesc} block={block} isTwoColumns={isTwoColumns} />
    ))}
  </Card>
}

export function Detail (props) {
  const { block, isTwoColumns, targetDesc } = props
  const [isShowContent, setIsShowContent] = useState(true)

  const handleHeadClicked = useCallback(() => {
    setIsShowContent(!isShowContent)
  }, [isShowContent])

  return <>
    { block.head && <div onClick={handleHeadClicked} className='cursor-pointer' style={{display: 'flex', alignItems: 'center'}}>
        <h4 className='detail-card-content-head'> {block.head} </h4>
        <ArrowDropDownIcon />
      </div>
    }
    {
      isShowContent &&
      <div className='detail-card-content'>
        {
          block.items && block.items.map(item => (
            item.customElement ||

            <div className={isTwoColumns ? 'detail-card-row-two-columns' : 'detail-card-row'}>
              <span className='detail-card-row-label'>{_.startCase(item.label)}&nbsp;&nbsp;</span>
              <span title={send(targetDesc, item.name, `${item.rawValue}`)} className={`detail-card-row-value ${item.valueClassName}`}>{item.value}</span>
            </div>
          ))
        }
      </div>
    }
  </>
}
