import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { socialGroupOptionPromise, upsertGroupPromise } from 'api/Business'
import { AutoCompleteNew } from 'components/Select'
import { PromoBenefitType, PromoBenefitTypeTypeToValue, S3Path, S3PreSignType } from 'common/enum'
import ChipInForm from 'components/ChipInForm'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import useProductOptions from 'helpers/productOptions'
import { send } from 'helpers/utils'

const requiredColumns = ['deeplink', 'name', 'country_code', 'group_type']
const promoRequiredColumns = ['threshold', 'product_id1']
export default function Form (props) {
  const { isEdit, data, history } = props
  const [groupData, setGroupData] = useState({})
  const [domainData, setDomainData] = useState([])
  const [websiteData, setWebsiteData] = useState([])
  const [promoData, setPromoData] = useState({})
  const [socialGroupOptions, setSocialGroupOptions] = useState([])

  useEffect(() => {
    const { domains, websites, promo, ...group } = data
    setGroupData(group || { active: true })
    setDomainData(domains || [])
    setWebsiteData(websites || [])
    setPromoData(promo || {})
  }, [data])

  // const productOptions = useMemo(() => {
  //   const wellnessSKUs = ['com.fitonapp.corp.smb.yearly.free', 'com.fitonapp.fiton.health.yearly.free']
  //   const foo = groupData.group_type === 1
  //     ? products.filter(f => wellnessSKUs.includes(f.sku))
  //     : products.filter(f => f.active && f.admin)

  //   return foo.map(d => ({ label: d.name, value: d.id }))
  // }, [groupData.group_type, products])

  function handleSubmit () {
    upsertGroupPromise({
      group: groupData,
      promo: promoData,
      domains: domainData.filter(d => d.id || d.active),
      websites: websiteData.filter(d => d.id || d.active),
    }).then(() => history.goBack())
  }

  const handlePromoChange = useCallback(pair => {
    setPromoData({ ...promoData, ...pair })
  }, [promoData])

  const handleGroupChange = useCallback((pair) => {
    if (Object.keys(pair).includes('group_type'))
      handlePromoChange({ product_id1: null })

    setGroupData({ ...groupData, ...pair })
  }, [groupData, handlePromoChange])

  useEffect(() => {
    socialGroupOptionPromise().then(resp => setSocialGroupOptions(resp.map(foo => ({ label: foo.name, value: foo.id }))))
  }, [])  

  const isCorporateWellness = useMemo(() => {
    return groupData.group_type === PromoBenefitTypeTypeToValue.CorporateWellness
  }, [groupData.group_type])

  const productOptions = useProductOptions(send(promoData, 'product_id1'), !isCorporateWellness, !isCorporateWellness, true, isCorporateWellness)

  const disableBtn = useMemo(() => {
    return requiredColumns.find(column => isBlank(groupData[column]))
       || promoRequiredColumns.find(column => isBlank(promoData[column]))
  }, [groupData, promoData])

  const handleDomainChange = useCallback(val => val.replace(/@/g, ''), [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label='Name*' name={'name'} value={groupData.name} onChange={handleGroupChange} />
        <FormItem label='Deeplink*' name='deeplink' value={groupData.deeplink} onChange={handleGroupChange} />
        <FormItem label='Country Code*' name={'country_code'} value={groupData.country_code} onChange={handleGroupChange}/>

        <FormItem label={'Type*'}>
          <AutoCompleteNew
            value={groupData.group_type}
            options={PromoBenefitType}
            onChange={(event, selected) => {
              handleGroupChange({ group_type: selected && selected.value })
            }}
          />
        </FormItem>
        {/* <FormItem name={'social_group_id'} value={groupData.social_group_id} onChange={handleGroupChange} /> */}

        <FormItem label={'Social Group'}>
          <AutoCompleteNew
            value={groupData.social_group_id}
            options={socialGroupOptions}
            onChange={(event, selected) => {
              handleGroupChange({ social_group_id: selected && selected.value })
            }}
          />
        </FormItem>

        <FormItem name={'name_abbr'} value={groupData.name_abbr} onChange={handleGroupChange}/>

        <S3UploaderWithInput label='Logo URL' name={'logo_url'} filenames={groupData.logo_url}
          fileType={S3PreSignType.GroupLogo} prefix={`${S3Path}/group/logo`} filenameMask={/[^/]+$/}
          setFilenames={filename => handleGroupChange({ logo_url: filename })} />

        <FormItem name={'contact_name'} value={groupData.contact_name} onChange={handleGroupChange}/>
        <FormItem name={'contact_title'} value={groupData.contact_title} onChange={handleGroupChange}/>
        <FormItem name={'contact_email'} value={groupData.contact_email} onChange={handleGroupChange}/>
        {/* <FormItem name={'city'} value={groupData.city} onChange={handleGroupChange}/> */}
        {/* <FormItem name={'province'} value={groupData.province} onChange={handleGroupChange}/> */}

        <ChipInForm name='domain' keyName='domain' data={domainData} setData={setDomainData} onChange={handleDomainChange} />

        {/* <FormItem name={'url'} value={websiteData.url} onChange={handleWebsiteChange} /> */}
        <ChipInForm name='website' keyName='url' data={websiteData} setData={setWebsiteData} />

        {/* promo */}
        <FormItem type='number' label='Total Licenses*' name='threshold' value={promoData.threshold} onChange={handlePromoChange} readonly={isEdit && isCorporateWellness} />
        <FormItem label={'Product*'}>
          <AutoCompleteNew
            value={promoData.product_id1}
            options={productOptions}
            onChange={(event, selected) => {
              handlePromoChange({ product_id1: selected && selected.value })
            }}
          />
        </FormItem>
        {/* promo end */}

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(groupData['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => handleGroupChange({active: e.target.checked}) }
          />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}