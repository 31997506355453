import React, { useState, useEffect } from 'react'
import ContentForm from './_form'
import { featureBannerContentDetailPromise } from 'api/FeatureBanner'

export default function WorkoutDailyfixAdd (props) {
  const [newContent, setNewContent] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchFeatureBanner () {
      const { contents, ...content } = await featureBannerContentDetailPromise(id)
      setNewContent(content)
    }
    fetchFeatureBanner()
  }, [id])
  return (
    <ContentForm { ...props } originalContent={newContent} />
  )
}