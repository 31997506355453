import ContentForm from './form'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { flexNetworkMerchantDetailPromise } from 'api/FlexNetwork'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

export default function ChallengeEdit (props) {
  const ref = useRef()
  const location = useLocation()
  const [params, setParams] = useState({ studioPage: 1, userPurchasePage: 1, filters: {} })
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ studioPage: 1, userPurchasePage: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    flexNetworkMerchantDetailPromise(id, too).then(resp => setData(resp))
  }, [id, location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, studioPage: 1, userPurchasePage: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])
  // end

  return (
    <ContentForm {...props} params={params} isEdit={true} data={data} handleParamsChange={handleParamsChange} />
  )
}
