import _ from 'lodash'
// import Context from 'common/context'
import { convertMoveResourceVideo } from 'api/Video'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Grid, Input } from '@material-ui/core'
import { MultipleAutoCompleteSelect } from 'components/Select'
import { ShorthandToCountryAndLanguage } from 'common/enum'

export default function (props) {
  // const { handleNotification } = Context._currentValue
  const [resourceIds, setResourceIds] = useState([])
  const [languages, setLanguages] = useState([ShorthandToCountryAndLanguage[0]])

  const handleConvert = useCallback(async () => {
    const res = await convertMoveResourceVideo({ resourceIds: resourceIds.split(','), languages })
    // if (res.fileNotFound.length > 0) handleNotification('info', `some video files not found: ${res.fileNotFound.join(',')}`)
  }, [languages, resourceIds])

  const isBtnDisabled = useMemo(() => {
    return _.isEmpty(resourceIds) || _.isEmpty(languages)
  }, [languages, resourceIds])

  function truncateBlankSpace (event) {
    return event.target.value.replace(/[^\d|,]/g, '')
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container spacing={4}>
          <Grid item xs={12} className='text-align-left'>
            <h3>Convert Move Resource Videos</h3>
          </Grid>

          <Grid item xs={6}>
            <MultipleAutoCompleteSelect
              values={languages.map(l => l.value)}
              disableUnderline={false}
              options={ShorthandToCountryAndLanguage}
              onChange={(event, selected) => { setLanguages(selected) }} />
          </Grid>
          <Grid item xs={6}/>

          <Grid item xs={6}>
            <Input name={'ids'} value={resourceIds} onChange={(event) => setResourceIds(truncateBlankSpace(event))} placeholder={'move resource ids (e.g. 1,2,3)'} className={'full-width'} />
          </Grid>
          <Grid item xs={6}/>

          <Grid item xs={12} className='text-align-left'>
            <Button color='primary' variant='contained' onClick={handleConvert} disabled={isBtnDisabled}> CONVERT </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}