import React, { useState, useLayoutEffect, useRef, useEffect, useCallback } from 'react'
import Toolbar from './Toolbar'
import Table from './Table'
import { getStickerImageListPromise } from 'api/StickerImage'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import _ from 'lodash'

const FilterList = props => {
  const ref = useRef()
  const location = useLocation()
  const [params, setParams] = useState({ page: 1 })
  const [stickerImages, setStickerImages] = useState({})

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    getStickerImageListPromise(too).then(resp => setStickerImages(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (event) {
    handleParamsChange({ [event.target.name]: event.target.value }, 500)
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar handleSearchChange={handleSearchChange} params={params} {...props} />
        <br />
        <Table
          {...props}
          data={stickerImages}
          page={params.page}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default FilterList
