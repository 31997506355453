import './index.scss'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { getS3ObjectHeadInfo } from 'api/helper'
import { S3Uploader } from 'components/S3UploadInput'
import { assembleI18nImageSrc } from 'helpers/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { S3Bucket, ImageLanguageOptions } from 'common/enum'
import MyModal from 'components/MyModal'

export default function (props) {
  const { label, type, keyName, data, setData, subpath, sizeConstraint } = props
  const [imageStatus, setImageStatus] = useState([])
  const [isShow, setIsShow] = useState(true)
  const [oldName, setOldName] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState()

  // if (!name) return <></>

  const combinePath = useCallback((lan) => `i18n/${lan}`, [])

  const loadHeadInfo = useCallback(() => {
    if (!data[keyName]) return

    const targets = ImageLanguageOptions.map(({value: language}) => ({ type, subpath, name: data[keyName], bucket: S3Bucket, parentPath: combinePath(language) }))
    getS3ObjectHeadInfo(targets).then(data => {
      setImageStatus(data)
    })
  }, [combinePath, data, keyName, subpath, type])

  useEffect(() => {
    if (oldName === data[keyName]) return
    setOldName(data[keyName])

    loadHeadInfo()
  }, [data, keyName, loadHeadInfo, oldName])

  const imageDesc = useCallback((objectInfo) => {
    if (!objectInfo || !objectInfo.info) return 'If image not available, default image (en) will be used'
    
    return objectInfo.info.ContentLanguage === 'en' ? 'Using default image (en)' : 'Using localized version'
  }, [])

  const imageContent = useCallback((objectInfo) => {
    if (!objectInfo || !objectInfo.info) return 'Missing'

    const src = assembleI18nImageSrc(objectInfo)

    return <img src={src} alt='' style={{ maxHeight: '40px', maxWidth: '40px' }} className='cursor-pointer'
      onClick={() => {
        setModalContent(src)
        setModalOpen(true)
      }} />
  }, [])

  return <>
    {/* <S3UploaderWithInput label={label} name={keyName} parentPath={ParentPath.enUS} filenames={data[keyName]} fileType={type} subpath={subpath} isUseable={isUseable} sizeConstraint={sizeConstraint}
          setFilenames={(value) => { setData({ ...data, [keyName]: value })}}>
            {
              isShow
                ? <Remove className='cursor-pointer' onClick={() => setIsShow(false)} />
                : <Add className='cursor-pointer' onClick={() => setIsShow(true)} />
            }
    </S3UploaderWithInput> */}

    { isShow &&
      ImageLanguageOptions.map(language => {
        const isEN = language.value === 'en_us'
        const objectInfo = imageStatus.find(foo => {
          return foo.parentPath === combinePath(language.value)
        })

        return <FormItem key={`${keyName}-${language.value}`} label={isEN ? (label || _.startCase(keyName)) : ''}>
          <Grid container alignItems='center'>
            <Grid item xs={2} key={`thumbnail-${language.value}`}>{ imageContent(objectInfo) }</Grid>
            <Grid item xs={1} key={`language-${language.value}`}>{ language.label }</Grid>
            <Grid item container xs={6} key={`bool-${language.value}`}>
              { isEN ? data[keyName] : imageDesc(objectInfo) }
            </Grid>
            <Grid container item xs={3} justify='flex-end' key={`image-${language.value}`}>
              <S3Uploader parentPath={`i18n/${language.value}`} filenames={data[keyName]} fileType={type} withInput={false} subpath={subpath} afterUpload={loadHeadInfo} sizeConstraint={sizeConstraint}
                setFilenames={filename => (!data[keyName] || !filename) && setData({ ...data, [keyName]: filename })} />
            </Grid>
          </Grid>
        </FormItem>
      })
    }

    <MyModal open={modalOpen} setOpen={setModalOpen} isFullScreen={true}>
      <img className='image-position' alt='' src={modalContent} />
    </MyModal>
  </>
}
