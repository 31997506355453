import React, { useCallback } from 'react'
import { Card } from '@material-ui/core'
import DetailCard from './contentCard'
import { uuidV4 } from 'helpers/utils'

export default function (props) {
  const { placement, data, setData, size, handleChange, updatedContents, payload } = props

  const addNewContent = useCallback(() => {
    setData([{id: uuidV4(), new: true}, ...data])
  }, [data, setData])

  return <Card className='full-width'>
    <div className='form-card-title' style={{width: '100%'}}>
      <label className='form-card-label'>CONTENT</label>
      <label className='form-card-label cursor-pointer' onClick={addNewContent}>+ Add content</label>
    </div>

    {
      data && data.filter(d => !d.delete).map((content, idx) => {
        return <DetailCard key={`content-detail-card-${content.id}`} placement={placement} onChange={handleChange} size={size} idx={idx} content={content} updatedContents={updatedContents} payload={payload} />
      })
    }

  </Card>
}
