import React, { useState, useMemo, useEffect } from 'react'
import DynamicInput from 'components/DynamicInput'
import { Input, Grid } from '@material-ui/core'
import AutoCompleteNew from 'components/Select/AutoCompleteNew'
import { mealIngredientListPromise } from 'api/MealIngredient'
import { mealIngredientCategoryListPromise } from 'api/MealIngredientCategory'
import { mealIngredientPreparationListPromise } from 'api/MealIngredientPreparation'
import { mealIngredientUnitListPromise } from 'api/MealIngredientUnit'
import { ParamsForOption } from 'common/enum'

//  'amount_value', 'ingredient_category_id', 'ingredient_id', 'ingredient_preparation_id', 'ingredient_unit_id

export default function IngredientForm (props) {
  const [ingredientCategories, setIngredientCategories] = useState([])
  const [ingredients, setIngredients] = useState([])
  const [ingredientPreparations, setIngredientPreparations] = useState([])
  const [ingredientUnits, setIngredientUnits] = useState([])

  useEffect(() => {
    async function fetchIngredientCategories () {
      const res = await mealIngredientCategoryListPromise({type: 'option', ...ParamsForOption})
      setIngredientCategories(
        res.map(item => ({
          label: item.title,
          value: item.id
        }))
      )
    }
    fetchIngredientCategories()
  }, [])

  useEffect(() => {
    async function fetchIngredients () {
      const res = await mealIngredientListPromise({type: 'option', ...ParamsForOption})
      setIngredients(
        res.rows.map(item => ({
          label: item.titleSingle,
          value: item.id
        }))
      )
    }
    fetchIngredients()
  }, [])

  useEffect(() => {
    async function fetchIngredientPreparations () {
      const res = await mealIngredientPreparationListPromise({type: 'option', ...ParamsForOption})
      setIngredientPreparations(
        res.rows.map(item => ({
          label: item.title,
          value: item.id
        }))
      )
    }
    fetchIngredientPreparations()
  }, [])

  useEffect(() => {
    async function fetchIngredientUnits () {
      const res = await mealIngredientUnitListPromise({type: 'option', ...ParamsForOption})
      setIngredientUnits(
        res.map(item => ({
          label: item.titleSingle,
          value: item.id
        }))
      )
    }
    fetchIngredientUnits()
  }, [])

  const genElement = useMemo(() => {
    return (index, data, handleChange) => {
      return (
        <>
          <Grid item xs>
            <Input
              type={'number'}
              disableUnderline
              name={'amountValue'}
              className={'full-width'}
              value={ data['amountValue'] || '' }
              placeholder={`amount value ${index}`}
              onChange={(event) => {
                handleChange(index, 'amountValue', event.target.value)
              }}
            />
          </Grid>

          <Grid item xs>
            <AutoCompleteNew
              options={ingredientCategories || []}
              value={data['ingredientCategoryId']}
              placeholder={`ingredient category ${index}`}
              onChange={(event, selected) => {
                handleChange(index, 'ingredientCategoryId', (selected || {value: null}).value)
              }}
            />
          </Grid>

          <Grid item xs>
            <AutoCompleteNew
              options={ingredients || []}
              value={data['ingredientId']}
              placeholder={`ingredient ${index}`}
              onChange={(event, selected) => {
                handleChange(index, 'ingredientId', (selected || {value: null}).value)
              }}
            />
          </Grid>

          <Grid item xs>
            <AutoCompleteNew
              options={ingredientPreparations || []}
              value={data['ingredientPreparationId']}
              placeholder={`ingredient preparation ${index}`}
              onChange={(event, selected) => {
                handleChange(index, 'ingredientPreparationId', (selected || {value: null}).value)
              }}
            />
          </Grid>

          <Grid item xs>
            <AutoCompleteNew
              options={ingredientUnits || []}
              value={data['ingredientUnitId']}
              placeholder={`ingredient unit ${index}`}
              onChange={(event, selected) => {
                handleChange(index, 'ingredientUnitId', (selected || {value: null}).value)
              }}
            />
          </Grid>
        </>
      )
    }
  }, [ingredientCategories, ingredientPreparations, ingredientUnits, ingredients])

  return (
    <DynamicInput
      {...props}
      label={'Ingredient'}
      keyName={'ingredients'}
      genElement={genElement}
    />
  )
}