import React, { useState, useMemo, useEffect } from 'react'
import { Card } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { AutoCompleteNew } from 'components/Select'
import { kinds, languages } from './uploader'

export default function ProgramTableUpsertForm (props) {
  const [kind, setKind] = useState()
  const [language, setLanguage] = useState('fr_fr')
  const [currentKindInstances, setCurrentKindInstances] = useState()
  const [instanceId, setInstanceId] = useState()

  const languageOptions = useMemo(() => {
    return languages.map(language => ({ label: language, value: language.toLowerCase() }))
  }, [])

  const kindOptions = useMemo(() => {
    return Object.keys(kinds).map(key => ({ label: key, value: key }))
  }, [])

  useEffect(() => {
    if (!kinds[kind]) return setCurrentKindInstances(null)

    kinds[kind].req().then(resp => setCurrentKindInstances(resp.rows))
  }, [kind])

  const instanceOptions = useMemo(() => {
    if (!currentKindInstances) return

    return currentKindInstances.map(foo => ({ label: foo.name, value: foo.id }))
  }, [currentKindInstances])

  const currentInstance = useMemo(() => {
    if (!instanceId || !currentKindInstances) return

    return currentKindInstances.find(foo => foo.id === instanceId)
  }, [currentKindInstances, instanceId])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Language'}>
          <AutoCompleteNew value={language} options={languageOptions}
            onChange={(event, selected) => setLanguage(selected && selected.value)}
          />
        </FormItem>

        <FormItem label={'Kind'}>
          <AutoCompleteNew value={kind} options={kindOptions}
            onChange={(event, selected) => setKind(selected && selected.value)}
          />
        </FormItem>

        { instanceOptions &&
          <FormItem label={'Instance'}>
            <AutoCompleteNew value={instanceId} options={instanceOptions || []}
              onChange={(event, selected) => setInstanceId(selected && selected.value)}
            />
          </FormItem>
        }

        {
          kind && currentInstance &&
          kinds[kind].columns.map(column =>
            <S3UploaderWithInput key={column.name} name={column.name} parentPath={`i18n/${language.toLowerCase()}`} filenames={currentInstance[column.name]}
              fileType={kinds[kind].fileType} subpath={column.subpath}
              setFilenames={value => 123}
          />)
        }

      </Card>
    </div>
  )
}