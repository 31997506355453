import React, { useMemo } from 'react'
import { AutoCompleteNew } from 'components/Select'
import countryCities from '../../../assets/worldCities.json'
import countryCodes from '../../../assets/countryCodes.json'

const CountryCitiesSelector = props => {
  const { data, handleSelectChange } = props

  const options = useMemo(() => (
    countryCities.map(c => ({
        label: `${c.name}, ${c.country}`,
        value: `${c.name}-${countryCodes[c.country]}`,
        country: c.country,
        city: c.name,
      }))
  ), [])

  const handleLocationChange = (event, location) => {
    const value = !location
      ? { country_or_state: null, city: null }
      : { country_or_state: location.country && countryCodes[location.country], city: location.city }

    handleSelectChange(value)
  }

  return (
    <AutoCompleteNew
      label='City & Country'
      value={`${data.city}-${data.country_or_state}`}
      options={options}
      onChange={handleLocationChange}
    />
  )

}

export default CountryCitiesSelector