import React, { useState, useMemo, useCallback } from 'react'
import { Button, Card } from '@material-ui/core'
import { promoBulkCreatePromise } from 'api/Promo'
import { AutoCompleteNew, AutoCompleteRemote } from 'components/Select'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { isBlank } from 'common/usual'
import download from 'downloadjs'
import './index.scss'
import { fuzzySearchGroupPromise } from 'api/Business'
import useProductOptions from 'helpers/productOptions'
import { ParamsForOption } from 'common/enum'

const PromoAdd = props => {
  const [promo, setPromo] = useState({is_download: true})
  const [btnStatus, setBtnStatus] = useState(false)

  const productOptions = useProductOptions()

  const handleChange = useCallback((...objs) => {
    setPromo(
      assign({}, promo, ...objs)
    )
  }, [promo])

  const handleQuantityChange = useCallback(({quantity}) => {
    if (quantity <= 10000) return handleChange({ quantity })
  }, [handleChange])

  const fetchGroupFuzzySearch = useCallback((term) => {
    return fuzzySearchGroupPromise({ keywords: term, ...ParamsForOption }).then(data => {
      data.rows.forEach(r => {
        r.label = `${r.name} (${r.id})`
        r.value = r.id
      })
      return Promise.resolve(data)
    })
  }, [])

  const onSaveClick = () => {
    setBtnStatus(true)
    promoBulkCreatePromise(promo)
      .then(resp => {
        setPromo({ group_id: null })
        download(resp, 'promos.csv', 'application/csv')
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const btnDisabled = useMemo(() => (
    btnStatus ||
      isBlank(promo.product_id1) ||
      isBlank(promo.group_id) ||
      isBlank(promo.quantity)
  ), [btnStatus, promo])

  return (
    <div className={'root'}>
      <Card className={'form-container promo-form'}>

        <FormItem label={'Product'} name={'productId1'}>
          <AutoCompleteNew
            options={productOptions}
            value={promo.product_id1}
            type='number'
            onChange={(event, selected) => {
              handleChange({ product_id1: (selected || {value: null}).value })
            }}
          />
        </FormItem>

        <FormItem label={'Group'} name={'group_id'} >
          <AutoCompleteRemote
            value={promo.group_id}
            placeholder='Enter a ID or Name'
            style={{ width: 300, height: '1.5em' }}
            fetchData={fetchGroupFuzzySearch}
            onChange={(event, selected) => {
              handleChange({ group_id: (selected || {}).value })
            }}
          />
        </FormItem>
        
        <FormItem label={'Quantity'} type='number' name={'quantity'} value={promo.quantity} onChange={handleQuantityChange} placeholder='cannot exceed 10,000' />

        <Button
          disabled={btnDisabled}
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={onSaveClick}>
          Add
        </Button>
      </Card>
    </div>
  )
}

export default PromoAdd