import './index.scss'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew, AutoCompleteRemote } from 'components/Select'
import { requestSocialGroupListPromise } from 'api/SocialGroup'
import { isBlankV2 } from 'common/usual'
import { ParamsForOption, SocialFeedPostTypeOptions } from 'common/enum'

const spamOptions = [{ label: 'All', value: undefined }, { label: 'Spam', value: 'true' }]

const PostAndCommentToolbar = props => {
  const { params, handleSearchChange } = props
  const [optionsOutside, setOptionsOutside] = useState([])

  const fetchSocialGroupOptions = useCallback((name) => {
    if (!name) return []
    return requestSocialGroupListPromise({ name, ...ParamsForOption })
      .then(resp => resp.rows.map(row => ({ label: row.name, value: `${row.id}` })))
  }, [])

  useEffect(() => {
    if (!isBlankV2(optionsOutside)) return

    const payload = { ...ParamsForOption }
    if (params.socialGroupId) payload.id = params.socialGroupId
    requestSocialGroupListPromise(payload).then(resp =>
      setOptionsOutside(resp.rows.map(row => ({ label: row.name, value: `${row.id}` })))
    )
  }, [optionsOutside, params.socialGroupId])

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Input value={params.id} placeholder='Post ID' className='full-width' onChange={(event) => { handleSearchChange({ id: event.target.value }, 500) }} />
      </Grid>

      <Grid item xs={2}>
        <Input value={params.userId} placeholder='User ID' className='full-width' onChange={event => { handleSearchChange({ userId: event.target.value }, 500) }} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteRemote
          inputLeft={true}
          placeholder='Group Name'
          disableUnderline={false}
          value={params.socialGroupId}
          optionsOutside={optionsOutside}
          fetchData={fetchSocialGroupOptions}
          onChange={(event, selected) => handleSearchChange({socialGroupId: selected && selected.value})}
        />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew placeholder='Post Type' inputLeft={true} value={params.postType} disableUnderline={false} options={SocialFeedPostTypeOptions} onChange={(event, selected) => handleSearchChange({postType: selected && selected.value}) } />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew placeholder='Spam' inputLeft={true} value={params.spam} disableUnderline={false} options={spamOptions} onChange={(event, selected) => handleSearchChange({spam: selected && selected.value}) } />
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>
  )
}

PostAndCommentToolbar.propTypes = {
  className: PropTypes.string,
}

export default PostAndCommentToolbar
