import React from 'react'
import PropTypes from 'prop-types'
import { AutoCompleteNew } from 'components/Select'
import { Input, TextField } from '@material-ui/core'
import { UserReportStatus } from 'common/enum'

const ContentToolbar = props => {
  const { params, onChange, onSearchChange } = props
  const statusEnum = Object.keys(UserReportStatus).map(key => ({ label: UserReportStatus[key], value: key }))

  return (
    <div style={{ display: 'flex' }} className={'userReportToolbar'}>
      <TextField placeholder='Reported User ID' name='reportedUserId' value={params.reportedUserId} onChange={onChange} style={{'padding-top':'13px'}} inputProps={{ 'data-lpignore': true }} />
      &nbsp;
      <TextField placeholder='Reported by user id' name='reporterId' value={params.reporterId} onChange={onChange} style={{'padding-top':'13px'}} inputProps={{ 'data-lpignore': true }} />
      &nbsp;
      <AutoCompleteNew
        label={'Status'}
        options={statusEnum}
        disableUnderline={false}
        value={`${params.status}`}
        onChange={(event, selected)=>{
          onSearchChange({ status: (selected || {}).value }, 0)
        }}
      />
    </div>
  )
}

ContentToolbar.propTypes = {
  className: PropTypes.string,
}

export default ContentToolbar
