import { getApiClient } from './ApiClient'

export const getProgramTipListPromise = (params) => {
  return getApiClient()
    .get('/program-tips', { params })
    .then(resp => resp.data)
}

export const getProgramTipByIdPromise = id => {
  return getApiClient()
    .get(`/program-tips/${id}`)
    .then(resp => resp.data)
}

export const upsertProgramTipPromise = (body) => {
  return getApiClient()
    .post('/program-tips', { body })
    .then(resp => resp.data)
}
