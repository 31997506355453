import { getThemeRelationDetailRoute } from 'api/Theme'
import React, { useEffect, useState } from 'react'
import Form from './form'

export default function (props) {
  const { type, id } = props.match.params
  const [rawData, setRawData] = useState({})

  useEffect(() => {
    getThemeRelationDetailRoute(id, { type })
      .then(resp => {
        setRawData(resp)
      })
  }, [id, type])
  
  return <Form rawData={rawData} {...props} />
}
