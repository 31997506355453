import './index.scss'
import React, { useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { productUpsertPromise } from 'api/Product'
import ActiveSwitch from 'components/ActiveSwitch'
import { AutoCompleteNew } from 'components/Select'
import { TimeUnit } from 'common/enum'

const requiredColumns = [{ key: 'sku' }]
export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, history } = props
  const [product, setProduct] = useState({})

  useEffect(() => {
    setProduct({
      oldId: data.id,
      ...data,
    })
  }, [data])

  function handleSubmit () {
    productUpsertPromise(product)
      .then(() => history.goBack())
  }

  const handleValueChange = useCallback((map) => {
    setProduct({ ...product, ...map })
  }, [product])

  const plainInput = useCallback((name, other) => 
    <FormItem name={name} value={product[name]} {...other}
      onChange={map => { setProduct({ ...product, ...map }) } } />
  , [product])

  const plainSwitch = useCallback((name, desc) =>
    <FormItem name={name} title={desc}>
      <Switch name={name} checked={Boolean(product[name])} onChange={ e => handleValueChange({ [name]: e.target.checked }) } />
    </FormItem>
  , [product, handleValueChange])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { plainInput('id', { label: 'ID', placeholder: 'If you want to use the system ID, leave it blank' }) }
        { plainInput('sku', { label: 'SKU' }) }
        { plainInput('name') }
        { plainInput('type') }

        <FormItem label={'Version'}>
          <NumberAutoComplete name='version' from={1} to={10} value={product.version} handleSelectChange={version => { handleValueChange({ version }) }} />
        </FormItem>
        <FormItem label={'Duration'}>
          <NumberAutoComplete name='duration' from={1} to={100} value={product.duration} handleSelectChange={duration => { handleValueChange({ duration }) }} />
        </FormItem>
        <FormItem label={'Unit'}>
          <AutoCompleteNew value={product.unit} options={TimeUnit} onChange={(event, selected) => handleValueChange({ unit: selected && selected.value })} />
        </FormItem>
        <FormItem label={'Discount'}>
          <NumberAutoComplete name='discount' from={1} to={100} value={product.discount} handleSelectChange={discount => { handleValueChange({ discount }) }} />
        </FormItem>
        <FormItem label={'Trial Days'}>
          <NumberAutoComplete name='trial_days' from={1} to={100} value={product.trial_days} handleSelectChange={trial_days => { handleValueChange({ trial_days }) }} />
        </FormItem>

        { plainInput('price', { type: 'number' }) }
        { plainInput('front_price', { type: 'number' }) }
        { plainInput('original_price', { type: 'number' }) }
        { plainInput('stripe_plan_id') }
        { plainInput('stripe_product_id') }
        { plainInput('promotional_sku') }

        { plainSwitch('popular') }
        { plainSwitch('promotion') }
        { plainSwitch('app_known_sku', 'this SKU is in Apple in-app purchase or not, iOS side will do different showing/redirect action according to this column') }
        { plainSwitch('pro') }
        { plainSwitch('health') }
        { plainSwitch('admin') }

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={product} columns={requiredColumns} setBody={setProduct} isNeedCheck={true} />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}