import React, { useState, useEffect } from 'react'
import PartnerWorkoutCategoryForm from './_form'
import { partnerWorkoutCategoryDetailPromise } from 'api/PartnerWorkoutCategory'

export default function PartnerWorkoutCategoryEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchPartnerWorkoutCategory () {
      setData(
        await partnerWorkoutCategoryDetailPromise(id)
      )
    }
    fetchPartnerWorkoutCategory()
  }, [id])
  
  return (
    <PartnerWorkoutCategoryForm data={data} {...props} />
  )
}