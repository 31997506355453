import './index.scss'
import React, { useState, useCallback } from 'react'
import { Button, Card } from '@material-ui/core'
import { Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import UploadItem from 'components/UploadItem'

export default function WorkoutResourceAddStep2 (props) {
  const { data, handleChange, handleStepChange, hidden, handleSubmit } = props
  const [step2BtnStatus, setStep2BtnStatus] = useState(false)

  const commonProps = useCallback((name) => ({
    name,
    data,
    handleChange,
  }), [data, handleChange])

  return (
    <div className={'root workout-resource-add workout-category step2'} hidden={hidden}>
      <Card className={'form-container'}>
        <FormItem label={'Cover'} name={'cover_url'}>
          <UploadItem {...commonProps('cover_url')} />
        </FormItem>

        <FormItem label={'Cover Big'} name={'cover_url_big'}>
          <UploadItem {...commonProps('cover_url_big')} />
        </FormItem>

        <FormItem label={'Male Cover'} name={'male_cover_url'}>
          <UploadItem {...commonProps('male_cover_url')} />
        </FormItem>

        <FormItem label={'Male Cover Big'} name={'male_cover_url_big'}>
          <UploadItem {...commonProps('male_cover_url_big')} />
        </FormItem>

        <div className="info-item">
          <span className="label">Active</span>
          <Switch
            name="active"
            checked={Boolean(data.active)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({active: event.target.checked})
            }}
          />
        </div>

        <div className="info-item">
          <Button
            className="previous-btn"
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              handleStepChange('step1')
            }}
          >
            Previous
          </Button>
          &nbsp;
          <Button
            className="submit-btn"
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={step2BtnStatus}
            onClick={ () => {
              setStep2BtnStatus(true)
              handleSubmit().finally(() => {
                setStep2BtnStatus(false)
              })
            }}
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  )
}