import React from 'react'
import { Grid, Input } from '@material-ui/core'
import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  function handleQueryChange (event) {
    handleSearchChange({ [event.target.name]: event.target.value })
  }

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='ID' name='id' value={params.id} className={'block-value'} onChange={handleQueryChange} />
        </Grid>
        <Grid item xs={2}>
          <Input placeholder='Name' name='name' value={params.name} className={'block-value'} onChange={handleQueryChange} />
        </Grid>
        <Grid item xs={7} />
        <Grid container item xs={1} justify='flex-end'>
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/trainers/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
