import React, { useCallback } from 'react'
import DynamicInput from 'components/DynamicInput'
import { Input, Grid } from '@material-ui/core'

export default function NoteInput (props) {

  const genElement = useCallback((...names) => {
    return (index, data, handleChange) => {
      return (
        <>
          {
            names.map(name => (
              <Grid item xs key={name}>
                <Input
                  disableUnderline
                  name={name}
                  className={'full-width'}
                  value={ data[name] || '' }
                  placeholder={`${name} ${index}`}
                  onChange={(event) => {
                    handleChange(index, name, event.target.value)
                  }}
                />
              </Grid>
            ))
          }
        </>
      )
    }
  }, [])

  return (
    <DynamicInput
      {...props}
      label={'Note'}
      keyName={'details'}
      genElement={genElement('heading', 'title', 'description')}
    />
  )
}
