import _ from 'lodash'
import 'assets/scss/form2.scss'
import React, { useCallback, useState } from 'react'
import { Button, Card, Grid } from '@material-ui/core'
import UploaderForSingleImage from 'components/UploaderForSingleImage'
import ActiveSwitch from 'components/ActiveSwitch'

export default function WorkoutResourceStep2 (props) {
  const { hidden, action, handleStepChange, rawWorkoutResource, workoutResource, handleSubmit, setWorkoutResource } = props
  const [step2BtnStatus, setStep2BtnStatus] = useState(false)

  const handleChange = useCallback((key, val) => {
    setWorkoutResource({ ...workoutResource, [key]: val })
  }, [setWorkoutResource, workoutResource])

  if (hidden) return <></>
  
  const columnsNeedCheck = [
    { key: 'name' },
    { key: 'intensity', label: 'Intensity' },
    { key: 'category_id', label: 'Primary Category' },
    { key: 'continue_time', label: 'Time' },
    { key: 'avg_rating' },
    { key: 'video_url_new', label: 'Video URL' },
    { key: 'trainer_id', label: 'Trainer' },
    { key: 'workout_equipment', label: 'Equipment Text' },
    { key: 'continue_time', label: 'Time' },
    { key: 'cover_url_horizontal' },
    { key: 'cover_url_vertical' },
    { key: 'cover_url_thumbnail' },
  ]

  const assets = [
    { key: 'cover_url_horizontal', pathInName: '960x756' },
    { key: 'cover_url_vertical', pathInName: '1005x1260' },
    { key: 'cover_url_thumbnail', pathInName: '280x380' },
    { key: 'cover_url_big', pathInName: '2224x880' },
  ]

  return <div className='form-elem-container'>
    <Card className='form-card-content'>
      <Grid container item xs={12}>
        {
          assets.map(({ key, pathInName }) => <Grid item xs={12} key={key}>
            <div style={{paddingTop: '10px'}}>
              <div>
                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                  <span>
                    {_.startCase(key)} (PNG, JPG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image size: <b>{pathInName} px</b>
                  </span>
                </div>
              </div>

              <div style={{marginTop: '15px'}}>
                <UploaderForSingleImage
                  keyName={key}
                  pathInFilename={pathInName}
                  handleChange={handleChange}
                  preferName={workoutResource.id}
                  filename={workoutResource[key]}
                  fileType='WorkoutResourceCoverBasePath'
                />
              </div>
            </div>
          </Grid>)
        }

        <Grid container style={{marginTop: '30px'}}>
          <Grid item container alignItems='center' xs={4}>
            Active
          </Grid>
          <Grid container item xs={8} justify='flex-end'>
            <ActiveSwitch mode={action} body={workoutResource} setBody={setWorkoutResource} isNeedCheck={true} columns={columnsNeedCheck} disabled={rawWorkoutResource.active} />
          </Grid>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button fullWidth size='large' color='secondary' type='submit' className='previous-btn' variant='contained' onClick={() => handleStepChange('step1')}>
            Previous
          </Button>
        </Grid>

        <Grid item xs={8}>
          <Button fullWidth className='submit-btn' color='primary' size='large' type='submit' variant='contained' disabled={step2BtnStatus}
            onClick={ () => {
              setStep2BtnStatus(true)
              if (rawWorkoutResource.active && !workoutResource.active && !window.confirm('you are trying to inactive this workout'))
                return setStep2BtnStatus(false)

              handleSubmit()
                .then(() => props.history.goBack())
                .finally(() => setStep2BtnStatus(false))
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Card>
  </div>
}
