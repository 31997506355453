import { CourseTypes, ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const getUploadUrlPromise = (body) => {
    return getApiClient()
        .post('tools/upload/presigned-url', { body })
        .then(resp => {
            return Promise.resolve(resp.data)
        })
}

export const getProgramByIdPromise = id => {
    return getApiClient()
        .get(`/program/${id}`)
        .then(resp => Promise.resolve(resp.data))
}

export const getProgramListPromise = (params) => {
    return getApiClient()
        .get('/program', { params })
        .then(resp => Promise.resolve(resp))
}

export const upsertProgramPromise = (body) => {
    return getApiClient()
        .post('/program', { body })
        .then(resp => resp.data)
}

export const options = {
    courseType: () => CourseTypes.map(ct => ({ key: ct.label, value: ct.num })),
    courseProduct: () => getApiClient().get('/course/sku', { params: ParamsForOption }).then(resp => resp.data),
    courseSocialGroup: () => getApiClient().get('/course/group', { params: ParamsForOption }).then(resp => resp.data),
    courseTrainer: () => getApiClient().get('/course/trainer', { params: ParamsForOption }).then(resp => resp.data.map(d => ({ key: d.key, value: d.value }))),
}
