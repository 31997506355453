import Form from './form'
import { getFeatureSetDetailRoute } from 'api/Product'
import React, { useState, useLayoutEffect } from 'react'

export default function ChallengeEdit (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getFeatureSetDetailRoute(id).then(setData)
  }, [id])
  return (
    <Form { ...props } data={data} />
  )
}
