import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import EditBtn from 'components/EditBtn'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
} from '@material-ui/core'

const ProgramTable = (props) => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Program ID</TableCell>
                  <TableCell>Start At</TableCell>
                  <TableCell>End At</TableCell>
                  <TableCell>Quit At</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data && data.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.user_id}</TableCell>
                      <TableCell>{record.program_id}</TableCell>
                      <TableCell>{formatDateTime(record.start_at)}</TableCell>
                      <TableCell>{formatDateTime(record.end_at)}</TableCell>
                      <TableCell>{formatDateTime(record.quit_at)}</TableCell>
                      <TableCell>{formatDateTime(record.created_at)}</TableCell>
                      <TableCell>{record.active ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>
                        <EditBtn history={props.history} path={`/user-programs/edit/${record.id}`} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={(data && data.count) || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramTable
