import './index.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'

const spamOptions = [{ label: 'All', value: undefined }, { label: 'Spam', value: 'true' }]
const PostAndCommentToolbar = props => {
  const { params, handleSearchChange } = props

  function handleInputChange (event) {
    handleSearchChange({ [event.target.name]: event.target.value }, 500)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Input name='id' value={params.id} placeholder='ID' className='full-width' onChange={handleInputChange} />
      </Grid>

      <Grid item xs={2}>
        <Input name='userId' value={params.userId} placeholder='User ID' className='full-width' onChange={handleInputChange} />
      </Grid>

      <Grid item xs={2}>
        <Input name='postId' value={params.postId} placeholder='Post ID' className='full-width' onChange={handleInputChange} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew placeholder='Spam' value={params.spam} disableUnderline={false} options={spamOptions} onChange={(event, selected) => { handleSearchChange({spam: selected && selected.value}) }} />
      </Grid>
    </Grid>
  )
}

PostAndCommentToolbar.propTypes = {
  className: PropTypes.string,
}

export default PostAndCommentToolbar
