import React, { useCallback, useState } from 'react'
import { addOrRemoveFriend } from 'api/User'
import Form from './form'

export default function (props) {
  const { userId } = props
  const [friendId, setFriendId] = useState('')

  const addFriend = useCallback(() => {
    addOrRemoveFriend({ action: 'add', userId, friendId })
  }, [friendId, userId])
  
  const removeFriend = useCallback(() => {
    addOrRemoveFriend({ action: 'remove', userId, friendId })
  }, [friendId, userId])

  return <Form placeholder={'Friend ID'} value={friendId} setValue={setFriendId} firstBtnAction={addFriend} secondBtnAction={removeFriend} />
}
