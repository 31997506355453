import { requestCourseWebsiteDetailPromise } from 'api/CourseWebsite'
import React, { useCallback, useMemo, useState } from 'react'
import Step1 from './step1'
import Step2 from './step2'

export default function BlacklistAdd (props) {
  const { id } = props.match.params
  const [currentStep, setCurrentStep] = useState(1)
  const [data, setData] = useState({ active: false })

  useMemo(() => {
    if (!id) return

    requestCourseWebsiteDetailPromise(id).then(resp => {
      resp && setData(resp)
    })
  }, [id])

  const changeCurrentStep = useCallback((currentStep) => {
    setCurrentStep(currentStep)
  }, [])

  return currentStep === 1
    ? <Step1 data={data} setData={setData} changeCurrentStep={changeCurrentStep} {...props} />
    : <Step2 data={data} setData={setData} changeCurrentStep={changeCurrentStep} {...props} />
}