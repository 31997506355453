import _ from 'lodash'
import React, { useState, useLayoutEffect, useCallback, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { requestBlacklistPromise, deleteBlacklistPromise } from '../../api/RequestBlacklist'
import { RequestBlackListTable, RequestBlackListToolbar } from './components'

const WorkoutResourceList = props => {
  const ref = useRef()
  const location = useLocation()
  const [params, setParams] = useState({ page: 1 })
  const [requestBlacklist, setRequestBlacklist] = useState({})

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    requestBlacklistPromise(too).then(resp => setRequestBlacklist(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  function handleDeleteRecord (id) {
    deleteBlacklistPromise(id).then(() => window.location.reload())
  }

  return (
    <div className={'root'}>
      <div className={'content'}>
        <RequestBlackListToolbar handleSearchChange={handleParamsChange} params={params} {...props} />
        <br />
        <RequestBlackListTable
          {...props}
          data={requestBlacklist}
          page={params.page}
          onPageChange={handlePageChange}
          handleDeleteRecord={handleDeleteRecord}
        />
      </div>
    </div>
  )
}

export default WorkoutResourceList
