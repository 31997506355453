import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import FormTextField from './formTextField'
import { Card, Grid } from '@material-ui/core'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'
import { MultipleAutoCompleteSelect } from 'components/Select'
import { LocaleMatchingOptions } from 'common/enum'
import { getLocaleCountryOptions } from 'helpers/utils'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import titleMap from '../../targetDesc.json'
import UsStates from 'assets/usStates.json'

function CustomBooleanToggleButtonGroup (props) {
  return <BooleanToggleButtonGroup titles={titleMap[props.name]} {...props} />
}

export default function (props) {
  const { data, handleChange } = props
  const [localeCountries, setLocaleCountries] = useState()
  const [hideTarget, setHideTarget] = useState(false)

  useLayoutEffect(() => {
    getLocaleCountryOptions().then(resp => setLocaleCountries(resp))
  }, [])

  const toggleTarget = useCallback(() => {
    setHideTarget(!hideTarget)
  }, [hideTarget])

  const isTargetUsersInvalid = useMemo(() => {
    return data.target_users && !/^[1-9]\d{2,}(?:,[1-9]\d{2,})*$/.test(data.target_users)
  }, [data.target_users])

  useEffect(() => {
    if (data.target_pro === null) return
    if (data.target_health) handleChange('target_pro', null)
  }, [data.target_health, data.target_pro, handleChange])

  const handleUsersChange = useCallback((key, val) => {
    handleChange('target_users', val.replace(/[^\d,]|(?<=,),/g, ''))
  }, [handleChange])

  const usStateOptions = useMemo(() => Object.entries(UsStates).map(([k, v]) => ({ value: k, label: v })), [])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>TARGET</label>
      <label onClick={toggleTarget}>
        <ArrowDropDownIcon className='cursor-pointer' />
      </label>
    </Grid>

    <Grid container item xs={12} className={`form-card-content ${hideTarget ? 'hidden' : ''}`} >
      <Grid item xs={4}>
        <div className='form-input-elem'>
          {/* <FormTextField name='target_country' label='Countries' value={data.target_country} onChange={handleChange} /> */}
          <label className='full-width'>Countries</label>
          <MultipleAutoCompleteSelect options={localeCountries} values={data.target_country && data.target_country.split(',')} className='full-width' variant='outlined'
            onChange={(event, selected) => {
              const val = selected.map(s => s.value)
              handleChange('target_country', val.join(','))
            }}
          />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>States</label>
          <MultipleAutoCompleteSelect options={usStateOptions} values={data.target_us_state && data.target_us_state.split(',')} className='full-width' variant='outlined'
            onChange={(event, selected) => {
              const val = selected.map(s => s.value)
              handleChange('target_us_state', val.join(','))
            }}
          />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <FormTextField name='target_min_app_version' label='Min App Version' placeholder='example: 3.7.0' value={data.target_min_app_version} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <FormTextField name='target_users' label='Users' placeholder='Enter userid, separated by commas'  value={data.target_users} onChange={handleUsersChange} error={isTargetUsersInvalid} helperText={isTargetUsersInvalid && 'Wrong value'}/>
        </div>
      </Grid>

      <Grid item xs={4}>
        <div className='form-input-elem'>
          <label className='full-width'>Locale Languages</label>
          <MultipleAutoCompleteSelect options={LocaleMatchingOptions} values={data.target_locale_language && data.target_locale_language.split(',')} className='full-width' variant='outlined'
            onChange={(event, selected) => {
              const val = selected.map(s => s.value)
              handleChange('target_locale_language', val.join(','))
            }}
          />
        </div>
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='PRO' name='target_pro' value={data.target_pro} onChange={handleChange}
        />
      </Grid>

      <Grid item xs={4}>
        <CustomBooleanToggleButtonGroup
          label='Health' name='target_health' value={data.target_health} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Notification Permission' name='target_notification_permission' value={data.target_notification_permission} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Phone Verification' name='target_phone_verification' value={data.target_phone_verification} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Cast' name='target_cast' value={data.target_cast} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Program Reminder' name='target_program_reminder' value={data.target_program_reminder} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Meal Onboarding' name='target_meal_onboarding' value={data.target_meal_onboarding} onChange={handleChange} />
      </Grid>

      <Grid item xs={4} className='bottom-dashed'>
        <CustomBooleanToggleButtonGroup
          label='Download' name='target_download' value={data.target_download} onChange={handleChange} />
      </Grid>

      <Grid item xs={4}>
        <CustomBooleanToggleButtonGroup
          label='Daily Fix' name='target_daily_fix' value={data.target_daily_fix} onChange={handleChange} />
      </Grid>

      <Grid item xs={4}>
        <CustomBooleanToggleButtonGroup
          label='Premium Music' name='target_premium_music' value={data.target_premium_music} onChange={handleChange} />
      </Grid>

      <Grid item xs={4}>
        <CustomBooleanToggleButtonGroup
          label='Fitness Tracker' name='target_fitness_tracker' value={data.target_fitness_tracker} onChange={handleChange} />
      </Grid>

      <Grid item xs={4}>
        <CustomBooleanToggleButtonGroup
          label='Submitted Company Info' name='target_submitted_company_info' value={data.target_submitted_company_info} onChange={handleChange} />
      </Grid>
    </Grid>
  </Card>
}
