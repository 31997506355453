import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'
import './index.scss'
import EditBtn from 'components/EditBtn'

const PurchaseTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card className={clsx('purchase-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Original transaction ID</TableCell>
                  <TableCell>OS</TableCell>
                  <TableCell>Expire</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.rows &&
                  data.rows.map(purchase => (
                    <TableRow hover key={purchase.id}>
                      <TableCell>{purchase.id}</TableCell>
                      <TableCell>{purchase.user_id}</TableCell>
                      <TableCell>{purchase.sku}</TableCell>
                      <TableCell>{purchase.original_transaction_id}</TableCell>
                      <TableCell>{purchase.os_type_text}</TableCell>
                      <TableCell>
                        {purchase.expire && moment(purchase.expire).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(purchase.created).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(purchase.updated).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <EditBtn path={`/purchases/edit/${purchase.id}`} isClickable={purchase.is_free} {...props} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'actions'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

PurchaseTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default PurchaseTable
