import React, { useState } from 'react'
import Form from './form'

export default function CourseAdd (props) {
  const [data, setData] = useState({
    health: 0,
    pro: true,
    version: 4,
    popular: false,
    promotion: false,
    app_known_sku: false,
  })

  return <Form mode='add' data={data} setData={setData} {...props} />
}