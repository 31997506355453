import React from 'react'
import PropTypes from 'prop-types'
import { BooleanOptions } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import { Button, Grid, TextField } from '@material-ui/core'
import { checkPermission } from 'helpers/permission'

const PromoToolbar = props => {
  const { params, handleSearchChange, handleSync } = props

  return (
    <Grid container item xs={12} justify={'flex-end'} spacing={2} >
      <Grid item xs={2}>
        <TextField fullWidth variant='outlined' label='Name' size='small' value={params.name} className='background-color' onChange={event => handleSearchChange({name: event.target.value}, 500)} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew label='Edit Overrides' value={params.isOverride} options={BooleanOptions} inputLeft={true} variant='outlined' className='filter background-color'
          onChange={(event, selected) => handleSearchChange({isOverride: selected && selected.value})} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew label='Default Photo Uploaded' value={params.isDefaultPhotoUploaded} options={BooleanOptions} inputLeft={true} variant='outlined' className='filter background-color'
          onChange={(event, selected) => handleSearchChange({isDefaultPhotoUploaded: selected && selected.value})} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew label='Google Photo' value={params.isGooglePhotoEnabled} options={BooleanOptions} inputLeft={true} variant='outlined' className='filter background-color'
          onChange={(event, selected) => handleSearchChange({isGooglePhotoEnabled: selected && selected.value})} />
      </Grid>

      <Grid container item xs={4} justify={'flex-end'}>
        {
          checkPermission('120002') && <Button color='secondary' type='submit' variant='contained'
            onClick={e => {
              e.stopPropagation()
              if (!window.confirm('are you sure?')) return
              handleSync().then(() => window.location.reload())
            }}> Sync All </Button>
        }
      </Grid>
    </Grid>
  )
}

PromoToolbar.propTypes = {
  className: PropTypes.string,
}

export default PromoToolbar
