import React from 'react'
import ColorInput from 'components/ColorInput'
import { Card, Grid } from '@material-ui/core'
import FormTextField from 'components/FormTextField'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function (props) {
  const { data, handleChange } = props
  
  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={6}>
        <FormTextField name='name' label='Name' value={data.name} onChange={handleChange} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name='description' label='Description' value={data.description} onChange={handleChange} />
      </Grid>

      <Grid item xs={6}>
        <label className='full-width'>Priority</label>
        <NumberAutoComplete name='priority' from={1} to={100} value={data.priority} inputLeft={true} handleSelectChange={value => handleChange('priority', value) } variant='outlined' />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name='deeplink' label='Deeplink' value={data.deeplink} onChange={handleChange} />
      </Grid>

      <Grid item xs={6}>
        <ColorInput label='Text Color' name='text_color' value={data.text_color} handleChange={handleChange} />
      </Grid>

      <Grid item xs={6}>
        <ColorInput label='Background Color' name='background_color' value={data.background_color} handleChange={handleChange} />
      </Grid>

    </Grid>
  </Card>
}
