
import { getApiClient } from './ApiClient'

export function requestSocialBadgeListPromise (params) {
  return getApiClient()
    .get('/social-badges', { params })
    .then(resp => Promise.resolve(resp.data))
}

export function requestSocialBadgeDetailPromise (id) {
  return getApiClient()
    .get(`/social-badges/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertSocialBadgePromise = (body) => {
  return getApiClient()
    .post(`/social-badges`, { body })
    .then(resp => Promise.resolve(resp.data))
}

export function requestUserSocialBadgeListPromise (params) {
  return getApiClient()
    .get('/user-social-badges', { params })
    .then(resp => Promise.resolve(resp.data))
}

export function requestUserSocialBadgeDetailPromise (id) {
  return getApiClient()
    .get(`/user-social-badges/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertUserSocialBadgePromise = (body) => {
  return getApiClient()
    .post(`/user-social-badges`, { body })
    .then(resp => Promise.resolve(resp.data))
}
