import FohStudioForm from './form'
import React, { useState, useLayoutEffect, useCallback } from 'react'
import { getFohStudioDetailPromise } from 'api/studio'

export default function FohStudioEdit (props) {
  const [data, setData] = useState({ google_photos: true })
  const { match: { params: { id } } } = props

  const fetchDetail = useCallback(() => {
    getFohStudioDetailPromise(id).then(resp => setData(resp))
  }, [id])

  useLayoutEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return (
    <FohStudioForm { ...props } isEdit={true} data={data} reload={fetchDetail} />
  )
}
