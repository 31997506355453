import './edit.scss'
import _ from 'lodash'
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import Card from '@material-ui/core/Card/Card'
import FormTextField from 'components/FormTextField'
import { send } from 'helpers/utils'
import { removeUserFromGroupPromise } from 'api/Product'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'

export default function UserGroupForm (props) {
  const { data, history, reload } = props
  const [userGroups, setUserGroups] = useState([])

  useLayoutEffect(() => {
    setUserGroups(data && data.userGroups ? _.cloneDeep(data.userGroups) : [])
  }, [data])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    if (!data || !data.userGroups) return
    const removed = [], verified = [], notVerified = []
    userGroups.forEach((ug, idx) => {
      if (!ug.active) removed.push({ userId: ug.user_id, groupId: ug.group_id })
      const foo = data.userGroups[idx] || {}

      if (ug.verified === foo.verified) return
      if (ug.verified) return verified.push(ug.id)
      notVerified.push(ug.id)
    })

    removeUserFromGroupPromise({ removed, verified, notVerified })
      .then(reload)
  }, [data, reload, userGroups])

  const handleChange = useCallback((idx, key, val) => {
    const foo = userGroups[idx]
    foo[key] = val

    setUserGroups([...userGroups])
  }, [userGroups])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} User Group
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
            </Grid>

            <Grid container item xs={12} className='form-card-content' >
              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <FormTextField name='user_id' label='User ID' value={send(data, 'user', 'id')} readonly={true} onChange={() => {}} />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <FormTextField name='user_email' label='User Email' value={send(data, 'user', 'email')} readonly={true} onChange={() => {}} />
                </div>
              </Grid>
            </Grid>
          </Card>

          <br />

          <Card className='ew-user-gro'>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>Groups</label>
            </Grid>

            <Grid container item xs={12} className='form-card-content' >
              <Grid item xs={12}>
                <div className='form-input-elem'>
                  {/* <label className='full-width'>Groups</label> */}
                  {/* <Grid container item>
                    { data && data.userGroups && data.userGroups.filter(ug => !removed.includes(ug.id)).map(ug => <Chip key={`chip-${ug.id}`} label={`${ug.group.name} (${ug.group_id})`} onDelete={() => handleRemove(ug) } />) }
                  </Grid> */}
                  <Table>
                    <TableHead style={{ tableLayout: 'fixed' }}>
                      <TableRow>
                        <TableCell>Group ID</TableCell>
                        <TableCell>Group Name</TableCell>
                        <TableCell>Verified</TableCell>
                        <TableCell>Active</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className='new-user-groups-body'>
                      {userGroups.map((ug, idx) => (
                        <TableRow key={ug.id} hover>
                          <TableCell>{ug.group_id}</TableCell>
                          <TableCell>{ug.group.name}</TableCell>
                          <TableCell>
                            <BooleanToggleButtonGroup label='' name='verified' withoutNull={true} value={ug.verified} onChange={(name, val) => handleChange(idx, name, val)} />
                          </TableCell>
                          <TableCell>
                            <BooleanToggleButtonGroup label='' name='active' withoutNull={true} value={ug.active} onChange={(name, val) => handleChange(idx, name, val)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
