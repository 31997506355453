import './index.scss'
import moment from 'moment'
import MyModal from 'components/MyModal'
import BlankImage from 'components/BlankImage'
import { S3Uploader2 } from 'components/S3UploadInput'
import React, { useCallback, useEffect, useState } from 'react'
import { deleteS3ObjectPromise, getS3ObjectHeadInfo } from 'api/helper'
import { S3Bucket, ImageLanguageOptions, S3Path, S3FilePath } from 'common/enum'

export default function (props) {
  const { fileType, filename, setFilename } = props
  const [imageStatus, setImageStatus] = useState({})
  const [modalContent, setModalContent] = useState()
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  // const [localeFilename, setLocaleFilename] = useState(filename)
  const pathGen = S3FilePath[fileType]

  const loadHeadInfo = useCallback(() => {
    if (!filename) return setImageStatus({})

    const targets = ImageLanguageOptions.map(({value}) => ({ bucket: S3Bucket, subpath: pathGen(value), name: filename }))
    getS3ObjectHeadInfo(targets).then(data => {
      setImageStatus(
        data.reduce((res, d) => (res[d.subpath] = d, res), {})
      )
    })
  }, [filename, pathGen])

  const handleAfterUpload = useCallback(name => {
    if (name === filename) return loadHeadInfo()
    
    setFilename(name)
  }, [filename, loadHeadInfo, setFilename])

  const handleDelete = useCallback(() => {
    if (!window.confirm('are you sure?')) return

    const objects = ImageLanguageOptions.map(({value}) => ({bucket: S3Bucket, key: `${S3FilePath.ProgramCoverImage(value)}/${filename}`}))
    deleteS3ObjectPromise(objects).then(() => setFilename(null))
  }, [filename, setFilename])

  useEffect(() => {
    loadHeadInfo()
  }, [loadHeadInfo])

  const imageDesc = useCallback((language) => {
    const objectInfo = imageStatus[pathGen(language)]
    if (!objectInfo || !objectInfo.info) return 'If image not available, default image (en) will be used'
    
    return objectInfo.info.ContentLanguage === 'en' ? 'Using default image (en)' : 'Using localized version'
  }, [imageStatus, pathGen])

  const imageContent = useCallback((language) => {
    const objectInfo = imageStatus[pathGen(language)]
    if (!objectInfo || !objectInfo.info) return <BlankImage />

    const src = `${S3Path}/${pathGen(language)}/${filename}?t=${moment().unix()}`

    return <img src={src} alt='' style={{ maxHeight: '80px', maxWidth: '80px' }} className='cursor-pointer'
      onClick={() => {setModalContent(src); setPreviewModalOpen(true)}} />
  }, [imageStatus, pathGen, filename])

  return <>
    {
      ImageLanguageOptions.map(({ label, value, language }) => {
        return <div key={`${filename}-${value}`} className='i18n-image-row'>
          <div key={`thumbnail-${value}`} className='i18n-image-preview'>
            { imageContent(value) }
          </div>

          <div key={`bool-${value}`} className='text-align-left'>
            <span className='chip background-color-grey i18n-image-language'>{ label }</span>
            <div className='i18n-image-desc'>{ value !== 'en_us' ? imageDesc(value) : '' }</div>
          </div>

          <div key={`image-${value}`} className='i18n-image-btn'>
            <S3Uploader2
              bucket={S3Bucket}
              filename={filename}
              filepath={pathGen(value)}
              isDeletable={value === 'en_us'}
              params={{ ACL: 'public-read', Metadata: { ContentLanguage: language } }}

              afterUpload={handleAfterUpload}
              onDelete={handleDelete} />
          </div>
        </div>
      })
    }

    <MyModal open={previewModalOpen} setOpen={setPreviewModalOpen} isFullScreen={true}>
      <img className='image-position' alt='' src={modalContent} style={{ maxHeight: '100%', maxWidth: '100%' }} />
    </MyModal>
  </>
}
