import _ from 'lodash'
import { ProgramGuidePdfTypeOptions, S3FilePath } from 'common/enum'
import { ImageLanguageOptions } from 'common/enum'
import React, { useCallback, useMemo } from 'react'
import { S3Uploader2 } from 'components/S3UploadInput'
import { Button, Grid, Switch } from '@material-ui/core'
import { ImageLanguageMap, S3Bucket, S3Path } from 'common/enum'
import FormTextField from 'components/FormTextField'
import { AutoCompleteNew } from 'components/Select'

export default function (props) {
  const { files, setFiles, title, desc, type, isNutritionProgramType } = props
  const languages = props.languages ? _.at(ImageLanguageMap, props.languages) : ImageLanguageOptions

  const path = useMemo(() => S3FilePath[type](), [type])

  const handleChange = useCallback((idx, key, value) => {
    if (!files[idx]) return
    _.set(files[idx], key, value)
    setFiles([...files])
  }, [files, setFiles])

  const handleAdd = useCallback(() => {
    const maxRecord = _.maxBy(files.filter(f => !f.isDeleted), 'id') || { id: 0 }

    setFiles([...files, { id: 1 + parseInt(maxRecord.id), url: {} }])
  }, [files, setFiles])

  return <div style={{ width: '80%', maxWidth: '1000px', margin: '30px auto 0' }}>
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
        <div style={{alignSelf: 'end'}}>
          <span>{title || 'Guide PDF URLs'}</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className='desc-color'>{desc || 'If PDF not available, default PDF (en) will be used'}</span>
        </div>
        <Button variant='outlined' style={{color: '#3F44AB'}} onClick={handleAdd}>ADD</Button>
      </div>
    </div>

    {
      files.map(({ id, name, mobileFormat, type, url, isDeleted, week }, idx) => isDeleted || <div key={`container-${idx}`}>
        <Grid key={`pdf-grid-${idx}`} container spacing={2} style={{padding: '10px 0'}} >
          <Grid container item xs={6}>
            <Grid item xs={4} style={{display:'flex', alignItems:'center'}}>ID</Grid>
            <Grid item xs={8}>
              <FormTextField name='id' value={id} numberOnly={true} placeholder='ID' variant='outlined' size='small' className='full-width'
                onChange={(name, value) => handleChange(idx, name, parseInt(value))} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={3} style={{display:'flex', alignItems:'center'}}>Name</Grid>
            <Grid item xs={9}>
             <FormTextField name='name' value={name} placeholder='Name' variant='outlined' size='small' className='full-width'
               onChange={(name, value) => handleChange(idx, name, value)} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>Mobile Format</Grid>
            <Grid item xs={8}>
              <Switch name='mobileFormat' checked={Boolean(mobileFormat)} onChange={e => handleChange(idx, 'mobileFormat', e.target.checked)} />
            </Grid>
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>Type</Grid>
            <Grid item xs={9}>
              <AutoCompleteNew options={ProgramGuidePdfTypeOptions} value={type}
                onChange={(event, selected) => handleChange(idx, 'type', selected && selected.value)}
              />
            </Grid>
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={3} style={{display:'flex', alignItems:'center'}}>Week</Grid>
            <Grid item xs={9}>
              <FormTextField name='week' value={week} placeholder='1,2,3' variant='outlined' size='small' className='full-width'
                onChange={(name, value) => handleChange(idx, name, value || undefined)} />
            </Grid>
          </Grid>

          <Grid container item xs={1} justify='flex-end' style={{ display: 'flex', alignItems: 'right'}}>
            <Button variant='outlined' style={{color: 'red'}} onClick={() => handleChange(idx, 'isDeleted', true)}>DELETE</Button>
          </Grid>
        </Grid>
        {
          languages.map(({ label, value, language }) =>
            <Grid container key={`pdf-${language}-${idx}`} style={{marginBottom: '4px'}}>
              <Grid container item xs={1} alignItems='center'>
                <span className='chip background-color-grey i18n-image-language'>{ label }</span>
              </Grid>

              <Grid container item xs={8} alignItems='center' style={{whiteSpace: 'nowrap', overflow: 'scroll'}}>
                {url[language] && `${S3Path}/${path}/${url[language]}`}
              </Grid>

              <Grid container item xs={3} justify='flex-end'>
                <S3Uploader2 bucket={S3Bucket} filepath={path} params={{ ACL: 'public-read' }} filename={url[language]} isUseOriginalName={true} isDeletable={true} isNeedCompress={false} isCheckExist={true}
                  afterUpload={fname => handleChange(idx, ['url', language], fname)}
                  onDelete={() => handleChange(idx, ['url', language], undefined)} />
              </Grid>
            </Grid>
          )
        }
      </div>)
    }
  </div>
}
