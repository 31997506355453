import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
} from '@material-ui/core'

const LogListTable = props => {
  const { data, page, onPageChange, history } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Module</TableCell>
                  <TableCell>Operator</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(log => (
                  <TableRow
                    hover
                    key={log.id}
                    className={'table-row cursor-pointer'}
                    onClick={() => { history.push(`/log/${log.id}`) }}
                  >
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.actionText}</TableCell>
                    <TableCell>{log.moduleText}</TableCell>
                    <TableCell>{log.user && log.user.name}</TableCell>
                    <TableCell>{formatDateTime(log.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default LogListTable