import React, { useCallback } from 'react'
import DynamicInput from 'components/DynamicInput'
import { Input, Grid } from '@material-ui/core'

export default function DirectionInput (props) {
  
  const genElement = useCallback((name) => {
    return (index, data, handleChange) => {
      return <Grid item xs>
        <Input
          disableUnderline
          name={ name }
          className={'full-width'}
          value={ data[name] || '' }
          placeholder={`${name} ${index}`}
          onChange={(event) => {
            handleChange(index, name, event.target.value)
          }}
        />
      </Grid>
    }
  }, [])

  return (
    <DynamicInput
      {...props}
      label={'Direction'}
      keyName={'instructions'}
      genElement={genElement('description')}
    />
  )
}