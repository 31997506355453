import React from 'react'
import UpsertForm from './form'

export default function SkuCategoryAdd (props) {
  const data = {active: false}

  return (
    <UpsertForm data={data} {...props} />
  )
}
