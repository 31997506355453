import { getWeekProgramDetail } from 'api/ProgramWeek'
import React, { useEffect, useState } from 'react'
import Form from './form'

export default function BlacklistAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useEffect(() => {
    if (!id) return

    getWeekProgramDetail(id).then(resp => {
      resp && setData({ ...resp, week_course_recipe_ids: (resp.week_course_recipe || []).map(x => x.recipe_id) })
    })
  }, [id])

  return <div className='course-week'>
    <Form data={data} setData={setData} {...props} />
  </div>
}