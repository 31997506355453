import React from 'react'
import { Button } from '@material-ui/core'

export default function (props) {
  const { userId, getUserSocialGroup } = props

  return (
    <div className='info-item'>
      <Button variant='outlined' disabled={!userId} onClick={getUserSocialGroup}>Search Social Group</Button>
    </div>
  )
}
