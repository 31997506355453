import _ from 'lodash'
import { Grid } from '@material-ui/core'
import { ImageLanguageOptions, S3Bucket, S3FilePath } from 'common/enum'
import { randString, uuidV4 } from 'helpers/utils'
import React, { useCallback, useEffect, useState } from 'react'
import FormTextField from './formTextField'
import UploaderForI18nImage2 from 'components/UploaderForI18nImages/index2'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import DeleteIcon from '@material-ui/icons/Delete'
import { copyS3Object } from 'api/helper'
import Path from 'path'
import ReuseModal from './reuseModal'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import URI from 'urijs'
URI.escapeQuerySpace=false

const colors = ['FFFFFF', '333333', '359B33', 'EF5DA8', '000000']

export default function DetailCard (props) {
  const { placement, content, size, idx, onChange, payload, updatedContents } = props
  const [reuseModalOpen, setReuseModalOpen] = useState(false)
  const contentId = uuidV4()

  const valByKey = useCallback((key) => {
    const foo = updatedContents[content.id] || {}
    return key in foo ? foo[key] : content[key]
  }, [content, updatedContents])

  const handleChange = useCallback((key, val) => {
    onChange(content.id, { [key]: val })
  }, [content.id, onChange])

  const deleteContent = useCallback(() => {
    if (!window.confirm('are you sure')) return

    handleChange('delete', true)
  }, [handleChange])

  const handleChoose = useCallback((sourceContent) => {
    // handle backgrounds
    const sourceName = sourceContent.background
    const extName = Path.extname(sourceName)
    // for different file type
    // const originalName = valByKey('background') && Path.basename(valByKey('background'), Path.extname(valByKey('background')))
    const newName = `${randString()}${extName}`

    const files = ImageLanguageOptions.map(({value}) => {
      const path = S3FilePath.FeatureBannerContentBackground(value)
      return {
        from: `${S3Bucket}/${path}/${sourceName}`,
        to: { bucket: S3Bucket, key: `${path}/${newName}` },
        params: { ACL: 'public-read' }
      }
    })

    copyS3Object(files).then(resp => {
      onChange(content.id, {
        id: content.id,
        background: newName,
        ..._.pick(sourceContent, 'name', 'sequence', 'type', 'weight', 'cta_text', 'cta_deeplink', 'text_color', 'active')
      })
      setReuseModalOpen(false)
    })
  }, [content, onChange])

  const handleFilenameChange = useCallback(name => {
    handleChange('background', name)
  }, [handleChange])

  useEffect(() => {
    const name = placement.label
    const deeplink = valByKey('cta_deeplink')

    if (!name || !deeplink) return

    try {
      const url = URI(valByKey('cta_deeplink'))
      console.log(url.query(true).source, '==', name, '==', url.query(true).source===name)
      if (url.query(true).source === name) return

      url.setQuery('source', name)
      handleChange('cta_deeplink', url.toString())
    } catch (err) {
      console.log(err)
    }
    
  }, [handleChange, placement.label, valByKey])

  return !valByKey('delete') && <div key={`content-card-${content.id}`}>
    <div className='content-title-container'>
      <div onClick={() => hideContent(contentId)} style={{display: 'flex', alignItems: 'center'}} className='cursor-pointer'>
        <h4>CONTENT {idx + 1}</h4>
        <ArrowDropDownIcon />
      </div>
      <div onClick={deleteContent} className='content-delete-btn cursor-pointer'> <DeleteIcon />Remove content </div>
    </div>

    <div id={contentId}>
      <Grid container item xs={12} style={{paddingTop: 0}} className='form-card-content'>
        <Grid item xs={6}>
          <div className='form-input-elem'>
            <FormTextField label='Name' name='name' value={valByKey('name')} onChange={handleChange} />
          </div>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={6}>
          <div className='form-input-elem'>
            <FormTextField label='Title (optional)' name='title' value={valByKey('title')} onChange={handleChange} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-input-elem'>
            <FormTextField label='Subtitle (optional)' name='subtitle' value={valByKey('subtitle')} onChange={handleChange} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className='form-input-elem'>
            <FormTextField label='CTA Text' name='cta_text' value={valByKey('cta_text')} onChange={(key, val) => handleChange(key, _.toUpper(val))} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-input-elem'>
            <FormTextField label='CTA Text Color' name='text_color' value={valByKey('text_color')} onChange={handleChange} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className='color-picker'>
            { colors.map((color, idx) =>
              <div key={`color-picker-${valByKey('id')}-${color}-${idx}`} className='cursor-pointer color-circle' style={{ backgroundColor: `#${color}` }} onClick={() => handleChange('text_color', color)} />)
            }
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='form-input-elem'>
            <FormTextField label='CTA Deep Link' name='cta_deeplink' value={valByKey('cta_deeplink')} onChange={handleChange} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className='form-input-elem'>
            <label className='full-width'>Sequence</label>
            <NumberAutoComplete variant='outlined' name='sequence' inputLeft={true} from={1} to={100} value={valByKey('sequence')} handleSelectChange={(val) => handleChange('sequence', val)} />
          </div>
        </Grid>
      </Grid>

      <div className='form-card-content'>
        <div className=''>
          <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
            <span>
              Background Image (PNG, JPG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image size: <b>{size} px</b>
            </span>
            <span onClick={() => setReuseModalOpen(true)} className='cursor-pointer color-blue'>
              Reuse an existing asset
            </span>
          </div>
        </div>

        <UploaderForI18nImage2
          filename={valByKey('background')}
          setFilename={handleFilenameChange}
          fileType='FeatureBannerContentBackground'
        />
      </div>
    </div>

    <ReuseModal
      contents={payload.contents}
      modalOpen={reuseModalOpen}
      setModalOpen={setReuseModalOpen}
      handleChoose={handleChoose}
    />
  </div>
}

const hideContent = function (uuid) {
  const elem = document.getElementById(uuid)
  elem.className = elem.className.includes('hide-elem-vertical')
    ? elem.className.replace('hide-elem-vertical', '')
    : elem.className += ' hide-elem-vertical'
}