import './index.scss'
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import ActiveSwitch from 'components/ActiveSwitch'
import { AutoCompleteNew } from 'components/Select'
import { ParamsForOption, S3PreSignType } from 'common/enum'
import { fetchWorkoutOptions, optionEnum } from 'views/concerns/workoutOptions'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { moveResourceUpsertPromise, moveTypeListPromise } from 'api/MoveWorkout'

const requiredColumns = [{ key: 'name' }]
export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, history } = props
  const [moveResource, setMoveResource] = useState({})
  const [options, setOptions] = useState([])
  const [moveTypeOptions, setMoveTypeOptions] = useState([])
  const { trainer } = optionEnum

  useLayoutEffect(() => {
    fetchWorkoutOptions(trainer).then(resp => setOptions(resp))
  }, [trainer])

  useLayoutEffect(() => {
    moveTypeListPromise(ParamsForOption).then(resp => {
      setMoveTypeOptions(
        resp.map(r => ({ label: r.name, value: r.id }))
      )
    })
  }, [])

  useEffect(() => {
    setMoveResource({
      oldId: data.id,
      ...data,
    })
  }, [data])

  function handleSubmit () {
    moveResourceUpsertPromise(moveResource)
      .then(() => history.goBack())
  }

  const handleValueChange = useCallback((map) => {
    setMoveResource({ ...moveResource, ...map })
  }, [moveResource])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name={'name'} value={moveResource.name} onChange={map => { handleValueChange(map) } } />

        <FormItem label='Type'>
          <AutoCompleteNew options={moveTypeOptions} value={moveResource.move_type_id} onChange={(event, selected) => handleValueChange({move_type_id: (selected || {value: null}).value})} />
        </FormItem>

        <FormItem label='Trainer'>
          <AutoCompleteNew options={options[trainer] || []} value={moveResource.trainer_id} onChange={(event, selected) => handleValueChange({trainer_id: (selected || {value: null}).value})} />
        </FormItem>

        <FormItem label='Video' name={'video_url'} value={moveResource.video_url} readonly placeholder='upload the video at Video/Workout Resource' onChange={map => { handleValueChange(map) } } />
        <FormItem label='Preview Video' name={'preview_video_url'} value={moveResource.preview_video_url} readonly onChange={map => { handleValueChange(map) } } />
        <FormItem label='Download Video' name={'download_url_720p'} value={moveResource.download_url_720p} readonly onChange={map => { handleValueChange(map) } } />
        <S3UploaderWithInput label='Preview Cover' name={'preview_cover_url'}
          filenames={moveResource.preview_cover_url} fileType={S3PreSignType.MoveBaseWorkout}
          setFilenames={filenames => handleValueChange({ preview_cover_url: filenames })} />

        <FormItem name={'continue_time'} value={moveResource.continue_time} onChange={map => { handleValueChange(map) } } />

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={moveResource} columns={requiredColumns} setBody={setMoveResource} isNeedCheck={true} />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}