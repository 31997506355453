import { getApiClient } from './ApiClient'

export const requestBlacklistPromise = (params) => {
  return getApiClient()
    .get('/request-blacklist', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const createBlacklistPromise = (body) => {
  return getApiClient()
    .post('/request-blacklist', { body })
    .then(resp => Promise.resolve(resp.data))
}

export const deleteBlacklistPromise = (id) => {
  return getApiClient()
    .delete(`/request-blacklist/${id}`)
    .then(resp => Promise.resolve(resp.data))
}
