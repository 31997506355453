import ChallengeForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { requestUserSocialBadgeDetailPromise } from 'api/SocialBadge'

export default function ChallengeEdit (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    requestUserSocialBadgeDetailPromise(id).then(resp => setData(resp))
  }, [id])
  return (
    <ChallengeForm { ...props } data={data} />
  )
}
