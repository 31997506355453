import React from 'react'
import { Grid, Input } from '@material-ui/core'
import AddBtn from 'components/AddBtn'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  function handleQueryChange (key, val) {
    handleSearchChange({ [key]: val })
  }

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='price' className={'block-value'} value={params.price} onChange={(event) => { handleQueryChange('price', event.target.value) }} />
        </Grid>
        <Grid item xs={2}>
        <Input placeholder='sku' className={'block-value'} value={params.sku} onChange={(event) => { handleQueryChange('sku', event.target.value) }} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2} container justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/products/create`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
