import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import { booleanElement, send } from 'helpers/utils'
import { formatDateTime } from 'common/usual'

const MealTable = props => {
  const { data, page, onPageChange, history } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Studio ID</TableCell>
                  <TableCell>Successful</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover className='table-row' onClick={() => history.push(`/business/flex-card-events/${row.id}`)}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>{send(row, 'user', 'name')}</TableCell>
                    <TableCell>{row.merchant_id}</TableCell>
                    <TableCell>{row.studio_id}</TableCell>
                    <TableCell>{ booleanElement(row.successful) }</TableCell>
                    <TableCell>{ formatDateTime(row.created_at) }</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default MealTable