import UpsertForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { getSkuFeatureRelationDetailRoute } from 'api/Product'

export default function SkuFeatureRelationEdit (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getSkuFeatureRelationDetailRoute(id).then(setData)
  }, [id])

  return (
    <UpsertForm { ...props } data={data} />
  )
}
