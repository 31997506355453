import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import Close from '@material-ui/icons/Close'
import EditBtn from 'components/EditBtn'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'

export default function (props) {
  const { data=[], handlePageChange, page, deleteRecord } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Workout</TableCell>
                  <TableCell>Sequence</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.rows && data.rows.map(item => (
                    <TableRow hover key={item.id} >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.dailyfix && item.dailyfix.defaultTime}</TableCell>
                      <TableCell>{item.workout && item.workout.resource.name}</TableCell>
                      <TableCell>{item.sequence}</TableCell>
                      <TableCell>{formatDateTime(item.createdAt)}</TableCell>
                      <TableCell style={{ display: 'flex' }}>
                        <EditBtn history={props.history} path={`/workout-dailyfix/edit/${item.id}`} />
                        &nbsp;&nbsp;
                        <Close className={'cursor-pointer'} onClick={() => {
                          if (window.confirm('are you sure?')) {
                            deleteRecord(item.id)
                          }
                        }} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={handlePageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}
