import React from 'react'
import AddBtn from 'components/AddBtn'
import { Button, Grid, TextField } from '@material-ui/core'

export default function UserGroupToolbar (props) {
  const { params, onParamsChange, onExport } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='User ID' size='small' value={params.userId} className='background-color' onChange={event => onParamsChange({userId: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='User Email' size='small' value={params.userEmail} className='background-color' onChange={event => onParamsChange({userEmail: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Group ID' size='small' value={params.groupId} className='background-color' onChange={event => onParamsChange({groupId: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Group Name' size='small' value={params.groupName} className='background-color' onChange={event => onParamsChange({groupName: event.target.value}, 500)} />
        </Grid>
        <Grid container item xs={3} justify={'flex-end'}>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push('/business/users/create')
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
        <Grid item xs={1} container justify='flex-end'>
          <Button color='secondary' onClick={onExport} variant='contained'>
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
