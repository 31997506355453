import React, { useState, useEffect } from 'react'
import WorkoutDailyfixForm from './_form'
import { workoutDailyfixDetailPromise } from 'api/WorkoutDailyfix'

export default function WorkoutDailyfixAdd (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchWorkoutDailyfix () {
      setData(
        await workoutDailyfixDetailPromise(id)
      )
    }
    fetchWorkoutDailyfix()
  }, [id])
  return <WorkoutDailyfixForm data={data} {...props} />
}