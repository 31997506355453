import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealIngredientPreparationUpsertPromise } from 'api/MealIngredientPreparation'
import { isBlank } from 'common/usual'

export default function MealPreparationUpsertForm (props) {
  const { data } = props
  const [preparation, setPreparation] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus || isBlank(preparation.title)
  ), [btnStatus, preparation.title])

  useEffect(() => {
    setPreparation(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    mealIngredientPreparationUpsertPromise(preparation)
      .then(res => {
        props.history.goBack()
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setPreparation(
      assign({}, preparation, ...values)
    )
  }, [preparation])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: preparation
  }), [handleChange, preparation])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title'} name={'title'} {...commonProps} />

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(preparation.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(preparation)}</div> */}
    </div>
  )
}