import _ from 'lodash'
import { useEffectOnce } from 'react-use'
import ProgramNutritionTable from './table'
import { useLocation } from 'react-router-dom'
import ProgramNutritionToolBar from './toolbar'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { getProgramNutritionListPromise, upsertProgramNutritionPromise } from 'api/programNutrition'
import { getProgramListPromise } from 'api/program'
import { ParamsForOption } from 'common/enum'

export default function (props) {
  const ref = useRef()
  const location = useLocation()
  const [programNutrition, setProgramNutrition] = useState({})
  const [params, setParams] = useState({ page: 1, filters: {} })
  const [programs, setPrograms] = useState([])

  useEffectOnce(() => {
    getProgramListPromise({ size: 10000, ...ParamsForOption }).then(resp => setPrograms(resp.data.rows))
  }, [])

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  const loadData = useCallback(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    getProgramNutritionListPromise(too).then(resp => setProgramNutrition(resp))
  }, [location.search])

  // load data
  useEffect(() => {
    loadData()
  }, [loadData])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  const handleDelete = useCallback(id => {
    if (!window.confirm('are you sure?')) return

    upsertProgramNutritionPromise({ id, active: false })
      .then(loadData)
  }, [loadData])

  return (
    <div className={'root'}>
      <ProgramNutritionToolBar {...props} programs={programs} params={params} onParamsChange={handleParamsChange} />
      <div className={'content'}>
        <ProgramNutritionTable {...props} programs={programs} data={programNutrition} page={params.page} onPageChange={handlePageChange} onDelete={handleDelete} />
      </div>
    </div>
  )
}
