import './index.scss'
import EditIcon from 'icons/icon-eidt.svg'
import React, { useCallback, useMemo } from 'react'
import { checkPermission, getPermissionCodeByPath } from 'helpers/permission'

export default function EditBtn (props) {
  const { path, from, history, isClickable = true } = props

  const code = getPermissionCodeByPath(window.location.pathname, ['edit', 'upsert'])

  const handleClick = useCallback((e) => {
    if (!isClickable) return

    e.stopPropagation()
    history.push(path, { from })
  }, [from, history, isClickable, path])

  const classNames = useMemo(() => {
    const classes = 'cursor-pointer edit-btn'
    if (isClickable) return classes
    return `${classes} not-clickable`
  }, [isClickable])

  if (!checkPermission(code)) return (<></>)

  return (
    <div onClick={handleClick} className={classNames}>
      <img title='edit' src={EditIcon} alt='' className={'edit-btn-img'} />
    </div>
  )
}