import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Grid, Input } from '@material-ui/core'
import { last, times, orderBy } from 'lodash'
import { AutoCompleteNew } from 'components/Select'
import Close from '@material-ui/icons/Close'
import Add from '@material-ui/icons/Add'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { mealListPromise } from 'api/Meal'
import { dietCategoryListPromise } from 'api/MealPlan'
import './schedule.scss'
import { ParamsForOption } from 'common/enum'

const categoryIds = [3, 5, 1, 4, 2]
export default function WeekForm (props) {
  const { data=[], handleChange, mealsPerDay } = props
  const [recipes, setRecipes] = useState([])
  const [dietCategories, setDietCategories] = useState([])
  const [currentWeek, setCurrentWeek] = useState(1)

  const _categories = useMemo(() => {
    return orderBy (
      categoryIds.slice(0, mealsPerDay)
    )
  }, [mealsPerDay])

  useEffect(() => {
    async function fetchDietCategory () {
      setDietCategories(
        (await dietCategoryListPromise()).map(category => ({
          label: category.title,
          value: category.id,
        }))
      )
    }
    fetchDietCategory()
  }, [])

  useEffect(() => {
    async function fetchRecipes () {
      setRecipes(
        (await mealListPromise({type: 'option', ...ParamsForOption})).rows.map(row => ({
          label: row.title,
          value: row.id,
        }))
      )
    }
    fetchRecipes()
  }, [])

  function typeIn (index, name, value) {
    data[index][name] = value
    data[index]._updated = true
    handleChange({ schedule: data })
  }

  function addRow () {
    const next = (last(data) || {week: 0}).week + 1
    const newRow = times(7).reduce((res, index) => {
      times(mealsPerDay).forEach(i => {
        res.push({
          week: next,
          dow: index + 1,
          mealCategoryId: _categories[i]
        })
      })
      return res
    }, [])

    data.push(...newRow)
    handleChange({ schedule: data })
    setCurrentWeek((last(data) || {}).week || 0)
  }

  function addSingleRow (index, obj) {
    data.splice(index+1, 0, {
      week: obj.week,
      dow: obj.dow,
    })
    handleChange({ schedule: data })
  }

  function deleteRow (index) {
    data[index]._deleted = true
    handleChange({ schedule: data })
  }
  
  const previous = useCallback(() => {
    if (currentWeek > 1) {
      setCurrentWeek(currentWeek - 1)
    }
  }, [currentWeek])

  const next = useCallback(() => {
    if (currentWeek < (last(data) || {week: 0}).week) {
      setCurrentWeek(currentWeek + 1)
    }
  }, [currentWeek, data])

  return (
    <div className="info-item meal-plan-schedule" style={{ display: 'block' }}>
      <Grid container spacing={4}>
        <Grid container item xs={11} justify={'flex-start'}>
          <label className="label">Schedule</label>
        </Grid>
        <Grid container item xs={1} justify={'flex-end'} >
          <Add className={'cursor-pointer'} onClick={addRow} />
        </Grid>
      </Grid>

      { data.length > 0 &&
        <div className={'container'}>
          <div className={'previous'}>
            <ArrowBackIosIcon fontSize={'large'} className={'cursor-pointer'} onClick={previous} />
          </div>

          <div className={'content'}>
            {
              data.map((item, index) => {
                return !item._deleted && currentWeek === item.week && 
                  <Grid container spacing={0} key={index} >
                    <Grid container item xs={11} spacing={4} justify={'flex-start'}>
                      
                      <Grid container item xs={11} spacing={3} key={index}>
                        <Grid item xs={2}>
                          <Input
                            readOnly
                            disableUnderline
                            name={'week'}
                            type={'number'}
                            className={'full-width'}
                            value={ item.week }
                            placeholder={'week'}
                            onChange={(event) => {
                              typeIn(index, 'week', event.target.value)
                            }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Input
                            disableUnderline
                            name={'dow'}
                            type={'number'}
                            className={'full-width'}
                            value={ item.dow }
                            placeholder={'day of week'}
                            onChange={(event) => {
                              typeIn(index, 'dow', event.target.value)
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <AutoCompleteNew
                            options={dietCategories}
                            value={item.mealCategoryId}
                            onChange={(event, selected) => {
                              typeIn(index, 'mealCategoryId', (selected || {value: null}).value)
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <AutoCompleteNew
                            options={recipes}
                            value={item.recipeId}
                            onChange={(event, selected) => {
                              typeIn(index, 'recipeId', (selected || {value: null}).value)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item xs={1} justify={'flex-end'}>
                      <Close onClick={() => { deleteRow(index) }} fontSize={'small'} className={'cursor-pointer'} />
                      <Add onClick={ () => { addSingleRow(index, item) } } fontSize={'small'} className={'cursor-pointer'} />
                    </Grid>
                  </Grid>
              })
            }
          </div>

          <div className={'next'}>
            <ArrowForwardIosIcon fontSize={'large'} className={'cursor-pointer'} onClick={next} />
          </div>
        </div>
      }
    </div>
  )
}