import _ from 'lodash'
import React from 'react'
import { Card, Grid } from '@material-ui/core'
import UploaderForSingleImage from 'components/UploaderForSingleImage'

export default function (props) {
  const { data, handleChange } = props
  
  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>ASSETS</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={12} key='icon'>
        <div style={{paddingTop: '10px'}}>
          <div className=''>
            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
              <span>
                {_.startCase('icon')} (PNG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image size: <b>36*36 px</b>
              </span>
            </div>
          </div>

          <div style={{marginTop: '15px'}}>
            <UploaderForSingleImage
              keyName={'icon'}
              filename={data.icon}
              fileType='SocialBadgeCover'
              handleChange={handleChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  </Card>
}
