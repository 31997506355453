import AddBtn from 'components/AddBtn'
import React, { useCallback } from 'react'
import { PromoBenefitType } from 'common/enum'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'

export default function Toolbar (props) {
  const { params, onParamsChange } = props

  const handleChange = useCallback(event => {
    onParamsChange({ [event.target.name]: event.target.value })
  }, [onParamsChange])

  return <div className={'tool-bar'}>
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Input name='groupId' className='full-width' placeholder='Group ID' value={params.groupId} onChange={handleChange} />
      </Grid>

      <Grid item xs={2}>
        <Input name='groupName' className='full-width' placeholder='Group Name' value={params.groupName} onChange={handleChange} />
      </Grid>

      <Grid item xs={2}>
        <Input name='domain' className='full-width' placeholder='Domain' value={params.domain} onChange={handleChange} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew
          inputLeft={true}
          disableUnderline={false}
          placeholder='group type'
          options={PromoBenefitType}
          value={parseInt(params.groupType)}
          onChange={(event, selected) => {
            onParamsChange({ groupType: selected && selected.value }, 0)
          }}
        />
      </Grid>

      <Grid container xs={4} justify='flex-end'>
        <AddBtn color='primary' type='submit' variant='contained' style={{marginRight: 0}}
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/business/groups/create`)
          }}
        >
          Add
        </AddBtn>
      </Grid>
    </Grid>
  </div>
}
