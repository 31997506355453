import React, { useState, useEffect } from 'react'
import MealPlanForm from './_form'
import { mealPlanDetailPromise } from 'api/MealPlan'

export default function MealPlanEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMealPlan () {
      setData(
        await mealPlanDetailPromise(id)
      )
    }
    fetchMealPlan()
  }, [id])
  return (
    <MealPlanForm data={data} />
  )
}