import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealPlanUpsertPromise, dietListPromise } from 'api/MealPlan'
import { isBlank } from 'common/usual'
import { AutoCompleteNew } from 'components/Select'
import Schedule from './components/schedule'

export default function MealPlanUpsertForm (props) {
  const { data } = props
  const [plan, setPlan] = useState({})
  const [diets, setDiets] = useState([])
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus
      || isBlank(plan.mealsPerDay)
      || isBlank(plan.dietId)
  ), [btnStatus, plan])

  useEffect(() => {
    setPlan(data)
  }, [data])

  useEffect(() => {
    async function fetchDiets () {
      setDiets(
        (await dietListPromise()).map(d => ({
          label: d.title,
          value: d.id,
        }))
      )
    }
    fetchDiets()
  }, [])

  function handleSubmit () {
    setBtnStatus(true)
    mealPlanUpsertPromise(plan)
      .then(res => {
        if (!plan.id) {
          props.history.push('/meal-plans')
        }
        plan.id = res.id
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setPlan(
      assign({}, plan, ...values)
    )
  }, [plan])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: plan
  }), [handleChange, plan])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Meals Per Day'} name={'mealsPerDay'} type={'number'} {...commonProps} />

        <FormItem label={'Diet'} name={'dietId'} >
          <AutoCompleteNew
            options={diets}
            value={plan.dietId}
            onChange={(event, selected) => {
              handleChange({dietId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(plan.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Schedule mealsPerDay={plan.mealsPerDay} handleChange={handleChange} data={plan.schedule} />

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

          {/* <div>{JSON.stringify(plan)}</div> */}
    </div>
  )
}