import React from 'react'
import { Button, Grid } from '@material-ui/core'
import AddBtn from 'components/AddBtn'

export default function WorkoutCategoryToolbar (props) {

  return (
    <div className={'tool-bar'}>
      <Grid container item xs={12} justify={'flex-end'} >
        <AddBtn
          color="primary"
          type="submit"
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/meal-plan/add`)
          }}
          variant="contained"
        >
          Add
        </AddBtn>
      </Grid>
    </div>
  )
}
