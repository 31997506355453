import _ from 'lodash'
import 'assets/scss/form2.scss'
import { Button, Grid } from '@material-ui/core'
import SetupCard from './components/setup'
import DetailsCard from './components/detail'
import TargetCard from './components/target'
import AssetsCard from './components/asset'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { challengeUpsertPromise } from 'api/Challenge'

export default function ChallengeForm (props) {
  const { data, history, isEdit } = props
  const [challenge, setChallenge] = useState({})
  const columnsNotNull = ['code']

  useLayoutEffect(() => {
    if (!_.isEmpty(challenge)) return

    setChallenge(data)
  }, [challenge, data])

  const handleChange = useCallback((key, value, other) => {
    const payload = { ...challenge, [key]: value, ...other }
    payload.set_duration = !!payload.duration
    payload.set_specific_date = !!payload.start_date || !!payload.end_date
    payload.set_time_limit = payload.set_specific_date || !!payload.duration

    setChallenge(payload)
  }, [challenge])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    challengeUpsertPromise(challenge)
      .then(() => history.goBack())
  }, [challenge, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!challenge[cname]) return true
  }, [challenge, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Challenge
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SetupCard data={challenge} isEdit={isEdit} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DetailsCard data={challenge} isEdit={isEdit} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TargetCard data={challenge} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AssetsCard data={challenge} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>
  </div>
}
