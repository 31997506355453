import ProgramNutritionForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { getProgramNutritionByIdPromise } from 'api/programNutrition'

export default function (props) {
  const [programNutrition, setProgramNutrition] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getProgramNutritionByIdPromise(id)
      .then(resp => setProgramNutrition(resp))
  }, [id])
  return (
    <ProgramNutritionForm { ...props } isEdit={true} data={programNutrition} />
  )
}
