import React from 'react'
import { Grid, TextField } from '@material-ui/core'

export default function MerchantToolbar (props) {
  const { params, onParamsChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='User ID' size='small' value={params.userId} className='background-color' onChange={event => onParamsChange({userId: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='User Name' size='small' value={params.userName} className='background-color' onChange={event => onParamsChange({userName: event.target.value}, 500)} />
        </Grid>
      </Grid>
    </div>
  )
}
