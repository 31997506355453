import { getApiClient } from './ApiClient'

export const workoutTrainerListPromise = (params) => {
  return getApiClient()
    .get('/workout-trainer-all', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const trainerListPromise = (params) => {
  return getApiClient()
    .get('/trainers', { params })
    .then(resp => resp.data)
}

export const trainerDetailPromise = (id) => {
  return getApiClient()
    .get(`/trainers/${id}`)
    .then(resp => resp.data)
}

export const upsertTrainerPromise = (body) => {
  return getApiClient()
    .post('/trainers', { body })
    .then(resp => resp.data)
}
