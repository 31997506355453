import React, { useState, useEffect } from 'react'
import WorkoutResourceForm from './components'
import { workoutResourceDetailPromise } from 'api/WorkoutResource'

export default function WorkoutResourceFormView (props) {
  const [data, setData] = useState({active: false})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchWorkoutResource () {
      const wr = await workoutResourceDetailPromise(id)
      setData(wr)
    }

    fetchWorkoutResource()
  }, [id])

  return (
    <WorkoutResourceForm {...props} data={data} type={'edit'} />
  )
}