import _ from 'lodash'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { partnerWorkoutResourceListPromise } from 'api/PartnerWorkoutResource'
import PartnerWorkoutResourceListTable from './components/PartnerWorkoutResourceListTable'
import PartnerWorkoutResourceListToolbar from './components/PartnerWorkoutResourceListToolbar'

const PartnerWorkoutResourceList = props => {
  const ref = useRef()
  const location = useLocation()
  const [partnerResource, setPartnerResource] = useState({})
  const [params, setParams] = useState({ page: 1 })

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    partnerWorkoutResourceListPromise(too).then(resp => setPartnerResource(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <PartnerWorkoutResourceListToolbar {...props} params={params} onChange={handleParamsChange} />
        <br />
        <PartnerWorkoutResourceListTable {...props} page={params.page} data={partnerResource} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default PartnerWorkoutResourceList