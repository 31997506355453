import { getApiClient } from './ApiClient'

export const videoUpload = (body) => {
  return getApiClient()
    .post('/videos/upload', { body })
    .then(resp => resp.data)
}

export const convertMoveResourceVideo = body => {
  return getApiClient()
    .post('/move-resources/convert-video', { body })
    .then(resp => resp.data)
}

export const generateMoveWorkoutVideos = body => {
  return getApiClient()
    .post('/move-workout-videos/generate', { body })
    .then(resp => resp.data)
}
