import React, { useCallback, useEffect } from 'react'
import FormItem from 'components/FormItem'
import { Switch } from '@material-ui/core'
import _ from 'lodash'

export default function AgeAndGenderSwitch (props) {
  const { data, setData } = props

  useEffect(() => {
    setData({
      ...data,
      male: _.isNil(data.male) ? true : data.male,
      female: _.isNil(data.female) ? true : data.female,
      senior: _.isNil(data.senior) ? true : data.senior,
      non_senior: _.isNil(data.non_senior) ? true : data.non_senior,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = useCallback((key, value) => {
    setData({ ...data, [key]: value })
  }, [data, setData])

  return <>
      <FormItem name='senior'>
        <Switch checked={Boolean(data.senior)} onChange={ e => handleChange('senior', e.target.checked) } />
      </FormItem>
      <FormItem name='non_senior'>
        <Switch checked={Boolean(data.non_senior)} onChange={ e => handleChange('non_senior', e.target.checked) } />
      </FormItem>
      <FormItem name='female'>
        <Switch checked={Boolean(data.female)} onChange={ e => handleChange('female', e.target.checked) } />
      </FormItem>
      <FormItem name='male'>
        <Switch checked={Boolean(data.male)} onChange={ e => handleChange('male', e.target.checked) } />
      </FormItem>
    </>
}
