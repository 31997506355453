import './index.scss'
import React from 'react'
import { Delete } from '@material-ui/icons'
import { checkPermission, getPermissionCodeByPath } from 'helpers/permission'

export default function EditBtn (props) {
  const { onClick: handleClick } = props

  const code = getPermissionCodeByPath(window.location.pathname, ['delete', 'edit', 'upsert'])

  if (!checkPermission(code)) return (<></>)

  return (
    <div onClick={handleClick} className={'cursor-pointer edit-btn'}>
      <Delete className='cursor-pointer' />
    </div>
  )
}