import { getApiClient } from './ApiClient'

export const mealPlanListPromise = (params) => {
  return getApiClient()
    .get('/meal-plans', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealPlanDetailPromise = (id) => {
  return getApiClient()
    .get(`/meal-plans/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealPlanUpsertPromise = (body) => {
  return getApiClient()
    .post('/meal-plans/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const dietListPromise = (params) => {
  return getApiClient()
    .get('/diets', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const dietCategoryListPromise = (params) => {
  return getApiClient()
    .get('/diet-categories', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

