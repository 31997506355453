import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { ThemeTypeOptions } from 'common/enum'
import AddBtn from 'components/AddBtn'

const PromoToolbar = props => {
  const { params, handleSearchChange } = props

  return (
    <Grid container item xs={12} justify={'flex-end'} spacing={2} >
      <Grid item xs={2}>
        <Input value={params.title} placeholder='Title' className='full-width' onChange={(event) => { handleSearchChange({ title: event.target.value }, 500) }} />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteNew disableUnderline={false} options={ThemeTypeOptions} placeholder='Type' value={params.type}
          onChange={(event, selected) => handleSearchChange({ type: selected && selected.value })}
        />
      </Grid>

      <Grid item xs={7} />

      <Grid container item xs={1} justify={'flex-end'}>
        <AddBtn
          className={'add-btn'}
          color="primary"
          type="submit"
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/themes/add`)
          }}
          variant="contained"
        > Add </AddBtn>
      </Grid>
    </Grid>
  )
}

PromoToolbar.propTypes = {
  className: PropTypes.string,
}

export default PromoToolbar
