import React from 'react'
import { Grid, Input } from '@material-ui/core'

const PromoToolbar = props => {
  const { params, handleSearch } = props

  return (
    <Grid container item xs={12}>
      <Input placeholder='Resource ID' value={params.workout_id} onChange={(event) => { handleSearch({ workout_id: event.target.value}) }} />
    </Grid>
  )
}

export default PromoToolbar
