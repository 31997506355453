import { getApiClient } from './ApiClient'

export const getVideoResourceList = (params) => {
  return getApiClient().get('/video-resources', { params }).then(resp => resp.data)
}

export const getVideoResourceDetail = (id) => {
  return getApiClient().get(`/video-resources/${id}`).then(resp => resp.data)
}

export const upsertVideoResource = (body) => {
  return getApiClient().post('/video-resources', { body }).then(resp => resp.data)
}
