import React, { useCallback, useEffect, useState } from 'react'
import FormTextField from 'components/FormTextField'
import { Card, Grid, Switch } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { achievementListPromise } from 'api/Achievement'
import DateTimeUTCInput from 'components/DateTimeUTCInput'
import { ParamsForOption } from 'common/enum'

export default function (props) {
  const { data, isEdit, handleChange } = props
  const [achievements, setAchievements] = useState([])

  useEffect(() => {
    achievementListPromise({ page: 1, size: 1000, ...ParamsForOption }).then(resp => {
      const options = resp.rows.map(foo => ({ label: `${foo.general_grey_desc} (${foo.id})`, value: foo.id, iconGrey: foo.icon_grey }))
      setAchievements(options)
    })
  }, [])

  const handleDateChange = useCallback((key, date) => {
    handleChange(key, date && date.format('YYYY-MM-DD'))
  }, [handleChange])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
      <Switch name={'active'} checked={Boolean(data.active)} onChange={e => handleChange('active', e.target.checked)} />
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <Grid item xs={6}>
        <div className='form-input-elem'>
          <FormTextField name='name' label='Name' value={data.name} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className='form-input-elem'>
          <FormTextField name='male_name' label='Male name (Optional)' value={data.male_name} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item xs={6}>
        <DateTimeUTCInput label='Start Date' format='YYYY-MM-DD' hideTime value={data.start_date} handleChange={date => handleDateChange('start_date', date)} />
      </Grid>

      <Grid item xs={6}>
        <DateTimeUTCInput label='End Date' format='YYYY-MM-DD' hideTime value={data.end_date} handleChange={date => handleDateChange('end_date', date)} />
      </Grid>

      <Grid item xs={12}>
        <div className='form-input-elem'>
          <label className='full-width'>Achievement*</label>
          <AutoCompleteNew options={achievements} value={data.code} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => {
              handleChange(
                'code', selected && selected.value,
                { achievement_icon_grey: selected && selected.iconGrey }
              )
            }}
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className='form-input-elem'>
          <FormTextField multiline name='description' label='Description' value={data.description} onChange={handleChange}/>
        </div>
      </Grid>

    </Grid>
  </Card>
}
