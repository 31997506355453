import { options } from 'api/course'
import React, { useEffect, useState } from 'react'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import Cache from 'common/cache'
import { promoListPromise } from 'api/Promo'
import { ParamsForOption } from 'common/enum'

export const CourseTypeAutoComplete = generator('courseType')
export const CourseTrainerMultipleAutoComplete = generator('courseTrainer', true)
export const CourseProductAutoComplete = generator('courseProduct')
export const CourseSocialGroupAutoComplete = generator('courseSocialGroup')

function generator (funcName, multiple=false) {
  return function (props) {
    const { label, value, handleSelectChange } = props
    const [items, setItems] = useState([])
  
    useEffect(() => {
      async function fetchData () {
        if (!Cache.get(funcName)) {
          Cache.set(funcName, options[funcName]())
        }

        const items = await Cache.get(funcName)
        setItems(items.map(item => ({ label: item.key, value: item.value })))
      }
      fetchData()
    }, [])

    const _props = { label, options: items, disableUnderline: true }
  
    return multiple
      ? <MultipleAutoCompleteSelect {..._props} values={value} onChange={(event, selected) => handleSelectChange(selected.map(s => s.value))} />
      : <AutoCompleteNew {..._props} value={value} onChange={(event, selected) => handleSelectChange((selected || {value: null}).value)} />
  }
}

export async function coursePromoOptions () {
  const promos = await promoListPromise({ size: 10000, code: 'course-promo', ...ParamsForOption })
  if (!promos || !promos.rows) return []
  return promos.rows.map(p => ({ label: p.code, value: p.id }))
}
