import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { workoutResourceOptionPromise } from 'api/WorkoutResource'
import { getProgramOptions, upsertProgramWorkout } from 'api/ProgramWorkout'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'


export default function WorkoutCategoryUpsertForm (props) {
  const { oldId, data, setData } = props
  const [programs, setPrograms] = useState([])
  const [workouts, setWorkouts] = useState([])

  const handleChange = useCallback((pair) => {
    setData({ ...data, ...pair })
  }, [data, setData])

  const handleSubmit = useCallback(() => {
    data.oldId = oldId
    upsertProgramWorkout({ ...data, programId: undefined })
      .then(() => props.history.goBack())
  }, [data, oldId, props.history])

  const formItem = useCallback((name, label, props) => {
    return <FormItem label={label} name={name} value={data[name]} onChange={handleChange} {...props} />
  }, [data, handleChange])

  useEffect(() => {
    workoutResourceOptionPromise({ type: 'option' })
      .then(resp => setWorkouts(resp.map(r => ({ label: `${r.name} (${r.id})`, value: r.id }))))

    getProgramOptions()
      .then(resp => setPrograms(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        {/* { formItem('id', 'ID', { readonly: !!oldId }) } */}
        { formItem('subtitle') }

        <FormItem label='Week'>
          <NumberAutoComplete name='duration' from={1} to={100} value={data.week} handleSelectChange={week => { handleChange({ week }) }} />
        </FormItem>

        <FormItem label='Day'>
          <NumberAutoComplete name='duration' from={0} to={7} value={data.sequence} handleSelectChange={sequence => { handleChange({ sequence }) }} />
        </FormItem>


        <FormItem label='Program' name='program'>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.program_id}
            options={programs}
            onChange={(event, selected) => {
              handleChange({ program_id: selected && selected.value })
            }}
          />
        </FormItem>

        <FormItem label='Workout' name='workout_id'>
          <AutoCompleteNew
            disableUnderline={true}
            value={data.content_id}
            options={workouts}
            onChange={(event, selected) => {
              handleChange({ content_id: selected && selected.value })
            }}
          />
        </FormItem>

        <FormItem name={'active'}>
          <Switch name='active' checked={Boolean(data.active)}
            onChange={ e => handleChange({ active: e.target.checked }) }
          />
        </FormItem>

        <Button
          className='save-btn'
          color='primary'
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}