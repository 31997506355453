import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid } from '@material-ui/core'

export default function Toolbar (props) {
  // const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          {/* <Input value={params.programName} placeholder='Program Name' className={'block-value'} onChange={(event) => { handleSearchChange({programName: event.target.value}) }} /> */}
        </Grid>
        <Grid item xs={8} />
        <Grid container item xs={2} justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/program-tools/add`)
            }}
            variant='contained'>
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
