import React, { useCallback, useState } from 'react'
import { addUserToSocialGroupPromise, removeUserFromSocialGroupPromise } from 'api/User'
import Form from './form'

export default function (props) {
  const { userId } = props
  const [groupId, setGroupId] = useState('')

  const addToGroup = useCallback(() => {
    addUserToSocialGroupPromise({ userId: userId, groupId: groupId })
  }, [groupId, userId])
  
  const removeFromGroup = useCallback(() => {
    removeUserFromSocialGroupPromise({ userId: userId, groupId: groupId })
  }, [groupId, userId])

  return <Form placeholder={'Group Id'} value={groupId} setValue={setGroupId} firstBtnAction={addToGroup} secondBtnAction={removeFromGroup} />
}
