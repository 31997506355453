import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { isBlank } from 'common/usual'
import { assign } from 'lodash'
import { createBlacklistPromise } from 'api/RequestBlacklist'
import { RequestBlacklistType } from 'common/enum'

export default function WorkoutCategoryUpsertForm (props) {
  const { data } = props
  const [blacklist, setBlacklist] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus ||
    isBlank(blacklist.type) ||
    isBlank(blacklist.blockValue)
  ), [btnStatus, blacklist])

  useEffect(() => {
    setBlacklist(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    createBlacklistPromise(blacklist)
      .then(resp => {
        if (!blacklist.id) {
          props.history.push('/request-blacklist')
        }
      })
      .finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setBlacklist(
      assign({}, blacklist, ...values)
    )
  }, [blacklist])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Type'} name={'type'}>
          <AutoCompleteNew
            options={RequestBlacklistType}
            value={blacklist.type}
            onChange={(event, selected) => {
              handleChange({type: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem
          label={'Block value'}
          name={'blockValue'}
          value={blacklist.blockValue}
          onChange={(foo) => { handleChange(foo) }}
        />
        
        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}