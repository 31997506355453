import _ from 'lodash'
import JSONEditor from 'components/JSONEditor'
import Card from '@material-ui/core/Card/Card'
import { Button, Grid } from '@material-ui/core'
import FormTextField from 'components/FormTextField'
import { MultipleAutoCompleteSelect } from 'components/Select'
import { syncFohStudioPromise, upsertFohStudioPromise } from 'api/studio'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'
import UploaderForSingleImage from 'components/UploaderForSingleImage'

export default function UserGroupForm (props) {
  const { data, history } = props
  const [studio, setStudio] = useState(data)
  const [description, setDescription] = useState()
  const [regularOpeningHours, setRegularOpeningHours] = useState()

  useLayoutEffect(() => {
    setStudio(data)
  }, [data])

  const readonlyKeys = useMemo(() => {
    return ['google_place_id', 'name', 'street_primary', 'city', 'state', 'zipcode', 'longitude', 'latitude']
  }, [])

  // displayName, regularOpeningHours, rating, websiteURI, editorialSummary
  const editableKeys = useMemo(() => {
    return ['display_name', 'website']
  }, [])

  const OverrideOnOptions = useMemo(() => {
    return editableKeys.concat('rating', 'description', 'regular_opening_hours').map(k => ({ label: _.camelCase(k), value: k }))
  }, [editableKeys])

  const OverrideOnColumns = useMemo(() => {
    return OverrideOnOptions.map(x => x.value)
  }, [OverrideOnOptions])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertFohStudioPromise(studio)
      .then(() => props.history.push('/foh-studios'))
  }, [props.history, studio])

  const handleChange = useCallback((key, val) => {
    if (_.isEqual(studio[key], val)) return

    const payload = { [key]: val }

    if (OverrideOnColumns.includes(key))
      payload.override_on = _.isEqual(data[key], val)  
        ? [..._.pull(studio.override_on, key)]
        : _.uniq((studio.override_on || []).concat(key))

    setStudio(prev => ({ ...prev, ...payload }))
  }, [OverrideOnColumns, data, studio])

  const handleOverrideOnChange = useCallback(value => {
    setStudio(prev => ({ ...prev, override_on: value }))
  }, [])

  const handleJsonEditorOnBlur = useCallback((key, getter) => {
    handleChange(key, getter())
  }, [handleChange])

  const handleSync = useCallback(() => syncFohStudioPromise({ id: data.id }), [data.id])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {studio.id ? 'Edit' : 'New'} FOH Studio
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>OVERVIEW</label>
              
              {
                data.id && <Button color='secondary' size='small' variant='contained' onClick={e => {
                  e.stopPropagation()
                  handleSync().then(() => window.location.reload())
                }}> Sync </Button>
              }
              
            </Grid>

            <Grid container item xs={12} className='form-card-content' >
              {
                readonlyKeys.map(key => <Grid item xs={6} key={key}>
                  <div className='form-input-elem' key={key}>
                    <FormTextField name={key} label={_.startCase(key)} value={studio[key]} readonly={true} onChange={() => {}} />
                  </div>
                </Grid>)
              }
            </Grid>
          </Card>

          <br />

          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
            </Grid>

            <Grid container item xs={12} className='form-card-content' >
              {
                editableKeys.map(key => <Grid item xs={6} key={key}>
                  <div className='form-input-elem' key={key}>
                    <FormTextField name={key} label={_.startCase(key)} value={studio[key]} onChange={handleChange} />
                  </div>
                </Grid>)
              }

              <Grid item xs={6} key={'rating'}>
                <div className='form-input-elem' key='rating'>
                  <FormTextField decimalOnly={true} name='rating' label='Rating' value={studio.rating} onChange={handleChange} />
                </div>
              </Grid>

              <Grid item xs={6} key='override_on'>
                <div className='form-input-elem'>
                  <label className='full-width'>Override On</label>
                  <MultipleAutoCompleteSelect options={OverrideOnOptions} values={studio.override_on || []} className='full-width' variant='outlined'
                    onChange={(event, selected) => handleOverrideOnChange(selected.map(s => s.value))}
                  />
                </div>
              </Grid>

              <Grid item xs={6} className='bottom-dashed'>
                <BooleanToggleButtonGroup
                  label='Google Photos' name='google_photos' withoutNull={true} value={studio.google_photos} onChange={handleChange} />
              </Grid>

              <Grid item xs={12} key='icon' className='bottom-dashed'>
                <div style={{paddingTop: '10px'}}>
                  <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                      <span>Default Photo</span>
                    </div>
                  </div>

                  <div style={{marginTop: '15px'}}>
                    <UploaderForSingleImage
                      keyName='default_photo'
                      fileType='FohStudioPhoto'
                      handleChange={handleChange}
                      filename={studio.default_photo}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <Grid item>
                  <label className='form-card-label'>Regular Opening Hours</label>
                </Grid>

                <Grid item style={{border: '1px #E5E5E5 solid'}}>
                  <JSONEditor
                    height='300px'
                    setJSONGetter={setRegularOpeningHours}
                    initValue={studio.regular_opening_hours || {}}
                    handleBlur={() => handleJsonEditorOnBlur('regular_opening_hours', regularOpeningHours)}
                  />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid item>
                  <label className='form-card-label'>Description</label>
                </Grid>

                <Grid item>
                  <JSONEditor
                    height='300px'
                    setJSONGetter={setDescription}
                    initValue={studio.description || {}}
                    handleBlur={() => handleJsonEditorOnBlur('description', description)}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
