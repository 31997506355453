import React, { useState, useEffect, useCallback, useRef } from 'react'
import { featureBannerListPromise } from 'api/FeatureBanner'
import FeatureBannerToolBar from './components/FeatureBannerToolBar'
import FeatureBannerTable from './components/FeatureBannerTable'
import { useLocation } from 'react-router-dom'
import QS from 'qs'
import _ from 'lodash'

const MealList = props => {
  const [params, setParams] = useState({})
  const [featureBanners, setFeatureBanners] = useState({})
  const location = useLocation()
  const ref = useRef()

  // load data
  useEffect(() => {
    const foo = QS.myParse(location.search, false)
    setParams(foo)
    featureBannerListPromise({ ...foo, size: 1000 }).then(resp => setFeatureBanners(resp))
  }, [location.search])

  const changeParams = useCallback((obj, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const too = _.pickBy({ page: 1, ...obj }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${QS.stringify(too)}`), delay)
  }, [props.history])

  return (
    <div className={'root'}>
      <FeatureBannerToolBar {...props} params={params} changeParams={changeParams} />
      <div className={'content'}>
        <FeatureBannerTable {...props} data={featureBanners} page={params.page} />
      </div>
    </div>
  )
}

export default MealList
