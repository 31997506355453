import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import JSONEditorPlugin from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'
import { uuidV4 } from 'helpers/utils'

export default function JSONEditor (props) {
  const { initValue, setJSONGetter, handleBlur, height='800px' } = props
  const [editor, setEditor] = useState()

  const id = useMemo(() => `json-editor-${uuidV4()}`, [])

  useEffect(() => {
    setEditor(new JSONEditorPlugin(
      document.getElementById(id),
      { modes: ['code', 'text', 'form'] }
    ))
  }, [id])

  useEffect(() => {
    if (!editor) return
    
    editor.set(initValue)
  }, [editor, initValue])

  useEffect(() => {
    setJSONGetter(() => function () {
      try { return editor.get() }
      catch (err) { window.alert('invalid json') }
    })
  }, [editor, setJSONGetter])


  return <div id={id} style={{ width: '100%', height }} onBlur={handleBlur}></div>
}

JSONEditor.propTypes = {
  setJSONGetter: PropTypes.func.isRequired,
  initValue: PropTypes.object.isRequired,
}
