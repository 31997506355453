import Path from 'path'
import Context from 'common/context'
import { Button } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { checkIfS3ObjectExistPromise, cloudFrontInvalidatePromise, compressImage, getUploadUrlSimplePromise, uploadFileToS3 } from 'api/helper'
import { randString } from 'helpers/utils'

const Uploader2 = props => {
  const { bucket, filename: preFilename, preferName, filenameFormatter, filepath, params, isDeletable, afterUpload, dropzoneText, onDelete, isForceRename, isUseOriginalName, isCheckExist, isNeedCompress=true, isUseable=true } = props
  const { handleNotification } = Context._currentValue
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [localeFilename, setLocaleFilename] = useState('')

  useEffect(() => {
    setLocaleFilename(preFilename)
  }, [preFilename])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleAfterUpload = useCallback(params => {
    afterUpload && afterUpload(params)
  }, [afterUpload])

  const checkIfExist = useCallback(async () => {
    if (!isCheckExist || preFilename === localeFilename) return false
    
    const [res] = await checkIfS3ObjectExistPromise([{ bucket, key: `${filepath}/${localeFilename}` }])

    return res.isExist
  }, [bucket, filepath, isCheckExist, localeFilename, preFilename])

  const backfillFilename = useCallback((file) => {
    if (isUseOriginalName) {
      const fname = file.name.replace(/[^\w._-]/g, '')
      if (/\w+\.\w+/.test(fname)) return setLocaleFilename(fname)
    }
    if (!isUseOriginalName && !isForceRename && preFilename) return

    const extname = Path.extname(file.name)
    const _name = `${preferName || randString()}${extname}`
    setLocaleFilename(filenameFormatter ? filenameFormatter(_name) : _name)
  }, [preFilename, filenameFormatter, isForceRename, isUseOriginalName, preferName])

  const handleChange = useCallback(async targets => {
    const _file = targets[0]
    if (!targets || targets.length <= 0) return
    if (!_file) return handleNotification('error', 'invalid file')

    backfillFilename(_file)

    // if (sizeConstraint) {
    //   const [width, height] = splitWidthAndHeight(sizeConstraint)

    //   for (const _file of _files) {
    //     const img = await getImageInfo(_file)

    //     if (`${img.width}` !== width || `${img.height}` !== height)
    //       return handleNotification('error', `invalid image size, expected: ${sizeConstraint}`)
    //   }
    // }

    setFile(_file)
  }, [backfillFilename, handleNotification])

  const upload = useCallback(async function () {
    try {
      const msg = 'A file with the same name already exists on S3, do you want to overwrite it?'
      if (await checkIfExist() && !window.confirm(msg)) return

      const presignedUrls = await getUploadUrlSimplePromise({ bucket, paths: [{filepath, filename: localeFilename, params}] })
      
      if (!presignedUrls || presignedUrls.length === 0)
        return handleNotification('error', 'retrieve AWS presigned url failed')

      const presignedUrl = presignedUrls[0]
      const res = await uploadFileToS3(presignedUrl.url, file)
      if (res.status !== 200) return handleNotification('error', )
      isNeedCompress && await compressImage(presignedUrl)
      preFilename && await cloudFrontInvalidatePromise(`/${presignedUrl.key}`)

      handleAfterUpload(localeFilename)
      handleNotification('success', 'file uploaded successfully')
    } catch (err) {
      // handleAfterUpload(preFilename)
      handleNotification('error', err.message)
    }
  }, [bucket, checkIfExist, file, filepath, handleAfterUpload, handleNotification, isNeedCompress, localeFilename, params, preFilename])

  const handleSave = useCallback(async () => {
    if (!file) return
    setOpen(false)
    await upload()
    setFile(null)
  }, [file, upload])

  return <div>
    { isDeletable && <Button variant='outlined' style={{color: 'red'}} onClick={onDelete}>DELETE</Button> }
    &nbsp;&nbsp;
    <Button variant='outlined' style={{color: '#3F44AB'}} disabled={!isUseable} onClick={() => setOpen(true)}>
      Upload
    </Button>

    <DropzoneDialog
      open={open}
      showAlerts={false}
      onSave={handleSave}
      showPreviews={false}
      clearOnUnmount={true}
      onClose={handleClose}
      onChange={handleChange}
      initialFiles={[]}
      showPreviewsInDropzone={true}
      filesLimit={1}
      maxFileSize={30000000000}
      dropzoneText={dropzoneText || 'drag image here or upload from computer'}
    />
  </div>
}

export default Uploader2
