import { CourseTypes, ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const getUploadUrlPromise = (body) => {
  return getApiClient()
    .post('tools/upload/presigned-url', { body })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

// course/convert-job
export const createVideoConvertJobPromise = payload => {
  return getApiClient()
    .post('course/convert-job', { body: [].concat(payload) })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const getCourseDetailPromise = id => {
  return getApiClient()
    .get(`/courses/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const getCourseListPromise = (params) => {
  return getApiClient()
    .get('/course', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const upsertCoursePromise = (body) => {
  return getApiClient()
    .post('/course', { body })
    .then(resp => resp.data)
}

export const options = {
  courseType: () => CourseTypes.map(ct => ({ key: ct.label, value: ct.num })),
  courseProduct: () => getApiClient().get('/course/sku', { params: ParamsForOption }).then(resp => resp.data),
  courseSocialGroup: () => getApiClient().get('/course/group', { params: ParamsForOption }).then(resp => resp.data),
  courseTrainer: () => getApiClient().get('/course/trainer', { params: ParamsForOption }).then(resp => resp.data.map(d => ({ key: d.key, value: d.value }))),
}

export const getUserCourseListPromise = params => {
  return getApiClient()
    .get('/user-courses', { params })
    .then(resp => resp.data)
}

export const getUserCourseDetailPromise = id => {
  return getApiClient()
    .get(`/user-courses/${id}`)
    .then(resp => resp.data)
}

export const addUserToCoursePromise = body => {
  return getApiClient()
    .post('/user-courses', { body })
    .then(resp => resp.data)
}

export const updateToCoursePromise = (id, body) => {
  return getApiClient()
    .put(`/user-courses/${id}`, { body })
    .then(resp => resp.data)
}
