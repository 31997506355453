import React, { useState, useEffect, useCallback } from 'react'
import { mealIngredientCategoryListPromise } from 'api/MealIngredientCategory'
import MealIngredientCategoryTable from './components/MealIngredientCategoryListTable'
import MealIngredientCategoryToolbar from './components/MealIngredientCategoryListToolbar'

const MealIngredientCategoryList = props => {
  const [categories, setCategories] = useState([])

  const getCategories = useCallback(async () => {
    const categories = await mealIngredientCategoryListPromise()
    if (categories) {
      setCategories(categories)
    }
  }, [])

  useEffect(() => {
    getCategories()
  }, [getCategories])
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealIngredientCategoryToolbar {...props} />
        <br />
        <MealIngredientCategoryTable {...props} data={categories} />
      </div>
    </div>
  )
}

export default MealIngredientCategoryList