import UpsertForm from './form'
import { getSkuCategoryDetailRoute } from 'api/Product'
import React, { useState, useLayoutEffect } from 'react'

export default function SkuCategoryEdit (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getSkuCategoryDetailRoute(id).then(setData)
  }, [id])

  return (
    <UpsertForm { ...props } data={data} />
  )
}
