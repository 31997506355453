import { Card, CardActions, CardContent, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import React, { useCallback } from 'react'
import EditBtn from 'components/EditBtn'
import { send } from 'helpers/utils'
import { getPermissionCodeByPath } from 'helpers/permission'

export default function (props) {
  const { data, page, onPageChange } = props

  const goToDetail = useCallback(id => {
    props.history.push(`/business/groups/${id}`)
  }, [props.history])

  return <Card>
    <CardContent className={'table-content'}>
        <div className={'inner'}>
          <Table>
            <TableHead style={{ tableLayout: 'fixed' }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Deeplink</TableCell>
                <TableCell>Country Code</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Total Licenses</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {((data && data.rows) || []).map(record => (
                  <TableRow key={record.id} className='cursor-pointer' onClick={() => goToDetail(record.id)} hover>
                    <TableCell>{record.id}</TableCell>
                    <TableCell className='overflow-text' style={{ maxWidth: '250px' }} hover={record.name}>
                      {record.name}
                    </TableCell>
                    <TableCell>{record.domains.map(d => <p>{d.domain}</p>)}</TableCell>
                    <TableCell>{record.deeplink}</TableCell>
                    <TableCell>{record.country_code}</TableCell>
                    <TableCell>{record.members || 0}</TableCell>
                    <TableCell>{send(record, 'promo', 'threshold') || 0}</TableCell>
                    <TableCell>{record.active ? 'TRUE' : 'FALSE'}</TableCell>
                    <TableCell>
                      <EditBtn path={`/business/groups/edit/${record.id}`} {...props} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
    </CardContent>
    <CardActions className={'flex-right'}>
      <TablePagination
        component="div"
        count={(data && data.count) || 0}
        onChangePage={onPageChange}
        page={page - 1}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </CardActions>
  </Card>
}
