import React from 'react'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

moment.tz.setDefault('Etc/UTC')

export default function (props) {
  const { value, onChange, disableUnderline=true } = props

  return <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
    <KeyboardDateTimePicker
      size='small'
      format='YYYY-MM-DD HH:mm:ss'
      InputProps={{ disableUnderline }}
      value={ value || null }
      onChange={ onChange }
    />
  </MuiPickersUtilsProvider>
}
