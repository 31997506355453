import React, { useCallback } from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, TextField } from '@material-ui/core'

export default function WorkoutCategoryToolbar (props) {
  const { params, onParamsChange } = props

  const handleChange = useCallback(event => {
    onParamsChange({
      [event.target.name]: event.target.value
    }, 500)
  }, [onParamsChange])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='User ID' size='small' name='user_id' value={params.user_id || ''} className='background-color' onChange={handleChange} />
        </Grid>
        <Grid container item xs={8} justify={'flex-end'}>
          <AddBtn
            type='submit'
            color='primary'
            variant='contained'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push('/user-social-badges/create')
            }}
          >
            Add User Social Badge
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
