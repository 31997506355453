import React, { useState, useEffect } from 'react'
import MealForm from './_form'
import { mealDetailPromise } from 'api/Meal'

export default function MealEdit (props) {
  const [data, setData] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchMeal () {
      setData(
        await mealDetailPromise(id)
      )
    }
    fetchMeal()
  }, [id])
  return (
    <MealForm mode='edit' data={data} {...props}/>
  )
}