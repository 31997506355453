import React, { useState, useEffect } from 'react'
import WorkoutTrendingForm from './_form'
import { workoutTrendingDetailPromise } from 'api/WorkoutTrending'

export default function WorkoutTrendingEdit (props) {
  const [trending, setTrending] = useState({})
  const {
    match: { params: { id } }
  } = props

  useEffect(() => {
    async function fetchTrending () {
      const data = await workoutTrendingDetailPromise(id)
      setTrending(data)
    }
    fetchTrending()
  }, [id])

  return (
    <WorkoutTrendingForm trending={trending} />
  )
}