import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealIngredientCategoryGroceryUpsertPromise } from 'api/MealIngredientCategoryGrocery'
import { isBlank } from 'common/usual'

export default function MealCategoryGroceryUpsertForm (props) {
  const { data } = props
  const [grocery, setGrocery] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus || isBlank(grocery.title)
  ), [btnStatus, grocery.title])

  useEffect(() => {
    setGrocery(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    mealIngredientCategoryGroceryUpsertPromise(grocery)
      .then(res => {
        if (!grocery.id) {
          props.history.push('/meal-ingredient/category-groceries')
        }
        grocery.id = res.id
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setGrocery(
      assign({}, grocery, ...values)
    )
  }, [grocery])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: grocery
  }), [handleChange, grocery])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title'} name={'title'} {...commonProps} />
        <FormItem label={'Sort'} name={'sort'} type={'number'} {...commonProps} />

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(grocery.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(grocery)}</div> */}
    </div>
  )
}