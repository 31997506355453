import React from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { booleanString, formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

const AttachmentTable = props => {
  const { content } = props

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Group ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Spam</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {content &&
                  <TableRow className={'table-row'} key={content.id}>
                    <TableCell>{content.id}</TableCell>
                    <TableCell>{content.userId}</TableCell>
                    <TableCell>{content.groupId}</TableCell>
                    <TableCell>{content.typeText}</TableCell>
                    <TableCell>{booleanString(content.spam)}</TableCell>
                    <TableCell>{formatDateTime(content.createdAt)}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default AttachmentTable