import { ParamsForOption } from 'common/enum'
import { isBlank } from 'common/usual'
import { isFunction, first } from 'lodash'

const list = {
  workoutCategory: {
    req: require('api/WorkoutCategory').workoutCategoryListPromise,
    reqParams: [{ type: 'option', ...ParamsForOption }],
  },
  workoutEquipment: {
    req: require('api/WorkoutEquipment').workoutEquipmentListPromise,
    reqParams: [{ type: 'option', ...ParamsForOption }],
  },
  workoutTarget: {
    req: require('api/WorkoutTarget').workoutTargetListPromise,
    reqParams: [{ type: 'option', ...ParamsForOption }],
  },
  workoutTrainer: {
    req: require('api/WorkoutTrainer').workoutTrainerListPromise,
    reqParams: [{ type: 'option', ...ParamsForOption }],
    formatParams: ['userId', 'name']
  },
  workoutResource: {
    req: require('api/WorkoutResource').workoutResourceOptionPromise,
  },
  workoutResourceNotLive: {
    req: require('api/WorkoutResource').workoutResourceOptionPromise,
    reqParams: [{ workoutType: [0, 2] }],
  },
  workout: {
    req: require('api/WorkoutResource').workoutResourceOptionPromise,
    reqParams: [{ workoutType: [0, 2] }],
    formatParams: [(item) => (first(item.workout) || {}).id, 'name']
  },
  workoutCategoryShowingType: {
    req: require('api/WorkoutCategory').workoutCategoryShowingTypeListPromise,
    formatParams: ['value', 'name']
  },
  partner: {
    req: require('api/Partner').partnerListPromise
  }
}

export const optionEnum = {
  category: 'workoutCategory',
  equipment: 'workoutEquipment',
  target: 'workoutTarget',
  trainer: 'workoutTrainer',
  resource: 'workoutResource',
  resourceNotLive: 'workoutResourceNotLive',
  workout: 'workout',
  categoryShowingType: 'workoutCategoryShowingType',
  partner: 'partner'
}

export async function fetchWorkoutOptions (...options) {
  options = isBlank(options) ? Object.keys(list) : options
  const result = {}

  await Promise.all(options.map(key => {
    const { req, reqParams=[], formatParams=[], subField } = list[key]

    return req(...reqParams).then(res => {
      result[key] = format(
        subField ? res[subField] : res,
        ...formatParams
      )
    })
  }))

  return result
}

function format (items,  value='id', label='name') {
  return (items || []).map(item => ({
    ...item,
    label: isFunction(label) ? label(item) : item[label],
    value: isFunction(value) ? value(item) : item[value],
  }))
}