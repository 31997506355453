import React from 'react'
import { Button, Grid, Input } from '@material-ui/core'

export default function (props) {
  const { value, setValue, placeholder, firstBtnAction, secondBtnAction } = props

  return (
    <div className='info-item'>
      <Grid container>
        <Grid item xs={4}>
          <Input disableUnderline value={value} className='full-width' placeholder={placeholder} onChange={e => setValue(e.target.value)} />
        </Grid>

        <Grid item xs={1}>
          <Button color='primary' type='submit' variant='contained' disabled={!value} onClick={firstBtnAction}>
            Add
          </Button>
        </Grid>

        <Grid item xs={7}>
          <Button color='primary' type='submit' variant='contained' disabled={!value} onClick={secondBtnAction}>
            Remove
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

