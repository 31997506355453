import { getApiClient } from './ApiClient'

export const requestCourseWebsiteListPromise = (params) => {
  return getApiClient()
    .get('/course/websites', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const requestCourseWebsiteDetailPromise = (id) => {
  return getApiClient()
    .get(`/course/websites/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertCourseWebsitePromise = (body) => {
  return getApiClient()
    .post('/course/websites', { body })
    .then(resp => Promise.resolve(resp.data))
}
