import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const partnerListPromise = () => {
  return getApiClient()
    .get('/partners', { params: ParamsForOption })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const linkUserToPartnerPromise = async function (body) {
  return getApiClient().post('/partner/link', { body }).then(resp => resp.data)
}

export const unlinkUserToPartnerPromise = async function (body) {
  return getApiClient().post('/partner/unlink', { body }).then(resp => resp.data)
}
