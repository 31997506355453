import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { fetchWorkoutOptions, optionEnum } from '../../../concerns/workoutOptions'
import AddBtn from 'components/AddBtn'

export default function WorkoutCategoryToolbar (props) {
  const [options, setOptions] = useState({})
  const { handleChange, params } = props

  useEffect(() => {
    async function fetchOptions () {
      const _options = await fetchWorkoutOptions(optionEnum.partner)
      setOptions(_options)
    }
    fetchOptions()
  }, [])

  function handleSelectChange (key, selected) {
    handleChange({
      [key]: (selected || {value: null}).value
    })
  }

  return (
    <div className={'tool-bar'}>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <AutoCompleteNew
            label='Partner'
            inputLeft={true}
            disableUnderline={false}
            options={options.partner || []}
            value={params.partnerId}
            onChange={(event, selected) => {
              handleSelectChange('partnerId', selected)
            }}
          />
        </Grid>

        <Grid item xs={8} />

        <Grid container item xs={1} justify={'flex-end'}>
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/partner-workout-category/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
