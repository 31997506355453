import React, { useCallback, useState } from 'react'
import MyModal from 'components/MyModal'
import moment from 'moment'

export default function (props) {
  const { src, height='80px', width='80px' } = props
  const [open, setOpen] = useState(false)
  
  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])

  if (!src ) return <img alt='' src='/images/blank.jpg' width={width} height={height} style={{borderRadius: '6px'}} />

  const url = `${src}?t=${moment().unix()}`

  return <div onClick={handleClick} className='cursor-pointer'>
    <img alt='' src={url} width={width} height={height} style={{ borderRadius: '6px' }} />

    <MyModal open={open} setOpen={setOpen} isFullScreen={true}>
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img alt='' src={url} style={{ maxHeight: '100%', maxWidth: '100%' }} />
      </div>
    </MyModal>
  </div>
}
