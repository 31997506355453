import { getApiClient } from './ApiClient'

export const getTranslationRelationTypes = (params) => {
  return getApiClient().get('/translation-relation-types', { params }).then(resp => resp.data)
}

export const populateTranslationItemRelations = (payload) => {
  const body = { ...payload, ids: payload.ids && payload.ids.split(',') }
  return getApiClient().post('/translation-item-relations', { body }).then(resp => resp.data)
}
