import React from 'react'
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core'

function SimpleTab (props) {
  const { tabs, panels, onChange } = props
  const [value, setValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
    onChange()
  }

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {tabs.map((t, i) => (
              <Tab key={i} label={t.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {panels.map((p, i) => (
          <TabPanel key={i} value={value} index={i}>
            {p.content}
          </TabPanel>
      ))}
    </>
  )
}

function TabPanel(props) {
  const { value, index, other, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/* {value === index && children} */}
      {children}
    </div>
  )
}

SimpleTab.prototype = {
  tabs: PropTypes.array.isRequired,
  panels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SimpleTab