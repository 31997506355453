import FormItem from 'components/FormItem'
import { Card, Button } from '@material-ui/core'
import ActiveSwitch from 'components/ActiveSwitch'
import React, { useState, useCallback, useLayoutEffect } from 'react'
import { featureBannerPlacementUpsertPromise } from 'api/FeatureBanner'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, history } = props
  const [placement, setPlacement] = useState({})

  useLayoutEffect(() => setPlacement(data), [data])

  function handleSubmit () {
    featureBannerPlacementUpsertPromise(placement)
      .then(() => history.push('/feature-banner/placements'))
  }

  const handleValueChange = useCallback((map) => {
    setPlacement({ ...placement, ...map })
  }, [placement])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name='name' value={placement.name} onChange={handleValueChange} />
        <FormItem name='image_size' placeholder='100x200' value={placement.image_size} onChange={handleValueChange} />

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch rawBody={data} body={placement} columns={[]} setBody={setPlacement} isNeedCheck={false} />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}