import { getApiClient } from './ApiClient'

export const moveTypeListPromise = (params) => {
  return getApiClient()
    .get('/move-types', { params })
    .then(resp => resp.data)
}

export const moveResourceListPromise = (params) => {
  return getApiClient()
    .get('/move-resources', { params })
    .then(resp => resp.data)
}

export const moveResourceDetailPromise = (id) => {
  return getApiClient()
    .get(`/move-resources/${id}`)
    .then(resp => resp.data)
}

export const moveResourceUpsertPromise = (body) => {
  return getApiClient()
    .post('/move-resources/upsert', { body })
    .then(resp => resp.data)
}


export const moveWorkoutListPromise = (params) => {
  return getApiClient()
    .get('/move-workouts', { params })
    .then(resp => resp.data)
}

export const moveWorkoutDetailPromise = (id) => {
  return getApiClient()
    .get(`/move-workouts/${id}`)
    .then(resp => resp.data)
}

export const moveWorkoutUpsertPromise = (body) => {
  return getApiClient()
    .post('/move-workouts/upsert', { body })
    .then(resp => resp.data)
}

export const cropCoverForMoveResourcePromise = body => {
  return getApiClient()
    .post('/move-resources/crop-cover', { body })
    .then(resp => resp.data)
}