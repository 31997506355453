import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid } from '@material-ui/core'

export default function Toolbar (props) {
  return (
    <div className={'tool-bar'}>
      <Grid container justify='flex-end'>
        <AddBtn
          color="primary"
          type="submit"
          style={{marginRight: 0}}
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/course-websites/create`)
          }}
          variant="contained"
        >
          Add
        </AddBtn>
      </Grid>
    </div>
  )
}
