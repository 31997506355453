import { getApiClient } from './ApiClient'

export const workoutDailyfixListPromise = (params) => {
  return getApiClient()
    .get('/workout-dailyfixes', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutDailyfixDetailPromise = (id) => {
  return getApiClient()
    .get(`/workout-dailyfixes/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutDailyfixDeletePromise = (id) => {
  return getApiClient()
    .delete(`/workout-dailyfixes/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutDailyfixUpsertPromise = (params) => {
  return getApiClient()
    .post('/workout-dailyfixes/upsert', {
      body: params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const dailyfixPromise = (params) => {
  return getApiClient()
    .get('/dailyfixes', { params })
    .then(resp => {
      return Promise.resolve(resp.data)
    }) 
}
