import { Button, Grid } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import Card from '@material-ui/core/Card/Card'
import FormTextField from 'components/FormTextField'
import { addUserToGroupPromise } from 'api/Product'

export default function UserGroupForm (props) {
  const { history } = props
  const [data, setData] = useState({})

  const handleChange = useCallback((name, value) => {
    setData({ ...data, [name]: value })
  }, [data])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    addUserToGroupPromise(data)
  }, [data])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} User Group
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
            </Grid>

            <Grid container item xs={12} className='form-card-content' >
              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <FormTextField name='userId' label='User ID' numberOnly={true} value={data.userId} onChange={handleChange} />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <FormTextField name='groupId' label='Group ID' numberOnly={true} value={data.groupId} onChange={handleChange} />
                </div>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
