import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Button, Grid } from '@material-ui/core'
import FormTextField from 'components/FormTextField'

export default function (props) {
  const { files, setFiles, title, desc, dailyTask } = props

  const handleChange = useCallback((idx, key, value) => {
    if (!files[idx]) return
    _.set(files[idx], key, value)
    setFiles([...files])
  }, [files, setFiles])

  const handleAdd = useCallback(() => {
    const maxRecord = _.maxBy(files.filter(f => !f.isDeleted), 'id') || { id: 0 }

    setFiles([...files, { id: 1 + parseInt(maxRecord.id), description: dailyTask }])
  }, [files, setFiles, dailyTask])

  return <div style={{ width: '80%', maxWidth: '1000px', margin: '30px auto 0' }}>
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px' }}>
        <div style={{ alignSelf: 'end' }}>
          <span>{title || 'Weekly Tasks'}</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className='desc-color'>{desc || 'If no task set, default daily task will show at that week'}</span>
        </div>
        <Button variant='outlined' style={{ color: '#3F44AB' }} onClick={handleAdd}>ADD</Button>
      </div>
    </div>

    {
      files.map(({ id, week, description, isDeleted }, idx) => isDeleted || <div key={`container-${idx}`}>
        <Grid key={`weekly-task-grid-${idx}`} container spacing={2} style={{ padding: '10px 0' }} >
          <Grid container item xs={6}>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>ID</Grid>
            <Grid item xs={8}>
              <FormTextField name='id' value={id} numberOnly={true} placeholder='ID' variant='outlined' size='small' className='full-width'
                onChange={(name, value) => handleChange(idx, name, parseInt(value))} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>Week</Grid>
            <Grid item xs={9}>
              <FormTextField name='week' value={week} placeholder='1' variant='outlined' size='small' className='full-width'
                onChange={(name, value) => handleChange(idx, name, parseInt(value))} />
            </Grid>
          </Grid>
          

          <Grid container item xs={6}>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>Description</Grid>
            <Grid item xs={8}>
              <FormTextField name='description' value={description} numberOnly={false} placeholder='weeklyTask description' variant='outlined' size='small' className='full-width'
                onChange={(name, value) => handleChange(idx, name, value)} />
            </Grid>
          </Grid>

          <Grid container item xs={1} justify='flex-end' style={{ display: 'flex', alignItems: 'right' }}>
            <Button variant='outlined' style={{ color: 'red' }} onClick={() => handleChange(idx, 'isDeleted', true)}>DELETE</Button>
          </Grid>
        </Grid>
      </div>)
    }
  </div>
}
