import { getApiClient } from './ApiClient'

export const requestSocialGroupListPromise = (params) => {
  return getApiClient()
    .get('/social-groups', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const requestSocialGroupDetailPromise = (id) => {
  return getApiClient()
    .get(`/social-groups/${id}`)
    .then(resp => Promise.resolve(resp.data))
}

export const upsertSocialGroupPromise = (body) => {
  return getApiClient()
    .post('/social-groups', { body })
    .then(resp => Promise.resolve(resp.data))
}
