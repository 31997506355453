import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { logDetailPromise } from 'api/Log'
import { Card } from '@material-ui/core'
import DetailRow from './row'
import ReactJson from 'react-json-view'
import { startCase } from 'lodash'

const UserDetail = props => {
  const {
    match: {
      params: { id },
    },
  } = props

  const [log, setLog] = useState({})

  const getLogDetail = useCallback(async () => {
    setLog(await logDetailPromise(id))
  }, [id])

  useEffect(() => {
    getLogDetail()
  }, [getLogDetail])

  const payload = useMemo(() => {
    return JSON.parse(log.requestData || '{}')
  }, [log.requestData])

  return (
    <div className={'root'}>
      <Card className={'detail-container'}>
        <DetailRow label={'Operator'}>{log.user && log.user.name}</DetailRow>
        <DetailRow label={'Action'}>{log.actionText}</DetailRow>
        <DetailRow label={'Module'}>{startCase(log.moduleText || '')}</DetailRow>
        <DetailRow label={'Request Data'}>
          <ReactJson src={payload} theme="Solarized" />
        </DetailRow>
        <DetailRow label={'Response Data'}>
          <ReactJson src={JSON.parse(log.responseData || '{}')} theme="Solarized" />
        </DetailRow>
      </Card>
    </div>
  )
}

export default UserDetail
