import { getApiClient } from './ApiClient'

export const workoutResourceVersionListPromise = (params) => {
  return getApiClient()
    .get('/workout-resource-versions', { params })
    .then(resp => resp.data)
}

export const workoutResourceVersionDetailPromise = (id) => {
  return getApiClient()
    .get(`/workout-resource-versions/${id}`)
    .then(resp => resp.data)
}

export const workoutResourceVersionRollbackPromise = (id, type) => {
  return getApiClient()
    .post(`/workout-resource-versions/${id}`, { body: { type } })
    .then(resp => resp.data)
}
