import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { optionEnum } from '../concerns/workoutOptions'
import { isBlank } from 'common/usual'
import { fetchWorkoutOptions } from '../concerns/workoutOptions'
import { assign } from 'lodash'
import { dailyfixPromise, workoutDailyfixUpsertPromise } from 'api/WorkoutDailyfix'
import { ParamsForOption } from 'common/enum'

export default function WorkoutCategoryUpsertForm (props) {
  const { data } = props
  const { workout } = optionEnum
  const [workoutDailyfix, setWorkoutDailyfix] = useState({})
  const [options, setOptions] = useState({})
  const [dailyfixes, setDailyfixes] = useState([])
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus ||
    isBlank(workoutDailyfix.workoutId) ||
    isBlank(workoutDailyfix.dailyfixId)
  ), [btnStatus, workoutDailyfix])

  useEffect(() => {
    setWorkoutDailyfix(data)
  }, [data])

  useEffect(() => {
    async function fetchDailyfixes () {
      setDailyfixes(
        (await dailyfixPromise(ParamsForOption)).map(df => ({ label: df.defaultTime, value: df.id }))
      )
    }
    fetchDailyfixes()
  }, [])

  useEffect(() => {
    async function fetchOptions () {
      setOptions(
        await fetchWorkoutOptions(workout)
      )
    }
    fetchOptions()
  }, [workout])

  function handleSubmit () {
    setBtnStatus(true)
    workoutDailyfixUpsertPromise(workoutDailyfix)
      .then(resp => props.history.goBack())
      .finally(() => setBtnStatus(false))
  }

  const handleChange = useCallback((...values) => {
    setWorkoutDailyfix(
      assign({}, workoutDailyfix, ...values)
    )
  }, [workoutDailyfix])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Time'} name={'dailyfixId'}>
          <AutoCompleteNew
            options={dailyfixes}
            value={workoutDailyfix.dailyfixId}
            onChange={(event, selected) => {
              handleChange({dailyfixId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem label={'Workout'} name={'workoutId'}>
          <AutoCompleteNew
            options={options[workout] || []}
            value={workoutDailyfix.workoutId}
            onChange={(event, selected) => {
              handleChange({workoutId: (selected || {value: null}).value})
            }}
          />
        </FormItem>

        <FormItem
          label={'Sequence'}
          name={'sequence'}
          type={'number'}
          value={workoutDailyfix.sequence}
          onChange={handleChange}
        />
        
        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}