import { useEffect, useRef } from 'react'

const useDebounce = (fn, ms = 200, deps = []) => {
  let timeout = useRef()
  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      fn()
    }, ms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  const cancel = () => {
    clearTimeout(timeout.current)
    timeout = null
  }

  return [cancel]
}

export default useDebounce
