import React, { useCallback, useState, useEffect } from 'react'
import { assign } from 'lodash'
import Step1 from './Step1'
import Step2 from './Step2'
import { fetchWorkoutOptions, optionEnum } from '../../concerns/workoutOptions'
import { workoutCategoryUpsertPromise } from 'api/WorkoutCategory'

export default function WorkoutCategoryForm (props) {
  const { data } = props
  const [category, setCategory] = useState({})
  const [currentStep] = useState('step1')
  const [options, setOptions] = useState({})
  const { categoryShowingType, resource } = optionEnum

  const handleChange = useCallback((...objs) => {
    setCategory(
      assign({}, category, ...objs)
    )
  }, [category])

  useEffect(() => {
    async function fetchOptions () {
      const _options = await fetchWorkoutOptions(categoryShowingType, resource)
      setOptions(_options)
    }
    fetchOptions()
  }, [categoryShowingType, resource])

  useEffect(() => {
    setCategory(data)
  }, [data])

  function handleStepChange (step) {
    // setCurrentStep(step)
    console.log('not ready')
  }

  function handleSubmit () {
    return workoutCategoryUpsertPromise({ ...category, showing_type: undefined })
  }

  const commonProps = {
      ...props,
      options,
      handleChange,
      handleStepChange,
      handleSubmit,
      data: category,
      setData: setCategory,
  }

  return (
    <>
      <Step1
        {...props}
        {...commonProps}
        hidden={currentStep === 'step2'}
      />
      <Step2
        {...commonProps}
        hidden={currentStep !== 'step2'}
      />
    </>
  )
}