import { getApiClient } from './ApiClient'

export const getProgramToolList = (params) => {
  return getApiClient().get('/program-tools', { params }).then(resp => resp.data)
}

export const getProgramToolDetail = (id) => {
  return getApiClient().get(`/program-tools/${id}`).then(resp => resp.data)
}

export const upsertProgramTool = (body) => {
  return getApiClient().post('/program-tools', { body }).then(resp => resp.data)
}
