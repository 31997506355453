import { Button, Card, Grid, Switch } from '@material-ui/core'
import { featureBannerContentUpsertPromise, featureBannerListPromise, featureBannerPlacementDetailPromise } from 'api/FeatureBanner'
import { S3PreSignType } from 'common/enum'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import UploaderForI18nImages from 'components/UploaderForI18nImages'
import { send } from 'helpers/utils'
import { assign } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const FeatureBannerContentForm = props => {
  const { originalContent } = props
  const [content, setContent] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [campaignOptions, setCampaignOptions] = useState([])
  const [placement, setPlacement] = useState({})

  useEffect(() => {
    setContent(originalContent)
  }, [originalContent])

  useEffect(() => {
    featureBannerListPromise({ size: 1000 }).then(resp => {
      const options = resp.rows.map(foo => ({ label: foo.name, value: foo.id, placementId: foo.placementId }))
      setCampaignOptions(options)
    })
  }, [])

  const handleChange = useCallback((...objs) => {
    setContent(
      assign({}, content, ...objs)
    )
  }, [content])

  const onSaveClick = () => {
    setBtnStatus(true)
    // content.campaignId = campaignId
    if (!content.campaignId) {
      window.alert('no campaignId')
      return
    }
    featureBannerContentUpsertPromise(content)
      .then(() => props.history.push('/feature-banner/contents'))
      .finally(() => setBtnStatus(false))
  }

  const commonProps = useMemo(() => ({
    data: content,
    onChange: handleChange
  }), [content, handleChange])

  const btnDisabled = useMemo(() => (
    btnStatus || !content.name || !content.background
  ), [btnStatus, content])

  useEffect(() => {
    if (!content.campaignId || campaignOptions.length <= 0) return
    const placementId = campaignOptions.find(foo => foo.value === content.campaignId).placementId
    if (!placementId) return

    featureBannerPlacementDetailPromise(placementId).then(resp => setPlacement(resp))
  }, [campaignOptions, content.campaignId])

  return (
    <div className={'root'}>
      <Card className={'form-container promo-form'}>
        <FormItem label='Name' name={'name'} {...commonProps} />
        <FormItem label={'Sequence'}>
          <NumberAutoComplete name='sequence' from={1} to={100} value={content.sequence} handleSelectChange={(sequence) => { handleChange({ sequence }) }} {...props} />
        </FormItem>

        <FormItem label='Campaign' name={'campaign'} {...commonProps}>
          <AutoCompleteNew
            disableUnderline={true}
            value={content.campaignId}
            options={campaignOptions}
            onChange={(event, selected) => {
              handleChange({ campaignId: selected && selected.value })
            }}
          />
        </FormItem>

        <FormItem label='Title' name={'title'} {...commonProps} />
        <FormItem label='Subtitle' name={'subtitle'} {...commonProps} />
        <FormItem label='CTA Text' name={'ctaText'} {...commonProps} />
        <FormItem label='CTA DeepLink' name={'ctaDeeplink'} {...commonProps} />
        <FormItem label='Text Color' name={'textColor'} {...commonProps} placeholder='FFFFFF' />

        <UploaderForI18nImages
          label={`Background (${placement && placement.image_size || 'set campaign and placement first'})`} type={S3PreSignType.FeatureBannerContentBackground}
          keyName={'background'} data={content} setData={setContent} sizeConstraint={send(placement, 'image_size')}
          isUseable={!!placement.id} />
{/*         
        <S3UploaderWithInput
          filenameMask={/[^/]+$/} prefix={`${S3Path}/feature_banner`}
          label={`Background (${send(placement, 'image_size')})`} name={'background'} filenames={content.background} fileType={S3PreSignType.ContentBackground} size={send(placement, 'image_size')}
          setFilenames={filenames => handleChange({ background: filenames })} /> */}

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(content.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name='active'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <br />

        <Grid container justify='center' >
          <Grid item xs={8}>
            <Button
              disabled={btnDisabled}
              color='primary'
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              onClick={onSaveClick}>
              Save
            </Button>
          </Grid>
        </Grid>
        
      </Card>
    </div>
  )
}

export default FeatureBannerContentForm
