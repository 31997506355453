import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { ParamsForOption, S3PreSignType, StickerImageTypeOptions } from 'common/enum'
import { workoutTrainerListPromise } from 'api/WorkoutTrainer'
import { AutoCompleteNew } from 'components/Select'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { findTargetFromOptions } from 'helpers/utils'
import JSONEditor from 'components/JSONEditor'
import { upsertStickerImagePromise } from 'api/StickerImage'
import moment from 'moment-timezone'
import UploaderForI18nImage from 'components/UploaderForI18nImages'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, setData } = props
  const [trainers, setTrainers] = useState([])
  const [jsonValueGetter, setJsonValueGetter] = useState()

  function formatDateTime (dt) {
    if (!dt) return dt

    return moment.tz(dt, 'Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
  }

  const handleChange = useCallback((pair) => {
    setData({ ...data, ...pair })
  }, [data, setData])

  const handleSubmit = useCallback(() => {
    const payload = { ...data, text_template: jsonValueGetter(), start_date: formatDateTime(data.start_date), end_date: formatDateTime(data.end_date) }
    upsertStickerImagePromise(payload).then(() => props.history.goBack())
  }, [data, jsonValueGetter, props.history])

  const formItem = useCallback((name, label, props) => {
    return <FormItem label={label} name={name} value={data[name]} onChange={handleChange} {...props} />
  }, [data, handleChange])

  useEffect(() => {
    workoutTrainerListPromise({ type: 'option', ...ParamsForOption })
      .then(resp => setTrainers(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { formItem('name') }
        
        <FormItem label={'Type'}>
          <AutoCompleteNew
            value={data.type}
            options={StickerImageTypeOptions}
            onChange={(event, selected) => {
              handleChange({ type: selected && selected.value })
            }}
          />
        </FormItem>

        {/* trainer */}
        {
          data.type === findTargetFromOptions(StickerImageTypeOptions, { label: 'trainer' }) &&
            <FormItem label='Trainer' name='trainer_id'>
              <AutoCompleteNew
                disableUnderline={true}
                value={data.trainer_id}
                options={trainers}
                onChange={(event, selected) => {
                  handleChange({ trainer_id: selected && selected.value })
                }}
              />
            </FormItem>
        }

        {/* seasonal */}
        {
          data.type === findTargetFromOptions(StickerImageTypeOptions, { label: 'seasonal' }) &&
            <>
              { formItem('start_date', null, { placeholder: 'YYYY-MM-DD HH:mm:ss' } ) }
              { formItem('end_date', null, { placeholder: 'YYYY-MM-DD HH:mm:ss' }) }
            </>
        }

        { formItem('width') }
        { formItem('height') }

        <FormItem label={'Order'}>
          <NumberAutoComplete name='order' from={1} to={100} value={data.order} handleSelectChange={order => handleChange({ order })} {...props} />
        </FormItem>

        <UploaderForI18nImage label={'Image'} type={S3PreSignType.StickerImageCover} keyName={'image_url'} data={data} setData={setData} />
        <UploaderForI18nImage label={'Icon (180x180)'} type={S3PreSignType.StickerImageCover} keyName={'icon'} subpath='icon' data={data} setData={setData} />

        {/* <S3UploaderWithInput label='Image' name={'image_url'} parentPath={ParentPath.enUS} filenames={data['image_url']} fileType={S3PreSignType.StickerImageCover}
          setFilenames={filenames => handleChange({ image_url: filenames })} />

        <S3UploaderWithInput label='Icon (180x180)' name={'icon'} parentPath={ParentPath.enUS} filenames={data['icon']} fileType={S3PreSignType.StickerImageCover} subpath='icon'
          setFilenames={filenames => handleChange({ icon: filenames })} /> */}

        <FormItem name={'use_camera'}>
          <Switch name='use_camera' checked={Boolean(data.use_camera)}
            onChange={ e => handleChange({ use_camera: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'active'}>
          <Switch name='active' checked={Boolean(data.active)}
            onChange={ e => handleChange({ active: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'Text Template'}>
          <JSONEditor initValue={data.text_template || []} setJSONGetter={setJsonValueGetter} height='300px' />
        </FormItem>

        <Button
          className='save-btn'
          color='primary'
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}