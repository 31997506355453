import { Grid } from '@material-ui/core'
import FormTextField from 'components/FormTextField'
import React from 'react'

const colors = ['FFFFFF', '333333', '359B33', 'EF5DA8', '000000']
export default function ColorInput (props) {
  const { name, value, label, handleChange } = props

  return <div>
    <Grid container xs={12} spacing={2}>
      <Grid item xs={8}>
        <div className='form-input-elem'>
          <FormTextField label={label} name={name} value={value} onChange={handleChange} />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className='color-picker'>
          { colors.map((color, idx) =>
            <div key={`color-picker-${color}-${idx}`} className='cursor-pointer color-circle' style={{ backgroundColor: `#${color}` }} onClick={() => handleChange(name, color)} />)
          }
        </div>
      </Grid>
    </Grid>
  </div>
}
