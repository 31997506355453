import _ from 'lodash'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import { workoutDailyfixListPromise } from 'api/WorkoutDailyfix'
import { workoutDailyfixDeletePromise } from 'api/WorkoutDailyfix'
import WorkoutDailyfixTalbe from './components/WorkoutDailyfixTable'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import WorkoutDailyfixToolbar from './components/WorkoutDailyfixToolbar'

export default function (props) {
  const ref = useRef()
  const location = useLocation()
  const [data, setData] = useState([])
  const [params, setParams] = useState({page: 1})

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    workoutDailyfixListPromise(too).then(resp => setData(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  const handleDelete = useCallback(id => {
    workoutDailyfixDeletePromise(id)
      .then(res => window.location.reload())
  }, [])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <WorkoutDailyfixToolbar
          params={params}
          onChange={handleParamsChange}
          {...props}
        />
        <br />
        <WorkoutDailyfixTalbe
          data={data}
          page={params.page}
          deleteRecord={handleDelete}
          handlePageChange={handlePageChange}
          {...props}
        />
      </div>
    </div>
  )
}
