import _ from 'lodash'
import { checkEnvironment } from 'helpers/utils'

export const OSType = Object.freeze({
  iOS: 0,
  Android: 1,
  web: 2,
})

export const OSTypeOptions = Object.entries(OSType).map(([label, value]) => ({ label, value }))

export const RequestBlacklistType = Object.freeze([
  { label: 'ip address', value: 'ip_address' },
  { label: 'phone', value: 'phone' },
  { label: 'user id', value: 'user_id' },
  { label: 'corp wellness email domain', value: 'corp_wellness_email_domain' }
])

export const OSTypeInverted = Object.freeze(_.invert(OSType))

export const UserReportStatus = Object.freeze({ 0: 'Pending', 1: 'Blocked', 2: 'Ignored' })

export const CourseTypes = Object.freeze([
  { label: 'Workout', value: 4, num: 4 },
  { label: 'Nutrition', value: 5, num: 5 },
  { label: 'Wellness', value: 6, num: 6 },
])
export const NumToCourseType = Object.freeze(CourseTypes.reduce((res, item) => {
  return res[item.num] = item, res
}, {}))

export const FeedPostCreatedByType = Object.freeze({
  0: 'User',
  1: 'System',
  2: 'Webhook',
})

export const S3PreSignType = Object.freeze({
  Theme: 'theme',
  Course: 'course',
  Program: 'program_cover',
  RawCourse: 'course_raw_media',
  SocialGroupCover: 'social_group_cover',
  WeekCoursePreviewCover: 'week_course_preview_cover',
  WorkoutMusicRawVideo: 'workout_music_raw_video',
  WorkoutNomusicRawVideo: 'workout_nomusic_raw_video',
  CourseWeekGuideImage: 'course_week_guide',
  WebAssetsCourse: 'web_assets_course',
  WorkoutSubtitle: 'workout_subtitle',
  VideoCover: 'video_cover',
  Trainer: 'trainer_cover',
  ContentBackground: 'feature_banner_content',
  TrainerAvatar: 'trainer_avatar',
  GroupLogo: 'group_logo',
  StickerImageCover: 'sticker_image_cover',
  MealCategoryCover: 'meal_cover',
  WorkoutCover: 'workout_cover',
  FeatureBannerContentBackground: 'feature_banner_content_background',
  InviteTemplateImage: 'invite_template_image',
  MoveBaseWorkout: 'move_base_workout',
})

// (Workout, Trainer Tip, Course Preview, Nutrition Course, Nutrition Pro Video)
export const UploadVideoTypes = Object.freeze([
  { label: 'Workout', value: 1 },
])

export const SocialGroupType = Object.freeze({
  Course: 1,
  ChallengeBase: 2,
  InterestBased: 3,
  UniversityGroup: 4,
  PublicGroup: 5,
  CorporateGroup: 6,
})

export const CorporateWellnessSKUs = Object.freeze(
  [
    'com.fitonapp.corp.smb.yearly.free',
    'com.fitonapp.corp.smb.healthplus.yearly.free',
    'com.fitonapp.fiton.pro.lifetime.free',
    'com.fitonapp.fiton.healthplus.lifetime',
  ]
)

export const PromoBenefitType = Object.freeze([
  { label: 'Student', value: 0 },
  { label: 'CorporateWellness', value: 1 },
  { label: 'Partner', value: 2 },
])

export const PromoBenefitTypeValueToLabel = PromoBenefitType.reduce((res, type) => {
  res[type.value] = type.label
  return res
}, {})

export const PromoBenefitTypeTypeToValue = PromoBenefitType.reduce((res, type) => ({ ...res, [type.label]: type.value }), {})

export const SubtitleLanguageOptions = Object.freeze([
  { label: 'EN', value: 'en' },
  { label: 'DE', value: 'de' },
  { label: 'FR', value: 'fr' },
  { label: 'PT', value: 'pt' },
])

export const ShorthandToCountryAndLanguage = Object.freeze([
  { label: 'EN', value: 'en', countryId: 263, languageId: 40,  shorthand: 'EN' },
  { label: 'DE', value: 'de', countryId: 83,  languageId: 51,  shorthand: 'GER' },
  // { label: 'FR', value: 'fr', countryId: 76,  languageId: 47,  shorthand: '' },
  { label: 'ES', value: 'es', countryId: 209, languageId: 148, shorthand: 'LAS' },
])

export const ImageLanguageOptions = Object.freeze([
  { label: 'en_US', value: 'en_us', language: 'en' },
  { label: 'fr_FR', value: 'fr_fr', language: 'fr' },
  { label: 'de_DE', value: 'de_de', language: 'de' },
  { label: 'es_ES', value: 'es_es', language: 'es' },
  { label: 'pt_BR', value: 'pt_br', language: 'pt' },
])

export const ImageLanguageMap = Object.freeze(
  _.keyBy(ImageLanguageOptions, 'language')
)

export const localeMapForMoveWorkout = Object.freeze([
  { label: 'en_US', value: 'en_US' },
  { label: 'de_MN', value: 'de_MN' },
  { label: 'es_ES', value: 'es_ES' },
])

export const Environment = Object.freeze({
  Staging: 'staging',
  Production: 'prod',
  Development: 'dev',
})

export const S3Path =
  checkEnvironment(Environment.Production)
    ? 'https://s3.us-east-2.amazonaws.com/fiton-production'
    : 'https://s3.us-east-2.amazonaws.com/fiton-staging'

export const S3Bucket = checkEnvironment(Environment.Production) ? 'fiton-production' : 'fiton-staging'

export const TrainerTypeOptions = Object.freeze([
  { label: 'Workout', value: 1 },
  { label: 'Nutrition', value: 2 },
])

// export const VideoResourceCategoryOptions = Object.freeze([
//   { label: 'Flexitarian', value: 'Flexitarian' },
//   { label: 'IntermittentFasting', value: 'IntermittentFasting' },
//   { label: 'Keto', value: 'Keto' },
//   { label: 'MINDFULEATING', value: 'MINDFULEATING' },
//   { label: 'Macros', value: 'Macros' },
//   { label: 'MealPrep', value: 'MealPrep' },
//   { label: 'Mediterranean', value: 'Mediterranean' },
//   { label: 'Nutrition', value: 'Nutrition' },
//   { label: 'Paleo', value: 'Paleo' },
//   { label: 'Vegetarian', value: 'Vegetarian' },
//   { label: 'Whole30', value: 'Whole30' },
// ])

export const VideoResourceTypeOptions = Object.freeze([
  { label: 'nutrition course', value: 'nutrition course' },
  { label: 'nutrition pro video', value: 'nutrition pro video' },
  { label: 'nutrition program', value: 'nutrition program' },
])

export const UploadObjectTypeOptions = Object.freeze([
  { label: 'Locale', value: 'locale' },
  { label: 'Nutrition PRO Video', value: 'pro habits/', bucket: 'static.fitonapp.com' },
  { label: 'Nutrition Course Video', value: 'nutrition/', bucket: 'static.fitonapp.com' },
  { label: 'Course Weekly Preview Video', value: 'course intros/', bucket: 'static.fitonapp.com' },
  { label: 'Nutrition Program Video', value: 'nutrition course re-edits/', bucket: 'static.fitonapp.com' },
])
export const ValueToUploadObjectTypeOptions = Object.freeze(
  UploadObjectTypeOptions.reduce((res, item) => {
    res[item.value] = item
    return res
  }, {})
)

export const ThemeTypeOptions = Object.freeze([
  { label: 'Recipe', value: 'Recipe' },
  { label: 'Course', value: 'Course' },
  { label: 'Workout', value: 'Workout' },
  { label: 'Fitness Program', value: 'Program' },
  { label: 'Nutrition Program', value: 'Nutrition Program' },
])

export const ThemeDefaultCoverMap = Object.freeze({
  'Course': 'course.png',
  'Workout': 'workout.png',
  'Program': 'program.png',
  'Nutrition Program': 'nutrition_program.png',
})

export const BooleanOptions = Object.freeze([
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
])

export const BooleanOptions2 = Object.freeze([
  { label: 'True', value: true },
  { label: 'False', value: false },
])

export const BooleanOptionsWithNull = Object.freeze([
  { label: 'Null', value: 'null' },
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
])

export const UserRoleOptions = Object.freeze([
  { label: 'CommonUser', value:  1 },
  { label: 'Trainer', value:  2 },
  { label: 'VirtualCoach', value:  3 },
  { label: 'FitOn', value:  5 },
  { label: 'TrainerCommonUser', value:  6 },
])

export const UserGenderOptions = Object.freeze([
  { label: 'default', value: 0 },
  { label: 'man', value: 1 },
  { label: 'women', value: 2 },
  { label: 'non_gender', value: 3 },
])

export const StickerImageTypeOptions = Object.freeze([
  { label: 'trainer', value: 1 },
  { label: 'location', value: 2 },
  { label: 'workout_status', value: 3 },
  { label: 'for_fun', value: 4 },
  { label: 'text', value: 5 },
  { label: 'background', value: 6 },
  { label: 'seasonal', value: 7 },
])

export const VideoResourceDownloadUrlPrefix = Object.freeze({
  'nutrition course':    'https://s3.us-east-2.amazonaws.com/static.fitonapp.com/nutrition',
  'nutrition pro video': 'https://s3.us-east-2.amazonaws.com/static.fitonapp.com/pro+habits',
  'nutrition program':   'https://s3.us-east-2.amazonaws.com/static.fitonapp.com/nutrition+course+re-edits',
})

export const ParentPath = Object.freeze({
  enUS: 'i18n/en_us'
})

const _SocialFeedPostAccounts = Object.freeze({
  Prod: [{ label: 'All', value: 100 }, { label: 'Pro', value: 101 }, { label: 'NonPro', value: 102 }],
  Staging: [{ label: 'All', value: 40 }, { label: 'Pro', value: 41 }, { label: 'NonPro', value: 42 }],
})
export const SocialFeedPostAccountOptions = checkEnvironment(Environment.Production) ?  _SocialFeedPostAccounts.Prod : _SocialFeedPostAccounts.Staging

export const SocialFeedPostType = Object.freeze({
  System: 1,
  Text: 2,
  Image: 3,
  SignUp: 4,
  ChallengeJoin: 6,
  Workout: 7,
  Recipe: 8,
  Advice: 9,
  Achievement: 10,
  Quote: 11,
  UpgradeToPro: 12,
  ProfileUpdated: 13,
  Friendship: 14,
  ChallengeComplete: 22,
  MealPlanJoin: 23,
  AddedActivity: 24,
  NewProgramStart: 25,
  ShareWorkout: 26,
  ShareChallenge: 27,
  ShareTrainer: 28,
  ShareRecipe: 29,
  ShareAdvice: 30,
  ShareProfileCard: 31,
  ShareBeforeAfter: 32,
  ShareQuote: 33,
  SharePhotoWall: 34,
  Customized: 35,
  ShareCourse: 36,
  ExternalLink: 37,
  SharePost: 38,
  Video: 39,
  ShareVideo: 40,
  ShareCourseTask: 41,
  ShareTheme: 42,
  ExternalLinkV2: 43,
  CompleteChallengeDailyTaskV2: 44,
  ShareChallengeV2: 45,
  ShareUserChallengeDailyTaskV2: 46,
  ShareUserChallengeV2: 47,
  ChallengeV2Join: 48,
  ChallengeV2Complete: 49,
  GroupChallengeCreate: 50,
  ShareProgram: 51,

  SystemPost: 100, // just for webhook
})

export const SocialFeedPostTypeOptions = Object.freeze(
  Object.entries(SocialFeedPostType).map(([label, v]) => ({ label, value: `${v}`  }))
)

export const TimeUnit = Object.freeze([
  { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
  { label: 'Week', value: 'week' },
])

export const InviteTemplateUseImageType = Object.freeze([
  { value: 0, label: 'not use image' },
  { value: 1, label: 'use client image' },
  { value: 2, label: 'use server image url image' },
])

export const UserReportType = Object.freeze({
  PhotoWall: 1,
  Challenge: 3,
  Message: 4,
  Room: 5,
  User: 6,
  SocialFeed: 7,
  SocialComment: 8
})

export const UserReportTypeText = Object.freeze(_.invert(UserReportType))

export const WorkoutResourceType = Object.freeze([
  { value: 1, label: 'video' },
  { value: 2, label: 'move based' }
])

export const ProgramVideoTypeOptions = Object.freeze([
  { value: 1, label: 'Video' },
  { value: 2, label: 'Move-Based' },
  { value: 3, label: 'Nutrition' },
])

export const ProgramGuidePdfTypeOptions = Object.freeze([
  { value: 1, label: 'Top Tips'},
  { value: 2, label: 'Grocery List' },
  { value: 3, label: 'Education' },
  { value: 4, label: 'Diet' },
])

export const ProgramVideoValueToType = Object.freeze(
  _.fromPairs(ProgramVideoTypeOptions.map(type => ([type.value, type.label])))
)

export const ProgramVideoTypes = Object.freeze(
  _.fromPairs(ProgramVideoTypeOptions.map(type => ([type.label, type.value])))
)

export const WorkoutResourceValueToType = WorkoutResourceType.reduce((res, { label, value }) => {
  res[value] = label
  return res
}, {})

export const S3FilePath = Object.freeze({
  AchievementBasePath: () => 'achievement',
  ChallengeBasePath: size => 'challenge_cover',
  WorkoutResourceCoverBasePath: () => 'workout_cover',
  FeatureBannerContentBackground: language => `i18n/${language}/feature_banner/background`,
  ProgramCoverImage: language => `i18n/${language}/program/cover`,
  CourseLogoNew: language => `i18n/${language}/course/logo_new`,
  CourseHeroMobile: language => `i18n/${language}/course/hero_mobile`,
  CourseHeroDesktop: language => `i18n/${language}/course/hero_desktop`,
  WorkoutCoverBasePath: () => 'workout_cover',
  SocialBadgeCover: () => 'social_badge/icon',
  ProgramGuidePdfUrl: () => 'program/guide',
  ProgramBackgroundUrl: () => 'program/background',
  ProgramGuideCoverUrl: () => 'program/guide_cover',
  MealCover: () => 'meal_cover',
  FohStudioPhoto: () => 'foh_studio/photo',
  WorkoutEquipment: () => 'equipment/cover',
  WorkoutEquipmentNew: () => 'equipment/cover/new',
})

export const LocaleMatchingOptions = Object.freeze([
  { label: 'English', value: 'en' },
  { label: 'French',  value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Spanish; Castilian', value: 'es' },
  { label: 'Portuguese', value: 'pt' },
])

export const ChallengeTypeOptions = Object.freeze([
  { label: 'default', value: 0 },
  { label: 'time_limited', value: 1 },
  { label: 'custom', value: 5 },
])

export const SocialBadgeDescriptions = Object.freeze({
  PRO: 'pro',
  Health: 'health',
  Trainer: 'trainer',
  Ambassador: 'ambassador',
})

export const FeedbackType = Object.freeze({
  NORMAL: 0,
  VIDEO_ISSUE: 1,
  CHALLENGE_REPORT: 2
})
export const FeedbackTypeText = Object.freeze(_.invert(FeedbackType))

export const ChallengeFromCustomToOfficialColumnsNeedBeNull = Object.freeze([
  'created_user_id' ,'challenge_avatar' ,'cover_horizontal' ,'cover_vertical' ,'cover_thumbnail'
].reduce((res, column) => ({ ...res, [column]: null }), {}))

export const ProgramContentType = Object.freeze({
  Video: 0,
  Advice: 1,
  Tip: 2,
  Recipe: 3,
  TaskVideo: 4,
  WeeklyTask: 5,
  ProgramWorkout: 6,
  CourseVideo: 7,
  CourseWorkout: 8,
})

export const CourseVideoOptions = Object.freeze([
  { label: 'Video', value: ProgramContentType.CourseVideo },
  { label: 'Workout', value: ProgramContentType.CourseWorkout },
])

export const NutritionProgramTypeOptions = Object.freeze([
  { label: 'Advice', value: 1 },
  { label: 'Tip', value: 2 },
  { label: 'Recipe', value: 3 },
  { label: 'Video - Resource', value: 0 },
  { label: 'Video - Program', value: 4 },
])
export const NutritionProgramValueToType = Object.freeze(
  NutritionProgramTypeOptions.reduce((res, pair) => ({ ...res, [pair.value]: pair.label }), {})
)

export const NutritionProgramTypes = Object.freeze(
  _.fromPairs(NutritionProgramTypeOptions.map(type => ([type.label, type.value])))
)

export const PageSize = 5000

export const ParamsForOption = Object.freeze({ _purpose: 'options' })

export const UserSearchKindOptions = Object.freeze([
  { label: 'Email', value: 'email' },
  { label: 'FitOn User ID', value: 'userId' },
  { label: 'Peerfit User ID', value: 'pfUserId' },
])

export const WeekProgramType = Object.freeze({
  Original: 1, //TODO: update the name
  Course: 2,
})
