import _ from 'lodash'
import React from 'react'
import { Card, Grid } from '@material-ui/core'
import UploaderForSingleImage from 'components/UploaderForSingleImage'

export default function (props) {
  const { data, handleChange } = props
  
  const assets = [
    { key: 'cover_vertical', pathInName: '1005x1260' },
    { key: 'cover_horizontal', pathInName: '960x756' },
    { key: 'cover_thumbnail', pathInName: '280x380' },

    { key: 'male_cover_vertical', pathInName: '1005x1260' },
    { key: 'male_cover_horizontal', pathInName: '960x756' },
    { key: 'male_cover_thumbnail', pathInName: '280x380' },
  ]

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>ASSETS</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      {
        assets.map(({ key, pathInName }) => <Grid item xs={12} key={key}>
          <div style={{paddingTop: '10px'}}>
            <div className=''>
              <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                <span>
                  {_.startCase(key)} (PNG, JPG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image size: <b>{pathInName} px</b>
                </span>
              </div>
            </div>

            <div style={{marginTop: '15px'}}>
              <UploaderForSingleImage
                keyName={key}
                isForceRename={true}
                filename={data[key]}
                pathInFilename={pathInName}
                fileType='ChallengeBasePath'
                handleChange={handleChange}
              />
            </div>
          </div>
        </Grid>)
      }
    </Grid>
  </Card>
}
