import { getCourseListPromise } from 'api/course'
import { getTimezonesPromise } from 'api/helper'
import React, { useEffect, useState } from 'react'
import Form from './form'
import { ParamsForOption } from 'common/enum'

export default function AddUserToCourse (props) {
  const [courses, setCourses] = useState([])
  const [timezones, setTimezones] = useState([])

  useEffect(() => {
    getCourseListPromise({ size: 10000, ...ParamsForOption }).then(resp => setCourses(resp.rows))

    getTimezonesPromise(ParamsForOption).then(resp => setTimezones(resp))
  }, [])

  return <Form courses={courses} timezones={timezones} data={{}} {...props} />
}