import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { getThemeListPromise } from 'api/Theme'
import AddBtn from 'components/AddBtn'
import { ParamsForOption } from 'common/enum'

const PromoToolbar = props => {
  const [themeOptions, setThemeOptions] = useState([])
  const { params, handleSearchChange } = props
  const { type } = props.match.params

  useLayoutEffect(() => {
    getThemeListPromise({ size: 10000, ...ParamsForOption })
      .then(resp => {
        const foo = resp && resp.rows && resp.rows.map(r => ({ label: `${r.title} (${r.id})`, value: r.id }))
        setThemeOptions(foo || [])
      })
  }, [])


  return (
    <Grid container item xs={12} justify={'flex-end'} spacing={2}>
      <Grid item xs={2}>
        <AutoCompleteNew
            disableUnderline={false}
            value={params.themeId}
            options={themeOptions}
            onChange={(event, selected) => {
              handleSearchChange({ themeId: (selected || {}).value })
            }}
          />
      </Grid>

      <Grid item xs={9} />

      {/* type: item target category */}
      <Grid container item xs={1} justify={'flex-end'}>
        <AddBtn
          className={'add-btn'}
          color='primary'
          type='submit'
          onClick={e => {
            e.stopPropagation()
            props.history.push(`/theme-details/${type}/add`)
          }}
          variant='contained'
        > Add </AddBtn>
      </Grid>
    </Grid>
  )
}

PromoToolbar.propTypes = {
  className: PropTypes.string,
}

export default PromoToolbar
