import React, { useMemo } from 'react'
import Close from '@material-ui/icons/Close'
import Add from '@material-ui/icons/Add'
import { Grid } from '@material-ui/core'

export default function DynamicInput (props) {
  const { label, keyName, handleChange, data, genElement } = props
  const items = useMemo(() => (data || []), [data])

  function addRow () {
    items.push({})
    handleChange({ [keyName]: [...items] })
  }

  function deleteRow (index) {
    items[index]._deleted = true
    handleChange({ [keyName]: [...items] })
  }

  function typeIn (index, name, value) {
    items[index][name] = value
    items[index]._updated = true
    handleChange({ [keyName]: [...items] })
  }

  return (
    <div className="info-item" style={{ display: 'block' }}>
      <Grid container spacing={4}>
        <Grid container item xs={11} justify={'flex-start'}>
          <label className="label">{label}</label>
        </Grid>
        <Grid container item xs={1} justify={'flex-end'} >
          <Add className={'cursor-pointer'} onClick={addRow} />
        </Grid>
      </Grid>
      {
        items.map((item, i) => {
          return !item._deleted &&
            <Grid container spacing={0} key={i} >
              <Grid container item xs={11} spacing={4} justify={'flex-start'}>
                { genElement(i, item, typeIn) }
              </Grid>
              <Grid container item xs={1} justify={'flex-end'}>
                <Close onClick={() => { deleteRow(i) }} fontSize={'small'} className={'cursor-pointer'} />
              </Grid>
            </Grid>
        })
      }
    </div>
  )
}