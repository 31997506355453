import Step1 from './Step1'
import Step2 from './Step2'
import React, { useState, useEffect } from 'react'
import { workoutResourceCreateOrUpdatePromise } from 'api/WorkoutResource'
import _ from 'lodash'

function WorkoutResourceAdd (props) {
  const { type, data } = props
  const [workoutResource, setWorkoutResource] = useState({})
  const [currentStep, setCurrentStep] = useState('step1')

  useEffect(() => {
    setWorkoutResource({action: type, schedule: 0, ...data})
  }, [data, type])

  function handleChange (...objs) {
    setWorkoutResource(
      _.assign({}, workoutResource, ...objs)
    )
  }

  function handleStepChange (step) {
    setCurrentStep(step)
  }

  function handleSubmit () {
    return workoutResourceCreateOrUpdatePromise(workoutResource)
  }

  return (
    <div className='form-page'>
      <Step1
        action={type}
        handleChange={handleChange}
        rawWorkoutResource={data}
        workoutResource={workoutResource}
        setWorkoutResource={setWorkoutResource}
        handleStepChange={handleStepChange}
        hidden={currentStep === 'step2'}
        handleSubmit={handleSubmit}
      />
      <Step2
        { ...props }
        action={type}
        handleChange={handleChange}
        rawWorkoutResource={data}
        workoutResource={workoutResource}
        setWorkoutResource={setWorkoutResource}
        handleStepChange={handleStepChange}
        hidden={currentStep !== 'step2'}
        handleSubmit={handleSubmit}
      />
    </div>
  )

}

export default WorkoutResourceAdd