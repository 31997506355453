import { Button, Card, Grid } from '@material-ui/core'
// import { getAdviceForOptionPromise } from 'api/Advice'
import { getJshRecipesPromise } from 'api/Util'
import { getVideoResourceList } from 'api/VideoResource'
import { getProgramTipListPromise, upsertProgramTipPromise } from 'api/programTip'
import { NutritionProgramTypeOptions, NutritionProgramTypes, PageSize, ParamsForOption } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MyModal from 'components/MyModal'
import FormTextField from 'components/FormTextField'

export default function (props) {
  const { data, handleChange } = props
  const [options, setOptions] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const func = () => {
      switch (data.content_type) {
      case NutritionProgramTypes['Video - Resource']:
      case NutritionProgramTypes['Video - Program']:
        return getVideoResourceList
      // case NutritionProgramTypes.Advice:
      //   return getAdviceForOptionPromise
      case NutritionProgramTypes.Tip:
        return getProgramTipListPromise
      case NutritionProgramTypes.Recipe:
        return getJshRecipesPromise
      default:
        return async () => ({ rows: [] })
      }
    }

    func()({ size: PageSize, ...ParamsForOption }).then(resp => {
      setOptions(resp.rows.map(r => ({ label: `${r.name || r.title || r.description} (${r.id})`, value: r.id })))
    })
  }, [data.content_type])

  const isTip = useMemo(() => data.content_type === NutritionProgramTypes.Tip, [data.content_type])
  const isAdvice = useMemo(() => data.content_type === NutritionProgramTypes.Advice, [data.content_type])
  const isWeeklyTask = useMemo(() => data.content_type === NutritionProgramTypes.WeeklyTask, [data.content_type])

  const openModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const afterSave = useCallback(foo => {
    setOptions([...options, { label: `${foo.description} (${foo.id})`, value: foo.id }])
    handleChange('content_id', foo.id)
    setModalOpen(false)
  }, [handleChange, options])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>CONTENT</label>
    </Grid>
    
    <Grid container item xs={12} className='form-card-content'>
      <Grid item xs={6}>
        <div className='form-input-elem'>
          <label className='full-width'>Content Type</label>
          <AutoCompleteNew name='content_type' options={NutritionProgramTypeOptions} value={data.content_type} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => {
              handleChange('content_type', selected && selected.value, { content_id: null })
            }}
          />
        </div>
      </Grid>

      {
        isAdvice && <Grid item xs={6}>
          <div className='form-input-elem'>
            <label className='full-width'>Content (advice id)</label>
            <FormTextField name='content_id' value={data.content_id} onChange={handleChange} />
          </div>
        </Grid>
      }
      
      {isWeeklyTask && <Grid item xs={6}>
        <div className='form-input-elem'>
          <label className='full-width'>Content</label>
          <FormTextField name='content_id' value={data.content_id} onChange={handleChange} />
        </div>
      </Grid> }

      { !isWeeklyTask && !isAdvice && <Grid item xs={isTip ? 5 : 6}>
        <div className='form-input-elem'>
          <label className='full-width'>Content</label>
          <AutoCompleteNew name='content_id' options={options} value={data.content_id} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => handleChange('content_id', selected && selected.value)}
          />
        </div>
      </Grid> 
      }

      {
        isTip && <Grid item xs={1}>
          <Button id='save-btn' variant='contained' color='secondary' onClick={openModal} style={{ marginTop: '20px' }}>
            ADD
          </Button>
        </Grid>
      }
    </Grid>

    <TipModal open={modalOpen} setOpen={setModalOpen} afterSave={afterSave} />
  </Card>
}

function TipModal (props) {
  const { open, setOpen, afterSave } = props
  const [data, setData] = useState({ active: true })

  const handleChange = useCallback((name, value) => {
    setData({ ...data, [name]: value })
  }, [data])

  const handleSave = useCallback(() => {
    upsertProgramTipPromise(data)
      .then(resp => afterSave(resp.data))
  }, [afterSave, data])

  return <MyModal open={open} setOpen={setOpen}>
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <FormTextField name='description' label='description' value={data.description} onChange={handleChange} />
      </Grid>

      <Grid container item xs={12} justify={'flex-end'}>
        <Button id='tip-save-btn' variant='contained' color='primary' onClick={handleSave}> SAVE </Button>
      </Grid>
    </Grid>
  </MyModal>
}
