import React, { useState, useEffect, useCallback } from 'react'
import { mealPlanListPromise } from 'api/MealPlan'
import MealPlanTable from './components/MealPlanListTable'
import MealPlanToolbar from './components/MealPlanListToolbar'

const MealPlanList = props => {
  const [plans, setPlans] = useState([])

  const getUnits = useCallback(async () => {
    setPlans(
      await mealPlanListPromise()
    )
  }, [])

  useEffect(() => {
    getUnits()
  }, [getUnits])
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealPlanToolbar {...props} />
        <br />
        <MealPlanTable {...props} data={plans} />
      </div>
    </div>
  )
}

export default MealPlanList