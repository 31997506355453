import { getApiClient } from './ApiClient'

export const purchaseListPromise = (params) => {
  return getApiClient()
    .get('/purchase-list', { params })
    .then(resp => resp.data)
}

export async function purchaseDetailPromise (id) {
  return getApiClient()
    .get(`/purchases/${id}`)
    .then(resp => resp.data)
}

export async function updatePurchasePromise (id, body) {
  return getApiClient()
    .put(`/purchases/${id}`, { body })
}
