import React from 'react'
import AddBtn from 'components/AddBtn'
import { Button, Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { ProgramVideoTypeOptions } from 'common/enum'

export default function Toolbar (props) {
  const { params, handleSearchChange, handleExport } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField name='program-name' label='Program Name' fullWidth variant='outlined' size='small' className='background-color' value={params.name} onChange={event => handleSearchChange({name: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={3}>
          <AutoCompleteNew name='program-autocomplete' label='Type' disableUnderline={false} value={parseInt(params.programType)} options={ProgramVideoTypeOptions} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => handleSearchChange({programType: selected && selected.value})}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={1} container justify='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/programs/create`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
        <Grid item xs={1} container justify='flex-end'>
          <Button color='secondary' onClick={handleExport} variant='contained'>
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
