import { upsertProgramTool } from 'api/ProgramTool'
import { AutoCompleteNew } from 'components/Select'
import FormTextField from 'components/FormTextField'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'

export default function (props) {
  const columnsNotNull = ['name', 'type']
  const { data, history } = props
  const [programTool, setProgramTool] = useState({})

  useLayoutEffect(() => {
    if (data) setProgramTool(data)
  }, [data])

  const handleChange = useCallback((key, value) => {
    setProgramTool(prev => ({ ...prev, [key]: value }))
  }, [])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertProgramTool(programTool)
      .then(() => history.goBack())
  }, [programTool, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!programTool[cname]) return true
  }, [programTool, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Program Tool
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
              <Switch id='active' name={'active'} checked={Boolean(programTool.active)} onChange={e => handleChange('active', e.target.checked)} />
            </Grid>
            
            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Name</label>
                  <FormTextField name='name' value={programTool.name} onChange={handleChange} />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Type</label>
                  <AutoCompleteNew name='type' enumName='ProgramToolType' value={programTool.type} className='full-width' variant='outlined' inputLeft={true}
                    onChange={(event, selected) => handleChange('type', selected && selected.value)}
                  />
                </div>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
