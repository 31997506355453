import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'

const MealTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(meal => (
                  <TableRow className={'table-row'} key={meal.id} hover>
                    <TableCell>{meal.id}</TableCell>
                    <TableCell>{meal.title}</TableCell>
                    <TableCell>{meal.recipeCategory && meal.recipeCategory.title }</TableCell>
                    <TableCell>{meal.active ? 'TRUE' : 'FALSE'}</TableCell>
                    <TableCell>{formatDateTime(meal.createdAt)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/meal/edit/${meal.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default MealTable