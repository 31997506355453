import _ from 'lodash'
import 'assets/scss/index.scss'
import './index.scss'
import { Button, Card, Grid, Input } from '@material-ui/core'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { getTranslationRelationTypes, populateTranslationItemRelations } from 'api/Translation'
import { ParamsForOption } from 'common/enum'

const TranslationItemRelationView = function (props) {
  const [payload, setPayload] = useState({})
  const [tableToType, setTableToType] = useState({})
  const [tables, setTables] = useState([])

  useLayoutEffect(() => {
    getTranslationRelationTypes(ParamsForOption).then(data => {
      const typesGroupByTable = _.groupBy(data, 'table')
      setTableToType(typesGroupByTable)
      setTables(Object.keys(typesGroupByTable).map(table => ({ label: table, value: table })))
    })
  }, [])

  const disabled = useMemo(() => {
    return !payload.table || _.isEmpty(payload.columns)
  }, [payload.columns, payload.table])

  const columns = useMemo(() => {
    const foo = tableToType[payload.table]
    if (!foo) return []

    return foo.map(type => ({ label: type.column, value: type.column }))
  }, [payload.table, tableToType])

  const handleSubmit = useCallback(() => {
    populateTranslationItemRelations(payload)
  }, [payload])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <Grid container>
          <Grid item xs={4} />

          <Grid container item xs={4} spacing={2}>
            
            <Grid container item xs={3} justify='flex-end'>Table</Grid>
            <Grid container item xs={9}>
              <AutoCompleteNew
                disableUnderline={false}
                value={payload.table}
                options={tables}
                onChange={(event, selected) => {
                  setPayload({ ...payload, table: selected && selected.value })
                }}
              />
            </Grid>

            <Grid container item xs={3} justify='flex-end'>Column</Grid>
            <Grid container item xs={9}>
              <MultipleAutoCompleteSelect
                options={columns}
                values={payload.columns}
                disableUnderline={false}
                className={'full-width'}
                onChange={(event, selected) => setPayload({ ...payload, columns: selected.map(s => s.value)} )} />
            </Grid>

            <Grid container item xs={3} justify='flex-end'>IDs</Grid>
            <Grid container item xs={9}>
              <Input
                type='text'
                name='ids' placeholder='1,2,3 (blank means all)' className='input-right full-width' value={payload.ids}
                onChange={event => setPayload({ ...payload, ids: event.target.value }) }
              />
            </Grid>

            <Grid container item xs={3} />
            <Grid container item xs={9}>
            <Button variant='contained' color='primary' className='full-width' onClick={ handleSubmit } disabled={disabled}>
              Submit
            </Button>
            </Grid>

          </Grid>

          <Grid item xs={4} />
        </Grid>
      </Card>
    </div>
  )
}

export default TranslationItemRelationView
