import React, { useMemo, useCallback } from 'react'
import { TableCell } from '@material-ui/core'
import ArrowDropDown from 'icons/arrow_drop_down.svg'
import ArrowDropUp from 'icons/arrow_drop_up.svg'
import PropTypes from 'prop-types'

export default function SortableCellContainer (props) {
  const { customOrderBy, cells, onOrderByChange } = props
  const orderBy = useMemo(() => {
    return (customOrderBy && customOrderBy.column) ? customOrderBy : { column: 'id', order: 'desc' }
  }, [customOrderBy])

  const handleOrderChange = (event, column) => {
    const order = orderBy.column === column
      ? (orderBy.order === 'asc' ? 'desc' : 'asc')
      : 'desc'

    orderBy.column = column
    orderBy.order = order
    if (onOrderByChange) onOrderByChange(orderBy)
  }

  const showArrow = useCallback(column => orderBy.order === 'asc' ? ArrowDropUp : ArrowDropDown, [orderBy])

  return cells.map(cell => (
    <TableCell
      key={cell.name}
      className='sortable-th'
      onClick={e => {
        if (!cell.name) return
        handleOrderChange(e, cell.name)
      }}
    >
      { cell.children }
      { orderBy.column === cell.name && <img src={ showArrow(cell.name) } alt=''/> }
    </TableCell>
  ))
}

SortableCellContainer.propTypes = {
  cells: PropTypes.array.isRequired,
  onOrderByChange: PropTypes.func
}
