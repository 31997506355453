import React, { useState, useEffect, useCallback } from 'react'
import { workoutEquipmentListPromise } from 'api/WorkoutEquipment'
import WorkoutEquipmentTable from './components/WorkoutEquipmentTable'
import WorkoutEquipmentToolbar from './components/WorkoutEquipmentToolbar'

const WorkoutEquipmentList = props => {
  const [categories, setCategories] = useState([])

  const getCategories = useCallback(async () => {
    const categories = await workoutEquipmentListPromise()
    if (categories) {
      setCategories(categories)
    }
  }, [])

  useEffect(() => {
    getCategories()
  }, [getCategories])
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <WorkoutEquipmentToolbar {...props} />
        <br />
        <WorkoutEquipmentTable {...props} data={categories} />
      </div>
    </div>
  )
}

export default WorkoutEquipmentList