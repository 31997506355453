import { getApiClient } from './ApiClient'

export const getProgramNutritionListPromise = (params) => {
  return getApiClient()
    .get('/program-nutrition', { params })
    .then(resp => resp.data)
}

export const getProgramNutritionByIdPromise = id => {
  return getApiClient()
    .get(`/program-nutrition/${id}`)
    .then(resp => resp.data)
}

export const upsertProgramNutritionPromise = (body) => {
  return getApiClient()
    .post('/program-nutrition', { body })
    .then(resp => resp.data)
}
