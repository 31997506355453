import Form from './form'
import React, { useMemo, useState } from 'react'
import { purchaseDetailPromise } from 'api/Purchase'

export default function BlacklistAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useMemo(() => {
    if (!id) return

    purchaseDetailPromise(id).then(resp => resp && setData(resp))
  }, [id])

  return <Form data={data} setData={setData} {...props} />
}