import Step1 from './step1'
import Step2 from './step2'
import { getCourseDetailPromise } from 'api/course'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'

export default function CourseAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({ free_user: true, pro_health_user: true, health_plus_user: true, trainer_courses: [] })
  const [currentStep, setCurrentStep] = useState('step1')

  const formatDateTime = useCallback((dt) => {
    if (!dt) return dt

    return moment.tz(dt, 'Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
  }, [])

  useMemo(() => {
    if (!id) return

    getCourseDetailPromise(id).then(resp => {
      if (!resp) return

      setData({
        ...resp,
        oldId: resp.id,
        launch_time: formatDateTime(resp.launch_time),
        cut_off_date: formatDateTime(resp.cut_off_date),
        enrollment_end: formatDateTime(resp.enrollment_end),
        enrollment_begin: formatDateTime(resp.enrollment_begin),
        trainers: resp.trainer_courses.map(tc => tc.trainer_id),
      })
    })
  }, [formatDateTime, id])

  useEffect(() => {
  }, [props])

  return <>
    <Step1 mode={id ? 'edit' : 'add'} data={data} setData={setData} currentStep={currentStep} handleStepChange={setCurrentStep} {...props} />
    <Step2 mode={id ? 'edit' : 'add'} data={data} setData={setData} currentStep={currentStep} handleStepChange={setCurrentStep} {...props} />
  </>
}