import React, { useMemo, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useDebounce } from 'react-use'

const SelectComponentRemote = props => {
  const { label, value, placeholder, onChange, fetchData, optionsOutside, disabled=false, disableUnderline=true, inputLeft=false, ...rest } = props
  const [options, setOptions] = useState([])
  const [keyword, setKeyword] = useState()
  const filterOptions = useMemo(() => createFilterOptions({ limit: 50 }), [])

  useDebounce(() => {
    if (!keyword) return setOptions(optionsOutside || [])

    fetchData(keyword).then(res => {
      setOptions((res.rows || res).map(row => ({ label: row.label, value: row.value })))
    })
  }, 400, [fetchData, keyword])

  const selected = useMemo(() => {
    return options.find(o => o.value === value) || null
  }, [options, value])

  return (
    <Autocomplete
        {...rest}
        id='size-small-standard'
        size='small'
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        filterOptions={filterOptions}
        onChange={onChange}
        value={selected}
        className={inputLeft ? 'inputLeft' : 'inputRight'}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            label={label}
            value={keyword}
            onChange={event => setKeyword(event.target.value)}
            placeholder={placeholder || label}
            InputProps={{
              ...params.InputProps,
              disableUnderline
            }}
          />
        )}
      />
  )
}

export default SelectComponentRemote
