import { getApiClient } from './ApiClient'

export const functionSwitchListPromise = (params) => {
  return getApiClient()
    .get('/function-switches', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const functionSwitchDetailPromise = (id) => {
  return getApiClient()
    .get(`/function-switches/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const functionSwitchUpdatePromise = (id, body) => {
  return getApiClient()
    .put(`/function-switches/${id}`, { body })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
