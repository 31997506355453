import { getApiClient } from './ApiClient'

export const promoListPromise = (params) => {
  return getApiClient()
    .get('/promo-list', {
      params
    })
    .then(resp => {
      const data = resp.data
      return Promise.resolve(data)
    })
}

export const promoDetailPromise = id => {
  return getApiClient().get(`/promo/${id}`).then(resp => {
    return Promise.resolve(resp.data)
  })
}

export const promoBulkCreatePromise = body => {
  return getApiClient()
    .post('/promo/bulk-create', { body })
}

export const promoCreateOrUpdatePromise = promo => {
  const param = {
    id: promo.id,
    code: promo.code,
    productId1: promo.productId1,
    threshold: promo.threshold,
    active: promo.active,
    redeemed: promo.redeemed,
    expire: promo.expire,
    groupId: promo.groupId
  }
  return getApiClient().post('/promo', {
    body: param
  }).then(resp => {
    return Promise.resolve(resp.data)
  })
}