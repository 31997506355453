import { Chip, Grid, Input } from '@material-ui/core'
import FormItem from 'components/FormItem'
import React, { useCallback, useState } from 'react'

export default function ChipInForm (props) {
  const { name, data, setData, keyName, onChange } = props
  const [value, setValue] = useState('')

  const handleDeleteData = useCallback((index) => {
    data[index].active = false
    setData([...data])
  }, [data, setData])

  const handleKeyUp = useCallback(event => {
    const value = event.target.value
    if (event.keyCode !== 13 || !value) return

    setData([
      ...data,
      { [keyName]: value, active: true }
    ])

    setValue('')
  }, [data, keyName, setData])

  const handleChange = useCallback(event => {
    setValue(onChange ? onChange(event.target.value) : event.target.value)
  }, [onChange])

  return <>
    <FormItem name={name}>
      <Grid container spacing={2}>
        <Grid container item xs={9} justify='flex-end'>
          { data.map((d, index) => d.active && <Chip key={`chip-${index}`} label={d[keyName]} onDelete={() => handleDeleteData(index) } />) }
        </Grid>
        <Grid item xs={3}>
          <Input value={value} onChange={handleChange} disableUnderline onKeyUp={handleKeyUp} placeholder='press enter key' className='value' classes={{ input: 'text' }} />
        </Grid>
      </Grid>
    </FormItem>
  </>
}
