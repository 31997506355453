import React from 'react'
import { Grid, Input } from '@material-ui/core'
import { addAdminPromise } from 'api/Admin'
import AddBtn from 'components/AddBtn'
import { getCodesForList } from 'helpers/permission'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  function handleAddAdmin () {
    const permissions = getCodesForList()
    addAdminPromise({ user_id: params.user_id, permissions }).then(resp => {
      handleSearchChange({})
    })
  }

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Input placeholder='User ID' name='user_id' className={'block-value'} value={params.user_id}
            onChange={event => handleSearchChange({user_id: event.target.value})} />
        </Grid>
        <Grid item xs={9}>
          <AddBtn color='primary' variant='contained' onClick={handleAddAdmin} disabled={!params.user_id}> Add </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
