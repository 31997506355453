import React, { useRef } from 'react'
import { CloudUploadOutlined } from '@material-ui/icons'
import { workoutResourceUploadPromise } from 'api/WorkoutResource'
import Context from 'common/context'

export default function UploadComponent (props) {
  const { name, data, handleChange, ...rest } = props
  const { handleNotification } = Context._currentValue
  const ref = useRef()

  return (
    <>
      <input
        {...rest}
        type='file'
        hidden={true}
        accept='image/*'
        id={name}
        onChange={(event) => {
          handleNotification('info', 'uploading...')
          workoutResourceUploadPromise(event.target.files[0], data.id, name)
            .then(res => {
              if (res.data.url !== data[name]) {
                handleChange({[name]: res.data.url})
              } else {
                ref.current.style.backgroundImage = `url(${res.data.url}?random_number=${new Date().getTime()})`
              }
            })
        }}
      />

      <label
        htmlFor={name}
        className={name}
        ref={ref}
        style={{backgroundImage: `url(${data[name]})`}}
      >
        <CloudUploadOutlined />
      </label>
    </>
  )
}