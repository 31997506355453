import { getApiClient } from './ApiClient'

export const requestCourseWeekListPromise = (params) => {
  return getApiClient()
    .get('/course/weeks', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const requestCourseWeekDetailPromise = (id) => {
  return getApiClient()
    .get(`/course/weeks/${id}`)
    .then(resp => ({ ...resp.data, courseId: undefined }))
}

export const upsertCourseWeekPromise = (body) => {
  return getApiClient()
    .post('/course/weeks', { body: { courseWeeks: [].concat(body) }})
    .then(resp => Promise.resolve(resp.data))
}
