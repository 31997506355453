import React, { useCallback } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

export default function BooleanToggleButtonGroup (props) {
  const { name, label, value, onChange, options } = props

  const handleValueChange = useCallback((event, value) => {
    onChange(name, value)
  }, [onChange, name])

  return <div>
    { label && <label>{label}</label> }
    <ToggleButtonGroup color='primary' value={value} onChange={handleValueChange} size='small'>
      {
        options.map(({ label, value }) =>
          <ToggleButton key={label} value={value}>{label}</ToggleButton>)
      }
    </ToggleButtonGroup>
  </div>
}
