import { Grid, Input } from '@material-ui/core'
import { userDetailPromise } from 'api/User'
import useDebounce from 'helpers/useDebounce'
import React, { useState } from 'react'

export default function UserIdInput (prop) {
  const { userId, setUserId } = prop
  const [user, setUser] = useState({})

  useDebounce(() => {
    if (!userId) return setUser({})

    userDetailPromise(userId).then(data => setUser(data))
  }, 500, [userId])

  return <div className='info-item'>
    <Grid container>
      <Grid item xs={4}>
        <Input disableUnderline className='left-input' placeholder='User ID' value={userId} onChange={event => setUserId(event.target.value)} />
      </Grid>

      <Grid item xs={8}>{user.name}</Grid>
    </Grid>
  </div>
}
