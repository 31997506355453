import React, { useState, useEffect, useCallback } from 'react'
import { mealIngredientUnitListPromise } from 'api/MealIngredientUnit'
import MealIngredientUnitTable from './components/MealIngredientUnitListTable'
import MealIngredientUnitToolbar from './components/MealIngredientUnitListToolbar'

const MealIngredientUnitList = props => {
  const [units, setUnits] = useState([])

  const getUnits = useCallback(async () => {
    setUnits(
      await mealIngredientUnitListPromise()
    )
  }, [])

  useEffect(() => {
    getUnits()
  }, [getUnits])
  
  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealIngredientUnitToolbar {...props} />
        <br />
        <MealIngredientUnitTable {...props} data={units} />
      </div>
    </div>
  )
}

export default MealIngredientUnitList