const __cache__ = {}

export default {
  get (key) {
    return  __cache__[key]
  },

  set (key, value) {
    return __cache__[key] = value
  }
}