import _ from 'lodash'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import './index.scss'
import { getEnumOptionsPromise } from 'api/Util'

const SelectComponent = props => {
  const [_options, _setOptions] = useState([])
  const { label, name, placeholder, options, enumName, value, onChange, error, disabled=false, disableUnderline=true, limit=50, inputLeft=false, variant='standard', ...rest } = props

  useLayoutEffect(() => {
    if (!enumName) return _setOptions(options || [])

    getEnumOptionsPromise({ names: enumName })
      .then(resp => _setOptions(resp && _.map(resp[enumName], (value, label) => ({ label, value }))))
  }, [enumName, options])

  const filterOptions = useMemo(() => (
    createFilterOptions({ limit })
  ), [limit])

  const selected = useMemo(() => {
    return _options.find(o => o.value === value) || null
  }, [_options, value])

  const className = useMemo(() => `autoCompleteNew ${rest.className ? rest.className : ''} ${inputLeft ? 'inputLeft' : ''}`, [inputLeft, rest.className])

  return (
    <Autocomplete
        {...rest}
        className={className}
        id="size-small-standard"
        size="small"
        disabled={disabled}
        options={_options || []}
        getOptionLabel={(option) => option.label || ''}
        filterOptions={filterOptions}
        value={selected}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            error={error}
            name={name}
            {...params}
            variant={variant}
            label={label}
            placeholder={placeholder || label}
            InputProps={{
              ...params.InputProps,
              disableUnderline
            }}
          />
        )}
      />
  )
}

SelectComponent.prototype = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  inputValue: PropTypes.any,
  handleSelectChange: PropTypes.func.isRequired
}

export default SelectComponent