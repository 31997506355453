import './index.scss'
import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import validate from 'validate.js'
import { setToken, setAdminId, setAdminName } from 'api/ApiClient'
import { loginPromise } from 'api/Auth'
import { intiAdminPermission } from 'helpers/permission'

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
}

const Login = props => {
  const { history } = props

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleLogin = async event => {
    event.preventDefault()
    const response = await loginPromise(
      formState.values.username,
      formState.values.password
    )
    if (response) {
      setToken(response.token)
      setAdminId(response.id)
      setAdminName(response.email)
      intiAdminPermission(response.id)
      history.push('/users')
    }
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={'page-background'}>
      <div className={'content-body'}>
        <form className={'form'} onSubmit={handleLogin}>
          <TextField
            className={'text-field'}
            fullWidth
            label="Username"
            name="username"
            onChange={handleChange}
            type="text"
            value={formState.values.username || ''}
            variant="outlined"
          />
          <TextField
            className={'text-field'}
            error={hasError('password')}
            fullWidth
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ''}
            variant="outlined"
          />
          <Button
            className={'sign-in'}
            color="primary"
            disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained">
            Sign in now
          </Button>
        </form>
      </div>
    </div>
  )
}

export default withRouter(Login)
