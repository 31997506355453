import React, { useCallback } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import EditBtn from 'components/EditBtn'
import { FileCopy } from '@material-ui/icons'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
  Grid,
} from '@material-ui/core'
import { ProgramVideoValueToType } from 'common/enum'
import _ from 'lodash'

const ProgramTable = (props) => {
  const { data, page, onPageChange, history } = props

  const handleDuplicate = useCallback(program => {
    const check = window.confirm('Do you want to duplicate this program')
    if (!check) return

    history.push({
      pathname: '/programs/create',
      state: { program }
    })
  }, [history])

  const targeting = useCallback(row => {
    return ['Senior', 'Non Senior', 'Female', 'Male'].map(name => `${name}(${row[_.snakeCase(name)]})`).join(' / ')
  }, [])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Deeplink</TableCell>
                  <TableCell>Targeting</TableCell>
                  <TableCell>Enrollment Begin</TableCell>
                  {/* <TableCell>Enrollment End</TableCell> */}
                  <TableCell>Duration</TableCell>
                  <TableCell>Members</TableCell>
                  {/* <TableCell>Workout Count</TableCell> */}
                  {/* <TableCell>PRO Only</TableCell> */}
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data && data.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.name}</TableCell>
                      <TableCell>{ProgramVideoValueToType[record.type]}</TableCell>
                      <TableCell>{record.deeplink}</TableCell>
                      <TableCell>{targeting(record)}</TableCell>
                      <TableCell>{formatDateTime(record.enrollment_begin)}</TableCell>
                      {/* <TableCell>{formatDateTime(record.enrollment_end)}</TableCell> */}
                      <TableCell>{record.duration} {record.duration_unit}</TableCell>
                      <TableCell>{record.members}</TableCell>
                      {/* <TableCell>{record.total_count}</TableCell> */}
                      {/* <TableCell>{record.is_pro ? 'TRUE': 'FALSE'}</TableCell> */}
                      <TableCell>{record.active ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={6}>
                            <EditBtn path={`/programs/${record.id}/edit`} {...props} />
                          </Grid>
                          <Grid item xs={6}>
                            <FileCopy className='cursor-pointer' titleAccess='duplicate' onClick={() => handleDuplicate(record)} />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={(data && data.count) || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramTable
