import React, { useCallback } from 'react'
import EditBtn from 'components/EditBtn'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination,
  Grid,
} from '@material-ui/core'
import { boolStr, send } from 'helpers/utils'
import { formatDateTime } from 'common/usual'

const WorkoutCategory = (props) => {
  const { data, page, onParamsChange } = props

  const handlePageChange = useCallback((event, page) => {
    onParamsChange({ page: page + 1 })
  }, [onParamsChange])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Badge</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data && data.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.user_id}</TableCell>
                      <TableCell>
                        <span className='chip' style={{width: 'auto', backgroundColor: `#${send(record.social_badge, 'background_color')}`}}>
                          {send(record.social_badge, 'name')}
                        </span>
                      </TableCell>
                      <TableCell>{boolStr(record.active)}</TableCell>
                      <TableCell>{formatDateTime(record.created_at)}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={6}>
                            <EditBtn path={`/user-social-badges/${record.id}/edit`} {...props} />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component='div'
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          onChangePage={handlePageChange}
          count={(data && data.count) || 0}
        />
      </CardActions>
    </Card>
  )
}

export default WorkoutCategory
