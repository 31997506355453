import _ from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { mealIngredientPreparationListPromise } from 'api/MealIngredientPreparation'
import MealIngredientPreparationTable from './components/MealIngredientPreparationListTable'
import MealIngredientPreparationToolbar from './components/MealIngredientPreparationListToolbar'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

const MealIngredientPreparationList = props => {
  const ref = useRef()
  const location = useLocation()
  const [preparations, setPreparations] = useState([])
  const [params, setParams] = useState({ page: 1 })

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    mealIngredientPreparationListPromise(too).then(resp => setPreparations(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload) {
    handleParamsChange(payload, 500)
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealIngredientPreparationToolbar {...props} params={params} handleSearchChange={handleSearchChange} />
        <br />
        <MealIngredientPreparationTable {...props} page={params.page} data={preparations} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default MealIngredientPreparationList