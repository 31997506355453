import './index.scss'
import { deleteS3ObjectPromise } from 'api/helper'
import { S3Uploader2 } from 'components/S3UploadInput'
import React, { useCallback, useMemo } from 'react'
import { S3Bucket, S3FilePath, S3Path } from 'common/enum'
import ImageView from 'components/ImageView'

export default function (props) {
  const { keyName, filename, preferName, pathInFilename, fileType, handleChange, isForceRename } = props
  // const [imageStatus, setImageStatus] = useState({})
  const basicPath = S3FilePath[fileType]()

  // const loadHeadInfo = useCallback(() => {
  //   if (!filename) return

  //   const targets = ImageLanguageOptions.map(({value}) => ({ bucket: S3Bucket, subpath: basicPath, name: filename }))
  //   getS3ObjectHeadInfo(targets).then(data => {
  //     setImageStatus(
  //       data.reduce((res, d) => (res[d.subpath] = d, res), {})
  //     )
  //   })
  // }, [basicPath, filename])

  // useEffect(() => loadHeadInfo(), [loadHeadInfo])

  const handleDelete = useCallback(() => {
    if (!window.confirm('are you sure?')) return

    handleChange(keyName, null)
    const target = [{ bucket: S3Bucket, key: `${basicPath}/${filename}` }]
    deleteS3ObjectPromise(target)
  }, [basicPath, filename, handleChange, keyName])

  const handleAfterUpload = useCallback(name => {
    if (name === filename) return
    //  loadHeadInfo()
    
    handleChange(keyName, name)
  }, [filename, handleChange, keyName])

  const imageUrl = useMemo(() => {
    if (!filename) return

    return `${S3Path}/${basicPath}/${filename}`
  }, [basicPath, filename])

  return <>
    <div>
      <div className='i18n-image-row'>
        <div className='i18n-image-preview'>
          <ImageView src={imageUrl} />
        </div>

        <div className='i18n-image-btn'>
          <S3Uploader2
            bucket={S3Bucket}
            isDeletable={true}
            filename={filename}
            filepath={basicPath}
            preferName={preferName}
            isForceRename={isForceRename}
            filenameFormatter={pathInFilename && ((name) => `${pathInFilename}/${name}`)}

            params={{ ACL: 'public-read' }}
            afterUpload={handleAfterUpload}
            onDelete={handleDelete} />
        </div>
      </div>
    </div>
  </>
}
