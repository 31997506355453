import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Grid, Input } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import './index.scss'
import { fetchWorkoutOptions, optionEnum } from 'views/concerns/workoutOptions'
import WorkoutResourceUploader from '../WorkoutResourceUploader'
import AddBtn from 'components/AddBtn'

const WorkoutResourceToolbar = props => {
  const [options, setOptions] = useState([])
  const { category, equipment, target, trainer } = optionEnum
  const { params, className, onExport, onSearch } = props

  useEffect(() => {
    fetchWorkoutOptions(category, equipment, target, trainer)
      .then(resp => setOptions(resp))
  }, [category, equipment, target, trainer])

  function handleFilterChange (key, selected) {
    onSearch({[key]: selected && selected.value })
  }

  return (
    <div className={clsx('tool-bar', className)}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Input name='id' value={params.id} placeholder='ID' style={{ width: '100%', marginTop: '14px' }}
            onChange={event => onSearch({ id: event.target.value }, 500)} />
        </Grid>

        <Grid item xs>
          <AutoCompleteNew
            name='categoryId'
            label={'Category'}
            options={options[category] || []}
            disableUnderline={false}
            value={parseInt(params.categoryId)}
            onChange={(e, selected) => handleFilterChange('categoryId', selected)}
          />
        </Grid>

        <Grid item xs>
          <AutoCompleteNew
            name='equipmentId'
            label={'Equipment'}
            options={options[equipment] || []}
            disableUnderline={false}
            value={parseInt(params.equipmentId)}
            onChange={(e, selected) => handleFilterChange('equipmentId', selected)}
          />
        </Grid>

        <Grid item xs>
          <AutoCompleteNew
            label={'Target Area'}
            options={options[target] || []}
            disableUnderline={false}
            value={parseInt(params.targetAreaId)}
            onChange={(e, selected) => handleFilterChange('targetAreaId', selected)}
          />
        </Grid>

        <Grid item xs>
          <AutoCompleteNew
            label={'Trainer'}
            options={options[trainer] || []}
            disableUnderline={false}
            value={parseInt(params.trainerId)}
            onChange={(event, selected) => handleFilterChange('trainerId', selected)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={9}></Grid>
        <Grid container item xs={3} justify='flex-end'>
          <AddBtn
            color="primary"
            type="submit"
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/workout-resource/add`)
            }}
            variant="contained"
          >
            Add
          </AddBtn>
          {/* &nbsp; */}
          {/* <WorkoutResourceUploader /> */}
          &nbsp;
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={e => {
              e.stopPropagation()
              onExport('csv')
            }}>
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

WorkoutResourceToolbar.propTypes = {
  className: PropTypes.string,
}

export default WorkoutResourceToolbar
