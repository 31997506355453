import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { updateToCoursePromise } from 'api/course'
import { AutoCompleteNew } from 'components/Select'
import { CourseTypes } from 'common/enum'
import _ from 'lodash'
import { formatDateTimeToUTC } from 'helpers/utils'

export default function UpdateUserCourseForm (props) {
  const { courses, timezones, history, data } = props
  const [btnStatus, setBtnStatus] = useState(false)
  const [userCourse, setUserCourse] = useState({})

  useEffect(() => {
    setUserCourse({
      ...data,
      start_at: formatDateTimeToUTC(data.start_at),
      end_at: formatDateTimeToUTC(data.end_at),
    })
  }, [data])

  const disableBtn = useMemo(() => {
    const requiredColumns = ['user_id', 'course_id', 'start_at', 'time_zone_id']
    return btnStatus || _.reduce(requiredColumns, (res, column) => res || isBlank(userCourse[column]), false)
  }, [btnStatus, userCourse])

  const courseOptions = useMemo(() => {
    return courses.map(c => ({ label: c.name, value: c.id }))
  }, [courses])

  const timezoneOptions = useMemo(() => {
    return timezones.map(tz => ({ label: tz.name, value: tz.id }))
  }, [timezones])

  function handleSubmit () {
    setBtnStatus(true)

    updateToCoursePromise(userCourse.id, userCourse)
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  const handleValueChange = useCallback((pair) => {
    setUserCourse({ ...userCourse, ...pair })
  }, [userCourse])

  const typeName = useMemo(() => {
    const courseType = CourseTypes.find(ct => ct.num === userCourse.type)
    if (!courseType) return ''

    return courseType.label
  }, [userCourse.type])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label='User ID' name='user_id' value={userCourse.user_id} readonly={true} isRequired={true} onChange={handleValueChange} />

        <FormItem label='Course' isRequired={true}>
          <AutoCompleteNew disableUnderline={true} options={courseOptions} value={userCourse.course_id} disabled={true} onChange={(event, selected) => {
            const course = courses.find(c => c.id === selected.value) || {}
            const courseType = CourseTypes.find(ct => ct.num === course.type) || {}
            handleValueChange({ course_id: course.id, type: courseType.label })
          }}/>
        </FormItem>

        <FormItem label='Type' name='type_name' value={typeName} readonly={true} />

        <FormItem label='Start At' name='start_at' value={userCourse.start_at} placeholder='YYYY-MM-DD HH:mm:ss UTC' isRequired={true} onChange={handleValueChange} />
        <FormItem label='End At' name='end_at' value={userCourse.end_at} placeholder='YYYY-MM-DD HH:mm:ss UTC' isRequired={false} onChange={handleValueChange} />

        <FormItem label='Time Zone' name='time_zone_id' isRequired={true}>
          <AutoCompleteNew disableUnderline={true} options={timezoneOptions} value={userCourse.time_zone_id} onChange={(event, selected) => {
            handleValueChange({ time_zone_id: (selected || {}).value })
          }}/>
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(userCourse['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => handleValueChange({ active: e.target.checked }) }
          />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}