import { InfoOutlined } from '@material-ui/icons'
import React from 'react'

export default function InfoTag (props) {
  const { title } = props

  return <span title={title}>
    <InfoOutlined style={{fontSize: '13px'}}  />
  </span>
}
