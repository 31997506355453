import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AddBtn from 'components/AddBtn'
import MyModal from 'components/MyModal'
import { Button, Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import FormTextField from 'components/FormTextField'
import { linkUserToPartnerPromise, partnerListPromise } from 'api/Partner'

export default function UserGroupToolbar (props) {
  const blankWidth = 2
  const { params, onParamsChange, reloadData } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [payload, setPayload] = useState({})
  const [peerfitPartners, setPeerfitPartners] = useState([])

  const handleChange = useCallback((name, value) => {
    setPayload(prev => ({ ...prev, [name]: value }))
  }, [])

  const isSubmitBtnClickable = useMemo(() => payload.userId && payload.partnerUserId && payload.partnerId, [payload])

  const handleCancel = useCallback(() => {
    setPayload({})
    setModalOpen(false)
  }, [])

  const handleSubmit = useCallback(() => {
    setModalOpen(false)
    linkUserToPartnerPromise(payload).then(() => {
      setPayload({})
      reloadData()
    })
  }, [payload, reloadData])

  useEffect(() => {
    partnerListPromise().then(resp =>
        setPeerfitPartners(resp.filter(r => /peerfit/i.test(r.name)).map(r => ({ label: r.name, value: r.id }))))
  }, [])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='User ID or Email' size='small' value={params.query} className='background-color' onChange={event => onParamsChange({query: event.target.value}, 500)} />
        </Grid>
        <Grid container item xs={8} justify={'flex-end'}>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              setModalOpen(true)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>

      <MyModal open={modalOpen} setOpen={setModalOpen}>
        <br /><br />
        <Grid container spacing={2}>
          <Grid item xs={blankWidth} />
          <Grid container item xs={12 - 2 * blankWidth}>
            <label>Link Peerfit account To FitOn account</label>
          </Grid>
          <Grid item xs={blankWidth} />

          <Grid item xs={blankWidth} />
          <Grid container item xs={12 - 2 * blankWidth}>
            <FormTextField numberOnly={true} placeholder='FitOn User ID' fullWidth name='userId' value={payload.userId} onChange={handleChange} />
          </Grid>
          <Grid item xs={blankWidth} />

          <Grid item xs={blankWidth} />
          <Grid container item xs={12 - 2 * blankWidth}>
            <FormTextField placeholder='Peerfit User ID' fullWidth name='partnerUserId' value={payload.partnerUserId} onChange={handleChange} />
          </Grid>
          <Grid item xs={blankWidth} />

          <Grid item xs={blankWidth} />
          <Grid container item xs={12 - 2 * blankWidth}>
            <AutoCompleteNew
              inputLeft={true}
              placeholder='Partner'
              variant='outlined'
              options={peerfitPartners}
              value={payload.partnerId}
              onChange={(event, selected) => handleChange('partnerId', selected && selected.value)}
            />
          </Grid>
          <Grid item xs={blankWidth} />

          <Grid item xs={blankWidth} />
          <Grid container item xs={3}>
            <Button variant='contained' component='label' color='secondary' size='small' fullWidth onClick={handleCancel}>
              cancel
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button variant='contained' component='label' color='primary' size='small' fullWidth onClick={handleSubmit} disabled={!isSubmitBtnClickable}>
              submit
            </Button>
          </Grid>
          <Grid item xs={blankWidth} />
        </Grid>
        <br /><br />
      </MyModal>
    </div>
  )
}
