import React from 'react'
import { Card } from '@material-ui/core'
import DetailRow from 'components/DetailRow'

const CourseWeekDetail = props => {
  const { courseWeek } = props

  return (
    <div className={'root'}>
      <Card>
        <DetailRow label={'id'}> {courseWeek.id} </DetailRow>
        <DetailRow label={'course_id'}> {courseWeek.course_id} </DetailRow>
        <DetailRow label={'week'}> {courseWeek.week} </DetailRow>
        <DetailRow label={'title'}> {courseWeek.title} </DetailRow>
        <DetailRow label={'description'}> {courseWeek.description} </DetailRow>
        <DetailRow label={'preview_video_url'}> {courseWeek.preview_video_url} </DetailRow>
        <DetailRow label={'active'}> {`${courseWeek.active}`} </DetailRow>
        <DetailRow label={'preview_video_cover_url'}> {courseWeek.preview_video_cover_url} </DetailRow>
      </Card>
    </div>
  )
}

export default CourseWeekDetail
