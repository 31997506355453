import Form from './form'
import React, { useEffect, useState } from 'react'
import { getProgramToolDetail } from 'api/ProgramTool'

export default function ProgramToolEdit (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useEffect(() => {
    if (!id) return

    getProgramToolDetail(id)
      .then(resp => resp && setData(resp))
  }, [id])

  return <div>
    <Form data={data} {...props} />
  </div>
}