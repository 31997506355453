import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { formatDateTimeUTC } from 'helpers/utils'
import { updateUserProgramPromise } from 'api/UserProgram'

export default function ProgramTableUpsertForm (props) {
  const { data, history } = props
  const [requiredColumns, setRequiredColumns] = useState([])
  const [userProgram, setUserProgram] = useState({a: 1, b:2})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() =>
    btnStatus
    || requiredColumns.map(column => isBlank(userProgram[column])).includes(true)
    , [btnStatus, userProgram, requiredColumns])

  useEffect(() => {
    setUserProgram({
      ...data,
      start_at: formatDateTimeUTC(data.start_at),
      end_at: formatDateTimeUTC(data.end_at),
      quit_at: formatDateTimeUTC(data.quit_at),
      finish_at: formatDateTimeUTC(data.finish_at),
    })
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    updateUserProgramPromise(userProgram)
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  const handleValueChange = useCallback((map) => {
    setUserProgram({ ...userProgram, ...map })
  }, [userProgram])

  const programFormItem = (label, name, isRequired, props) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])
    return <FormItem
      label={label} name={name} value={userProgram[name]} isRequired={isRequired} {...props}
      onChange={map => { setUserProgram({ ...userProgram, ...map }) } }
    />
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { programFormItem('User ID', 'user_id', false, { readonly: true }) }

        <FormItem label={'Program'} name={'program'} value={userProgram.program && userProgram.program.name} isRequired={false} readonly={true} />

        {programFormItem('Start At(UTC time)', 'start_at', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })}
        {programFormItem('End At(UTC time)', 'end_at', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })}
        {programFormItem('Quit At(UTC time)', 'quit_at', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })}
        {programFormItem('Finish At(UTC time)', 'finish_at', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })}

        <FormItem label={'Unlock Week'}>
          <NumberAutoComplete name='unlock_week' from={1} to={100} value={userProgram.unlock_week} handleSelectChange={(unlock_week) => { handleValueChange({ unlock_week }) }} />
        </FormItem>

        { programFormItem('Timezone ID', 'time_zone_id', false) }

        <FormItem label={'Active'} name={'active'}>
          <Switch
            name={'active'}
            checked={Boolean(userProgram['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => setUserProgram({...userProgram, active: e.target.checked}) }
          />
        </FormItem>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}