import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import _, { assign } from 'lodash'
import { workoutEquipmentUpsertPromise } from 'api/WorkoutEquipment'
import { isBlank } from 'common/usual'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import UploaderForSingleImage from 'components/UploaderForSingleImage'

export default function WorkoutEquipmentUpsertForm (props) {
  const { data } = props
  const [equipment, setEquipment] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() => (
    btnStatus ||
      isBlank(equipment.name)
  ), [btnStatus, equipment.name])

  useEffect(() => {
    setEquipment(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    workoutEquipmentUpsertPromise(equipment)
      .then(res => {
        if (!equipment.id) {
          props.history.push('/workout-equipments')
        }
        equipment.id = res.id
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setEquipment(
      assign({}, equipment, ...values)
    )
  }, [equipment])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: equipment
  }), [handleChange, equipment])

  const images = useMemo(() => ([
    { key: 'imageUrl', size: '384*246', fileType: 'WorkoutEquipment' },
    { key: 'imageNew', size: '216*216', fileType: 'WorkoutEquipmentNew' },
  ]), [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        {
          ['name', 'displayName', 'shopDeeplink'].map(key =>
            <FormItem name={key} {...commonProps} />)
        }

        <FormItem label={'Sort'} name={'sort'} type={'number'}>
          <NumberAutoComplete name='sort' from={1} to={100} value={equipment.sort} handleSelectChange={sort => handleChange({ sort })} />
        </FormItem>

        {
          ['enableSearch', 'shopAvailable', 'active'].map(key =>
            <FormItem id={key} label={_.startCase(key)} name={key}>
              <Switch
                name={key}
                checked={Boolean(equipment[key])}
                onChange={e => handleChange({ [key]: e.target.checked })}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </FormItem>)
        }

        {
          equipment.name && images.map(({ key, size, fileType }) =>
            <div className='form-item'>
              <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                <span>{_.startCase(key)} (PNG, JPG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image Size: <b>{size}</b></span>
              </div>
              <UploaderForSingleImage keyName={key} preferName={_.kebabCase(equipment.name)} handleChange={(k, v) => handleChange({ [k]: v })} filename={equipment[key]} fileType={fileType} />
            </div>
          )
        }
        

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(equipment)}</div> */}
    </div>
  )
}