import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'

const VideoResourceTable = (props) => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>SKU Category</TableCell>
                  <TableCell>Feature Set</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.rows?.map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record?.sku_category?.name}</TableCell>
                      <TableCell>{record?.feature_set?.name}</TableCell>
                      <TableCell>{record.sort}</TableCell>
                      <TableCell>{booleanElement(record.active)}</TableCell>
                      <TableCell>
                        <EditBtn path={`/sku-feature-relations/${record.id}/edit`} {...props} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          page={page-1}
          rowsPerPage={10}
          count={data?.count || 0}
          rowsPerPageOptions={[10]}
          onPageChange={onPageChange}
        />
      </CardActions>
    </Card>
  )
}

export default VideoResourceTable
