import React from 'react'
import UpsertForm from './form'

export default function SkuFeatureRelationAdd (props) {
  const data = {active: false, sort: null}

  return (
    <UpsertForm data={data} {...props} />
  )
}
