import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const socialGroupOptionPromise = () => {
  return getApiClient()
    .get('/option/social-groups', { params: ParamsForOption })
    .then(resp => resp.data)
}

export const groupListPromise = (params) => {
  return getApiClient()
    .get('/groups', { params })
    .then(resp => resp.data)
}

export const groupDetailPromise = (id) => {
  return getApiClient()
  .get(`/groups/${id}`)
  .then(resp => resp.data)
}

export const fuzzySearchGroupPromise = (params) => {
  return getApiClient().get('/groups/keywords', { params }).then(resp => {
    const data = resp.data
    return Promise.resolve(data)
  })
}

export const upsertGroupPromise = (body) => {
  return getApiClient()
  .post('/groups', { body })
  .then(resp => resp.data)
}

export const groupMembersPromise = (params) => {
  return getApiClient()
    .get('/groups/members', { params })
    .then(resp => resp.data)
}

export const groupMembersDownloadPromise = (params) => {
  return getApiClient().get('/groups/members', { params: { ...params, type: 'csv' } })
}

export const groupMemberDetailPromise = (id) => {
  return getApiClient()
    .get(`groups/members/${id}`)
    .then(resp => resp.data)
}
