import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'
import './index.scss'
import EditBtn from 'components/EditBtn'
import SortableTableCells from 'components/SortableTableCells'
import { WorkoutResourceValueToType } from 'common/enum'

const WorkoutResourceTable = props => {
  const { data, onPageChange, onOrderByChange, page } = props

  return (
    <Card className={clsx('workout-resource-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCells
                    onOrderByChange={onOrderByChange}
                    cells={[
                      { name: 'id', children: 'ID' },
                      { name: 'name', children: 'Name' },
                      { name: 'type', children: 'Type' },
                      { name: 'name_male', children: 'Name Male' },
                      { name: 'continue_time', children: 'Continue Time' },
                      { name: 'intensity', children: 'Intensity' },
                      { name: 'avg_rating', children: 'Avg Rating' },
                      { name: 'quality_score', children: 'Quality Score' },
                      { name: 'trainer', children: 'Trainer' },
                    ]}
                  />
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.rows && data.rows.map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{WorkoutResourceValueToType[item.type]}</TableCell>
                    <TableCell>{item.name_male}</TableCell>
                    <TableCell>{item.continue_time}</TableCell>
                    <TableCell>{item.intensity}</TableCell>
                    <TableCell>{item.avg_rating}</TableCell>
                    <TableCell>{item.quality_score}</TableCell>
                    <TableCell>{(item.trainer || {}).name}</TableCell>
                    <TableCell>
                      <EditBtn module='workout' history={props.history} path={`/workout-resource/edit/${item.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'actions'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

WorkoutResourceTable.propTypes = {
  className: PropTypes.string,
}

export default WorkoutResourceTable