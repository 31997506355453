import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { partnerWorkoutCategoryUpsertPromise } from 'api/PartnerWorkoutCategory'
import { fetchWorkoutOptions, optionEnum } from '../concerns/workoutOptions'
import { AutoCompleteNew } from 'components/Select'

export default function PartnerWorkoutCategoryForm (props) {
  const { data } = props
  const [category, setCategory] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [options, setOptions] = useState({})

  useEffect(() => {
    async function fetchOptions () {
      const _options = await fetchWorkoutOptions(optionEnum.category, optionEnum.partner)
      setOptions(_options)
    }
    fetchOptions()
  }, [])

  const disableBtn = useMemo(() => (
    btnStatus
  ), [btnStatus])

  useEffect(() => {
    setCategory(data)
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    partnerWorkoutCategoryUpsertPromise(category)
      .then(res => {
        props.history.goBack()
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setCategory(
      assign({}, category, ...values)
    )
  }, [category])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: category
  }), [handleChange, category])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Sort'} name={'sort'} type={'number'} {...commonProps} />

        <FormItem label={'Partner'} name={'partnerId'} >
          <AutoCompleteNew
            options={options.partner || []}
            value={category.partnerId}
            onChange={(event, selected) => {
              handleChange({ partnerId: (selected || {value: null}).value })
            }}
          />
        </FormItem>

        <FormItem label={'Workout Category'} name={'workoutCategoryId'}>
          <AutoCompleteNew
            options={options.workoutCategory || []}
            value={category.workoutCategoryId}
            onChange={(event, selected) => {
              handleChange({ workoutCategoryId: (selected || {value: null}).value })
            }}
          />
        </FormItem>

        <FormItem label={'Active'} name={'active'}>
          <Switch
            checked={Boolean(category.active)}
            onChange={e => {
              handleChange({ active: e.target.checked })
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(category)}</div> */}
    </div>
  )
}