import _ from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import { socialFeedPostOrCommentDetailPromise, toggleSocialFeedPostOrCommentSpam } from 'api/SocialFeed'
import { Card, Switch } from '@material-ui/core'
import DetailRow from './components/row'
import { formatDateTime } from 'common/usual'
import AttachmentListView from './components/attachmentListView'
import PostView from './components/postListView'

const FeatureBannerCampaignDetail = props => {
  const [postOrComment, setPostOrComment] = useState({})
  const { match: { params: { id } } } = props
  const kind = new URLSearchParams(props.location.search).get('kind')

  const getCampaignDetail = useCallback(async () => {
    
    setPostOrComment(await socialFeedPostOrCommentDetailPromise(id, { kind }))
  }, [id, kind])

  useEffect(() => {
    getCampaignDetail()
  }, [getCampaignDetail])

  const toggleSpam = useCallback(event => {
    const spam = event.target.checked

    toggleSocialFeedPostOrCommentSpam({ id, spam, type: kind })
      .then(() => setPostOrComment({ ...postOrComment, spam }))
  }, [id, kind, postOrComment])
  
  const excludeColumns = [
    'id', 'description', 'updatedAt', 'createdAt', 'socialFeedAttachments', 'lastActivityAt', 'postType', 'comments', 'createdBy', 'post', 'spam',
    'reactions', 'latestCommentIds', 'deeplinkKey', 'friendsPublic', 'reactionUserName', 'lastActivityAt', 'commentAble', 'manualActive', 'visibility'
  ]

  return (
    <div className={'root'}>
      <Card className={'detail-container'}>
        <DetailRow label={'ID'}>{ postOrComment.id }</DetailRow>
        {
          _.without(Object.keys(postOrComment), ...excludeColumns)
            .map(key => (<DetailRow label={_.startCase(key)}>{`${postOrComment[key]}`}</DetailRow>))
        }
        <DetailRow label={'Description'}>{ postOrComment.description }</DetailRow>
        <DetailRow label={'Spam'}>{
          <Switch
            name='spam'
            checked={Boolean(postOrComment.spam)}
            onChange={toggleSpam}
          />
        }</DetailRow>
        <DetailRow label={'Created At'}>{ formatDateTime(postOrComment.createdAt) }</DetailRow>
        <DetailRow label={'Updated At'}>{ formatDateTime(postOrComment.updatedAt) }</DetailRow>
      </Card>

      <br />

      {
        postOrComment.socialFeedAttachments && <AttachmentListView {...props} content={postOrComment.socialFeedAttachments}  />
      }

      {
        postOrComment.post && <PostView content={postOrComment.post} />
      }
    </div>
  )
}

export default FeatureBannerCampaignDetail
