import Table from './table'
import Toolbar from './toolbar'
import React, { useState, useEffect } from 'react'
import { featureBannerPlacementListPromise } from 'api/FeatureBanner'

const RecordList = props => {
  const [records, setRecords] = useState([])

  useEffect(() => {
    featureBannerPlacementListPromise().then(res => setRecords(res))
  }, [])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar {...props} />
        <br />
        <Table {...props} data={records} />
      </div>
    </div>
  )
}

export default RecordList
