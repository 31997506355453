import React, { useState, useEffect } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { Grid } from '@material-ui/core'
import { dailyfixPromise } from 'api/WorkoutDailyfix'
import AddBtn from 'components/AddBtn'
import { ParamsForOption } from 'common/enum'

export default function WorkoutDailyfixToolbar (props) {
  const { onChange, params } = props
  const [dailyfix, setDailyfix] = useState([])

  useEffect(() => {
    async function fetchDailyfix () {
      setDailyfix(
        (await dailyfixPromise(ParamsForOption)).map(df => ({
          label: df.defaultTime,
          value: df.id
        }))
      )
    }
    fetchDailyfix()
  }, [])

  return (
    <Grid item container spacing={0}>
      <Grid item xs={4}>
        <AutoCompleteNew
          label={'Time'}
          options={dailyfix}
          disableUnderline={false}
          value={parseInt(params.dailyfixId)}
          onChange={(event, selected) => {
            onChange({ dailyfixId: selected && selected.value })
          }}
        />
    </Grid>

    <Grid item xs={4}></Grid>

    <Grid container item xs={4} justify={'flex-end'} >
      <AddBtn
        color="primary"
        type="submit"
        onClick={e => {
          e.stopPropagation()
          props.history.push(`/workout-dailyfix/add`)
        }}
        variant="contained"
      >
        Add
      </AddBtn>
    </Grid>
  </Grid>
  )
}