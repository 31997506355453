import React, { useMemo, useState, useEffect } from 'react'
import { Card, Button } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { upsertCourseVideoPromise } from 'api/CourseVideo'
import moment from 'moment'
import _ from 'lodash'
import { CourseVideoOptions } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import ActiveSwitch from 'components/ActiveSwitch'

export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, history } = props
  const [requiredColumns, setRequiredColumns] = useState([])
  const [courseVideo, setCourseVideo] = useState({ active: false })
  const [btnStatus, setBtnStatus] = useState(false)

  const disableBtn = useMemo(() =>
    btnStatus
      || _.isNull(courseVideo.summary)
      || requiredColumns.map(column => isBlank(courseVideo[column])).includes(true)
  , [btnStatus, courseVideo, requiredColumns])

  useEffect(() => {
    setCourseVideo({ ...data })
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    upsertCourseVideoPromise(courseVideo)
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  const courseVideoFormItem = (label, name, isRequired, isReadonly, props) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])
    return <FormItem
      label={label} name={name} value={courseVideo[name]} isRequired={isRequired} readonly={isReadonly} {...props}
      onChange={map => { setCourseVideo({ ...courseVideo, ...map }) } }
    />
  }

  const isStarted = useMemo(() => {
    if (!data.course) return false

    return moment(data.course.launch_time) <= moment()
  }, [data])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name='content type'>
          <AutoCompleteNew
            disableUnderline={true}
            value={courseVideo.content_type}
            options={CourseVideoOptions}
            onChange={(event, selected) => setCourseVideo(prev => ({ ...prev, content_type: selected && selected.value }))}
          />
        </FormItem>

        { courseVideoFormItem('Content ID', 'content_id', true, isStarted, { placeholder: 'workout ID or video resource ID' }) }
        { courseVideoFormItem('Course ID', 'course_id', true, isStarted) }
        { courseVideoFormItem('Week', 'week', true, isStarted) }
        { courseVideoFormItem('Sequence', 'sequence', true) }
        { courseVideoFormItem('Subtitle', 'subtitle', true) }
        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={courseVideo} setBody={setCourseVideo} isNeedCheck={false} />
        </FormItem>

        <Button fullWidth className="save-btn" color="primary" size="large" type="submit" variant="contained" onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}