import React, { useCallback, useState } from 'react'
import MyModal from 'components/MyModal'
import { Button, Grid, Input } from '@material-ui/core'
import { addSeatsToGroupPromise } from 'api/Product'


export default function Toolbar (props) {
  const { modalData, modalOpen, setModalOpen, reloadData } = props
  const [data, setData] = useState({})
  
  const handleChange = useCallback((event) => {
    const { name, value } = event.target
    setData({ ...data, id: modalData.id, [name]: value })
  }, [data, modalData.id])

  const handleAdd = useCallback(() => {
    addSeatsToGroupPromise(data).then(() => {
      setModalOpen(false)
      reloadData()
    })
  }, [data, reloadData, setModalOpen])

  return <MyModal open={modalOpen} setOpen={setModalOpen}>
    <br /><br />
    <Grid container spacing={2}>
      <Grid item xs={3}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Input name='originalTransactionId' onChange={handleChange} placeholder='Original Transaction ID' fullWidth  />
      </Grid>
      <Grid item xs={3}>&nbsp;</Grid>

      <Grid item xs={3}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Input name='seats' onChange={handleChange} placeholder='Seats' fullWidth  />
      </Grid>
      <Grid item xs={3}>&nbsp;</Grid>

      <Grid item xs={3}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Button variant='contained' component='label' color='primary' fullWidth onClick={handleAdd}>Add</Button>
      </Grid>
      <Grid item xs={3}>&nbsp;</Grid>
    </Grid>
  </MyModal>
}
