import _ from 'lodash'
import 'assets/scss/form2.scss'
import './components/component.scss'
import { Button, Grid } from '@material-ui/core'
import SetupCard from './components/setup'
import TargetCard from './components/target'
import ContentCard from './components/content'
import PlacementCard from './components/placement'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { featureBannerCampaignUpsertPromise, featureBannerContentListPromise } from 'api/FeatureBanner'
import { ParamsForOption } from 'common/enum'

export default function FeatureBannerForm (props) {
  const { originalCampaign, history } = props
  const [contents, setContents] = useState([])
  const [campaign, setCampaign] = useState({})
  const [placement, setPlacement] = useState({})
  const [updatedContents, setUpdatedContents] = useState([])
  const [contentsInDB, setContentsInDB] = useState([])
  const columnsNotNull = ['name', 'placement_id']

  useEffect(() => {
    featureBannerContentListPromise({ page: 1, size: 10000, ...ParamsForOption })
      .then(resp => setContentsInDB(resp.rows))
  }, [])

  useLayoutEffect(() => {
    const { contents: _contents = [], feature_banner_placement, ...campaign } = originalCampaign
    setCampaign({
      ...campaign,
      start_date: campaign.start_date && (1000 * campaign.start_date),
      end_date: campaign.end_date && (1000 * campaign.end_date),
    })
    setContents(_contents)
    setUpdatedContents(_(_contents).filter(c => _.isString(c.id)).keyBy('id').value())
  }, [originalCampaign])

  const handleCampaignChange = useCallback((key, value, other) => {
    setCampaign({ ...campaign, [key]: value, ...other })
  }, [campaign])

  const handleContentChange = useCallback((id, pairs) => {
    setUpdatedContents({ ...updatedContents, [id]: { ...updatedContents[id], id, ...pairs } })
  }, [updatedContents])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    const foo = {
      ...campaign,
      start_date: campaign.start_date && (campaign.start_date / 1000),
      end_date: campaign.end_date && (campaign.end_date / 1000),
    }
    featureBannerCampaignUpsertPromise({campaign: foo, contents: updatedContents})
      .then(() => history.goBack())
  }, [campaign, history, updatedContents])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!campaign[cname]) return true
  }, [campaign, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          { originalCampaign.id ? 'Edit' : 'New' } Campaign
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SetupCard data={campaign} handleChange={handleCampaignChange} />
        </Grid>

        <Grid item xs={6}>
          <PlacementCard data={campaign} onPlacementChange={setPlacement} handleChange={handleCampaignChange} />
        </Grid>

        <Grid item xs={12}>
          <TargetCard data={campaign} handleChange={handleCampaignChange} />
        </Grid>

        <Grid container item xs={12}>
          <ContentCard placement={placement} size={placement.image_size} data={contents} setData={setContents} updatedContents={updatedContents} handleChange={handleContentChange} payload={{ contents: contentsInDB }} />
        </Grid>
      </Grid>
    </div>
  </div>
}
