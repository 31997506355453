import React from 'react'
import { Card, Grid } from '@material-ui/core'

const Row = props => {
  const { guide = {} } = props

  return <div>
    <Grid container spacing={3} className='detail-table-row'>
      <Grid item xs={1} className='value'>{ guide.id }</Grid>
      <Grid item xs={3} className='value'>{ guide.name }</Grid>
      <Grid item xs={8} className='value'>{ guide.url }</Grid>
    </Grid>
  </div>
}

const GuidePdf = props => {
  const { guide_pdf_urls = [] } = props.courseWeek

  return (
    <div className={'root'}>
      <Card>
        <Grid container spacing={3} className={'detail-table-row'}>
          <Grid item xs={1} className='label'>ID</Grid>
          <Grid item xs={3} className='label'>Name</Grid>
          <Grid item xs={8} className='label'>URL</Grid>
        </Grid>
        {
          guide_pdf_urls && guide_pdf_urls.map(guide => <Row guide={guide} />)
        }
      </Card>
    </div>
  )
}

export default GuidePdf
