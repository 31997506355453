import { Android, Apple, Computer } from '@material-ui/icons'
import React from 'react'

export function PlatformIcons (props) {
  const { platforms = [] } = props
  const icons = [
    { value: 0, icon: <Apple /> },
    { value: 1, icon: <Android /> },
    { value: 2, icon: <Computer /> },
  ]

  return icons.map(({ value, icon }) => platforms.includes(value) && icon)
}
