import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react'
import { MultipleAutoCompleteSelect, AutoCompleteNew } from 'components/Select'
import { fetchWorkoutOptions, optionEnum } from 'views/concerns/workoutOptions'
import { Button, Card, Switch } from '@material-ui/core'
import PropTypes from 'prop-types'
import FormItem from 'components/FormItem'
import AgeAndGenderSwitch from 'components/AgeAndGenderSwitch'
import { getWorkoutSubcategoryPromise } from 'api/WorkoutResource'
import { ParamsForOption, WorkoutResourceType } from 'common/enum'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'


const WorkoutResourceAddStep1 = (props) => {
  const { workoutResource, handleChange, handleStepChange, hidden, action, setWorkoutResource } = props
  const [options, setOptions] = useState({})
  const [workoutSubcategories, setWorkoutSubcategories] = useState([])
  const { trainer, category, target, resourceNotLive, equipment } = optionEnum

  const isMeditation = useMemo(() => {
    return workoutResource.category_id === 30
  }, [workoutResource.category_id])

  useLayoutEffect(() => {
    getWorkoutSubcategoryPromise(ParamsForOption)
      .then(data => setWorkoutSubcategories(data.map(d => ({ label: d.name, value: d.id }))))
  }, [])

  useEffect(() => {
    async function loadOptions () {
      const _options = await fetchWorkoutOptions(trainer, category, target, resourceNotLive, equipment)
      setOptions(_options)
    }
    loadOptions()
  }, [category, equipment, resourceNotLive, target, trainer])

  function handleSubmitStep1 () {
    return handleStepChange('step2')

    // setSubmitStep1BtnStatus(true)
    // handleSubmit()
    //   .then(res => {
    //     handleChange({id: res.id})
    //     handleStepChange('step2')
    //   }).finally(() => {
    //     setSubmitStep1BtnStatus(false)
    //   })
  }

  const commonProps = useMemo(() => ({
    data: workoutResource,
    onChange: handleChange
  }), [workoutResource, handleChange])

  const isEdit = useMemo(() => (
    !!workoutResource.id
  ), [workoutResource.id])

  useEffect(() => {
    if (!isMeditation) return
    if (workoutResource.music_station_id + workoutResource.music_bpm + workoutResource.show_calories === 0)
      return

    handleChange({ music_station_id: 0, music_bpm: 0, show_calories: 0 })
  }, [handleChange, isMeditation, workoutResource.category_id, workoutResource.music_bpm, workoutResource.music_station_id, workoutResource.show_calories])

  const qualityScoreDefaultValue = ['quality_score', 'quality_score_bmi_low', 'quality_score_bmi_med', 'quality_score_bmi_high'].reduce((res, field) => ({ ...res, [field]: '0.700' }), {})

  return (
    <div className={'root'} hidden={hidden}>
      <Card className={'form-container'}>
        <FormItem label={'ID'} name={'id'} readonly={action === 'edit'} {...commonProps} />
        <FormItem label={'Name'} name={'name'} required={true} {...commonProps} />
        <FormItem label={'Name (Male)'} name={'name_male'} {...commonProps} />
        <FormItem label={'About'} name={'workout_about'} {...commonProps} />
        <FormItem label={'Apple HealthKit category'} name={'healthkit_category'}  type={'number'} {...commonProps} />
        <FormItem name={'avg_heart_rate'} {...commonProps} />
        {/* <FormItem label={'Intensity'} name={'intensity'} type={'number'} {...commonProps} /> */}

        <FormItem label='intensity'>
          <NumberAutoComplete name='intensity' from={1} to={3} value={workoutResource.intensity} handleSelectChange={intensity => handleChange({ intensity })} {...props} />
        </FormItem>

        <div className="info-item">
          <span className="label">Type</span>
          <AutoCompleteNew
            options={WorkoutResourceType}
            value={workoutResource.type}
            onChange={(event, selected) => {
              handleChange({type: (selected || {value: 1}).value})
            }}
          />
        </div>

        <div className="info-item">
          <span className="label">Trainer</span>
          <AutoCompleteNew
            options={options[trainer] || []}
            value={workoutResource.trainer_id}
            onChange={(event, selected) => {
              handleChange({trainer_id: (selected || {value: null}).value})
            }}
          />
        </div>

        <div className="info-item">
          <span className="label">Primary Category</span>
          <AutoCompleteNew
            options={options[category] || []}
            value={workoutResource.category_id}
            onChange={(event, selected) => {
              handleChange({
                category_id: (selected || {value: null}).value,
                avg_heart_rate: selected && selected.heart_rate,
                is_workout: selected.is_workout,
              })
            }}
          />
        </div>

        {
          [11, 30].includes(workoutResource.category_id) &&
          <FormItem name='sub_category'>
            <AutoCompleteNew
              options={workoutSubcategories}
              value={workoutResource.subcategory_id}
              onChange={(event, selected) => {
                handleChange({subcategory_id: (selected || {value: null}).value})
              }}
            />
          </FormItem>
        }

        <div className="info-item">
          <span className="label">Secondary Categories</span>
          <MultipleAutoCompleteSelect
            options={options[category] || []}
            values={workoutResource.secondary_category_ids || []}
            onChange={(event, selected) => {
              handleChange({secondary_category_ids: selected.map(s => s.value)})
            }}
          />
        </div>

        <div className="info-item">
          <span className="label">Target Area</span>
          <MultipleAutoCompleteSelect
            options={options[target] || []}
            values={workoutResource.target_ids || []}
            onChange={(event, selected) => {
              handleChange({target_ids: selected.map(s => s.value)})
            }}
          />
        </div>

        <div className="info-item">
          <span className="label">Nextup</span>
          <MultipleAutoCompleteSelect
            options={options[resourceNotLive] || []}
            values={workoutResource.next_up_ids}
            onChange={(event, selected) => {
              handleChange({next_up_ids: selected.map(s => s.value)})
            }}
          />
        </div>

        <FormItem label={'Nextup Title'} name={'nextup_title'} {...commonProps} />

        <div className="info-item">
          <span className="label">Equipment</span>
          <MultipleAutoCompleteSelect
            options={options[equipment] || []}
            values={workoutResource.equipment_ids || []}
            onChange={(event, selected) => {
              const values = selected.map(s => s.value)
              handleChange({
                equipment_ids: values,
                workout_equipment: 
                  options[equipment]
                    .filter(o => values.includes(o.value))
                    .map(e => e.label)
                    .join(', ')
              })
            }}
          />
        </div>

        <FormItem label={'Equipment Text'} name={'workout_equipment'} {...commonProps} />
        {/* <FormItem label={'Music BPM'} name={'music_bpm'} type={'number'} readonly={isMeditation} {...commonProps} /> */}

        <FormItem label='Music BPM'>
          <NumberAutoComplete name='music_bpm' from={0} to={2} value={workoutResource.music_bpm} disabled={isMeditation} handleSelectChange={music_bpm => handleChange({ music_bpm })} {...props} />
        </FormItem>

        <FormItem label={'Music Station ID'} name={'music_station_id'} type={'number'} readonly={isMeditation} {...commonProps} />
        <FormItem label={'Time'} name={'continue_time'} type={'number'} readonly={isEdit} {...commonProps} />
        <FormItem label={'Avg Rating'} name={'avg_rating'} defaultBtn={() => handleChange({ avg_rating: '4.700' })} {...commonProps} />
        <FormItem label={'Quality Score'} name={'quality_score'} defaultBtn={() => handleChange(qualityScoreDefaultValue)} {...commonProps} />
        <FormItem name={'quality_score_bmi_low'} {...commonProps} />
        <FormItem name={'quality_score_bmi_med'} {...commonProps} />
        <FormItem name={'quality_score_bmi_high'} {...commonProps} />
        <FormItem label={'Video URL'} name={'video_url_new'} readonly={isEdit} {...commonProps} />
        <FormItem label={'Download URL'} name={'download_url_720p'} readonly={isEdit} {...commonProps} />
        <FormItem label={'No Music URL'} name={'video_url_no_music'} readonly={isEdit} {...commonProps} />
        <FormItem label={'Caption URL'} name={'subtitle_prefix'} readonly={isEdit} {...commonProps} />
        { workoutResource.id
          && <>
            <div className="info-item">
              <span className='label'>Workout Deeplink</span>
              <span className='value'>
                { `https://fiton.app/?r=browse/workout/${workoutResource.id}` }
              </span>
            </div>
            <div className="info-item">
              <span className='label'>Workout Start Deeplink</span>
              <span className='value text'>
                { `https://fiton.app/?r=browse/workout/${workoutResource.id}/start` }
              </span>
            </div>
            </>
        }

        <FormItem label={'Schedule'} name={'schedule'}>
          <Switch name='schedule' checked={Boolean(workoutResource.schedule)}
            onChange={ e => handleChange({ schedule: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'is_exclusive'}>
          <Switch name='is_exclusive' checked={Boolean(workoutResource.is_exclusive)}
            onChange={ e => handleChange({ is_exclusive: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'show_calories'}>
          <Switch disabled={isMeditation} name='show_calories' checked={Boolean(workoutResource.show_calories)}
            onChange={ e => handleChange({ show_calories: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'post_workout_skip'}>
          <Switch name='post_workout_skip' checked={Boolean(workoutResource.post_workout_skip)}
            onChange={ e => handleChange({ post_workout_skip: e.target.checked }) }
          />
        </FormItem>

        {/* <FormItem label={'Active'} name={'active'}>
          <Switch name='active' checked={Boolean(workoutResource.active)}
            onChange={ e => handleChange({ active: true }) }
          />
        </FormItem> */}

        <AgeAndGenderSwitch data={workoutResource} setData={setWorkoutResource} />

        <FormItem name={'available'}>
          <Switch name='available' checked={Boolean(workoutResource.available)}
            onChange={ e => handleChange({ available: e.target.checked }) }
          />
        </FormItem>

        <FormItem name={'is_workout'}>
          <Switch name='is_workout' checked={Boolean(workoutResource.is_workout)}
            onChange={ e => handleChange({ is_workout: e.target.checked }) }
          />
        </FormItem>

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmitStep1}
          disabled={!workoutResource.id || !workoutResource.name || !workoutResource.category_id}
        >
          NEXT
        </Button>
      </Card>
    </div>
  )
}

WorkoutResourceAddStep1.propTypes = {
  workoutResource: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired
}

export default WorkoutResourceAddStep1
