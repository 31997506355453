import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { startCase } from 'lodash'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { assign } from 'lodash'
import { mealIngredientUpsertPromise } from 'api/MealIngredient'
import { mealIngredientCategoryGroceryListPromise } from 'api/MealIngredientCategoryGrocery'
import { isBlank } from 'common/usual'
import { AutoCompleteNew } from 'components/Select'
import { ParamsForOption } from 'common/enum'

export default function MealIngredientUpsertForm (props) {
  const { data } = props
  const [ingredient, setIngredient] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [groceries, setGroceries] = useState([])

  const disableBtn = useMemo(() => (
    btnStatus || isBlank(ingredient.titleSingle)
  ), [btnStatus, ingredient.titleSingle])

  useEffect(() => {
    setIngredient(data)
  }, [data])

  useEffect(() => {
    async function fetchGroceries () {
      const groceries = await mealIngredientCategoryGroceryListPromise({type: 'option', ...ParamsForOption})
      setGroceries(
        groceries.map(grocery => ({
          label: grocery.title,
          value: grocery.id,
        }))
      )
    }
    fetchGroceries()
  }, [])

  function handleSubmit () {
    setBtnStatus(true)
    mealIngredientUpsertPromise(ingredient)
      .then(res => {
        props.history.goBack()
      }).finally(() => {
        setBtnStatus(false)
      })
  }

  const handleChange = useCallback((...values) => {
    setIngredient(
      assign({}, ingredient, ...values)
    )
  }, [ingredient])

  const commonProps = useMemo (() => ({
    onChange: handleChange,
    data: ingredient
  }), [handleChange, ingredient])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem label={'Title Single'} name={'titleSingle'} {...commonProps} />
        <FormItem label={'Title Plural'} name={'titlePlural'} {...commonProps} />

        <FormItem label={'Category Grocery'} name={'ingredientCategoryGroceryId'}>
          <AutoCompleteNew
            options={groceries}
            value={ingredient.ingredientCategoryGroceryId}
            onChange={(event, selected) => {
              handleChange({ ingredientCategoryGroceryId: selected ? selected.value : null })
            }}
          />
        </FormItem>

        {
          ['hiddenForShoppingList', 'vegetarian', 'vegan', 'pescatarian',
            'paleo', 'keto', 'whole30', 'dairy', 'gluten', 'active'].map(name => {
              return (
                <FormItem label={startCase(name)} name={name} key={name}>
                  <Switch
                    checked={Boolean(ingredient[name])}
                    onChange={e => {
                      handleChange({ [name]: e.target.checked })
                    }}
                    name={name}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </FormItem>
              )
            })
        }

        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableBtn}
        >
          SUBMIT
        </Button>
      </Card>

      {/* <div>{JSON.stringify(ingredient)}</div> */}
    </div>
  )
}