import React, { useState } from 'react'
import { Card, Grid, Switch } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { useEffectOnce } from 'react-use'
import { getProgramListPromise } from 'api/program'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { ParamsForOption } from 'common/enum'

export default function (props) {
  const { data, handleChange } = props
  const [programs, setPrograms] = useState([])

  useEffectOnce(() => {
    getProgramListPromise({ size: 10000, ...ParamsForOption }).then(resp => setPrograms(resp.data.rows.map(r => ({ label: `${r.name} (${r.id})`, value: r.id }))))
  }, [])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
      <Switch id='active' name={'active'} checked={Boolean(data.active)} onChange={e => handleChange('active', e.target.checked)} />
    </Grid>
    
    <Grid container item xs={12} className='form-card-content'>
      <Grid item xs={6}>
        <div className='form-input-elem'>
          <label className='full-width'>Program*</label>
          <AutoCompleteNew name='program_id' options={programs} value={data.program_id} className='full-width' variant='outlined' inputLeft={true}
            onChange={(event, selected) => {
              handleChange('program_id', selected && selected.value)
            }}
          />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className='form-input-elem'>
          <label className='full-width'>Week</label>
          <NumberAutoComplete name='week' from={1} to={100} value={data.week} inputLeft={true}
            handleSelectChange={value => handleChange('week', value) } variant='outlined' />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className='form-input-elem'>
          <label className='full-width'>Sequence</label>
          <NumberAutoComplete name='sequence' from={1} to={100} value={data.sequence} inputLeft={true}
            handleSelectChange={value => handleChange('sequence', value) } variant='outlined' />
        </div>
      </Grid>

    </Grid>
  </Card>
}
