import _ from 'lodash'
import React, { useMemo, useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { upsertCourseWebsitePromise } from 'api/CourseWebsite'
import JSONEditor from 'components/JSONEditor'
import DummyData from '../../../assets/courseWebsiteDummyData.json'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, setData, history, changeCurrentStep } = props
  const [blob, setBlob] = useState()
  const [btnStatus, setBtnStatus] = useState(false)
  const [jsonValueGetter, setJsonValueGetter] = useState()

  const disableBtn = useMemo(() => (
    btnStatus
  ), [btnStatus])

  useEffect(() => {
    if (_.isEmpty(data)) changeCurrentStep(1)
    setBlob(data.blob || DummyData)
  }, [changeCurrentStep, data])

  function handleSubmit () {
    setBtnStatus(true)
    upsertCourseWebsitePromise({ ...data, blob: jsonValueGetter() })
      .then(resp => history.goBack())
      .finally(() => setBtnStatus(false))
  }

  function handlePrevious () {
    setData({ ...data, blob })
    changeCurrentStep(1)
  }

  return (
    <div className={'root'}>
      <JSONEditor initValue={blob} setJSONGetter={setJsonValueGetter} />

      <br />

      <div className='flex-space-between' style={{ display: 'flex' }}>
        <div style={{ width: '20%' }}>
          <Button fullWidth size="large" color='secondary' variant='contained' onClick={handlePrevious}>
            Previous
          </Button>
        </div>
        <div style={{ width: '79%' }}>
          <Button fullWidth
            className="save-btn"
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableBtn}
          >
            SUBMIT
          </Button>
        </div>
      </div> 
    </div>
  )
}