import React, { useState, useEffect, useCallback } from 'react'
import { userChallengeListPromise } from 'api/Challenge'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { formatDateTime } from 'common/usual'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'

function ChallengeTable (props) {
  const { userId } = props
  const [userChallenge, setUserChallenge] = useState({})
  const [page, setPage] = useState(1)

  const fetchUserChallenge = useCallback(async () => {
    setUserChallenge(await userChallengeListPromise(page, 10, userId))
  }, [page, userId])

  const handlePageChange = (event, page) => {
    setPage(page+1)
  }

  useEffect(() => {
    fetchUserChallenge()
  }, [fetchUserChallenge])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created AT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (userChallenge.rows || []).map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.challenge.name}</TableCell>
                      <TableCell>{row.active ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>{formatDateTime(row.created_at)}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'actions'}>
        <TablePagination
          component="div"
          count={userChallenge.count || 0}
          onChangePage={handlePageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

ChallengeTable.propTypes = {
  userId: PropTypes.any.isRequired
}

export default ChallengeTable