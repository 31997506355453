import { Button } from '@material-ui/core'
import { checkPermission, getPermissionCodeByPath } from 'helpers/permission'
import React from 'react'

const AddBtn = props => {
  const code = getPermissionCodeByPath(window.location.pathname, ['add', 'upsert'])

  if (!checkPermission(code)) return <></>
  
  return <Button {...props} />
}

export default AddBtn
