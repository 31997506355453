import { send } from 'helpers/utils'
import EditBtn from 'components/EditBtn'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, CardActions, TablePagination } from '@material-ui/core'

const UserGroupTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Group ID</TableCell>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>{send(row.user, 'email')}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.group_id}</TableCell>
                    <TableCell>{send(row.group, 'name')}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/business/users/edit/${row.id}`} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default UserGroupTable