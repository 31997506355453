import React, { useState, useEffect, useCallback } from 'react'
import { mealIngredientCategoryGroceryListPromise } from 'api/MealIngredientCategoryGrocery'
import MealIngredientCategoryGroceryTable from './components/MealIngredientCategoryGroceryListTable'
import MealIngredientCategoryGroceryToolbar from './components/MealIngredientCategoryGroceryListToolbar'

const MealIngredientCategoryGroceryList = props => {
  const [grocery, setGrocery] = useState([])

  const getGrocery = useCallback(async () => {
    const grocery = await mealIngredientCategoryGroceryListPromise()
    if (grocery) {
      setGrocery(grocery)
    }
  }, [])

  useEffect(() => {
    getGrocery()
  }, [getGrocery])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <MealIngredientCategoryGroceryToolbar {...props} />
        <br />
        <MealIngredientCategoryGroceryTable {...props} data={grocery} />
      </div>
    </div>
  )
}

export default MealIngredientCategoryGroceryList