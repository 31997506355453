import React, { useMemo, useState, useEffect, useLayoutEffect } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { assign } from 'lodash'
import { upsertSocialGroupPromise } from 'api/SocialGroup'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import { S3UploaderWithInput } from 'components/S3UploadInput'
import { S3PreSignType, SocialGroupType } from 'common/enum'
import { AutoCompleteNew } from 'components/Select'
import { getSocialGroupCategoryList } from 'api/SocialFeed'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, history } = props
  const [requiredColumns, setRequiredColumns] = useState(['sort'])
  const [socialGroup, setSocialGroup] = useState({})
  const [btnStatus, setBtnStatus] = useState(false)
  const [categories, setCategories] = useState([])

  const disableBtn = useMemo(() =>
    btnStatus || requiredColumns.map(column => isBlank(socialGroup[column])).includes(true)
  , [btnStatus, socialGroup, requiredColumns])

  useLayoutEffect(() => {
    getSocialGroupCategoryList()
      .then(resp => setCategories(resp.map(r => ({ label: r.name, value: r.id }))))
  }, [])

  useEffect(() => {
    setSocialGroup({ ...data, private: !!data.private, active: !!data.active, featured: !!data.featured })
  }, [data])

  function handleSubmit () {
    setBtnStatus(true)
    upsertSocialGroupPromise(socialGroup)
      .then(() => history.goBack())
      .catch(() => setBtnStatus(false))
  }

  const socialGroupFormItem = (label, name, isRequired, isMultiline=false) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])
    return <FormItem
      label={label} name={name} value={socialGroup[name]} isRequired={isRequired} multiline={isMultiline}
      onChange={map => setSocialGroup(assign({}, socialGroup, map)) }
    />
  }

  function switcher (label, name) {
    return <FormItem label={label} name={name}>
      <Switch
        name={name}
        checked={Boolean(socialGroup[name])}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={e => setSocialGroup({...socialGroup, [name]: e.target.checked}) }
      />
    </FormItem>
  }

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        { socialGroupFormItem('Name', 'name', true) }
        { socialGroupFormItem('Description', 'description', true, true) }
        { socialGroupFormItem('Group ID', 'group_id', false) }

        <FormItem label={'Sort'} name={'sort'} isRequired={true}>
          <NumberAutoComplete
            from={1} to={100} value={socialGroup.sort} disableUnderline={true}
            handleSelectChange={(selected) => setSocialGroup({...socialGroup, sort: selected})} />
        </FormItem>

        <FormItem label={'Type'} name={'type'} isRequired={true}>
          <AutoCompleteNew
            disableUnderline={true}
            value={ socialGroup.type }
            options={Object.keys(SocialGroupType).map(label => ({ label, value: SocialGroupType[label] }))}
            onChange={(event, selected)=>{
              setSocialGroup({...socialGroup, type: selected && selected.value})
            }}
          />
        </FormItem>

        <FormItem label='Category' name='category_id'>
          <AutoCompleteNew
            disableUnderline={true}
            value={socialGroup.category_id}
            options={categories}
            onChange={(event, selected) => setSocialGroup({...socialGroup, category_id: selected && selected.value})}
          />
        </FormItem>

        {
          socialGroup.type !== SocialGroupType.Course &&
            <S3UploaderWithInput label='Cover Url (1500*1160)' name={'cover_url'} filenames={socialGroup['cover_url']} fileType={S3PreSignType.SocialGroupCover}
              setFilenames={filenames => setSocialGroup({ ...socialGroup, cover_url: filenames })} />
        }

        {
          socialGroup.type !== SocialGroupType.Course &&
            <S3UploaderWithInput label='Cover Url Thumbnail (687*687)' name={'cover_url_thumbnail'}
              filenames={socialGroup['cover_url_thumbnail']} fileType={S3PreSignType.SocialGroupCover} prefix='thumbnail'
              setFilenames={filenames => setSocialGroup({ ...socialGroup, cover_url_thumbnail: filenames })} />
        }

        { switcher('Featured', 'featured') }
        { switcher('Private', 'private') }
        { switcher('Active', 'active') }
        
        <Button fullWidth className="save-btn" color="primary" size="large" type="submit" variant="contained" onClick={handleSubmit} disabled={disableBtn}>
          Submit
        </Button>
      </Card>
    </div>
  )
}