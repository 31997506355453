import Form from './form'
import { productDetailPromise } from 'api/Product'
import React, { useMemo, useState } from 'react'

export default function CourseAdd (props) {
  const { id } = props.match.params
  const [data, setData] = useState({})

  useMemo(() => {
    if (!id) return

    productDetailPromise(id).then(resp => {
      resp && setData(resp)
    })
  }, [id])

  return <Form mode='edit' data={data} setData={setData} {...props} />
}