import { getApiClient } from './ApiClient'

export const mealIngredientCategoryGroceryListPromise = (params) => {
  return getApiClient()
    .get('/meal-ingredient/category-groceries', {
      params
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealIngredientCategoryGroceryDetailPromise = (id) => {
  return getApiClient()
    .get(`/meal-ingredient/category-groceries/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const mealIngredientCategoryGroceryUpsertPromise = (body) => {
  return getApiClient()
    .post('/meal-ingredient/category-groceries/upsert', {
      body
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}
