/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { checkPermission } from 'helpers/permission';
import { getPermissionCodeByPath } from 'helpers/permission'

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

function NavigationItem (props) {
  const { page } = props
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  function preventActionWhenSamePathIsClicked (e, href) {
    const cc = new URL(href, window.location)
    if (cc.pathname === window.location.pathname)
      return e.preventDefault()
  }

  const isPermitted = useCallback((obj) => {
    const code = getPermissionCodeByPath(obj.href)

    return checkPermission(code)
  }, [])
  
  return (
    <>
      <ListItem
        className={classes.item}
        disableGutters
        key={page.title}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          to={isPermitted(page) ? page.href : '#'}
          onClick={e => {
            handleClick()
            preventActionWhenSamePathIsClicked(e, isPermitted(page) ? page.href : '#')
          }}
        >
          <div className={classes.icon}>{page.icon}</div>
          {page.title}
        </Button>
        {
          page.subItems && (open 
            ? <ExpandLess className={'cursor-pointer'} onClick={handleClick} /> 
            : <ExpandMore className={'cursor-pointer'} onClick={handleClick} />
          )
        }
      </ListItem>
      {
        page.subItems && <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {
              (page.subItems || []).filter(foo => !foo.isHidden && isPermitted(foo)).map(item => (
                <ListItem className={classes.nested} key={item.title}>
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={item.href}
                      onClick={e => preventActionWhenSamePathIsClicked(e, item.href)}
                    >
                      <div className={classes.icon}>{item.icon}</div>
                      {item.title}
                    </Button>
                </ListItem>
              ))
            }
          </List>
        </Collapse>
      }
      
    </>
  )
}

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {
        pages.map(page => (
          <NavigationItem page={page} key={page.title} />
        ))
      }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
