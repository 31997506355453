import './index.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { workoutResourceVersionDetailPromise, workoutResourceVersionRollbackPromise } from 'api/WorkoutResourceVersion'
import _ from 'lodash'

const FeatureBannerCampaignDetail = props => {
  const { match: { params: { id } } } = props
  const [data, setData] = useState({})

  useEffect(() => {
    workoutResourceVersionDetailPromise(id).then(res => setData(res))
  }, [id])

  const rollback = useCallback((type) => {
    if (window.confirm('are you sure'))
      workoutResourceVersionRollbackPromise(id, type)
  }, [id])

  return (
    <div className={'root'}>
      <Card className={'detail-container'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Before</TableCell>
              <TableCell>After</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data && data.before_change_data && Object.keys(data.before_change_data).map(column => {
                const cls = data.changed_fields.includes(column) && 'red-color'
                return <TableRow>
                  <TableCell>
                    <span class={'bold-font'}>{ _.startCase(column) }</span>
                  </TableCell>
                  <TableCell>
                    <span class={cls}>{ `${data.before_change_data[column]}` }</span>
                  </TableCell>
                  <TableCell>
                    <span class={cls}>{ `${data.after_change_data[column]}` }</span>
                  </TableCell>
                </TableRow>
              })
            }
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Button variant='outlined' onClick={ () => rollback('before') }>ROLLBACK</Button>
              </TableCell>
              <TableCell>
                <Button variant='outlined' onClick={ () => rollback('after') }>ROLLBACK</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  )
}

export default FeatureBannerCampaignDetail
